import styled from "styled-components";
import CodeMirror from "@uiw/react-codemirror";

export const StyledCodeMirror = styled(CodeMirror)`
      /* border:1px solid rgba(0,0,0,0.2) !important; */
  .cm-scroller{
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
  }
  .cm-editor.cm-focused {
    outline: none;
  }
      
`;

export const StyledDiv = styled.div`
    .header{
      display: flex;
      justify-content: space-between;
      border: 1px solid rgba(0,0,0,0.2);
      border-bottom: none;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      padding: 0.7rem 1rem;

        span{
          font-size: 12px;
          font-weight: 600;
        }
        .iconContainer{
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
    }

    
`;