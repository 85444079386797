import { MoreOutlined, RightOutlined } from "@ant-design/icons";
import { Card, Dropdown, Table, TableColumnsType, Button, message } from "antd";
import { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserDrawer from "../drawer";
import Modal from "../../../../components/common/modal";
import ManageToken from "./manageToken";
import { AppDispatch, RootState } from "../../../../redux/store";
import api from "../../../../api/apiClient";
import {
  setActiveUsers,
  setInvitedUsers,
} from "../../../../redux/feature/auth/authSlice";
import { StyledTable } from "../../../../components/common/table/styles";
import { formatDateToLocalTime } from "../../../../utils/helper";
import { EmptyDataComponent } from "../../../../components/common/empty";
import CustomButton from "../../../../components/common/button";
import PageLoading from "../../../../components/Loader";
import { isInaccessible } from "@testing-library/react";

interface DataType {
  key: React.Key;
  name: string;
  email: string;
  created: string;
  account_roles: [string];
  expires_at?: string;
  invited_at?: string;
  role?: string;
}

interface ActiveUsersProps {
  usersFilter: string;
}

const Users: React.FC<ActiveUsersProps> = ({ usersFilter }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userData, setUserData] = useState<DataType | null>(null);
  const [inviteLoading, setInviteLoading] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const { activeUsers, invitedUsers, loading } = useSelector(
    (state: RootState) => state.auth
  );

  console.log("⏩ ~ invitedUsers:", invitedUsers);
  const fetchUsers = useCallback(
    async (type: "active" | "invited") => {
      try {
        const response = await api.get(`/users?type=${type}`);
        if (type === "active") {
          dispatch(setActiveUsers(response.data.data));
        } else {
          dispatch(setInvitedUsers(response.data.data));
        }
      } catch (err) {
        console.error("Error fetching users:", err);
      }
    },
    [dispatch]
  );
  useEffect(() => {
    if (usersFilter === "Active Users") {
      fetchUsers("active");
    } else if (usersFilter === "Invited Users") {
      fetchUsers("invited");
    }
  }, [usersFilter, fetchUsers]);

  const drawerHandler = useCallback((userData: DataType | null) => {
    setUserData(userData);
    setIsDrawerOpen((prevState) => !prevState);
  }, []);

  const modalHandler = useCallback(() => {
    setIsModalOpen((prevState) => !prevState);
  }, []);

  const resendInviteHandler = async (
    email: string,
    role: string | undefined
  ) => {
    setInviteLoading(true);
    try {
      await api.post("/invite-user", {
        email: email,
        role: role,
      });
      message.success("Invitation sent successfully");
    } catch (err: any) {
      console.log("⏩ ~ err:", err);
      message.error(err?.response?.data?.Error);
    } finally {
      setInviteLoading(false);
    }
  };
  const activeUsersColumn: TableColumnsType<DataType> = useMemo(
    () => [
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "Email",
        dataIndex: "email",
      },
      {
        title: "Created At",
        dataIndex: "created_at",
        render: (_, record) => formatDateToLocalTime(record.created),
      },
      {
        title: "Roles",
        dataIndex: "account_roles",
        render: (_, record) =>
          record.account_roles
            .map((role) =>
              role.includes("_")
                ? role
                    .replace("_", " ")
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")
                : role === "administrator"
                ? "Admin"
                : role.charAt(0).toUpperCase() + role.slice(1)
            )
            .join(", "),
      },
      {
        title: "",
        dataIndex: "action",
        render: (_, record) => {
          const items = [
            {
              key: "1",
              label: <span>Manage Roles</span>,
              onClick: () => drawerHandler(record),
            },
            {
              key: "2",
              label: <span>Manage Tokens</span>,
              onClick: modalHandler,
            },
          ];
          return (
            <Dropdown menu={{ items }}>
              <MoreOutlined />
            </Dropdown>
          );
        },
      },
    ],
    []
  );

  const invitedUsersColumn: TableColumnsType<DataType> = useMemo(
    () => [
      {
        title: "Email",
        dataIndex: "email",
      },
      {
        title: "Invite Sent On",
        dataIndex: "invited_at",
        render: (_, record) => formatDateToLocalTime(record.invited_at),
      },
      {
        title: "Valid Until",
        dataIndex: "expires_at",
        render: (_, record) => formatDateToLocalTime(record.expires_at),
      },
      {
        title: "",
        dataIndex: "action",
        render: (_, record) => (
          <CustomButton
            type="default"
            icon={<RightOutlined />}
            text="Resend Invite"
            position="end"
            onClick={() => resendInviteHandler(record.email, record.role)}
          />
        ),
      },
    ],
    []
  );

  return (
    <>
      {loading ? (
        <PageLoading />
      ) : usersFilter === "Active Users" ? (
        !activeUsers || activeUsers.length == 0 ? (
          <EmptyDataComponent title="No Users Found" isAddButton={false} />
        ) : (
          <StyledTable
            className="table"
            pagination={false}
            columns={activeUsersColumn}
            dataSource={activeUsers}
            size="middle"
          />
        )
      ) : !invitedUsers || invitedUsers.length == 0 ? (
        <EmptyDataComponent title="No Invite Users Found" isAddButton={false} />
      ) : (
        <StyledTable
          className="table"
          pagination={false}
          columns={invitedUsersColumn}
          dataSource={invitedUsers}
          size="middle"
          loading={inviteLoading}
        />
      )}

      {isDrawerOpen && (
        <UserDrawer
          isOpen={isDrawerOpen}
          type="edit"
          onClose={() => drawerHandler(null)}
          user={userData}
        />
      )}

      <Modal
        title="API Tokens List"
        isOpen={isModalOpen}
        onClose={modalHandler}
        isSuccessBtnShown={false}
        width="810px"
      >
        <ManageToken />
      </Modal>
    </>
  );
};

export default Users;
