import { Drawer, DrawerProps } from "antd";
import styled from "styled-components";

interface StyledDrawerProps extends DrawerProps {
  isStyledHeader?: boolean;
}

export const StyledDrawer = styled(Drawer) <StyledDrawerProps>`
position: relative;
padding-bottom: 67px;

& .ant-drawer-body {
  scrollbar-width: none; 
  padding: 0;
}

& .ant-drawer-body::-webkit-scrollbar {
  display: none; 
}

& .actions{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color:#f8fafc;
  padding: 16px 24px;
  width: -webkit-fill-available;
  border-top: 1px solid #e2e8f0;
}
& .btn{
  height: 35px;
}
/* .ant-select-selector{
  border: none!important;
}
.ant-select-selection-item{
  font-size: 12px;
  font-weight: 600;
  color: #465780;
}
.ant-select-selection-item:hover{
  font-size: 12px;
  font-weight: 600;
  color: #465780;
} */

.ant-drawer-header {
    ${({ isStyledHeader }) => isStyledHeader ? `
      background-color: #f8fafc;
      border-bottom: 0;
      padding-bottom: 5px;
      padding-right: 16px;
    `
    :
    `
            background-color: #f8fafc;

    `
  }
}
`;