import styled from "styled-components";
import { CopyOutlined } from "@ant-design/icons";

export const ContentStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Container = styled.div`
  padding: 10px 0;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;

export const CopyIcon = styled(CopyOutlined)`
  cursor: pointer;
  color: #1890ff;
  &:hover {
    color: #40a9ff;
  }
`;
