import { Form, Input, Select } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
const { Option } = Select;

const GroupByTag: React.FC<any> = () => {
  const [tagValue, setTagValue] = useState<string | null>(null);
  const { billingDetails, planModalDetail } = useSelector((state: any) => state.pricePlan);

  const tags = useMemo(() => {
    const tagsData = billingDetails?.rateCards?.map((card: any) => 
      card.tag === "newTag" ? card?.newTagName : card?.tag
    ) || [];
    const uniqueTags = Array.from(new Set(tagsData));
    return uniqueTags.map(tag => ({ value: tag, label: tag }));
  }, [billingDetails]);

  useEffect(()=>{
    if(!planModalDetail){
      setTagValue(null)
    }
  },[planModalDetail])

  return (
    <>
      <Form.Item label="Group By Tag" name="tag">
        <Select placeholder="Group By Tag" onChange={setTagValue}>
          {(tags ?? []).map((tag: any) => (
            <Option key={tag.value} value={tag.value}>
              {tag.label}
            </Option>
          ))}
          <Option key="newTag" value="newTag">
            Create New Tag
          </Option>
        </Select>
      </Form.Item>
      {tagValue === "newTag" && (
        <Form.Item label="Enter Tag Name" name="newTagName">
          <Input placeholder="Enter new tag" />
        </Form.Item>
      )}
    </>
  );
};

export default GroupByTag;
