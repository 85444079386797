import { Tabs } from "antd";
import styled from "styled-components";

export const StreamContainer = styled.div`
 & .ingestEvent {
    padding: 7px 12px;
    border-Radius: 8px;
    font-weight: 500;
     svg{
        margin-right: 0 !important;
    }
    font-size: 12px;
    color: white;
    background: #2853C2 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
  }
  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .eventAction{
    border: 1px solid red;
  }
   
`;

export const EventDetailHeader = styled.div`
display: flex;
justify-content: space-between;
align-items: start;

h4,p{
  margin: 0;
}
.id{
  color: #465780;
  font-size: 13px;
}
.EventAction{

  width: 120px;
  .ant-select-selector{
    background-color: #F8FAFC !important;
    border : none !important;
    font-size: 12px;
    font-weight: 500 !important;
    box-shadow: none;
  }
  
 
}
.EventAction:hover{
  .ant-select-selection-item{
    color: initial !important;
  }

}
.headerTitle{
  font-size: 18px;
  font-weight: 600;
}
.headerSubTitle{
  font-size: 14px;
  color: #919BAE;
}
`;

export const StyledTab = styled(Tabs)`

  .ant-tabs-nav{
    padding: 0 24px;
    background-color: #F8FAFC;
    position: sticky;
    top: 0;
    z-index: 10;
  }
  .ant-tabs-content-holder{
    padding: 0px 24px;
  }

`;

export const ReingestContainer = styled.div`

  padding: 24px 0;

  .propertyCardContainer{
    margin-bottom: 1rem;
      display: flex;
      gap: 1rem;
      p,h3{
        margin: 0;
        font-size: 12px;
      }
      p{
        font-weight: 500;
      }
      .ant-card{
        background-color: #F8FAFC;
        border:none;
      }
      .ant-card-body{
        padding: 10px!important;
      }
      .accId{
        flex: 1;
      }
  }

`;
