import { CopyOutlined, MoreOutlined } from "@ant-design/icons";
import { EventDetailHeader } from "../styles";
import { Select } from "antd";
import { useState } from "react";
import ReingestModal from "./ReingestModal";
import AlertModal from "../../../../components/common/alertModal";

const DrawerHeader: React.FC<{ selectedIngest: any }> = ({  selectedIngest }) => {
  const [isEventActionSelectOpen, setIsEventActionSelectOpen] = useState(false);
  const [isEventActionName, setIsEventActionName] = useState("");
  const eventActionNameHandler = () => {
    setIsEventActionName("");
  };
  return (
    <EventDetailHeader>
      <div className="EventTitle">
        <h4>Event Details</h4>
        <p className="id">
          {selectedIngest?.billoptim_ingest_event_id} <CopyOutlined style={{ marginLeft: "5px" }} />
        </p>
      </div>
      <div
        className="EventAction"
        onMouseEnter={() => setIsEventActionSelectOpen(true)}
        onMouseLeave={() => setIsEventActionSelectOpen(false)}
      >
        <Select
          open={isEventActionSelectOpen}
          onBlur={() => setIsEventActionSelectOpen(false)}
          onFocus={() => setIsEventActionSelectOpen(true)}
          style={{ width: "100%" }}
          value="Event Actions"
          suffixIcon={<MoreOutlined />}
          onChange={setIsEventActionName}
          
        >
          <Select.Option value="REVERT">Revert Event</Select.Option>
          <Select.Option value="REINGEST">Reingest Event</Select.Option>
        </Select>
      </div>

      <AlertModal
        open={isEventActionName === "REVERT"}
        close={eventActionNameHandler}
        title="Revert the Event?"
        subtitle="Reverting this event will revert all changes caused by it, such as
          Usages, Revenue and Entitlements (Feature Credits). This action cannot
          be undone."
      />
      <ReingestModal
        open={isEventActionName === "REINGEST"}
        close={eventActionNameHandler}
        selectedIngest={selectedIngest}
      />
    </EventDetailHeader>
  );
};

export default DrawerHeader;
