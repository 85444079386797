import { Table, TableColumnsType, Tag } from "antd";
import CustomButton from "../../../../components/common/button";
import {
  CopyFilled,
  CopyOutlined,
  EditFilled,
  RightOutlined,
} from "@ant-design/icons";
import { DrawerHeader, SettingDetailData, StyledDiv } from "./style";
import { EmptyData } from "../../../../components/common/empty";
import { useState } from "react";
import CustomDrawer from "../../../../components/common/drawer";
import { StyledTable } from "../../../../components/common/table/styles";

export interface SettingTableType {
  key: React.Key;
  name: string;
  id: string;
  namespace: string;
  type: string;
  value: string;
}

const SettingTable: React.FC<{
  data: SettingTableType[];
  type?: string;
  emptyDescription?: string;
  emptyBtnOnClick?: () => void;
}> = ({
  data,
  type = "All",
  emptyDescription = "",
  emptyBtnOnClick = () => {},
}) => {
  const [isSettingDetailDrawerOpen, setIsSettingDetailDrawerOpen] =
    useState(false);
  const drawerHandler = () => {
    setIsSettingDetailDrawerOpen(!isSettingDetailDrawerOpen);
  };
  const tableData =
    type === "All"
      ? data
      : data.filter(
          (item) => item.namespace.toLowerCase() === type.toLowerCase()
        );

  const columns: TableColumnsType<SettingTableType> = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Namespace",
      dataIndex: "namespace",
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (_, { type }) => {
        let color =
          type === "Numeric"
            ? "orange"
            : type === "String"
            ? "blue"
            : type === "Json"
            ? "green"
            : "purple";

        return (
          <Tag color={color} key={type}>
            {type}
          </Tag>
        );
      },
    },
    {
      title: "Value",
      dataIndex: "value",
    },
    {
      dataIndex: "action",
      render: () => (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <CustomButton
            text="Edit"
            icon={<EditFilled />}
            onClick={() => console.log("test")}
            type="default"
          />
          <RightOutlined />
        </div>
      ),
    },
  ];

  return (
    <StyledDiv>
      {tableData.length > 0 ? (
        <StyledTable
          // className="table"
          pagination={false}
          columns={columns}
          dataSource={tableData}
          size="middle"
          onRow={() => {
            return {
              onClick: () => {
                drawerHandler();
              },
            };
          }}
        />
      ) : (
        <EmptyData
          description={emptyDescription}
          btnText="New Setting"
          onClick={emptyBtnOnClick}
        />
      )}

      <CustomDrawer
        isOpen={isSettingDetailDrawerOpen}
        onClose={drawerHandler}
        title={
          <DrawerHeader>
            <h4>Settings Details</h4>
            <p>
              1111 <CopyOutlined />
            </p>
          </DrawerHeader>
        }
        isActionButtonsShown={false}
      >
        <SettingDetailData>
          <div>
            <p className="title">Name</p>
            <p className="data">aaa</p>
          </div>
          <div>
            <p className="title">ID</p>
            <p className="data">1111</p>
          </div>
          <div>
            <p className="title">Name Space</p>
            <p className="data">User</p>
          </div>
          <div>
            <p className="title">Type</p>
            <Tag color="gold">• Numeric </Tag>
          </div>
          <div>
            <p className="title">Value</p>
            <p className="data">12</p>
          </div>
        </SettingDetailData>
      </CustomDrawer>
    </StyledDiv>
  );
};

export default SettingTable;
