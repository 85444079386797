import { Card } from "antd";
import styled from "styled-components";

export const UserCard = styled(Card)`

      width: 100%;
      margin-bottom: 8px !important;
      .ant-card-body{
        padding: 12px !important;
      }
      .data{
        margin-left: 4px;
      } 
      .ant-checkbox-inner{
        height: 12px !important;
        width: 12px !important;
      } 
      .ant-checkbox-inner::after {
        width: 5px; 
        height: 7px;
      }
      .ant-radio-checked .ant-radio-inner{
        border-color: #2853c2 !important;
        background-color: #2853c2 !important;
      }
      .ant-checkbox-checked .ant-checkbox-inner{
        border-color: #2853c2 !important;
        background-color: #2853c2 !important;
        
      }
`;
