import styled from "styled-components";

export const ApiTokenContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .tokenHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    h3{
      margin-bottom: 0 !important;
    }
  }
  .description {
    padding: 1.5rem;
    box-shadow: 0px 0px 5px -2px;
    border-radius: 20px;
  }
`;
