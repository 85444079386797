import { InfoCircleOutlined } from "@ant-design/icons";
import { ExtraContent } from "./styles";

type ExtraPropType = {
  text: any;
};

const Extra: React.FC<ExtraPropType> = ({ text }) => {
  return (
    <ExtraContent>
      <InfoCircleOutlined className="icon" />{" "}
      <span className="text">{text}</span>
    </ExtraContent>
  );
};

export default Extra;
