import React, { useEffect, useMemo, useState } from "react";
import { Container } from "../BillingDetails/Styles";
import { PlanModal, RateCardHeader } from "./Styles";
import {
  CheckCircleOutlined,
  CreditCardOutlined,
  DollarOutlined,
  FileProtectOutlined,
  InfoCircleOutlined,
  LineChartOutlined,
  MoreOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Input,
  Modal,
  Form,
  Menu,
  Dropdown,
  Table,
  Tag,
  Card,
  Row,
  Col,
  Typography,
} from "antd";
import CustomButton from "../../../../components/common/button";
import EditPlan from "./EditPlan";
import { EmptyDataComponent } from "../../../../components/common/empty";
import { useDispatch, useSelector } from "react-redux";
import {
  removeBillingRateCard,
  setIsOverridePlan,
  setPlanModalDetail,
} from "../../../../redux/feature/pricePlan/pricePlanSlice";
import { AppDispatch, RootState } from "../../../../redux/store";
import { getAddons } from "../../../../redux/feature/billableItems/billableThunk";
import TextedSwitch from "../../../../components/common/textedSwitch";
import CommonExpandItem from "./expandItems/CommonExpandItem";
import PricePlanDetailsTable from "../PricePlanTable";
import FilterOption from "../../../../components/common/filter/FilterOption";
import SearchInput from "../../../../components/common/search";
import { transformPricePlanPayload } from "../../../../utils/helper";
import Segment from "../../../../components/common/segmented";

const menuItems: any = {
  "1": { label: "Fixed Fee", value: "FIXED_FEE", icon: <DollarOutlined /> },
  "2": {
    label: "Usage Based Fee",
    value: "USAGE_BASED_FEE",
    icon: <LineChartOutlined />,
  },
  "3": {
    label: "Credit Grant Fee",
    value: "CREDIT_GRANT",
    icon: <CreditCardOutlined />,
  },
  "4": {
    label: "License Fee",
    value: "LICENSE",
    icon: <FileProtectOutlined />,
  },
  "5": {
    label: "Entitlement Fee",
    value: "ENTITLEMENT_FEE",
    icon: <CheckCircleOutlined />,
  },
  "6": {
    label: "Entitlement Overage",
    value: "ENTITLEMENT_OVERAGE",
    icon: <DollarOutlined />,
  },
};

const { Text, Title } = Typography;

const RateCard: React.FC<any> = ({
  form,
  isAttachPricePlan = false,
  isMidCyclePricing,
  pricePlanType,
  editAttachPlan,
  isNew,
  proceedBtnDisabled,
}) => {
  console.log("►►► ~ editAttachPlan:", editAttachPlan);
  const [editCardData, setEditCardData] = useState("");
  const [rateCards, setRateCards] = useState<any>();
  // const [currency, setCurrency] = useState<string[]>([]);

  console.log("►►► ~ rateCards1234:", rateCards);
  const dispatch = useDispatch<AppDispatch>();
  const {
    billingDetails,
    planModalDetail,
    singlePricePlan,
    overRideRateCards,
    isOverridePlan,
  } = useSelector((state: RootState) => state.pricePlan);
  console.log("⏩ ~ billingDetails:", billingDetails);
  const { addOns, loading, error } = useSelector(
    (state: RootState) => state.billable
  );
  const { AccountCurrency } = useSelector(
    (state: RootState) => state.adminCenter
  );
  const { currency_code } = AccountCurrency.find((item: any) => {
    return item.is_base === true;
  });
  useEffect(() => {
    if (addOns?.length === 0) {
      dispatch(
        getAddons({
          sort: "-updated_at",
        })
      );
    }
  }, [dispatch, addOns?.length]);

  useEffect(() => {
    let updatedRateCards;

    if (isAttachPricePlan) {
      if (isOverridePlan) {
        const { rateCards, ...rest } = singlePricePlan;
        updatedRateCards = {
          rateCards: overRideRateCards,
          ...rest,
        };
      } else {
        updatedRateCards = singlePricePlan;
      }
    } else {
      updatedRateCards = billingDetails || {};
    }
    setRateCards(updatedRateCards);
  }, [
    billingDetails?.rateCards?.length,
    billingDetails,
    isAttachPricePlan,
    editAttachPlan,
    overRideRateCards,
  ]);

  useEffect(() => {
    if (rateCards?.rateCards?.length <= 0) {
      proceedBtnDisabled(true);
    } else {
      proceedBtnDisabled(false);
    }
  }, [rateCards?.rateCards?.length]);

  // useEffect(() => {
  //   if (singlePricePlan?.supportedCurrencies) {
  //     setCurrency(singlePricePlan.supportedCurrencies);
  //   }
  // }, [singlePricePlan?.supportedCurrencies]);

  const [selectedCurrency, setSelectedCurrency] = useState(
    billingDetails?.supportedCurrencies?.[0] || ""
  );

  // useEffect(() => {
  //   if (currency.length > 0) {
  //     setSelectedCurrency(currency[0]);
  //   }
  // }, [currency]);

  // const rateCardDataSource = useMemo(() => {
  //   if (selectedPricePlan) {
  //     const pricePlanData = pricePlans
  //     ?.find((p: any) => p.pricePlanId === selectedPricePlan)
  //     ?.rateCards

  //     return pricePlanData;
  //   } else {
  //     return rateCards;
  //   }
  // }, [selectedPricePlan, pricePlans, rateCards]);

  const filteredMenuItems = useMemo(
    () =>
      pricePlanType === "ONE_TIME"
        ? ["1", "3", "5"].reduce(
            (acc, key) => ({ ...acc, [key]: menuItems[key] }),
            {}
          )
        : isMidCyclePricing
        ? ["1", "2", "4"].reduce(
            (acc, key) => ({ ...acc, [key]: menuItems[key] }),
            {}
          )
        : menuItems,
    [isMidCyclePricing, pricePlanType]
  );

  const handleMenuClick = (e: any) => {
    dispatch(setPlanModalDetail(menuItems[e.key]?.value));
  };

  const handleCardRemove = (record: any) => {
    dispatch(removeBillingRateCard(record?.uniqueID));
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {Object.keys(filteredMenuItems).map((key) => (
        <Menu.Item key={key} icon={menuItems[key].icon}>
          <span>{menuItems[key].label}</span>
        </Menu.Item>
      ))}
    </Menu>
  );

  const RateCardDropDown = (isBtn: boolean = true) => {
    return (
      <Dropdown overlay={menu} trigger={["hover"]}>
        {isBtn ? (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            title="  Add Rate Card"
          >
            Add Rate Card
          </Button>
        ) : (
          <div className="addRateCard">
            <PlusOutlined />
            Add Rate Card
          </div>
        )}
      </Dropdown>
    );
  };

  const handleOverride = () => {
    dispatch(setIsOverridePlan(!isOverridePlan));
  };

  const options = [
    {
      value: "-updated_at",
      label: "Updated At Descending",
    },
    {
      value: "2Bupdated_at",
      label: "Updated At Ascending",
    },
    {
      value: "-id",
      label: "ID Descending",
    },
    {
      value: "2Bid",
      label: "ID Ascending",
    },
  ];

  const renderPricePlanDetailsTable = () => (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <SearchInput
        // value={searchTerm}
        // onChange={(e) => setSearchTerm(e.target.value)}
        />
        <FilterOption options={options} defaultValue="-updated_at" />
      </div>
      <Row gutter={24}>
        <Col span={24}>
          <PricePlanDetailsTable
            setEditCardData={setEditCardData}
            setRateCards={setRateCards}
            rateCards={rateCards}
            isAttachPricePlan={isAttachPricePlan}
            selectedPricePlan={singlePricePlan?.pricePlanId}
            currency={selectedCurrency}
            isNew={isNew}
            pagination={false}
          />
        </Col>
      </Row>
    </div>
  );

  return (
    <>
      <Container>
        <RateCardHeader>
          {" "}
          <div className="title">RATE CARDS</div>
          <div className="rateCardLeftHeader">
            {isAttachPricePlan && (
              <>
                {isOverridePlan && (
                  <>
                    <div
                      className="addRateCard"
                      onClick={() =>
                        dispatch(setPlanModalDetail("Plan Minimum"))
                      }
                    >
                      <PlusOutlined /> Plan Minimum
                    </div>
                    {RateCardDropDown(false)}
                  </>
                )}
                <TextedSwitch
                  text="Override Rate Cards"
                  onChange={handleOverride}
                  value={isOverridePlan}
                  checked={isOverridePlan}
                />
              </>
            )}

            {!isAttachPricePlan && (
              <>
                {!isMidCyclePricing && pricePlanType === "RECURRING" && (
                  <div
                    className="addRateCard"
                    onClick={() => dispatch(setPlanModalDetail("Plan Minimum"))}
                  >
                    <PlusOutlined /> Plan Minimum
                  </div>
                )}
                {RateCardDropDown(false)}
              </>
            )}
          </div>
        </RateCardHeader>
        <div
          style={{ padding: "16px 24px", overflow: "auto", height: "660px" }}
        >
          {rateCards?.rateCards?.length || isAttachPricePlan ? (
            <>
              {billingDetails?.supportedCurrencies?.length > 1 && (
                <div style={{ marginBottom: "1rem" }}>
                  <Segment
                    options={billingDetails?.supportedCurrencies || []}
                    value={selectedCurrency}
                    onChange={setSelectedCurrency}
                  />
                </div>
              )}
              {renderPricePlanDetailsTable()}
            </>
          ) : (
            <EmptyDataComponent
              addButton={RateCardDropDown}
              title="No Pricing Rules configured"
              description="You can set rules for usage or revenue computation for a rate card conditionally."
            />
          )}
        </div>
      </Container>
      <EditPlan
        planModalDetail={planModalDetail}
        setPlanModalDetail={setPlanModalDetail}
        setEditCardData={setEditCardData}
        editCardData={editCardData}
        rateCards={rateCards}
      />
    </>
  );
};

export default RateCard;
