import { DatePicker } from "antd";
import styled from "styled-components";

export const AliasContainer = styled.div`

  & .header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 24px;
  }

  & .table{
  border: 1px solid rgba(0,0,0,0.1); 
  border-top-left-radius: 8px; 
  border-top-right-radius: 8px; 
}
`;

export const StyledDatePicker = styled(DatePicker)`
  width:100%;
padding:3px 11px ;
  .ant-picker-suffix{
    display: none;
  }
`;


