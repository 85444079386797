import React, { useEffect, useMemo, useState } from "react";
import { Card, Typography, Row, Col, Divider, Avatar, Tag } from "antd";
import {
  MoreOutlined,
  ArrowLeftOutlined,
  EyeOutlined,
  CopyOutlined,
  ExportOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Link, useParams } from "react-router-dom";
import { Label, StyledCard, StyledRow } from "./styles";
import Calculation from "./Calculation";
import CustomDrawer from "../../../components/common/drawer";
import NewSettingForm from "../../admin-center/setting/form";
import {
  EmptyData,
  EmptyDataComponent,
} from "../../../components/common/empty";
import PricingRuleLogCard from "./pricingRuleLogCard";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import PageLoading from "../../../components/Loader";
import moment from "moment";
import UserAvatar from "../../../components/common/avatar";
import { copyToClipboard } from "../../../utils/helper";
import { getInvoiceById } from "../../../redux/feature/billing/billingThunk";
import BackPageHeader from "../../../components/common/BackPageHeader";
const { Text } = Typography;

const Details: React.FC = () => {
  const [isRuleDrawerOpen, setIsRuleDrawerOpen] = useState(false);
  const drawerHandler = () => {
    setIsRuleDrawerOpen(!isRuleDrawerOpen);
  };
  const { selectedInvoice, loading } = useSelector(
    (state: RootState) => state.billing
  );
  const { id } = useParams();
  const { AccountCurrency } = useSelector(
    (state: RootState) => state.adminCenter
  );
  const dispatch = useDispatch<AppDispatch>();

  const baseCurrency = useMemo(() => {
    return AccountCurrency.find((currency: any) => currency.is_base);
  }, [AccountCurrency, selectedInvoice]);


  const totalAmount = useMemo(() => {
    const baseItems = selectedInvoice?.items || [];
  
    // Calculate the total amount from all items
    const total = baseItems.reduce((acc: number, item: any) => {
      const itemTotal = parseFloat(item?.total_value || '0');
      return acc + itemTotal;
    }, 0);
  
    return total.toFixed(2);
  }, [selectedInvoice, baseCurrency]);
  
  console.log("Total Amount111111111111111111:", totalAmount);
  

  useEffect(() => {
    if (!selectedInvoice) dispatch(getInvoiceById(id));
  }, [id]);

  return loading && !selectedInvoice ? (
    <PageLoading />
  ) : (
    <div>
      <BackPageHeader
        actions={[
          { label: "Preview PDF", icon: <EyeOutlined /> },
          { label: "Pricing Rule Logs", icon: <EyeOutlined /> },
        ]}
        path="/billing/invoice"
      />
      <StyledRow gutter={32}>
        <Col span={16}>
          <StyledCard className="detailCard">
            <div className="orderDetailContainer">
              <div className="orderDetailHeader">
                <h3>Order Details</h3>
                <div className="orderDetailItem">
                  <Label>Sequence Id</Label>
                  <p>{selectedInvoice?.sequence_id}</p>
                  <CopyOutlined
                    className="copy"
                    onClick={() => {
                      copyToClipboard(selectedInvoice?.sequence_id);
                    }}
                  />
                </div>
                {/* <div className="orderDetailItem">
                  <Label>Reference Id</Label>
                  <p>inv.23XGadj01F6.oEtW5</p>
                  <CopyOutlined className="copy" />
                </div> */}
              </div>
              <div>
                {" "}
                <Tag color="purple">{selectedInvoice?.status}</Tag>
              </div>
            </div>
            <Row gutter={12} className="cardContainer">
              <Col span={6}>
                <Card className="cardItem">
                  <Label>Price Plan</Label>
                  <p>{selectedInvoice?.price_plan_id}</p>
                </Card>
              </Col>
              <Col span={6}>
                <Card className="cardItem">
                  <Label>Start Date</Label>
                  <p>
                    {moment(selectedInvoice?.start_date).format("MMM DD YYYY")}
                  </p>
                </Card>
              </Col>
              <Col span={6}>
                <Card className="cardItem">
                  <Label>End Date</Label>
                  <p>
                    {moment(selectedInvoice?.end_date).format("MMM DD YYYY")}
                  </p>
                </Card>
              </Col>
              <Col span={6}>
                <Card className="cardItem">
                  <Label>Due Date</Label>
                  <p>
                    {moment(selectedInvoice?.due_date).format("MMM DD YYYY")}
                  </p>
                </Card>
              </Col>
            </Row>
          </StyledCard>

          <Calculation
            selectedInvoice={selectedInvoice}
            baseCurrency={baseCurrency}
          />
        </Col>

        <Col span={8}>
          <StyledCard>
            <div className="dueDetails">
              <p>Total Due</p>
              <div className="item">
                <p>{totalAmount ?? '0'}</p>
                <span className="currency">{baseCurrency?.currency_code}</span>
                {selectedInvoice?.status !== "DUE" && (
                  <Tag color="gold">• Draft Total</Tag>
                )}
              </div>
            </div>
            <Divider style={{ margin: 0, marginBottom: "0.5rem" }} />
            <div className="dueDetails">
              <p>Paid till now</p>
              <div className="item">
                <p>{selectedInvoice?.paid_amount}</p>
                <span className="currency">{baseCurrency?.currency_code}</span>
              </div>
            </div>
            <div className="timing">
              <p>Last Updated : {selectedInvoice?.updated_at}</p>
              <p>Generated At :{selectedInvoice?.created_at}</p>
            </div>
          </StyledCard>

          <StyledCard>
            <p>CUSTOMER DETAILS</p>
            <Divider className="detailDivider" />
            <Row align="middle">
              <Col>
                <UserAvatar user={selectedInvoice} />
              </Col>
              <Col style={{ marginLeft: "10px" }}>
                <Text>{selectedInvoice?.customer_name}</Text>
                <br />
                <Text type="secondary">{selectedInvoice?.customer?.email}</Text>
              </Col>
            </Row>
            <Divider className="detailDivider" />
            <div>
              <div className="details">
                <p>Customer Id</p>
                <p className="value">
                  {selectedInvoice?.customer_id}{" "}
                  <CopyOutlined
                    className="copy"
                    onClick={() => {
                      copyToClipboard(selectedInvoice?.customer_id);
                    }}
                  />
                </p>
              </div>
              <div className="details">
                <p>Account</p>
                <a href={`/customers/${selectedInvoice?.customer_id}/accounts/${selectedInvoice?.customer_account_id}/details`} className="value-link" target="_blank">
                  {selectedInvoice?.customer_account_name}{" "}
                  <ExportOutlined className="copy" />
                </a>
              </div>
              <div className="details">
                <p>Account ID</p>
                <p className="value">
                  {selectedInvoice?.customer_account_id}{" "}
                  <CopyOutlined
                    className="copy"
                    onClick={() => {
                      copyToClipboard(selectedInvoice?.customer_account_id);
                    }}
                  />
                </p>
              </div>
              <div className="details">
                <p>Price Plan</p>
                <a href={`/price-plan/${selectedInvoice?.price_plan_id}`} className="value-link" target="_black">
                  {selectedInvoice?.price_plan_id}{" "}
                  <ExportOutlined className="copy" />
                </a>
              </div>
            </div>
          </StyledCard>

          <StyledCard>
            <p>BILL TO</p>
            <Divider className="detailDivider" />
            <div>
              <div className="details">
                <p>Legal Name</p>
                <p className="value">
                  {selectedInvoice?.customer_account_name}
                </p>
              </div>
              <div className="details">
                <p>Billing Email</p>
                <p className="value">{selectedInvoice?.customer?.email}</p>
              </div>
              <div className="details">
                <p>Address</p>
                <p className="value">
                  {selectedInvoice?.customer?.line1}
                  <br />
                  {selectedInvoice?.customer?.line2 &&
                    selectedInvoice?.customer?.line2 + ", "}
                  <br />
                  {selectedInvoice?.customer?.city},{" "}
                  {selectedInvoice?.customer?.state}{" "}
                  {selectedInvoice?.customer?.postalCode}
                  <br />
                  {selectedInvoice?.customer?.country}
                </p>
              </div>
            </div>
          </StyledCard>
        </Col>
      </StyledRow>
      <CustomDrawer
        isOpen={isRuleDrawerOpen}
        onClose={drawerHandler}
        title="Pricing Rule Logs"
        isActionButtonsShown={false}
        width={500}
      >
        <div style={{ padding: "20px" }}>
          <PricingRuleLogCard />
          {/* <EmptyData description="No Pricing Rule Logs Found" /> */}
        </div>
      </CustomDrawer>
    </div>
  );
};

export default Details;
