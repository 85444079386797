import { Card } from "antd";
import styled from "styled-components";

export const ViewPricePlanHeader = styled.div`
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 10;
  .headerStyles {
    display: flex;
    align-items: center;
    padding: 1em 7rem;
    justify-content: space-between;
  }
  .header-btn{
    display: flex;
gap: 1em;
  }
`;


export const ViewPlanContainer=styled.div`
display: flex;
flex-direction: column;
gap: 20px;`


export const PlanCard = styled(Card)`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

.tooltip-container {
  display: flex;
  align-items: center;
  gap: 12px;

   .icon-span{
    background-color:#2853c2;
    padding: 12px;
    border-radius:100%;
  }
}

.plan_name{
font-weight: 700;
font-size: 18px;
}
.status {
  display: flex;
  gap: 8px;
}

.card-body-right-side{
  display: flex;
  gap: 12px;
  align-items: center;

  .content{
    display: flex;
    gap: 20px;
   flex-direction: column;

   
  }
}

`;