import { Button } from "antd";
import { ModalContainer } from "./styles";
import { ReactNode } from "react";
import CustomButton from "../button";

type ModalPropType = {
  children: React.ReactNode;
  title: string | React.ReactNode;
  isOpen: any;
  onSubmit?: () => void;
  onClose: () => void;
  successBtnTitle?: string;
  isSuccessBtnShown?: boolean;
  icon?: ReactNode | null;
  iconPosition?: "end" | "start";
  isCancelable?: boolean;
  width?: string;
  loading?: boolean;
  isGoBackButtonShown?: boolean;
  onGoBackBtnClick?: () => void;
  isSuccessBtnDisable?: boolean;
};

const Modal: React.FC<ModalPropType> = ({
  children,
  title,
  isOpen,
  onSubmit,
  onClose,
  isSuccessBtnShown = true,
  successBtnTitle = "Submit",
  icon = null,
  iconPosition = "start",
  isCancelable = true,
  width = "520px",
  loading = false,
  isGoBackButtonShown = false,
  onGoBackBtnClick = () => {},
  isSuccessBtnDisable = false,
}) => {
  return (
    <ModalContainer
      title={title}
      centered
      footer={null}
      closable={false}
      open={isOpen}
      onOk={onClose}
      onCancel={onClose}
      width={width}
    >
      <div className="body">{children}</div>
      <div className="actions">
        {isCancelable && (
          <CustomButton
            type="default"
            onClick={onClose}
            text="Cancel"
            isShowIcon={false}
            disabled={loading}
            isGrey={true}
          />
        )}
        {isSuccessBtnShown && (
          <div>
            <span className="goBack">
              {isGoBackButtonShown && (
                <CustomButton
                  isGrey={true}
                  text="Go Back"
                  isShowIcon={false}
                  onClick={onGoBackBtnClick}
                  disabled={loading}
                />
              )}
            </span>
            <CustomButton
              icon={icon}
              position={iconPosition}
              onClick={onSubmit}
              loading={loading}
              text={successBtnTitle}
              disabled={isSuccessBtnDisable}
            />
          </div>
        )}
      </div>
    </ModalContainer>
  );
};
export default Modal;
