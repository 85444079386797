import { Col, Segmented } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import CustomButton from "../../../components/common/button";
import {
  InfoCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import PricePlanDetailsTable from "../NewPricePlan/PricePlanTable";
import { EmptyDataComponent } from "../../../components/common/empty";
import { useNavigate } from "react-router-dom";
import FilterOption from "../../../components/common/filter/FilterOption";
import useSelection from "antd/es/table/hooks/useSelection";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import SearchInput from "../../../components/common/search";
import Segment from "../../../components/common/segmented";

const PlanRateCardTable: React.FC<any> = ({
  isAddCurrency,
  span = 18,
  isAddRoutes,
}) => {
  const [type, setType] = useState<"Rate Card" | "Pricing Rules">("Rate Card");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currency, setCurrency] = useState<string[]>([]);
  const navigate = useNavigate();
  const { filter } = useSelector((state: RootState) => state.customer);
  const { singlePricePlan } = useSelector(
    (state: RootState) => state.pricePlan
  );

  useEffect(() => {
    if (singlePricePlan?.supportedCurrencies) {
      setCurrency(singlePricePlan.supportedCurrencies);
    }
  }, [singlePricePlan?.supportedCurrencies]);

  const [selectedCurrency, setSelectedCurrency] = useState(
    singlePricePlan?.supportedCurrencies?.[0] || ""
  );

  useEffect(() => {
    if (currency.length > 0) {
      setSelectedCurrency(currency[0]);
    }
  }, [currency]);

  const options = [
    {
      value: "-rateCardDetails.displayName",
      label: "Rate Card Name Descending",
    },
    {
      value: "2BrateCardDetails.displayName",
      label: "Rate Card Name Ascending",
    },
    {
      value: "-type",
      label: "Rate Card Type Descending",
    },
    {
      value: "2Btype",
      label: "Rate Card Type Ascending",
    },
  ];

  return (
    <Col span={span}>
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <div>
          <Segment
            options={["Rate Card", "Pricing Rules"]}
            value={type}
            onChange={setType}
            isWhite={true}
          />
        </div>
        {type === "Rate Card" ? (
          <>
            {isAddCurrency && (
              <div
                style={{
                  display: "flex",
                  justifyContent: currency.length > 1 ? "space-between" : "end",
                  alignItems: "center",
                }}
              >
                {currency?.length > 1 && (
                  <Segment
                    options={currency}
                    value={selectedCurrency}
                    onChange={setSelectedCurrency}
                  />
                )}
                <CustomButton
                  type="default"
                  text="Add Currency"
                  icon={<PlusOutlined />}
                  position="start"
                  onClick={() => {}}
                />
              </div>
            )}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <SearchInput
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  if (isAddRoutes) {
                    if (e.target.value) {
                      navigate(
                        `/price-plan/${singlePricePlan.pricePlanId}?__sort__=${filter}&__search__=${e.target.value}`
                      );
                    } else {
                      navigate(
                        `/price-plan/${singlePricePlan.pricePlanId}?__sort__=${filter}`
                      );
                    }
                  }
                }}
              />
              <FilterOption
                options={options}
                defaultValue="-rateCardDetails.displayName"
              />
            </div>
            <div>
              <PricePlanDetailsTable
                pagination={false}
                isOverridePlan={false}
                setEditCardData={() => {}}
                setRateCards={() => {}}
                rateCards={singlePricePlan}
                isAttachPricePlan={false}
                isAction={false}
                searchTerm={searchTerm}
                currency={selectedCurrency}
                isNew={false}
              />
            </div>
          </>
        ) : (
          <EmptyDataComponent
            isAddButton={false}
            title=" No Pricing Rules configured"
            description="You can set rules for usage or revenue computation for a rate card conditionally.."
          />
        )}
      </div>
    </Col>
  );
};

export default PlanRateCardTable;
