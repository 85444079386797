import React from "react";
import dayjs from "dayjs";
import styled from "styled-components";

const DateCard = styled.div<any>`
  width: 46px;
  height: 46px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 0;
  border: 1px solid ${({ status }) => 
      status === 'Expired' ? 'gray' :status === 'Upcoming'? 'orange': '#008a68'};

  p {
    margin: 0;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    width: 100%;
    padding: 4px;
  }
  .month {
    background-color: ${({ status }) => 
      status === 'Expired' ? 'gray' : status === 'Upcoming'? 'orange': '#008a68'};
    color: white;
    p {
      font-size: 10px;
      font-weight: 400;
    }
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
  }
  .date {
    background-color: #daeaeb;
    color: black;
    p {
      font-size: 14px;
      font-weight: 500;
    }
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const CalendarCard: React.FC<any> = ({ startData ,status}) => {
  const date = dayjs(startData);
  const month = date.format("MMM"); // Short month name (e.g., "Sep")
  const day = date.date(); // Day of the month

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "50px",
      }}
    >
      <DateCard status={status}>
        <div className="month">
          <p>{month}</p>
        </div>
        <div className="date">
          <p>{day}</p>
        </div>
      </DateCard>
      <div
        style={{
          height: "100%",
          width: "1px",
          marginTop: "12px",
          backgroundColor: "#008a68",
        }}
      />
    </div>
  );
};

export default CalendarCard;
