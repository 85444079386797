import { InfoCircleOutlined } from "@ant-design/icons";
import { Card } from "antd";
import CustomTable from "../../../../../components/common/customTable";

type RestValueItemPropType = {
  title: string;
  data: any[];
  columns: string[];
  emptyMessage: string;
  isCopyShow?: boolean;
};

const RestValueItem: React.FC<RestValueItemPropType> = ({
  title,
  data,
  columns,
  emptyMessage,
  isCopyShow = true,
}) => {
  return (
    <div className="restValues">
      <p>{title}:</p>
      {data.length === 0 ? (
        <Card className="emptyValuesCard">
          <InfoCircleOutlined className="icon" />
          <span className="emptyText">{emptyMessage}</span>
        </Card>
      ) : (
        <CustomTable data={data} column={columns} isCopyShow={isCopyShow} />
      )}
    </div>
  );
};

export default RestValueItem;
