import styled from "styled-components";

export const PropertiesContainer = styled.div`
.propertyCardContainer{
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  p,h3{
    margin: 0;
    font-size: 12px;
  }
  p{
    margin-bottom: 5px;
  }
  .ant-card{
    background-color: #F8FAFC;
    flex: 1;
    border:none;
  }
  .ant-card-body{
    padding: 10px!important;
  }
}
.title{
  color: #475569;
  font-size:12px
}
.event-details__info-head{
  display: flex;
  align-items: center;
  gap: 5px;
}

.attributesAndDimensionsContainer{
  margin-bottom: 2rem;
  p{
    margin-top: 2rem;
    font-size: 12px;
    font-weight: 600;
  }
  .customTable{
    border:1px solid lightgray;
    border-radius: 10px;
    padding: 7px;
  }
  .thead{
    background-color:#F8FAFC ;
    border-radius: 10px;
    padding: 3px 10px;
    font-size: 10px;
    margin-bottom: 7px;

    .ant-col{
      font-size: 13px;
      display: flex;
      align-items: center;
    }
  }
  .tbody{
     padding: 0.8rem 10px;
    font-size: 10px;

    .ant-col{
        font-size: 13px;
        display: flex;
        align-items: center;
      }
      .value{
        font-weight: 600;
      }
  }
  .tbody:hover{
    background-color: #F8FAFC;
  }
}

.restValues{
  margin-bottom: 2rem;
  p{
    font-size: 12px;
    font-weight:600;
    
  }
  .emptyValuesCard{
    border: 1px solid lightgray;
    border-radius: 15px!important;

      .emptyText{
        color: rgba(0,0,0,0.5);
      }
  }
  .ant-card-body{
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon{
    margin-right: 5px;
    color: blue;
  }
}
`;