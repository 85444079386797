import { Card, Form, Input, Select } from "antd";
import { useState } from "react";

const priceTypeOptions = [
  { value: "perUnit", label: "Per Unit" },
  { value: "Flat", label: "Flat" },
];

type LineItemCardPropType = {
  nameKey: number;
};
const LineItemCard: React.FC<LineItemCardPropType> = ({ nameKey }) => {
  const [priceType, setPriceType] = useState("");

  return (
    <Card>
      <Form.Item label="Description" name={`description-${nameKey}`}>
        <Input size="small" placeholder="Enter item name" />
      </Form.Item>
      <Form.Item label="Price Type" name={`type-${nameKey}`}>
        <Select
          onChange={setPriceType}
          options={priceTypeOptions}
          placeholder="Price Type"
        />
      </Form.Item>
      {priceType === "perUnit" ? (
        <>
          <Form.Item label="Quantity" name={`quantity-${nameKey}`}>
            <Input type="number" min={1} placeholder="Quantity" />
          </Form.Item>
          <Form.Item label="Price Per Quantity" name={`price-${nameKey}`}>
            <Input type="number" min={0} placeholder="Price Per Quantity" />
          </Form.Item>
        </>
      ) : (
        <Form.Item label="Total Price" name={`totalPrice-${nameKey}`}>
          <Input type="number" min={0} placeholder="Total Price" />
        </Form.Item>
      )}
    </Card>
  );
};

export default LineItemCard;
