import { useEffect, useMemo } from "react";

import Stream from "./stream";
import Schema from "./schemas";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { getEvents, getIngest } from "../../redux/feature/events/eventThunk";
import PageLayout from "../../components/common/pageLayout";
import { useLocation, useNavigate } from "react-router-dom";

const Events = () => {
  // const [selectedEvent, setSelectedEvent] = useState<string | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();

  const { events, ingest, loading } = useSelector(
    (state: RootState) => state.event
  );
  const { filter } = useSelector((state: RootState) => state.customer);


  // useEffect(() => {
  //   if (ingest.length === 0) {
  //     dispatch(
  //       getIngest({
  //         schema_name: "billOptim-row",
  //         sort: "-timestamp",
  //       })
  //     );
  //   }
  // }, [ingest.length]);

  const currentTabKey = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const sortParam = searchParams.get("__sort__");
    if (location.pathname === "/event-schemas") {
      return "2";
    }
    return "1";
  }, [location.pathname, location.search]);

  const items = [
    {
      label: `Events Stream`,
      key: "1",
      children: <Stream />,
    },
    {
      label: `Event Schemas`,
      key: "2",
      children: <Schema currentTabKey={currentTabKey} />,
    },
    // {
    //   label: `Event Corrections`,
    //   key: "3",
    //    children: <PricePlanTable keyProp="one-time" />,
    // },
  ];

  const handleTabChange = (key: string) => {
    const path = key === "1" ? "/events" : "/event-schemas";
    
    const sortParameter = path === "/events" && filter === '-updated_at' ? "-timestamp" : filter;
    navigate(`${path}?__sort__=${sortParameter}`);
  };
  

  useEffect(() => {
    const currentPath = location.pathname;
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(
      "__sort__",
      currentPath === "/events" && filter === '-updated_at' ? "-timestamp" : filter
    );
    if (currentPath === "/events") {
      searchParams.set("event-type", "BillOptim-event");
    }
    navigate(`${currentPath}?${searchParams.toString()}`);
  }, [location.pathname, navigate, filter]);
  

 

  return (
    <PageLayout
      title="Events"
      activeKey={currentTabKey}
      isBtnShow={currentTabKey === "2" ? true : false}
      items={items}
      handleTabChange={handleTabChange}
      btnText={currentTabKey === "2" ? "New Event Schema" : ""}
      btnOnClick={
        currentTabKey === "2" ? () => navigate("/events/create") : () => {}
      }
    />
  );
};

export default Events;
