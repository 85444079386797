import styled from "styled-components";

export const CurrenciesContainer = styled.div`

& .base-currency{
  margin-bottom: 2.2rem;
}
& .table{
   border: 1px solid rgba(0,0,0,0.1); 
  border-top-left-radius: 8px; 
  border-top-right-radius: 8px; 
}

& .additional-header{
  display:flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

`;