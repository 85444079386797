import { useLocation, useNavigate } from "react-router-dom";
import PricePlanTable from "./table";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import PageLayout from "../../components/common/pageLayout";

const PricePlan = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeKey, setActiveKey] = useState("recurring");
  const { filter } = useSelector((state: RootState) => state.customer);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const path = location.pathname.split("/").pop(); // Get the last part of the path
    // if (path && (path === "recurring" || path === "one-time")) {
    //   setActiveKey(path);
    // }
    navigate(`/price-plan/${activeKey}?__sort__=${filter}`);
  }, [location.pathname, filter]);

  useEffect(() => {
    if (activeKey) {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [activeKey]);

  const handleTabChange = (key: any) => {
    setLoading(true);
    setActiveKey(key);
    navigate(`/price-plan/${key}?__sort__=${filter}`);
  };

  const items = useMemo(
    () => [
      {
        label: `Recurring Plans`,
        key: "recurring",
        children: (
          <PricePlanTable
            keyProp="BILLING"
            activeKey={activeKey}
            isLoading={isLoading}
          />
        ),
      },
      {
        label: `One-Time Plans`,
        key: "one-time",
        children: (
          <PricePlanTable
            keyProp="PURCHASE"
            activeKey={activeKey}
            isLoading={isLoading}
          />
        ),
      },
    ],
    [activeKey, isLoading]
  );

  return (
    <div>
      <PageLayout
        title="Price Plan"
        btnText="New Price Plan"
        activeKey={activeKey}
        handleTabChange={handleTabChange}
        btnOnClick={() =>
          navigate(
            `/price-plan/create?plan-type=${
              activeKey === "recurring" ? "RECURRING" : "ONE_TIME"
            }`
          )
        }
        items={items}
      />
    </div>
  );
};
export default PricePlan;
