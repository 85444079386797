import styled from "styled-components";

export const Header = styled.div`
    background-color: white;
    padding: 1.5rem 5rem;
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 1rem;
    box-shadow: 0px 2px 8px 0px #cbd5e1;

  .title{
    margin: 0;
    font-size: 16px;
    font-weight:500;
  }

  .header-item-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .title-container{
    align-items: center;
    gap: 1rem;
    display: flex;
  }
  .leftIcon{
    width: 33px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e2e8f0;;
    border-radius: 10px;
    font-size: 12px;
    
  }
  .btn-container{
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .icon{
    font-weight: bold !important;
    color: #64748b;
  }
`;