import { Checkbox } from "antd";
import styled from "styled-components";

export const FormContainer = styled.div`
  padding: 15px 4px;
`;
export const CustomCheckbox = styled(Checkbox)`
  border: 1px solid #d9d9d9;
  padding: 4px 8px;
`;
