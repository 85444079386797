import { Radio, Row } from "antd";
import styled from "styled-components";

export const StyledRow = styled(Row)`
  
  overflow-y: auto;
  .eventSubTitle{
    font-size: 13px;
    font-weight: 600;

    span{
      font-weight: 300;
      margin-left: 5px;
      font-size: 12px;
    }
  }
`;


