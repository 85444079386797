import { Link } from "react-router-dom";
import { Section } from "./styles";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { removeSelectedInvoice } from "../../../redux/feature/billing/billingSlice";

type BackPageHeaderPropType = {
  actions?: any[];
  text?: string;
  path?: string;
};

const BackPageHeader: React.FC<BackPageHeaderPropType> = ({
  actions,
  text = "Back",
  path = "/",
}) => {
  const dispatch=useDispatch<AppDispatch>()
  return (
    <Section>
      <Link to={path} className="headerItem" onClick={()=>{
        dispatch(removeSelectedInvoice())
      }}>
        {" "}
        <ArrowLeftOutlined
          style={{ height: "14px", width: "14px", fontWeight: "bold" }}
        />{" "}
        <span className="text">{text}</span>
      </Link>
      <div className="actions">
        {actions &&
          actions.map((item: any) => {
            return (
              <div
                key={item.key}
                className="headerItem"
                onClick={item?.onClick}
              >
                {" "}
                <span className="text">{item.label}</span>
                {item.icon}{" "}
              </div>
            );
          })}
      </div>
    </Section>
  );
};

export default BackPageHeader;
