import { useEffect, useMemo, useState } from "react";
import AddOns from "./addOns";
import UsageMeters from "./usageMeters";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { setFilter } from "../../redux/feature/customer/customerSlice";
import PageLayout from "../../components/common/pageLayout";
import { Form } from "antd";

const BillableItems:React.FC<any> = ({keyProp}) => {
  const { filter } = useSelector((state: RootState) => state.customer);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  //add-on related
  const [isAddOnModalOpen, setIsAddOnModalOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [form] = Form.useForm();

  const addOnModalHandler = () => {
    setIsAddOnModalOpen(!isAddOnModalOpen);
    form.resetFields();
    setEdit(false);
  };

  const currentTabKey = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const sortParam = searchParams.get("__sort__");
    if (location.pathname === "/add-ons" && sortParam === filter) {
      return "2";
    }
    return "1";
  }, [location.pathname, location.search, filter]);

  useEffect(() => {
    const currentPath = location.pathname;
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("__sort__", filter);
    navigate(`${currentPath}?${searchParams.toString()}`);
  }, [filter, location.pathname, navigate]);
  useEffect(() => {
    return () => {
      dispatch(setFilter("-updated_at"));
    };
  }, []);

  const handleTabChange = (key: string) => {
    const path = key === "1" ? "/usage-meter" : "/add-ons";

    navigate(`${path}?__sort__=${filter}`);
  };

  const items = [
    {
      label: `Usage Meter`,
      key: "1",
      children: (
        <UsageMeters 
        currentTabKey={currentTabKey}
        />
      ),
    },
    {
      label: `Add-ons`,
      key: "2",
      children: (
        <AddOns
          isModalOpen={isAddOnModalOpen}
          modalHandler={addOnModalHandler}
          form={form}
          edit={edit}
          setEdit={setEdit}
          currentTabKey={currentTabKey}
        />
      ),
    },
    // {
    //   label: `Features`,
    //   key: "3",
    //   children: <PricePlanTable keyProp="one-time" />,
    // },
  ];

  return (
    <PageLayout
      items={items}
      title="Billable Items"
      activeKey={currentTabKey}
      handleTabChange={handleTabChange}
      btnText={currentTabKey === "1" ? "New Usage Meter" : "New Add-On"}
      btnOnClick={
        currentTabKey === "1"
          ? () => navigate("/usage-meter/create")
          : addOnModalHandler
      }
    />
  );
};

export default BillableItems;
