import { Card } from "antd";
import styled from "styled-components";

export const NewEventSchemaContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .event-details {
    padding: 2rem 7rem;
  }
  .body {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .ant-form-item-required {
      font-size: 0.9rem;
      font-weight: 300 !important;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
    .name {
      margin-bottom: 1rem;
    }
    .form {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
    .configureId {
      height: 100%;
      display: flex;
      justify-content: left;
      align-items: center;
      color: #2853c2;
      cursor: pointer;
    }
    .templateItem {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: start;
    }
    .deleteIcon {
      border: 1px solid red;
      padding: 0.2rem 0.6rem;
      border-radius: 10px;
      color: red;
    }
  }
  .footerContent {
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 1rem 3rem;
  }
  .footerActions {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
`;

export const ItemCard = styled(Card)`
  background-color: #f8fafc;
  margin-bottom: 1rem;
  box-shadow: 0px 2px 8px 0px #cbd5e1 !important;

  .event-items {
    display: flex;
    justify-content: space-between;
  }
  .toggle-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .item-card-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .icon-container {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: gray;
  }
  .event-detail {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .ant-card-head-wrapper {
    min-height: 0 !important;
  }
  .ant-card-head {
    min-height: 0;
  }
  .ant-card-head-title {
    padding: 0.5rem 0 !important;
  }
  .ant-card-body {
    padding: 1rem;
    gap: 0 !important;
    display: flex;
    flex-direction: column;
  }
  .action {
    cursor: pointer;
  }
`;

export const EventSchemaCard = styled(Card)`
  box-shadow: 0px 2px 8px 0px #cbd5e1 !important;
  .card-body {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .cardItem {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .copyIcon {
      margin-left: 0.5rem;
      height: 12px;
      width: 12px;
    }
    .rightIcon {
      margin-left: 0.5rem;
    }
    .item-value {
      font-weight: 700;
    }
  }
  .divider {
    margin: 0 24px;
  }
`;

export const IngestDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
  }
`;
