import { Col, Row } from "antd";
import { StyledDiv } from "./styles";
import { CopyOutlined } from "@ant-design/icons";

type CustomTablePropType = {
  data: any;
  column: any;
  isCopyShow?: boolean;
};

const CustomTable: React.FC<CustomTablePropType> = ({
  data,
  column,
  isCopyShow = true,
}) => {
  return (
    <StyledDiv>
      <Row className="thead">
        {column.map((item: any) => {
          return (
            <Col span={isCopyShow ? 10 : 12}>
              <span>{item}</span>
            </Col>
          );
        })}
        {isCopyShow && <Col span={4}></Col>}
      </Row>
      {data.map((item: any) => {
        return (
          <Row className="tbody">
            <Col span={isCopyShow ? 10 : 12}>
              <span>{item?.field}</span>
            </Col>
            <Col span={isCopyShow ? 10 : 12}>
              <span className="value">{item?.value}</span>
            </Col>
            {isCopyShow && (
              <Col span={4}>
                <CopyOutlined style={{ height: "12px", width: "12px" }} />
              </Col>
            )}
          </Row>
        );
      })}
    </StyledDiv>
  );
};

export default CustomTable;
