import { Card } from "antd";
import styled from "styled-components";

export const StyledCard = styled(Card)`
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 12px;

  .card-header {
    font-weight: bold;
    color: #2856CC; 
    cursor: pointer;
    display: inline-block;
    margin-left: 0.5rem;
  }
  .ant-card-body{
    padding: 0;
    gap: 0;
  }
  .head{
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
  }

  .card-preview {
    color: #8c8c8c; /* Grey text for 'Preview' */
    margin-bottom: 8px;
  }

  .card-content {
    background-color: #f0f0f0; /* Light grey background for content */
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
  }
  .icon{
    margin-right: 0.1rem;
    height: 12px;
    width: 12px;
  }

 
`;