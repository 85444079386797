import styled from "styled-components";

export const ExtraContent = styled.div`
    display: flex;
    align-items: start;
    margin-top: 5px;
    gap: 5px;

    .text{
      font-size:12px;
    }

    .icon{
      height: 12px;
      width: 12px;
      margin-top: 3px;
    }
`;  