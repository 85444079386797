import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api/apiClient";
import { message } from "antd";

export const getPricePlans = createAsyncThunk(
  "event/getPricePlans",
  async (filterData:any, { rejectWithValue }) => {
    const {sort, search, type,status}=filterData

    try {
      const response = await api.get(`/price_plans?sort=${sort}&type=${type}${search ? `&search=${search}`:''}${status ? `&status=${status}`:''}`);
      return response.data.data;
    } catch (error: any) {
      console.log("🚀 ~ file: pricePlanThunk.ts:14 ~ error:", error);
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const getSinglePricePlan = createAsyncThunk(
  "event/getSinglePricePlan",
  async (pricePlanId: any, { rejectWithValue }) => {
    try {
      const response = await api.get(`/price_plans/${pricePlanId}`);
      return response.data;

    } catch (error: any) {
      console.log("🚀 ~ file: pricePlanThunk.ts:28 ~ error:", error);
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);


export const createPricePlan = createAsyncThunk(
  "event/createPricePlan",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await api.post("/price_plans", payload);
      message.success("price plan created successfully");
      return response.data.pricePlanId;
    } catch (error: any) {
      console.log("🚀 ~ file: pricePlanThunk.ts:29 ~ error:", error);
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const attachPricePlanToAccount = createAsyncThunk(
  "event/attachPricePlanToAccount",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await api.post(`/accounts/${payload.accountId}/price_schedule`, payload.data);
      message.success("price plan attached successfully");
      return response.data.data;
    } catch (error: any) {
      console.log("🚀 ~ file: pricePlanThunk.ts:42 ~ error:", error)
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const changePricePlanStatus = createAsyncThunk(
  "event/changePricePlanStatus",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/price_plans/${payload}/activate`
      );
      return response.data
    } catch (error: any) {
      console.log("🚀 ~ file: pricePlanThunk.ts:46 ~ error:", error)
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const deletePricePlan = createAsyncThunk(
  "pricePlan/deletePricePlan",
  async (pricePlanId: any, { rejectWithValue }) => {
    console.log("►►► ~ pricePlanId:", pricePlanId);
    try {
      const response = await api.delete(`/price_plans/${pricePlanId}`);
      message.success("price plan deleted successfully");
      return pricePlanId;
    } catch (error: any) {
      console.log("🚀 ~ file: pricePlanThunk.ts:29 ~ error:", error);
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const getAttachPricePlan = createAsyncThunk(
  "pricePlan/getAttachPricePlan",
  async (accountId: any, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `/accounts/${accountId}/pricing_schedules?include_price_plan_info=${true}`
      );
      return response.data.data;
    } catch (error: any) {
      console.log("🚀 ~ file: pricePlanThunk.ts:14 ~ error:", error);
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const activatePricePlan = createAsyncThunk(
  "pricePlan/activatePricePlan",
  async (pricePlanId: number, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `/price_plans/${pricePlanId}/activate`
      );
      message.success('activated price plan')
      return pricePlanId;
    } catch (error: any) {
      console.log("🚀 ~ file: pricePlanThunk.ts:14 ~ error:", error);
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const updateAttachPricePlan = createAsyncThunk(
  "pricePlan/updateAttachPricePlan",
  async (payload: any, { rejectWithValue }) => {
    const {pricePlanAttachId,...rest}=payload
    try {
      const response = await api.post(
        `/accounts/edit_price_schedule?price_plan_attach_id=${pricePlanAttachId}`,rest
      );
      console.log("►►► ~ response:", response)
      message.success('Price Schedule updated successfully')
      return response.data.data;
    } catch (error: any) {
      console.log("🚀 ~ file: pricePlanThunk.ts:14 ~ error:", error);
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);


export const getAttachPricePlanById = createAsyncThunk(
  "pricePlan/getAttachPricePlanById",
  async (payload: any, { rejectWithValue }) => {
const {accountId, aPlanId}=payload
    try {
      const response = await api.get(
        `/account/${accountId}/pricing_schedule?pricePlanAttachId=${aPlanId}`
      );
      return response.data.data;
    } catch (error: any) {
      console.log("🚀 ~ file: pricePlanThunk.ts:14 ~ error:", error);
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);
