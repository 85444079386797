import {
  Form,
  Input,
  InputRef,
  message,
  Select,
  Table,
  TableColumnsType,
  TableColumnType,
  Tag,
} from "antd";
import {
  CloseCircleOutlined,
  CopyOutlined,
  InfoCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FilterDropdownProps } from "antd/es/table/interface";
import { useLocation, useNavigate } from "react-router-dom";
import { EmptyData } from "../../../components/common/empty";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { timeAgo } from "../../../utils/helper";
import ViewPricePlan from "../ViewPricePlan";
import { Container } from "../NewPricePlan/BillingDetails/Styles";
import FilterDropdown from "../../../components/common/filter";
import { setFilter } from "../../../redux/feature/customer/customerSlice";
import CustomButton from "../../../components/common/button";
import PageLoading from "../../../components/Loader";
import FilterOption from "../../../components/common/filter/FilterOption";
import { StyledTable } from "../../../components/common/table/styles";
import {
  getPricePlans,
  getSinglePricePlan,
} from "../../../redux/feature/pricePlan/pricePlanThunk";
import SearchInput from "../../../components/common/search";

type PricePlanColumnsType = {
  key: React.Key;
  name: string;
  cycle?: string;
  status: string;
  currencies: string[];
  supportedCurrencies: string[];
  lastUpdated: string;
  updatedAt: Date;
};
type DataIndex = keyof PricePlanColumnsType;

const PricePlanTable = ({
  keyProp,
  activeKey,
  isLoading,
}: {
  keyProp?: string;
  activeKey?: string;
  isLoading?: boolean;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { pricePlans, loading, error } = useSelector(
    (state: RootState) => state.pricePlan
  );
  const [form] = Form.useForm();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchKey, setSearchKey] = useState(searchTerm);
  const [load, setLoad] = useState(false);
  const [status, setStatus] = useState("");
  const { filter } = useSelector((state: RootState) => state.customer);
  const location = useLocation();

  useEffect(() => {
    setLoad(true);
    const handler = setTimeout(() => {
      setSearchKey(searchTerm);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    dispatch(
      getPricePlans({
        sort: filter.startsWith("2B") ? filter.slice(2) : filter,
        search: searchKey,
        status: status,
        type: activeKey === "recurring" ? "BILLING" : "PURCHASE",
      })
    );
    setLoad(false);
  }, [searchKey, status, filter, activeKey]);

  const onSubmitFilter = async () => {
    try {
      const values = await form.validateFields();
      setStatus(values.status);
      if (values.status) {
        navigate(
          `/price-plan/${activeKey}?__sort__=${filter}${
            searchTerm ? `&__search__=${searchTerm}&` : "&"
          }status=${values.status}`
        );
      } else {
        navigate(
          `/price-plan/${activeKey}?__sort__=${filter}${
            searchTerm ? `&__search__=${searchTerm}` : ""
          }`
        );
      }
    } catch (error) {}
  };

  const pricePlanDataSource = useMemo(() => {
    return pricePlans?.map((item: any) => item);
  }, [pricePlans]);

  const columns: TableColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, { status }) => {
        return (
          <Tag color={status === "ACTIVE" ? "green" : "orange"}>• {status}</Tag>
        );
      },
    },
    ...(keyProp !== "PURCHASE"
      ? [
          {
            title: "Cycle",
            dataIndex: "pricingCycleInterval",
          },
        ]
      : []),
    {
      title: "Currencies",
      dataIndex: "supportedCurrencies",
      render: (_, { supportedCurrencies }) => {
        return supportedCurrencies.map((cur: any) => (
          <Tag color="purple"> {cur} </Tag>
        ));
      },
    },
    {
      title: "Last Updated",
      dataIndex: "updatedAt",
      render: (_, { updatedAt }) => {
        return timeAgo(updatedAt);
      },
    },
    {
      title: "",
      dataIndex: "action",
      render: () => <CopyOutlined />,
    },
  ];

  const options = [
    {
      value: "-updated_at",
      label: "Updated At Descending",
    },
    {
      value: "2Bupdated_at",
      label: "Updated At Ascending",
    },
  ];

  const handleSearchChange = useCallback(
    (e: any) => {
      const newValue = e.target.value;
      setSearchTerm(newValue);

      navigate(
        `/price-plan/${activeKey}?__sort__=${filter}${
          status ? `&status=${status}` : ""
        }${newValue ? `&__search__=${newValue}` : ""}`
      );
    },
    [activeKey, filter, status, navigate]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <SearchInput value={searchTerm} onChange={handleSearchChange} />
        <div style={{ display: "flex", gap: "5px" }}>
          {status && (
            <CustomButton
              text="Clear Filter"
              type="link"
              icon={false}
              onClick={() => {
                setStatus("");
                form.resetFields();
                navigate(
                  `/price-plan/${activeKey}?__sort__=${filter}${
                    searchTerm ? `&__search__=${status}` : ""
                  }`
                );
              }}
            />
          )}
          <FilterDropdown onSubmitFilter={onSubmitFilter}>
            <Form layout="vertical" form={form}>
              <Form.Item label="Status" name="status">
                <Select placeholder="Status">
                  <Select.Option value="ACTIVE">Active</Select.Option>
                  <Select.Option value="DRAFT">Draft</Select.Option>
                </Select>
              </Form.Item>
            </Form>
          </FilterDropdown>
          <FilterOption options={options} defaultValue="-updated_at" />
        </div>
      </div>
      {pricePlans.length > 0 ? (
        isLoading ? (
          <StyledTable
            pagination={false}
            columns={columns}
            loading={true} // Show only the loading spinner
            dataSource={[]} // Empty data source to hide the data
            size="middle"
          />
        ) : load ? (
          <StyledTable
            pagination={false}
            columns={columns}
            loading={true} // Show only the loading spinner
            dataSource={[]} // Empty data source to hide the data
            size="middle"
          />
        ) : (
          <StyledTable
            pagination={false}
            columns={columns}
            dataSource={pricePlanDataSource}
            size="middle"
            onRow={(record: any) => {
              return {
                onClick: () => {
                  if (record.status === "ARCHIVED") {
                    message.error("Price plan not found");
                    return;
                  }

                  // dispatch(getSinglePricePlan(record.pricePlanId));
                  navigate(
                    `/price-plan/${record.pricePlanId}?__sort__=${filter}`
                  );
                },
              };
            }}
          />
        )
      ) : (
        <EmptyData
          description="No Pricing Plan Found"
          btnText="New Price Plan"
          onClick={() => {
            navigate(
              `/price-plan/create?plan-type=${
                activeKey === "recurring" ? "RECURRING" : "ONE_TIME"
              }`
            )
          }}
        />
      )}
    </div>
  );
};

export default PricePlanTable;
