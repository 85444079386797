import { Col, Form, Input, InputNumber, Row, Select, Space } from "antd";
import { PriceModalContainer } from "../styles";
import { useMemo, useState } from "react";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const PriceModal: React.FC<{
  isPriceRangeShown: boolean;
  index?: number;
  editCardData?: any;
  id?: number;
  uniqueID: any;
  form: any;
  lastUnitChangeHandler: any;
  removeField?: (id: number) => void;
}> = ({
  isPriceRangeShown = false,
  index = 1,
  removeField,
  id,
  editCardData,
  uniqueID,
  lastUnitChangeHandler,
  form,
}) => {
  console.log("►►► ~ uniqueID:", uniqueID)
  Form.useWatch(form);
  const [isMinPriceSelected, setIsMinPriceSelected] = useState(false);
  const [isMaxPriceSelected, setIsMaxPriceSelected] = useState(false);
  const [pricingType, setPricingType] = useState("");
  const [lastUnitValue, setLastUnitValue] = useState<any>("-");
  const { billingDetails } = useSelector((state: any) => state.pricePlan);

  const handelShow = (
    setPrice: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setPrice((prev) => !prev);
  };

  const changePricingType = (value: string) => {
    setPricingType(value);
  };

  const spanSize = useMemo(() => {
    if (pricingType === "package" && isPriceRangeShown) return 3;
    else if (isPriceRangeShown) return 4;
    else return 6;
  }, [isPriceRangeShown, pricingType]);

  return (
    <>
      <PriceModalContainer>
        <Row style={{ minHeight: "72px" }}>
          <Col span={1}>
            <div className="indexWrapper">{`${uniqueID + 1}`}</div>
          </Col>
          <Col span={isPriceRangeShown ? 3 : 4}>
            <div className="itemWrapper">
              <Form.Item label="First Unit" name={`firstUnit-${uniqueID}`}>
                <Input
                  disabled={true}
                  size="small"
                  placeholder="0"
                  className="input"
                  defaultValue={'0'}
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={isPriceRangeShown ? 3 : 4}>
            <div className="itemWrapper">
              <Form.Item label="Last Unit" name={`lastUnit-${uniqueID}`}>
                <Input
                  type="number"
                  size="small"
                  placeholder={index === 2 || !isPriceRangeShown ? "∞" : "-"}
                  className="input"
                  disabled={index === 2 || !isPriceRangeShown}
                  onChange={(e) => {
                    setLastUnitValue(e?.target?.value);
                    // lastUnitChangeHandler(e)

                    form.setFieldValue(
                      `firstUnit-${uniqueID + 1}`,
                      e?.target?.value
                    );
                  }}
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={isPriceRangeShown ? 4 : 5}>
            <div className="itemWrapper">
              <Form.Item
                label="Type"
                className="type"
                name={`pricingType-${uniqueID}`}
                rules={[
                  {
                    required: true,
                    message: "Please input the rate card name!",
                  },
                ]}
              >
                <Select placeholder="Select" onChange={changePricingType}>
                  <Select.Option value="flat">Flat</Select.Option>
                  <Select.Option value="package">Package</Select.Option>
                  <Select.Option value="unit">Per Unit</Select.Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col span={isPriceRangeShown ? 3 : 5}>
            <div className="itemWrapper ">
              {billingDetails?.supportedCurrencies?.length > 0 &&
                billingDetails?.supportedCurrencies.map((currency: any) => (
                  <Form.Item
                    label={`${currency} Price`}
                    name={`${currency}-price-${uniqueID}`}
                    className="currency"
                  >
                    <Input
                      size="small"
                      type="number"
                      placeholder="Price"
                      className="input"
                    />
                  </Form.Item>
                ))}
            </div>
          </Col>

          {pricingType === "package" && (
            <Col span={isPriceRangeShown ? 4 : 5}>
              <div className="itemWrapper ">
                <Form.Item
                  label="Package Size"
                  name={`packageSize-${uniqueID}`}
                >
                  <InputNumber
                    size="small"
                    max={lastUnitValue ? Number(lastUnitValue) : ""}
                    placeholder={`Max-${lastUnitValue}`}
                    className="input"
                  />
                </Form.Item>
              </div>
            </Col>
          )}
          {isPriceRangeShown && pricingType !== "flat" && (
            <>
              <Col span={3}>
                <div className="itemWrapper ">
                  {billingDetails?.supportedCurrencies?.length > 0 &&
                    billingDetails?.supportedCurrencies.map((currency: any) =>
                      !isMinPriceSelected ? (
                        <p
                          onClick={() => handelShow(setIsMinPriceSelected)}
                          style={{ cursor: "pointer" }}
                          className="priceTag"
                        >
                          <PlusOutlined /> Min Price
                        </p>
                      ) : (
                        <Form.Item
                          label={
                            <div>
                              <span>{currency} Min</span>
                              <DeleteOutlined
                                style={{ marginLeft: "5px" }}
                                onClick={() =>
                                  handelShow(setIsMinPriceSelected)
                                }
                              />
                            </div>
                          }
                          name={`${currency}-min-${uniqueID}`}
                          className="currency"
                          rules={[
                            {
                              required: true,
                              message: "Please input the minimum price",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                const maxValue = getFieldValue(
                                  `${currency}-max-${uniqueID}`
                                );
                                if (value && maxValue) {
                                  if ( value <= maxValue) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error(
                                      "Min price must be less than  max price"
                                    )
                                  );
                                }
                              },
                            }),
                          ]}
                        >
                          <Input
                            size="small"
                            type="number"
                            placeholder="Min"
                            className="input"
                            onChange={() => {
                              form.validateFields([
                                `${currency}-min-${uniqueID}`,
                                `${currency}-max-${uniqueID}`,
                              ]);
                            }}
                          />
                        </Form.Item>
                      )
                    )}
                </div>
              </Col>
              <Col span={3}>
                <div className="itemWrapper">
                  {billingDetails?.supportedCurrencies?.length > 0 &&
                    billingDetails?.supportedCurrencies.map((currency: any) =>
                      !isMaxPriceSelected ? (
                        <p
                          onClick={() => handelShow(setIsMaxPriceSelected)}
                          style={{ cursor: "pointer" }}
                          className="priceTag"
                        >
                          <PlusOutlined /> Max Price
                        </p>
                      ) : (
                        <Form.Item
                          label={
                            <div>
                              <span>{currency} Max</span>
                              <DeleteOutlined
                                style={{ marginLeft: "5px" }}
                                onClick={() =>
                                  handelShow(setIsMaxPriceSelected)
                                }
                              />
                            </div>
                          }
                          name={`${currency}-max-${uniqueID}`}
                          className="currency"
                          rules={[
                            {
                              required: true,
                              message: "Please input the maximum price",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                const minValue = getFieldValue(
                                  `${currency}-min-${uniqueID}`
                                );
                                if (value && minValue) {
                                  if (value >= minValue) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error(
                                      "Max price must be greater than min price"
                                    )
                                  );
                                }
                              },
                            }),
                          ]}
                        >
                          <Input
                            size="small"
                            type="number"
                            placeholder="Max"
                            className="input"
                            onChange={() => {
                              form.validateFields([
                                `${currency}-min-${uniqueID}`,
                                `${currency}-max-${uniqueID}`,
                              ]);
                            }}
                          />
                        </Form.Item>
                      )
                    )}
                </div>
              </Col>
            </>
          )}
        </Row>
      </PriceModalContainer>
      {index >= 3 && (
        <Col span={1}>
          <DeleteOutlined
            style={{ margin: "0 0.5rem" }}
            onClick={() => (removeField ? removeField(id ? id : 1) : {})}
          />
        </Col>
      )}
    </>
  );
};

export default PriceModal;
