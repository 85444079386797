import React from "react";
import { javascript } from "@codemirror/lang-javascript";
import { StyledCodeMirror, StyledDiv } from "./styles";
import { CopyOutlined } from "@ant-design/icons";
import { copyToClipboard } from "../../../../../utils/helper";

const JsonPayload: React.FC<any> = ({
  selectedIngest,
  height = "450px",
  editable = false,
}) => {
  console.log("►►► ~ selectedIngest:", selectedIngest);
  // const [value, setValue] = React.useState("console.log('hello world!');");
  const onChange = React.useCallback((val: any, viewUpdate: any) => {
    console.log("val:", val);
    // setValue(val);
  }, []);

  const payload = {
    event_schema_id: selectedIngest?.event_schema_id,
    timestamp: selectedIngest?.timestamp,
    accountId: selectedIngest?.account_id,
    attributes: selectedIngest?.attributes,
    dimensions: selectedIngest?.dimensions,
    ingest_event_id: selectedIngest?.ingest_event_id,
  };

  return (
    <StyledDiv>
      <div className="header">
        <span>Event Payload</span>
        <span
          className="iconContainer"
          onClick={() => {
            copyToClipboard(JSON.stringify(payload, null, 2));
          }}
        >
          Copy{" "}
          <CopyOutlined
            style={{ height: "11px", width: "11px", marginLeft: "5px" }}
          />{" "}
        </span>
      </div>
      <StyledCodeMirror
        value={JSON.stringify(payload, null, 2)}
        height={height}
        extensions={[javascript({ jsx: true })]}
        onChange={onChange}
        editable={editable}
      />
    </StyledDiv>
  );
};
export default JsonPayload;
