import {
  ArrowLeftOutlined,
  EditOutlined,
  LeftOutlined,
  RightOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import {
  NewPricePlanContainer,
  PlanPreviewContainer,
  PricePlanContainer,
  PricePlanFormHeader,
} from "../../../../price-plans/NewPricePlan/Styles";
import { Breadcrumb, Button, Col, Form, Row, Space, Tag, Timeline } from "antd";
import { CustomSteps } from "../../../../price-plans/NewPricePlan/BillingDetails/Styles";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../../../components/common/button";
import PlanDetails from "./PlanDetails";
import ScheduleList from "./SchedulePreview";
import { useEffect, useMemo, useState } from "react";
import RateCard from "../../../../price-plans/NewPricePlan/RateCard";
import PricingRules from "../../../../price-plans/NewPricePlan/PricingRules";
import { AppDispatch, RootState } from "../../../../../redux/store";
import {
  removeSinglePricePlanData,
  setBillingDetails,
  setBillingRateCards,
  setEmptyBillingRateCards,
  setIsOverridePlan,
  setOverRideRateCards,
} from "../../../../../redux/feature/pricePlan/pricePlanSlice";
import {
  reverseTransformPricePlanPayload,
  reverseTransformRateCard,
  transformAttachPricePlanPayload,
} from "../../../../../utils/helper";
import {
  attachPricePlanToAccount,
  getAttachPricePlan,
  getSinglePricePlan,
  updateAttachPricePlan,
} from "../../../../../redux/feature/pricePlan/pricePlanThunk";
import { getAccountById } from "../../../../../redux/feature/customer/customerAsyncThunk";
import moment from "moment";
import dayjs from "dayjs";
import PageLoading from "../../../../../components/Loader";
import { AllAccountsHeader } from "../../Styles";
import { HeaderStyles, TitleStyles } from "../../../viewCustomer/styles";

const steps = [
  { title: "Price Plan Details" },
  {
    title: "Configure Rate Cards",
  },
  {
    title: "Configure Pricing Rules",
  },
];

const AttachPricePlan: React.FC<any> = ({ isEdit }) => {
  const { viewAccountDetails, filter } = useSelector(
    (state: any) => state.customer
  );
  const { attachPricePlan } = useSelector((state: any) => state.pricePlan);
  const { pricePlans, singlePricePlan, overRideRateCards, isOverridePlan } =
    useSelector((state: RootState) => state.pricePlan);
  const dispatch = useDispatch<AppDispatch>();
  const { customerId, accountId, aPlanId } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const [initSchedules, setInitSchedules] = useState();
  const [editAttachPlan, setEditAttachPlan] = useState<any>("");

  const [form] = Form.useForm();
  const [dates, setDates] = useState<any>({
    effectiveFrom: null,
    effectiveUntil: null,
    gracePeriod: 0,
    pricingCycleStartMonth: null,
    pricingCycleStartDay: null,
    pricingCycleInterval: null,
    pricePlanName:null
  });
  const [schedules, setSchedules] = useState<any[]>([]);

  const [current, setCurrent] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (!viewAccountDetails) {
      dispatch(getAccountById(accountId));
    }
    // if (!viewAccountDetails) navigate(`/customer?__sort__=${filter}`);
    return ()=>{
      dispatch(setIsOverridePlan(false))
    }
  }, []);

  useEffect(() => {
    dispatch(getAttachPricePlan(accountId));

    // return () => {
    //   dispatch(setOverRideRateCards([]));
    //   dispatch(setEmptyBillingRateCards({ rateCards: [], pricingRules: [] }));
    // };
  }, []);

  useEffect(() => {
    const edited = attachPricePlan?.find(
      (plan: any) => plan.price_plan_attach_id.toString() === aPlanId
    );
    console.log("►►► ~ useEffect ~ edited:", edited);
    if (edited) {
      form.setFieldsValue({
        attachPricePlanId: aPlanId,
        pricePlanId: edited.price_plan_id,
        effectiveFrom: dayjs(edited.effective_from),
        effectiveUntil: dayjs(edited.effective_until),
        interval: edited.pricing_cycle_interval,
        gracePeriod: edited.grace_period,
        startOffDay: edited.start_day_offset,
        startOffDate: edited.start_day_offset,
        startOffMonth: edited.start_month_offset,
      });
      dispatch(setIsOverridePlan(edited.isOverRide));
      dispatch(getSinglePricePlan(edited.price_plan_id));
      setEditAttachPlan(singlePricePlan);
    }

    const initSchedules = attachPricePlan
      ?.map((schedule: any) => {
        return {
          pricePlanName:schedule.priceplanname,
          eventScheduleId: schedule.price_plan_event_schedule_id,
          effectiveFrom: moment(schedule.effective_from)
            .local()
            .format("YYYY-MM-DD HH:mm:ss"),
          effectiveUntil: moment(schedule.effective_until)
            .local()
            .format("YYYY-MM-DD HH:mm:ss"),
          gracePeriod: schedule.grace_period,
          pricingCycleStartMonth: schedule.start_month_offset,
          pricingCycleStartDay: schedule.start_day_offset,
          pricingCycleInterval: schedule.pricing_cycle_interval,
          active:
            edited?.price_plan_event_schedule_id ===
              schedule.price_plan_event_schedule_id || undefined,
        };
      })
      ?.sort(
        (a: any, b: any) =>
          new Date(a.effectiveFrom)?.getTime() -
          new Date(b.effectiveFrom)?.getTime()
      );
    setSchedules(initSchedules);
    setInitSchedules(initSchedules);
  }, [attachPricePlan,aPlanId]);

  useEffect(()=>{
    const formData=reverseTransformPricePlanPayload(singlePricePlan)
        dispatch(
          setBillingDetails(formData)
        );
  },[isOverridePlan])

  const handleStepChange = async (e: number) => {
    try {
      if (current === 0) {
        const data = await form.validateFields();
        const formData=reverseTransformPricePlanPayload(singlePricePlan)
        dispatch(
          setBillingDetails(formData)
        );
      }
      setCurrent(e);
    } catch (error) {
      console.log("►►► ~ handleStepChange ~ error:", error);
    }
  };

  const handleNext = async () => {
    try {
      if (current === 0) {
        const data = await form.validateFields();
        dispatch(
          setBillingDetails(reverseTransformPricePlanPayload(singlePricePlan))
        );
      }
      setCurrent(current + 1);
    } catch (error) {
      console.log("►►► ~ handleStepChange ~ error:", error);
    }
  };

  const transformOverrideRateCardPayload=(overRideRateCards:any)=>{
    const cards: any = {
      usageRateCards: [],
      fixedFeeRateCards: [],
      licenseRateCards: [],
      creditGrantRateCards: [],
      otherPlan: [],
    };

    overRideRateCards.forEach((card:any) => {
      switch (card.cardType) {
        case "USAGE_BASED_FEE":
          cards.usageRateCards.push(card);
          break;
        case "FIXED_FEE":
          cards.fixedFeeRateCards.push(card);
          break;
        case "LICENSE":
          cards.licenseRateCards.push(card);
          break;
        case "CREDIT_GRANT":
          cards.creditGrantRateCards.push(card);
          break;
        default:
          cards.otherPlan.push(card);
          break;
      }
    });

    return cards
  }

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await form.validateFields();
      const values = form.getFieldsValue(true);
      const data = transformAttachPricePlanPayload(values);
      const updatedEventSchema = schedules?.map((event: any) => {
        const {
          gracePeriod,
          pricingCycleStartMonth,
          pricingCycleStartDay,
          pricingCycleInterval,
          ...rest
        } = event;
        return rest;
      });
      data.eventSchema = updatedEventSchema;

      if (editAttachPlan) {
        const cards: any = {
          usageRateCards: [],
          fixedFeeRateCards: [],
          licenseRateCards: [],
          creditGrantRateCards: [],
          otherPlan: [],
        };

        overRideRateCards.forEach((card) => {
          switch (card.cardType) {
            case "USAGE_BASED_FEE":
              cards.usageRateCards.push(card);
              break;
            case "FIXED_FEE":
              cards.fixedFeeRateCards.push(card);
              break;
            case "LICENSE":
              cards.licenseRateCards.push(card);
              break;
            case "CREDIT_GRANT":
              cards.creditGrantRateCards.push(card);
              break;
            default:
              cards.otherPlan.push(card);
              break;
          }
        });
        
        data.pricePlanDetailsOverride = transformOverrideRateCardPayload(overRideRateCards);
        dispatch(
          updateAttachPricePlan({
            ...data,
            isOverRide: isOverridePlan,
            pricePlanAttachId: aPlanId,
          })
        );
      } else {

        if(isOverridePlan) {
          data.pricePlanDetailsOverride = transformOverrideRateCardPayload(overRideRateCards)
        };

        const pricePlan = await dispatch(
          attachPricePlanToAccount({
            data,
            accountId,
            isOverRide: isOverridePlan,
          })
        ).unwrap();
      }
      // await dispatch(getPricePlans());
      // navigate("/price-plan");
      form.resetFields();
      navigate(
        `/customers/${customerId}/accounts/${accountId}/price-plan-schedule`
      );
      setEditAttachPlan("");
      dispatch(
        setEmptyBillingRateCards({
          rateCards: [],
          pricingRules: [],
        })
      );
      dispatch(setOverRideRateCards([]));
      dispatch(getAttachPricePlan(accountId));
    } catch (error) {
      console.log("🚀 ~ file: index.tsx:55 ~ handleSubmit ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <NewPricePlanContainer>
      {isLoading && <PageLoading />}
      <div className="attachPlanHeader">
        <Breadcrumb
          separator=">"
          items={[
            {
              title: "All Customers",
              href: "/customers",
            },
            {
              title: viewAccountDetails?.customer_name,
              href: `/customers/${viewAccountDetails?.customer_id}/details`,
            },
            {
              title: viewAccountDetails?.account_name,
            },
          ]}
        />
        <HeaderStyles>
          <div className="headerTitle">
            <div
              className="leftIcon"
              onClick={() => {
                dispatch(removeSinglePricePlanData(null));
                dispatch(setOverRideRateCards([]));
                dispatch(
                  setEmptyBillingRateCards({ rateCards: [], pricingRules: [] })
                );

                navigate(
                  `/customers/${viewAccountDetails?.customer_id}/accounts/${viewAccountDetails?.customer_account_id}/price-plan-schedule`
                );
              }}
              style={{ cursor: "pointer" }}
            >
              <ArrowLeftOutlined className="icon" />
            </div>
            <p>{isEdit ? "Edit Schedule" : "Attach Price Plan to Account"}</p>
          </div>
          <div className="headerAction">
            {isEdit && (
              <CustomButton
                type="default"
                text={"Restore Schedule"}
                icon={<RollbackOutlined />}
                onClick={() => {}}
              />
            )}
            <CustomButton
              type="primary"
              text={current === 2 ? "Finished" : "Proceed"}
              position="end"
              icon={<RightOutlined />}
              onClick={current === 2 ? () => handleSubmit() : handleNext}
            />
          </div>
        </HeaderStyles>
      </div>
      <PricePlanContainer>
        <Row gutter={24}>
          <Col span={18}>
            <div className="contentContainer">
              <div className="stepsContainer">
                <CustomSteps
                  direction="vertical"
                  size="small"
                  current={current}
                  items={steps}
                  onChange={handleStepChange}
                />
              </div>
              <div className="formContainer">
                {current === 0 ? (
                  <PlanDetails
                    setSchedules={setSchedules}
                    dates={dates}
                    initSchedules={initSchedules}
                    setDates={setDates}
                    schedules={schedules}
                    form={form}
                  />
                ) : current === 1 ? (
                  <RateCard
                    form={form}
                    isAttachPricePlan={true}
                    editAttachPlan={editAttachPlan}
                    isNew={false}
                    proceedBtnDisabled={()=>{}}
                  />
                ) : (
                  <PricingRules isAttachPriceRules={true} />
                )}
              </div>
            </div>
          </Col>
          <Col span={6}>
            <div className="contentContainer">
              <PlanPreviewContainer>
                <PricePlanFormHeader>SCHEDULE PREVIEW</PricePlanFormHeader>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    height: "706px",
                    overflowX: "hidden",
                    overflowY: "scroll",
                  }}
                >
                  {schedules.length === 0 ? (
                    <div>No Plan Attached yet</div>
                  ) : (
                    schedules
                      .filter((event: any) => event?.type !== "delete")
                      .map((item) => <ScheduleList item={item} />)
                  )}
                </div>
              </PlanPreviewContainer>
            </div>
          </Col>
        </Row>
      </PricePlanContainer>
    </NewPricePlanContainer>
  );
};

export default AttachPricePlan;
