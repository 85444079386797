import { Col, Row, Tabs } from "antd";
import Users from "./users";
import Currencies from "./currencies";
import AliasManagement from "./aliasManagement";
import Setting from "./setting";
import Profile from "./userProfile";
import ApiToken from "./apiToken";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import BillingInformation from "./billingInformation";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { getBillingInfo } from "../../redux/feature/adminCenter/adminCenterAsyncThunk";
import { AdminCenterContainer, AdminCenterTabs } from "./Styles";
import PageLoading from "../../components/Loader";

const AdminCenter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tabValue, setTabValue] = useState<any>("profile");
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch<AppDispatch>();
  const {loading:isLoading}=useSelector((state:RootState)=>state.auth)

  useEffect(() => {
    const path = location.pathname;
    console.log("►►► ~ useEffect ~ path:", path);
    const currentKey = path.split("/")[2] || "profile";
    setTabValue(currentKey);
    if (path === "/admin-center" || path === "/") {
      navigate(`/admin-center/${tabValue}`);
    }
    setLoading(false);
  }, [location.pathname]);

  useEffect(() => {
    dispatch(getBillingInfo());
  }, []);

  const items = [
    {
      label: "Profile",
      key: "profile",
      children:isLoading? (<PageLoading/>):( <Profile />),
    },
    {
      label: "Users",
      key: "users",
      children: <Users />,
    },
    {
      label: "Currencies",
      key: "currencies",
      children: <Currencies />,
    },
    {
      label: "Alias Management",
      key: "aliases",
      children: <AliasManagement />,
    },
    {
      label: "API Token",
      key: "api-tokens",
      children: <ApiToken />,
    },
    // {
    //   label: "Setting",
    //   key: "settings",
    //   children: <Setting />,
    // },
    {
      label: "Billing Information",
      key: "billing-information",
      children: <BillingInformation />,
    },
  ];

  const handleTabChange = (key: any) => {
    navigate(`/admin-center/${key}`);
  };

  return (
    <div>
      {loading ? (
        <PageLoading />
      ) : (
        <AdminCenterContainer>
          <h1 className="header">Admin Center</h1>

          <AdminCenterTabs
            tabPosition={"left"}
            activeKey={tabValue}
            onTabClick={handleTabChange}
            items={items}
          />
        </AdminCenterContainer>
      )}
    </div>
  );
};

export default AdminCenter;
