import {
  Card,
  Divider,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Select,
  Spin,
  Tag,
} from "antd";
import {
  EventSchemaDetailsCard,
  NestedCard,
  StyledRadio,
  UsageBasedFeeFormContainer,
} from "../styles";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PlusOutlined, RightOutlined } from "@ant-design/icons";
import NewField from "../../../../../events/schemas/newfield";
import Extra from "../../../../../../components/common/extra";
import CustomButton from "../../../../../../components/common/button";
import PerUnitCalculationFilter from "../../../../../billableItems/usageMeters/perUnitCalculationFilter";
import UsageFeeAddFilter from "./UsageFeeAddFilter";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../../redux/store";
import {
  changeEventStatus,
  createEvent,
  getEvents,
} from "../../../../../../redux/feature/events/eventThunk";
import {
  createUsageMeters,
  getUsageMeters,
} from "../../../../../../redux/feature/billableItems/billableThunk";
import {
  transformFormValues,
  transformUsageMeterFormValues,
} from "../../../../../../utils/helper";
import GroupByTag from "../../../../../../components/GroupTag.tsx";
import PageLoading from "../../../../../../components/Loader";

interface Field {
  id: number;
}

const UsageBasedFeeForm: React.FC<any> = ({
  form,
  usageMeterValue,
  setUsageMeterValue,
  createdEventSchema,
  setCreatedEventSchema,
  createdUsageMeter,
  setCreatedUsageMeter,
}) => {
  const Option = Select.Option;
  const [selectedEventSchema, setSelectedEventSchema] = useState<any>("");
  console.log("⏩ ~ createdEventSchema:", createdEventSchema);
  console.log("⏩ ~ createdUsageMeter:", createdUsageMeter);
  const [tagValue, setTagValue] = useState<any>("");
  const [aggregationTypeValue, setAggregationTypeValue] = useState("SUM");
  // const [dummy, setDummy] = useState(false);
  const [attributeFields, setAttributeFields] = useState<Field[]>([]);
  const [dimensionFields, setDimensionFields] = useState<Field[]>([]);
  const [changeProperty, setChangeProperty] = useState(false);
  const [changeOperator, setChangeOperator] = useState(false);
  const [attributeDimension, setAttributeDimension] = useState<any>([]);
  const [attributeList, setAttributeList] = useState<any>([]);
  const [isLoading, setLoading] = useState(false);
  const [addFilterFields, setAddFilterFields] = useState<Field[]>([]);
  const [addExpressionFields, setAddExpressionFields] = useState<Field[]>([]);
  const [usageFeeExpressionChange, setUsageFeeExpressionChange] =
    useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const { billingDetails } = useSelector((state: RootState) => state.pricePlan);

  const { events, loading, error } = useSelector(
    (state: RootState) => state.event
  );

  const { usageMeters } = useSelector((state: RootState) => state.billable);
  const { rateCards } = useSelector(
    (state: RootState) => state.pricePlan.billingDetails
  );
  console.log("⏩ ~ usageMeters:", usageMeters);

  // useEffect(() => {
  //   console.log("⏩ ~ rateCards:", rateCards);
  //   return () => setCreatedEventSchema("");
  // }, []);

  useEffect(() => {
    if (usageMeters?.length === 0) {
      dispatch(
        getUsageMeters({
          sort: "-updated_at",
        })
      );
    }
  }, [dispatch, usageMeters?.length]);

  const aggregationTypeHandler = (e: RadioChangeEvent) => {
    setAggregationTypeValue(e.target.value);
    if (e.target.value === "COUNT") {
      setAddFilterFields([]);
      setAddExpressionFields([]);
    }
  };
  const handleAddField = (
    setFields: React.Dispatch<React.SetStateAction<Field[]>>
  ) => {
    setFields((prevFields) => [...prevFields, { id: Date.now() }]);
  };

  const handleRemoveField = (
    id: number,
    setFields: React.Dispatch<React.SetStateAction<Field[]>>
  ) => {
    setFields((prevFields) => prevFields.filter((field) => field.id !== id));
  };

  const addAttributeField = useCallback(
    () => handleAddField(setAttributeFields),
    [attributeFields]
  );
  const removeAttributeField = useCallback(
    (id: number) => handleRemoveField(id, setAttributeFields),
    [attributeFields]
  );
  const addDimensionsField = useCallback(
    () => handleAddField(setDimensionFields),
    [dimensionFields]
  );
  const removeDimensionsField = useCallback(
    (id: number) => handleRemoveField(id, setDimensionFields),
    [dimensionFields]
  );
  const addFilterField = useCallback(
    () => handleAddField(setAddFilterFields),
    []
  );
  const removeFilterField = useCallback(
    (id: number) => handleRemoveField(id, setAddFilterFields),
    [addFilterFields]
  );
  const addExpressionField = useCallback(
    () => handleAddField(setAddExpressionFields),
    []
  );
  const removeExpressionField = useCallback(
    (id: number) => handleRemoveField(id, setAddExpressionFields),
    [addExpressionFields]
  );

  const handleEventSchemaCreate = async () => {
    setLoading(true);
    try {
      const values = await form1.validateFields();
      const data = transformFormValues(values);
      const eventCreated: any = await dispatch(createEvent(data)).unwrap();
      if (eventCreated) {
        await dispatch(
          changeEventStatus({
            eventSchemaId: eventCreated[0].event_schema_id,
            checked: true,
          })
        );
        setSelectedEventSchema(eventCreated[0]);
        setCreatedEventSchema(eventCreated[0]);
      }
      form1.resetFields();
      setAttributeFields([]);
      setDimensionFields([]);
    } catch (err) {
      console.log(
        "🚀 ~ file: UsageBasedFee.tsx:106 ~ handleEventSchemaCreate ~ err:",
        err
      );
    } finally {
      setLoading(false);
    }
  };

  const handleUsageMeterCreate = async () => {
    //  ...........................................................................................................................>>>>>>
    setLoading(true);
    try {
      const values = await form2.validateFields();
      if (selectedEventSchema && selectedEventSchema !== "newSchema") {
        values.eventSchemaId = selectedEventSchema?.event_schema_id;
      }
      const payload = transformUsageMeterFormValues(values);
      const usageMeter: any = await dispatch(
        createUsageMeters(payload)
      ).unwrap();
      if (usageMeter) {
        setUsageMeterValue(usageMeter);
        setCreatedUsageMeter(usageMeter);
      }
      // form.setFieldValue("usageMeterId", usageMeter.usageMeterId);
      form2.resetFields();
      setAddFilterFields([]);
      setAddExpressionFields([]);
    } catch (err) {
      console.log(
        "🚀 ~ file: UsageBasedFee.tsx:159 ~ handleUsageMeterCreate ~ err:",
        err
      );
    } finally {
      setLoading(false);
    }
  };

  const eventOptions = useMemo(
    () => [
      <Option key="newSchema" value="newSchema">
        Create New Event Schema
      </Option>,
      ...events?.map((event: any) => (
        <Option key={event.event_schema_id} value={event.event_schema_id}>
          {event.name}
        </Option>
      )),
    ],
    [events]
  );

  const usageMeterOptions = useMemo(
    () => [
      <Option key="newUsage" value="newUsage">
        Create New Usage Meter
      </Option>,

      ...usageMeters
        ?.filter(
          (item: any) =>
            !billingDetails.rateCards
              .map((card: any) => card.usageMeterId)
              .includes(item.usageMeterId)
        )
        ?.map((usageMeter: any) => (
          <Option key={usageMeter.usageMeterId} value={usageMeter.usageMeterId}>
            {usageMeter.name}
          </Option>
        )),
    ],
    [usageMeters]
  );

  const handleUsageMeterValueChange = (e: any) => {
    if (e == "newUsage") {
      setUsageMeterValue("newUsage");
      if (events?.length === 0) {
        dispatch(
          getEvents({
            sort: "-updated_at",
          })
        );
      }
    }
    setUsageMeterValue(e);
    setCreatedUsageMeter("");
    setCreatedEventSchema("");
  };

  useEffect(() => {
    if (selectedEventSchema && selectedEventSchema !== "newSchema") {
      const attributesData = selectedEventSchema?.attributes?.map(
        (attr: any) => ({
          value: attr.name,
          label: attr.name,
        })
      );
      const dimensionsData = selectedEventSchema?.dimensions?.map(
        (dim: any) => ({
          value: dim,
          label: dim,
        })
      );
      setAttributeDimension([
        ...(attributesData ?? []),
        ...(dimensionsData ?? []),
      ]);
      setAttributeList(attributesData);
    }
  }, [selectedEventSchema]);

  const renderFields = (
    fields: Field[],
    removeField: (id: number) => void,
    title: string,
    name1: string,
    name2: string,
    isUnitShow = true
  ) => (
    <>
      {fields.map((field, i) => (
        <NewField
          title={title}
          key={field.id}
          id={field.id}
          removeField={removeField}
          isUnitShow={isUnitShow}
          name1={`${name1}-${i}`}
          name2={`${name2}-${i}`}
        />
      ))}
    </>
  );

  const handleEventSchemaChange = (e: any) => {
    if (e === null) {
      setCreatedEventSchema("");
    } else if (e === "newSchema") {
      setSelectedEventSchema("newSchema");
    } else {
      const event = events.find((event: any) => event.event_schema_id == e);
      setSelectedEventSchema(event);
    }
    setCreatedEventSchema("");
  };

  const handlePropertyChange = () => {
    setChangeProperty(!changeProperty);
  };
  const handleOperatorChange = () => {
    setChangeOperator(!changeOperator);
  };
  const handleUsageFeeExpressionChange = () => {
    setUsageFeeExpressionChange(!usageFeeExpressionChange);
  };

  return (
    <UsageBasedFeeFormContainer>
      {isLoading && <PageLoading />}
      <Form.Item
        label="Choose Usage Meter"
        name="usageMeterId"
        rules={[
          {
            required: true,
            message: "Please select usage meter",
          },
        ]}
      >
        <Select
          placeholder="Choose Usage Meter"
          onChange={handleUsageMeterValueChange}
          value={usageMeterValue}
        >
          {usageMeterOptions}
        </Select>
      </Form.Item>

      {usageMeterValue === "newUsage" && (
        <>
          <Form.Item
            label="Choose Event Schema"
            name="eventSchema"
            extra={
              <Extra text="Event Schema to be associated for this Usage Meter. This is optional." />
            }
          >
            <Select
              placeholder="Choose Event Schema"
              onChange={handleEventSchemaChange}
              showSearch
              allowClear
              filterOption={(input, option: any) =>
                option?.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {eventOptions}
            </Select>
          </Form.Item>
          {createdEventSchema?.name && (
            <EventSchemaDetailsCard>
              <div className="heading">
                <p className="title">EVENT SCHEMA</p>
              </div>
              <Divider className="divider" />
              <div className="details">
                <p className="name">{createdEventSchema.name}</p>
                {createdEventSchema?.attributes?.length > 0 && (
                  <div>
                    <p className="title" style={{ marginBottom: "8px" }}>
                      ATTRIBUTES
                    </p>
                    {createdEventSchema?.attributes?.map((item: any) => (
                      <div className="attributes">
                        <Tag color="blue">{item?.name}</Tag>
                        <Tag>{item?.default_unit}</Tag>
                      </div>
                    ))}
                  </div>
                )}
                {createdEventSchema?.dimensions?.length > 0 && (
                  <div>
                    <p className="title" style={{ marginBottom: "8px" }}>
                      DIMENSIONS
                    </p>
                    {createdEventSchema?.dimensions?.map((item: any) => (
                      <div className="dimensions">
                        <Tag color="blue">{item}</Tag>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </EventSchemaDetailsCard>
          )}
          {selectedEventSchema === "newSchema" && (
            <NestedCard>
              <Form form={form1}>
                <Form.Item
                  style={{ paddingBottom: "30px" }}
                  label="Event Schema Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input the Event Schema Name!",
                    },
                  ]}
                >
                  <Input placeholder="Event Schema Name" />
                </Form.Item>
                {renderFields(
                  attributeFields,
                  removeAttributeField,
                  "ATTRIBUTES",
                  "attribute",
                  "unit"
                )}
                <p
                  onClick={addAttributeField}
                  style={{ paddingTop: "20px" }}
                  className="addAction"
                >
                  <PlusOutlined /> Add Attribute
                </p>
                {renderFields(
                  dimensionFields,
                  removeDimensionsField,
                  "DIMENSIONS",
                  "dimensions",
                  "",
                  false
                )}

                <p onClick={addDimensionsField}>
                  <PlusOutlined /> Add Dimension
                </p>
                <CustomButton
                  text="Create Event Schema"
                  icon={<RightOutlined />}
                  position="end"
                  onClick={handleEventSchemaCreate}
                  isGrey={true}
                  loading={isLoading}
                />
              </Form>
            </NestedCard>
          )}

          <Form form={form2} initialValues={{ aggregationType: "SUM" }}>
            <NestedCard>
              <Form.Item
                label="Usage Meter Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input the Usage Meter Name!",
                  },
                ]}
              >
                <Input placeholder="Search Event Schema" />
              </Form.Item>

              <Form.Item
                label="Billable Name"
                name="billableName"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please input the billable name!",
                //   },
                // ]}
              >
                <Input placeholder="Billable Name" />
              </Form.Item>
              {/* {eventSchemaValue === "newSchema" && (
              <> */}
              {selectedEventSchema && (
                <>
                  <Form.Item label="Aggregation Type" name="aggregationType">
                    <StyledRadio
                      defaultValue={aggregationTypeValue}
                      onChange={aggregationTypeHandler}
                    >
                      <Radio value="SUM">Sum</Radio>
                      <Radio value="COUNT">Count</Radio>
                    </StyledRadio>
                  </Form.Item>

                  {addFilterFields.map((field, i) => (
                    <UsageFeeAddFilter
                      remove={removeFilterField}
                      key={i}
                      id={field.id}
                      name1={`chooseAttributeDimension-${field.id}`}
                      name2={`operator-${field.id}`}
                      name3={`value-${field.id}`}
                      attributeDimension={attributeDimension}
                      onChangeProperty={handlePropertyChange}
                      onChangeOperator={handleOperatorChange}
                    />
                  ))}
                  <p onClick={addFilterField} style={{ cursor: "pointer" }}>
                    <PlusOutlined /> Add Filter
                  </p>

                  {aggregationTypeValue === "SUM" && (
                    <>
                      <Form.Item
                        label="Choose Attribute"
                        name="chooseAttribute"
                        rules={[
                          {
                            required: true,
                            message: "Please select attribute!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Choose Attribute"
                          options={attributeList}
                        />
                      </Form.Item>

                      {addExpressionFields.map((field, i) => (
                        <PerUnitCalculationFilter
                          remove={removeExpressionField}
                          key={i}
                          id={field.id}
                          name1={`computationOperator-${field.id}`}
                          name2={`computationAttribute-${field.id}`}
                          attributeOption={attributeList}
                          onChange={handleUsageFeeExpressionChange}
                        />
                      ))}
                      <p
                        onClick={addExpressionField}
                        style={{ cursor: "pointer" }}
                      >
                        <PlusOutlined /> Add expression
                      </p>
                    </>
                  )}
                </>
              )}

              <CustomButton
                text="Create Usage Meter"
                icon={<RightOutlined />}
                position="end"
                onClick={handleUsageMeterCreate}
                isGrey={true}
                loading={isLoading}
              />
            </NestedCard>
          </Form>
        </>
      )}

      {createdUsageMeter?.name && (
        <EventSchemaDetailsCard>
          <div className="heading">
            <p className="title">USAGE METER</p>
          </div>
          <Divider className="divider" />
          <div className="details">
            <p className="name">{createdUsageMeter.name}</p>
            {createdUsageMeter?.aggregation && (
              <div>
                <p className="title" style={{ marginBottom: "8px" }}>
                  AGGREGATION TYPE
                </p>
                <div className="attributes">
                  <Tag color="blue">{createdUsageMeter?.aggregation}</Tag>
                </div>
              </div>
            )}
          </div>
        </EventSchemaDetailsCard>
      )}
      {/* {dummy && (
        <NestedCard title={"USAGE METER"}>
          <h3>asasas</h3>
          <p>AGGREGATION TYPE</p>
          <Tag color="blue">SUM</Tag>
        </NestedCard>
      )} */}
      <Form.Item
        label="Rate Card Name"
        name="displayName"
        rules={[
          {
            required: true,
            message: "Please input the rate card name!",
          },
        ]}
      >
        <Input placeholder="Rate Card Name" />
      </Form.Item>
      <GroupByTag />
    </UsageBasedFeeFormContainer>
  );
};

export default UsageBasedFeeForm;
