import { InfoCircleOutlined } from "@ant-design/icons";
import { Alert, Form, Input, Select } from "antd";
import { useState } from "react";
import { DependencyForm } from "./styles";

const DependencyDrawerContent = () => {
  const [settingType, setSettingType] = useState<string | null>("organization");
  const [settingId, setSettingId] = useState<string | null>(null);

  const handleTypeChange = (value: string) => {
    setSettingType(value);
  };
  const handleIdChange = (value: string) => {
    setSettingId(value);
  };

  const listTypeData = [
    { value: "organization", label: "Organization" },
    { value: "customer", label: "Customer" },
    { value: "account", label: "Account" },
  ];
  const listIdData = [
    { value: "a23sa", label: "a23sa" },
    { value: "a23svb", label: "a23svb" },
    { value: "a23sda", label: "a23sda" },
  ];

  return (
    <DependencyForm layout="vertical" requiredMark={false}>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Please input Name First!" }]}
      >
        <Input placeholder="Name" type="text" />
      </Form.Item>
      <Form.Item
        label="Setting Of"
        name="settingOf"
        rules={[{ required: true, message: "Please input Setting First!" }]}
      >
        <Select
          onChange={handleTypeChange}
          options={listTypeData}
          defaultValue={settingType}
        />
      </Form.Item>
      <Form.Item
        label="Setting ID"
        name="settingID"
        rules={[{ required: true, message: "Please Select Setting ID First!" }]}
      >
        {settingType === "organization" ? (
          <Select
            placeholder="Setting ID"
            onChange={handleIdChange}
            options={listIdData}
          />
        ) : (
          <Input placeholder="Setting ID" type="text" />
        )}
      </Form.Item>
      <Form.Item>
        <Alert
          className="queryAlert"
          message={
            <>
              <p style={{ margin: "0px" }}>Preview :</p>
              <p className="query">
                setting.USER.ORGANIZATION.organization_id{" "}
              </p>
            </>
          }
        />
      </Form.Item>
      <Form.Item>
        <Alert
          className="desctiptionAlert"
          message={
            <>
              <h6 className="descriptionHeader">What’s a Dependency?</h6>
              <p className="descriptionBody">
                If an enrichment depends on external logic/data/values, it can
                be defined here.
              </p>
            </>
          }
          type="warning"
          icon={<InfoCircleOutlined style={{ color: "#2853C2" }} />}
          showIcon
          style={{
            backgroundColor: "#F8FAFC",
            border: "none",
          }}
        />
      </Form.Item>
    </DependencyForm>
  );
};

export default DependencyDrawerContent;
