import React, { useMemo, useState } from "react";
import moment from "moment";
import { Card, Col, Row, Space, Tag, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  ArrowDownOutlined,
  CalendarOutlined,
  CloseOutlined,
  DownloadOutlined,
  EditOutlined,
  RightOutlined,
} from "@ant-design/icons";
import CustomButton from "../../../../../components/common/button";
import { ScheduleData } from "../Styles";
import { StyledCard } from "./Styles";
import CalendarCard from "../CalendarCard";
import { AppDispatch } from "../../../../../redux/store";
import { setViewAttachPricePlan } from "../../../../../redux/feature/pricePlan/pricePlanSlice";

const ScheduleCard: React.FC<any> = ({
  attachPricePlan,
  isSummary = false,
  isNavigatable = false,
  showPast=false
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { customerId, accountId } = useParams();

  const sortedPlans = useMemo(() => {
    const statusOrder = ["Expired","Ongoing", "Upcoming"];
    const getStatus = (plan: any) => {
      const currentDate = moment();
      if (currentDate.isBefore(moment(plan.effective_from))) {
        return "Upcoming";
      } else if (
        currentDate.isBetween(
          moment(plan.effective_from),
          moment(plan.effective_until)
        )
      ) {
        return "Ongoing";
      } else {
        return "Expired";
      }
    };

    const sortedPricePlan = attachPricePlan
      ? attachPricePlan
          .slice()
          .filter((plan: any) => showPast || getStatus(plan) !== "Expired") // Include Expired when showAlsoPast is true
          .sort((a: any, b: any) => {
            const statusA = getStatus(a);
            const statusB = getStatus(b);
            return statusOrder.indexOf(statusA) - statusOrder.indexOf(statusB);
          })
      : [];

    return sortedPricePlan;
  }, [attachPricePlan, showPast]);

  return sortedPlans?.map((plan: any) => {
    const currentDate = moment();
    const status = currentDate.isBefore(moment(plan.effective_from))
      ? "Upcoming"
      : currentDate.isBetween(
          moment(plan.effective_from),
          moment(plan.effective_until)
        )
      ? "Ongoing"
      : "Expired";

    return (
      <Col
        span={24}
        key={plan?.price_plan_attach_id}
        style={{
          display: "flex",
          gap: "1rem",
          padding: "0",
          height: "max-content",
        }}
        onClick={() => {
          if (isNavigatable) {
            navigate(
              `/customers/${customerId}/accounts/${accountId}/price-plan-schedule/${plan?.price_plan_attach_id}`
            );
            dispatch(setViewAttachPricePlan(plan));
          } else {
            return;
          }
        }}
      >
        {!isSummary && <CalendarCard startData={plan.effective_from} status={status} />}
        <StyledCard className="detailCard">
          <div className="orderDetailContainer">
            <div className="orderDetailHeader">
              <div className="title">
                <Space>
                  <h3>{plan?.priceplanname}</h3>
                  {status !== "Expired" && (
                    <Tag color={status === "Ongoing" ? "green" : "geekblue"}>
                      • {status} Plan
                    </Tag>
                  )}
                  {plan?.isOverRide && <Tag color="gold"> • Overridden</Tag>}
                </Space>
                <Space>
                  <Tooltip title="Download Schedule">
                    <div className="icon">
                      <DownloadOutlined />
                    </div>
                  </Tooltip>
                  {isSummary ? (
                    <CustomButton
                      icon={<RightOutlined />}
                      onClick={() => {
                        navigate(
                          `/customers/${customerId}/accounts/${accountId}/price-plan-schedule`
                        );
                      }}
                      stopPropagation={true}
                      text="Manage Schedule"
                      type="default"
                      position="end"
                      isGrey={true}
                    />
                  ) : (
                    <>
                      {status !== "Expired" && (
                        <Tooltip title="Detach Plan">
                          <CustomButton
                            text="Detach Plan"
                            icon={<CloseOutlined />}
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent parent onClick
                              // Add detach logic here
                            }}
                            position="end"
                            type="default"
                          />
                        </Tooltip>
                      )}
                      {status === "Upcoming" && (
                        <Tooltip title="Edit Schedule">
                          <div
                            style={{ position: "relative", zIndex: "99999" }}
                          >
                            <CustomButton
                              text={"Edit Schedule"}
                              icon={<EditOutlined />}
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent parent onClick
                                navigate(
                                  `/customers/${customerId}/accounts/${accountId}/edit-schedule/${plan?.price_plan_attach_id}`
                                );
                              }}
                              position="end"
                            />
                          </div>
                        </Tooltip>
                      )}
                    </>
                  )}
                </Space>
              </div>
              <div className="orderDetailItem">
                <p className="version">Version : 1</p>
              </div>
              <div className="orderDetailItem">
                <ScheduleData>
                  <p>
                    <CalendarOutlined />
                  </p>
                  <p>{moment(plan.effective_from).format("MMM D, YYYY")}</p>
                  <p>
                    <ArrowDownOutlined rotate={270} />
                  </p>
                  <p>{moment(plan.effective_until).format("MMM D, YYYY")}</p>
                </ScheduleData>
              </div>
            </div>
          </div>
          <Row gutter={[20, 0]} className="cardContainer">
            <Col span={6}>
              <Card className="cardItem">
                <p className="cardTitle">Pricing Cycle</p>
                <p>{plan.pricing_cycle_interval ?? "-"}</p>
              </Card>
            </Col>
            <Col span={6}>
              <Card className="cardItem">
                <p className="cardTitle">Cycle Start Month</p>
                <p>{plan.start_month_offset ?? "-"}</p>
              </Card>
            </Col>
            <Col span={6}>
              <Card className="cardItem">
                <p className="cardTitle">Cycle Start Date</p>
                <p>{plan.start_day_offset ?? "-"}</p>
              </Card>
            </Col>
            <Col span={6}>
              <Card className="cardItem">
                <p className="cardTitle">Grace Period</p>
                <p>{plan.grace_period ?? "-"}</p>
              </Card>
            </Col>
          </Row>
        </StyledCard>
      </Col>
    );
  });
};

export default ScheduleCard;
