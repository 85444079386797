import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import api from "../../../api/apiClient";
import { message } from "antd";

export const getAllCustomer = createAsyncThunk(
  "customer/getAllCustomer",
  async (filterData: any, { rejectWithValue }) => {
    const { sort, search } = filterData;
    try {
      const response = await api.get(
        `/get-customers?sort=${sort}${search ? `&search=${search}` : ""}`
      );
      return response.data.customers;
    } catch (error: any) {
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const getAllCustomerAccounts = createAsyncThunk(
  "customer/getAllCustomerAccounts",
  async (filterData: any, { rejectWithValue }) => {
    const { sort, search } = filterData;
    try {
      const response = await api.get(
        `/get-customer-accounts?sort=${sort}${
          search ? `&search=${search}` : ""
        }`
      );
      return response.data.data;
    } catch (error: any) {
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const createCustomer = createAsyncThunk(
  "customer/createCustomer",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await api.post("/create-customer", payload);
      message.success("Customer created successfully");
      return response.data;
    } catch (error: any) {
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const editCustomer = createAsyncThunk(
  "customer/editCustomer",
  async (payload: any, { rejectWithValue }) => {
    console.log("►►► ~ payload:", payload);
    try {
      const response = await api.post("/update-customer", payload);
      message.success("Customer update successfully");

      return {
        name: payload.name,
        email: payload.email,
        phone: payload.phoneNumber,
        address_1: payload.line1,
        address_2: payload.line2,
        postal_code: payload.postalCode,
        city: payload.city,
        state: payload.state,
        country: payload.country,
        customer_id: payload.customer_id,
      };
    } catch (error: any) {
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);
export const deleteCustomer = createAsyncThunk(
  "customer/deleteCustomer",
  async (customerId: number, { rejectWithValue }) => {
    try {
      const response = await api.delete(
        `/delete-customer?customerId=${customerId}`
      );
      message.success("Customer deleted successfully");

      return customerId;
    } catch (error: any) {
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const deleteCustomerAccount = createAsyncThunk(
  "customer/deleteCustomerAccount",
  async (accountId: number, { rejectWithValue }) => {
    try {
      const response = await api.delete(
        `/delete-customer-account?customer_account_id=${accountId}`
      );
      message.success("Customer Account deleted successfully");

      return accountId;
    } catch (error: any) {
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);
export const editCustomerAccount = createAsyncThunk(
  "customer/editCustomerAccount",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await api.post(`/update-customer-account`, payload);
      message.success("Customer Account update successfully");

      return payload;
    } catch (error: any) {
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);
export const getCustomerById = createAsyncThunk(
  "customer/getCustomerById",
  async (filterData: any, { rejectWithValue }) => {
    const { customer_id, search } = filterData;
    try {
      const response = await api.get(
        `get-customer?customerId=${customer_id}${
          search ? `&search=${search}` : ""
        }`
      );
      console.log("►►► ~ response:", response);
      const customer = {
        ...response.data.customer,
        customerAccount: response.data.customerAccount,
      };
      return customer;
    } catch (error: any) {
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const createCustomerAccount = createAsyncThunk(
  "customer/createCustomerAccount",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await api.post("/create-customer-account", payload);
      message.success("Customer Account created successfully");
      return response.data;
    } catch (error: any) {
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const getAccountById = createAsyncThunk(
  "customer/getAccountById",
  async (accountId: any, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `get-customer-account?customerAccountId=${accountId}`
      );
      console.log("🚀 ~ file: pricePlanThunk.ts:105 ~ response:", response);
      return response.data.data;
    } catch (error: any) {
      console.log("🚀 ~ file: pricePlanThunk.ts:108 ~ error:", error);
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const createCredit = createAsyncThunk(
  "customer/createCredit",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await api.post(`credits`, payload);
      message.success("Credit created successfully");
      return response.data;
    } catch (error: any) {
      console.log("🚀 ~ file: pricePlanThunk.ts:108 ~ error:", error);
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const getCreditById = createAsyncThunk(
  "customer/getCreditById",
  async (creditId: any, { rejectWithValue }) => {
    try {
      const response = await api.get(`credits?customer_account_id=${creditId}`);
      console.log("🚀 ~ file: pricePlanThunk.ts:105 ~ response:", response);
      return response.data.data;
    } catch (error: any) {
      console.log("🚀 ~ file: pricePlanThunk.ts:108 ~ error:", error);
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const addedPayment = createAsyncThunk(
  "customer/addedPayment",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await api.post(`payments`, payload);
      message.success("Payment Added successfully");
      return response.data;
    } catch (error: any) {
      console.log("🚀 ~ file: pricePlanThunk.ts:108 ~ error:", error);
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);

export const getWallet = createAsyncThunk(
  "customer/getWallet",
  async (accountId: any, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `payments?customer_account_id=${accountId}`
      );
      return response.data.data;
    } catch (error: any) {
      console.log("🚀 ~ file: pricePlanThunk.ts:108 ~ error:", error);
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);
