import { Card } from "antd";
import Modal from "../../../../components/common/modal";
import { ReingestContainer } from "../styles";
import JsonPayload from "./jsonPayload";
import ModalHeaderTitle from "../../../../components/common/modalHeaderTitle";

const ReingestModal: React.FC<{selectedIngest:any, open: boolean; close: () => void }> = ({
  open,
  close,
  selectedIngest
}) => {
  return (
    <Modal
      title={
        <ModalHeaderTitle
          title="Reingest Event with Payload"
          subtitle="This will revert all the changes caused by this event and reingest
            it with this payload. You can edit the payload before reingesting."
        />
      }
      isOpen={open}
      onClose={close}
      successBtnTitle="Reingest"
    >
      <ReingestContainer>
        <div className="propertyCardContainer">
          <Card>
            <p>ID</p>
            <h3>{selectedIngest?.billoptim_ingest_event_id}</h3>
          </Card>
          <Card className="accId">
            <p>ACCOUNT ID</p>
            <h3>{selectedIngest?.account_id}</h3>
          </Card>
        </div>
        <JsonPayload height="280px" editable={true} selectedIngest={selectedIngest} />
      </ReingestContainer>
    </Modal>
  );
};

export default ReingestModal;
