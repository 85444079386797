import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { refreshToken, removeAuthToken, removeRefreshToken } from '../utils/localStorage';
import { getAuthToken } from '../utils/localStorage';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (config: any)  => {
    const token = getAuthToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    console.log("🚀 ~ file: apiClient.tsx:31 ~ error:", error)
    const originalRequest = error.config as any; 
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const newToken = await refreshToken(); 
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
        return api(originalRequest);
      } catch (err) {
        console.log("🚀 ~ file: apiClient.tsx:38 ~ err:", err)
        removeAuthToken()
        removeRefreshToken()
        window.location.href = '/login';
        return Promise.reject(err);
      }
    }
    
    return Promise.reject(error);
  }
);

export default api;