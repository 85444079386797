import { Form, Select } from "antd";
import Modal from "../../../../components/common/modal";
import ModalHeaderTitle from "../../../../components/common/modalHeaderTitle";
import { useState } from "react";

const dummyTemplate = [
  { value: "perUnit", label: "Per Unit" },
  { value: "Flat", label: "Flat" },
];
type InvoiceTemplatePropType = {
  isOpen: boolean;
  onClose: () => void;
  modalFor?: string;
};
const InvoiceTemplate: React.FC<InvoiceTemplatePropType> = ({
  isOpen,
  onClose,
  modalFor = "group",
}) => {
  const [template, setTemplate] = useState("");

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <ModalHeaderTitle
          title={`Invoice Template for ${modalFor}`}
          subtitle={`the chosen template will be used to generate future invoice pdfs for this ${modalFor}`}
        />
      }
    >
      <Form
        layout="vertical"
        requiredMark={false}
        style={{ marginTop: "20px" }}
      >
        <Form.Item label="Choose Template" name="type">
          <Select
            onChange={setTemplate}
            options={dummyTemplate}
            placeholder="Choose Template"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default InvoiceTemplate;
