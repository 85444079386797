import { Form } from "antd";
import styled from "styled-components";

export const StyledForm = styled.div`
width: 100%;

.flexible{
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}
.flexItem{
  width: 50%;
}

`;