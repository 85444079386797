import { Divider } from "antd";
import styled from "styled-components";

export const ProfileContainer = styled.div`
& .description{
  padding: 1.5rem;
  box-shadow: 0px 2px 8px 0px #cbd5e1 ;
  border-radius: 20px;
  background-color: white;

    h5{
      color: #64748b;
      font-weight: 400;
      font-size: 12px;
      letter-spacing: .1em;
    }
}

& .btn-text{
  font-size: 1rem;
}

& .basicInfoContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Rubik;


.infoDescription{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
  .profile-details__title{
    color: #64748b;
    line-height: 14px;
    font-weight: 400;
    font-size: 12px;
  } 
  .profile-details__value{
    line-height: 17px;
    font-weight: 500;
    font-size: 14px;
  }
}

& .divider{
  border-top: 1px dashed rgba(0,0,0,0.2);
}

`

export const CustomDivider = styled(Divider)`
    margin: 16px 0 !important;
    

`
