import styled from "styled-components";

export const BillingContainer = styled.div`
    padding: 0.5rem 0;
    .billingHeader , .tableHeader , .actions{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
    }
    .actions{
      gap: 16px;
      margin-bottom: 0 !important;

        .ant-form-item{
          margin-bottom: 0px;
        }
        /* .ant-picker{
          margin-bottom: 0 !important;
        } */
    }
    .count{
      color: #64748b;
      margin-left: 5px;
    }
  
`;