import { Form, Input, Select } from "antd";

type UsageMeterFieldsPropsType = {
  usageMeters: any;
};

const UsageMeterFields: React.FC<UsageMeterFieldsPropsType> = ({
  usageMeters,
}) => {
  return (
    <>
      <Form.Item
        label="Usage Meter"
        name="usage_meter_id"
        rules={[
          {
            required: true,
            message: "Please Select Usage Meter First!",
          },
        ]}
      >
        <Select size="large" placeholder="Usage Meter">
          {(usageMeters ?? []).map((usageMeter: any) => {
            return (
              <Select.Option
                key={usageMeter?.usageMeterId}
                value={usageMeter?.usageMeterId}
              >
                {usageMeter?.name}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label="Usage"
        name="usage"
        rules={[{ required: true, message: "Please input Usage First!" }]}
      >
        <Input placeholder="Usage" type="text" />
      </Form.Item>
    </>
  );
};

export default UsageMeterFields;
