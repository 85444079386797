import React, { useEffect, useState } from "react";
import { Container } from "../BillingDetails/Styles";
import { PricingRulesHeader } from "./Styles";
import { EmptyDataComponent } from "../../../../components/common/empty";
import { MoreOutlined, PlusOutlined } from "@ant-design/icons";
import EditPlan from "../RateCard/EditPlan";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { setPlanModalDetail } from "../../../../redux/feature/pricePlan/pricePlanSlice";
import EditPricingRule from "./EditPricingRule";
import { Dropdown, Table } from "antd";
import { StyledTable } from "../../../../components/common/table/styles";
import CustomButton from "../../../../components/common/button";

const PricingRules: React.FC<any> = ({ isAttachPriceRules = false }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [pricingRules, setPricingRules] = useState([]);
  const [editRule, setEditRule] = useState("");
  const { billingDetails } = useSelector((state: any) => state.pricePlan);

  const handleRuleRemove = (record: any) => {
    console.log("🚀 ~ file: index.tsx:59 ~ handleCardRemove ~ e:", record);
    const data = pricingRules.filter((rule: any) => rule.id === record.id);
    setPricingRules(data);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Invoice Timing",
      dataIndex: "invoiceTiming",
    },
    {
      title: "Apply On",
      dataIndex: "applyOn",
    },
    {
      title: "",
      dataIndex: "action",
      render: (_: any, record: any) => {
        const items = [
          {
            key: "1",
            label: <span>Edit Rate Card</span>,
            onClick: () => {
              setEditRule(record);
              dispatch(setPlanModalDetail("Pricing Rule"));
            },
          },
          {
            key: "2",
            label: <span>Delete Rate Card</span>,
            onClick: () => handleRuleRemove(record),
          },
        ];
        return (
          <Dropdown placement="bottom" menu={{ items }}>
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  useEffect(() => {
    setPricingRules(billingDetails?.pricingRules ?? []);
  }, [billingDetails]);

  const RateCardDropDown = () => {
    return (
      <CustomButton
        type="primary"
        icon={<PlusOutlined />}
        position="start"
        text=" Add Pricing Rule"
        onClick={() => {
          dispatch(setPlanModalDetail("Pricing Rule"));
        }}
      />
    );
  };
  return (
    <Container>
      <PricingRulesHeader>
        <div className="title">PRICING RULES</div>
        <div className="rateCardLeftHeader">
          <div
            className="addRateCard"
            onClick={() => {
              dispatch(setPlanModalDetail("Pricing Rule"));
            }}
          >
            <PlusOutlined />
            Add Pricing Rule
          </div>
        </div>
      </PricingRulesHeader>
      <div style={{ padding: "16px 24px" }}>
        {pricingRules.length || isAttachPriceRules ? (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div>
              <StyledTable
                pagination={false}
                columns={columns}
                dataSource={pricingRules}
                size="middle"
              />
            </div>
          </div>
        ) : (
          <EmptyDataComponent
            addButton={RateCardDropDown}
            title=" No Pricing Rules configured"
            description="You can set rules for usage or revenue computation for a rate card conditionally.."
          />
        )}
        <EditPricingRule
          setPricingRules={setPricingRules}
          pricingRules={pricingRules}
          editRule={editRule}
        />
      </div>
    </Container>
  );
};

export default PricingRules;
