import { Button, Card, Tag, Typography } from "antd";
import { PropertiesContainer } from "./styles";
import RestValueItem from "./RestValueItem";
import moment from "moment";
import { ArrowDownOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { StyledCard } from "../../../schemas/enrichValueItemcard/styles";

const { Title, Paragraph } = Typography;
const Properties: React.FC<{ propertyData: { [key: string]: any } }> = ({
  propertyData,
}) => {
  const dummyData: any = [
    // { field: "test1", value: "11" },
    // { field: "d1", value: "11" },
    // { field: "schemaName", value: "dummy" },
  ];

  const attributeAndDimensionData = [
    propertyData?.attributes.map((item: any) => {
      return { field: item?.attribute_name, value: item?.attribute_value };
    }),
    propertyData?.dimensions.map((item: any) => {
      return { field: item?.dimension_name, value: item?.dimension_value };
    }),
  ].flat();

  return (
    <PropertiesContainer>
      {propertyData.event_status === "UNPROCESSED" ? (
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <div>
            <div className="title">Event Time</div>
            <div>{propertyData?.timestamp}</div>
          </div>
          <div>
            <div className="title">Status</div>
            <div>{propertyData?.event_status}</div>
          </div>
          <div>
            <div className="title">Reason</div>
            <div>{propertyData?.event_status_reason}</div>
          </div>
          <StyledCard
            className="event-details__info"
            style={{ border: "1px solid #e8e8e8" }}
            
          >
            <div className="event-details__info-issue-box">
              <div className="event-details__info-head">
                <InfoCircleOutlined />
                <div>Learn More</div>
              </div>
              <div
                className="line"
                style={{ borderTop: "1px solid #e8e8e8", margin: "8px 0" }}
              />
              <div
                className="text-body1-regular"
                style={{ color: "#3f4d67" }}
              >
                Check documentation for more info!
              </div>
              <div className="event-details__info-head">
                <Button
                  type="link"
                  className="cursor-pointer base-link"
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                   Go to reference document
                  <ArrowDownOutlined
                    style={{ transform: "rotate(-90deg)", color: "#64748b" }}
                  />
                </Button>
              </div>
            </div>
          </StyledCard>
        </div>
      ) : (
        <>
          <div className="propertyCardContainer">
            <Card>
              <p>EVENT TIME</p>
              <h3>{`${moment(propertyData?.timestamp).format(
                "DD MMM YYYY HH:MM"
              )} UTC `}</h3>
            </Card>
            <Card>
              <p>MARKED FOR REVENUE</p>
              <Tag
                color={
                  propertyData?.event_status === "PROCESSED"
                    ? "green"
                    : "orange"
                }
              >
                {" "}
                • {propertyData?.event_status === "PROCESSED"
                  ? "YES"
                  : "NO"}{" "}
              </Tag>
            </Card>
          </div>
          <RestValueItem
            title="ATTRIBUTES & DIMENSIONS:"
            data={attributeAndDimensionData}
            columns={["item", "value"]}
            emptyMessage="No Attributes And Dimensions Found"
          />
          <RestValueItem
            title="ENRICHED VALUES:"
            data={dummyData}
            columns={["item", "value"]}
            emptyMessage="No Enriched Values Found"
          />
          <RestValueItem
            title="DEPENDENCIES:"
            data={dummyData}
            columns={["item", "value"]}
            emptyMessage="No Dependencies Found"
          />
          <RestValueItem
            title="RATED VALUES:"
            data={dummyData}
            columns={["Name", "Units"]}
            emptyMessage="No Rated Values Found"
            isCopyShow={false}
          />
        </>
      )}
    </PropertiesContainer>
  );
};

export default Properties;
