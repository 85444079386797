import React, { useCallback, useEffect, useMemo, useState } from "react";
import Modal from "../../../../../components/common/modal";
import { PlanModal } from "../../../../price-plans/NewPricePlan/RateCard/Styles";
import { Col, Form, Input, Row, Select, Steps } from "antd";
import CustomButton from "../../../../../components/common/button";
import ModalHeaderTitle from "../../../../../components/common/modalHeaderTitle";
import ActionCard from "./ActionCard";
import { PlusOutlined } from "@ant-design/icons";
import { UsePaymentContainer } from "../styles";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import {
  addedPayment,
  getWallet,
} from "../../../../../redux/feature/customer/customerAsyncThunk";
import PageLoading from "../../../../../components/Loader";
import { setIsAddPaymentModalOpen } from "../../../../../redux/feature/customer/customerSlice";
import { useParams } from "react-router-dom";

type AddPaymentModalPropType = {
  isOpen: boolean;
};

interface Field {
  id: number;
}

const AddPaymentModal: React.FC<AddPaymentModalPropType> = ({ isOpen }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const [paymentDetails, setPaymentDetails] = useState<any>();
  const [totalAmountAdded, setTotalAmountAdded] = useState<Number>(0);
  const [debitAmount, setDebitAmount] = useState<any[]>([]);
  const { accountId } = useParams();

  const { payments, loading, isAddPaymentModalOpen } = useSelector(
    (state: RootState) => state.customer
  );

  const { AccountCurrency } = useSelector(
    (state: RootState) => state.adminCenter
  );

  const updateCurrent = async (value: "next" | "prev") => {
    try {
      if (value === "next") {
        const values = await form.validateFields();
        setPaymentDetails(values);
      }

      setCurrent((prev) => (value === "next" ? prev + 1 : prev - 1));
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const currencyCode = useMemo(() => {
    return AccountCurrency.find((currency: any) => currency.is_base);
  }, [AccountCurrency]);

  const [actionFields, setActionFields] = useState<Field[]>([{ id: 9999 }]);
  const handleStepChange = async (e: number) => {
    try {
      if (e === 1) {
        const values = await form.validateFields();
        setPaymentDetails(values);
      }
      setCurrent(e);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };
  const remainingAmount = useMemo(() => {
    const totalDebit = Object.values(debitAmount).reduce(
      (acc, amount) => acc + Number(amount),
      0
    );

    return Number(totalAmountAdded) - Number(totalDebit);
  }, [debitAmount, totalAmountAdded]);

  const renderFields = (
    fields: Field[],
    removeField: (id: number, nameKey: number) => void
  ) => {
    return fields.map((field, i) => (
      <ActionCard
        key={field.id}
        id={field.id}
        onDelete={removeField}
        nameKey={field.id}
        currencyCode={currencyCode}
        debitAmount={debitAmount}
        setDebitAmount={setDebitAmount}
      />
    ));
  };

  const addActionField = useCallback(
    () => setActionFields((prevFields) => [...prevFields, { id: Date.now() }]),
    [actionFields]
  );

  const removeActionField = useCallback(
    (id: number, nameKey: number) => {
      setActionFields((prevFields) => {
        const updatedFields = prevFields.filter((field) => field.id !== id);
        form.resetFields([
          `type-${nameKey}`,
          `amount-${nameKey}`,
          `invoice_id-${nameKey}`,
        ]);

        return updatedFields.map((field, index) => ({
          ...field,
          id: field.id,
        }));
      });

      setDebitAmount((prev: any) => {
        const newState = { ...prev };
        delete newState[id];
        return newState;
      });
    },
    [form] // Dependencies
  );

  const handleOnSubmit = async () => {
    try {
      const values = await form.validateFields();

      const lineItemRecords = Object.keys(values)
        .filter((key) => key.startsWith("type"))
        .map((key, index) => {
          const valuesIndex = key.split("-")[1];
          return {
            order: index,
            amount: Number(values[`amount-${valuesIndex}`]),
            type: values[`type-${valuesIndex}`] ?? "INVOICE",
            togaiId: String(values[`invoice_id-${valuesIndex}`])
          };
        });
      const payload = {
        accountId: Number(accountId),
        description: paymentDetails.description,
        notes: paymentDetails.notes,
        totalAmount: paymentDetails.totalAmount,
        externalPaymentReference: {
          sourceName: paymentDetails.sourceName,
          sourceType: paymentDetails.sourceType,
          transactionNumber: paymentDetails.transactionNumber,
          description: paymentDetails.transaction_description,
        },
        lineItemRecords,
      };

      await dispatch(addedPayment(payload));
      handleModalClose();
      await dispatch(getWallet(accountId));
    } catch (error) {
      console.log("►►► ~ handleOnSubmit ~ error:", error);
    } finally {
    }
  };

  const rules = useMemo(() => {
    return [{ required: true, message: "Field is required!" }];
  }, []);

  const handleModalClose = () => {
    form.resetFields();
    setCurrent(0);
    setActionFields([{ id: 9999 }]);
    setDebitAmount([]);
    setTotalAmountAdded(0);
  };

  return (
    <Modal
      isOpen={isOpen}
      loading={loading}
      onClose={() => {
        handleModalClose();
        dispatch(setIsAddPaymentModalOpen(!isAddPaymentModalOpen));
      }}
      width="620px"
      isGoBackButtonShown={current === 0 ? false : true}
      successBtnTitle={current === 1 ? "Submit" : "Proceed"}
      onSubmit={() =>
        current === 1 ? handleOnSubmit() : updateCurrent("next")
      }
      onGoBackBtnClick={() => updateCurrent("prev")}
      title={
        <ModalHeaderTitle
          title="Add Payment"
          subtitle={
            <Steps
              className="steps"
              size="small"
              current={current}
              items={[
                {
                  title: "Record payment details",
                },
                {
                  title: "Use payment for invoice payments/credits",
                },
              ]}
              onChange={handleStepChange}
              style={{ width: "100%" }}
            />
          }
        />
      }
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={{ [`type-0`]: "INVOICE" }}
      >
        {current === 0 ? (
          <Row style={{ paddingTop: "1rem" }}>
            <Col span={16}>
              <Form.Item
                label={`Amount (${currencyCode?.currency_code})`}
                name="totalAmount"
                rules={rules}
              >
                <Input
                  type="number"
                  placeholder="Amount in USD"
                  onChange={(e: any) => {
                    setTotalAmountAdded(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item label="Description" name="description">
                <Input.TextArea placeholder="Description" />
              </Form.Item>
              <Form.Item label="Source Name" name="sourceName" rules={rules}>
                <Input placeholder="Source Name" />
              </Form.Item>
              <Form.Item
                label="Transaction Mode"
                name="sourceType"
                rules={rules}
              >
                <Select placeholder="Transaction Mode">
                  <Select.Option value={"CHEQUE"}>Cheque</Select.Option>
                  <Select.Option value={"CARD"}>Card</Select.Option>
                  <Select.Option value={"CASH"}>Cash</Select.Option>
                  <Select.Option value={"BANK_TRANSFER"}>
                    UPI/IMPS/NEFT/RTGS
                  </Select.Option>
                  <Select.Option value={"GATEWAY"}>Gateway</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Transaction/Cheque Number"
                name="transactionNumber"
                rules={rules}
              >
                <Input placeholder="Transaction/Cheque Number" />
              </Form.Item>
              <Form.Item
                label="Transaction Description"
                name="transaction_description"
              >
                <Input.TextArea placeholder="Transaction Description" />
              </Form.Item>
              <Form.Item label="Note" name="notes">
                <Input.TextArea placeholder="Note" />
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <UsePaymentContainer>
            <div className="availableAmount">
              <p>
                Available Amount:{" "}
                <span className="amount">{remainingAmount}</span>
              </p>
            </div>

            {renderFields(actionFields, removeActionField)}

            <div className="add-action" onClick={addActionField}>
              <PlusOutlined /> <span>Add Action</span>
            </div>
          </UsePaymentContainer>
        )}
      </Form>
    </Modal>
  );
};

export default AddPaymentModal;
