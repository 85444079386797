import { Card } from "antd";
import styled from "styled-components";

export const AccountDetails= styled.div`
display: flex;
flex-direction:column;
gap: 10px;
`

export const CardContainer=styled(Card)`

.card_body
{
    display: flex;
    flex-direction: column;
    gap: 10px;

}
.card_header{
    font-weight: 600;
}

.card-details{
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.item{
    display: flex;
    justify-content: space-between;
}

`