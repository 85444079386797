import styled from "styled-components";

export const MOdalTitleContainer = styled.div`

  display: flex;
  flex-direction: column;
  gap: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  .description {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    word-break: break-word;
    color: #64748b;
  }
`;

export const ModalBodyContainer = styled.div`
  padding: 24px 0;
`;
