// src/features/auth/authSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createAdditionalCurrency,
  createBaseCurrency,
  createBillingInfo,
  deleteBillingInfo,
  deleteUserToken,
  generateNewToken,
  getAccountCurrency,
  getAllCountry,
  getAllToken,
  getBillingInfo,
  getCurrencies,
  updateTokenStatus,
} from "./adminCenterAsyncThunk";

export interface userToken {
  authToken: any[];
  loading: boolean;
}
export interface AdminCenterState {
  currencies: any[];
  loading: boolean;
  userToken: userToken;
  newApiToken: any | undefined;
  alias: any[];
  country: any[];
  AccountCurrency: any[];
  isCurrencyModalOpen: boolean;
  billingInfo: any[];
}

const initialState: AdminCenterState = {
  currencies: [],
  loading: false,
  userToken: {
    authToken: [],
    loading: false,
  },
  newApiToken: undefined,
  alias: [],
  country: [],
  AccountCurrency: [],
  isCurrencyModalOpen: false,
  billingInfo: [],
};

const adminCenterSlice = createSlice({
  name: "adminCenter",
  initialState,
  reducers: {
    setRemoveToken: (state, action) => {
      state.newApiToken = action.payload;
    },

    addAlias: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.alias = [...state.alias, ...action.payload];
      } else {
        state.alias = [...state.alias, action.payload];
      }
    },

    setAlias: (state, action) => {
      state.alias = action.payload;
    },
    removeAlias: (state, action) => {
      state.alias = state.alias.filter(
        (alias) => alias.alias_id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrencies.fulfilled, (state, action) => {
        state.currencies = action.payload;
      })
      .addCase(getAccountCurrency.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAccountCurrency.fulfilled, (state, action) => {
        state.loading = false;
        state.AccountCurrency = action.payload;
        if (!state.AccountCurrency.find((a: any) => a.is_base)) {
          state.isCurrencyModalOpen = true;
        }
      })
      .addCase(getAccountCurrency.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(generateNewToken.pending, (state) => {
        state.userToken.loading = true;
      })
      .addCase(generateNewToken.fulfilled, (state, action: any) => {
        const newToken = {
          ...action.payload,
          is_active: true,
        };

        state.userToken.authToken = [...state.userToken.authToken, newToken];
        state.newApiToken = action.payload;
        state.userToken.loading = false;
      })
      .addCase(generateNewToken.rejected, (state, action) => {
        state.userToken.loading = false;
      })
      .addCase(getAllToken.pending, (state) => {
        state.userToken.loading = true;
      })
      .addCase(getAllToken.fulfilled, (state, action: any) => {
        state.userToken.authToken = action.payload;
        state.userToken.loading = false;
      })
      .addCase(getAllToken.rejected, (state, action) => {
        state.userToken.loading = false;
      })
      .addCase(updateTokenStatus.fulfilled, (state, action: any) => {
        const authToken = state.userToken.authToken;
        const updatedAuthToken = authToken.map((token) =>
          token.token_id === action.payload.tokenId
            ? { ...token, is_active: action.payload.status }
            : token
        );
        state.userToken.authToken = updatedAuthToken;
      })
      .addCase(deleteUserToken.fulfilled, (state, action: any) => {
        const tokenIdToDelete = action.payload;
        const updatedAuthToken = state.userToken.authToken.filter(
          (token) => token.token_id !== tokenIdToDelete
        );
        state.userToken.authToken = updatedAuthToken;
      })
      .addCase(getAllCountry.fulfilled, (state, action: any) => {
        state.country = action.payload;
      })
      .addCase(createBaseCurrency.fulfilled, (state, action: any) => {
        state.isCurrencyModalOpen = false;
      })
      .addCase(createBillingInfo.fulfilled, (state, action) => {
        if (action.payload.isDefault) {
          state.billingInfo = state.billingInfo.map((info) => ({
            ...info,
            isDefault: false,
          }));
        }
        state.billingInfo = [action.payload, ...state.billingInfo,];
      })
      .addCase(getBillingInfo.fulfilled, (state, action) => {
        state.billingInfo = action.payload.map((item: any) => { return { ...item, country: item.address.country } });
      })
      .addCase(deleteBillingInfo.fulfilled, (state, action) => {
        const billingIdDelete = action.payload;
        const updatedBillingInfo = state.billingInfo.filter(
          (bill) => bill.billingInformationId !== billingIdDelete
        );
        state.billingInfo = updatedBillingInfo;
      });
  },
});

export const { setRemoveToken } = adminCenterSlice.actions;

export const { addAlias, setAlias, removeAlias } = adminCenterSlice.actions;

export const adminCenterReducer = adminCenterSlice.reducer;
