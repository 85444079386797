import { Editor } from "@monaco-editor/react";
import { Form, Input, Select } from "antd";
import { useState } from "react";

const NewSettingForm = () => {
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [code, setCode] = useState("");

  const handleChange = (value: string) => {
    setSelectedType(value);
  };
  const handleEditorChange = (value: string | undefined) => {
    if (value !== undefined) {
      setCode(value);
    }
  };

  const listData = [
    { value: "json", label: "JSON" },
    { value: "j-logic", label: "Json Logic" },
    { value: "num", label: "Numeric" },
    { value: "str", label: "String" },
  ];

  return (
    <Form layout="vertical" requiredMark={false} style={{ padding: "20px" }}>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Please input Name First!" }]}
      >
        <Input placeholder="Name" type="text" />
      </Form.Item>
      <Form.Item
        label="ID"
        name="id"
        rules={[{ required: true, message: "Please input ID First!" }]}
      >
        <Input placeholder="ID" type="text" />
      </Form.Item>
      <Form.Item
        label="Type"
        name="type"
        rules={[{ required: true, message: "Please Select Type First!" }]}
      >
        <Select
          placeholder="Choose Type"
          onChange={handleChange}
          options={listData}
        />
      </Form.Item>
      {selectedType === "json" || selectedType === "j-logic" ? (
        <Form.Item label="JSON VALUE">
          <div
            style={{
              borderRadius: "8px",
              overflow: "hidden",
              border: "1px solid #d9d9d9",
            }}
          >
            <Editor
              language="json"
              height={200}
              value={code}
              onChange={handleEditorChange}
              options={{
                lineNumbers: "on",
                roundedSelection: false,
                scrollBeyondLastLine: false,
                theme: "vs-light",
              }}
            />
          </div>
        </Form.Item>
      ) : (
        <Form.Item
          label="Value"
          name="value"
          rules={[{ required: true, message: "Please input value First!" }]}
        >
          <Input placeholder="Value" type="text" />
        </Form.Item>
      )}
    </Form>
  );
};

export default NewSettingForm;
