import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";
import { AddItemCard, NewEventSchemaContainer } from "./styles";
import { Alert, Form, Input, message } from "antd";
import PageHeader from "../../../../components/common/pageHeader";
import NewItemCard from "../../../../components/common/card/NewItem";
import NewField from "../newfield";
import EnrichValue from "../enrichValue";
import CustomDrawer from "../../../../components/common/drawer";
import DependencyDrawerContent from "../drawerContents/Dependency";
import EnrichmentsDrawerContent from "../drawerContents/Enrichments";
import CustomButton from "../../../../components/common/button";
import { AxiosError } from "axios";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import {
  changeEventStatus,
  createEvent,
  getEvent,
  getEvents,
  updateEvent,
} from "../../../../redux/feature/events/eventThunk";
import { useNavigate, useParams } from "react-router-dom";
import { transformFormValues } from "../../../../utils/helper";
import PageLoading from "../../../../components/Loader";

interface Field {
  id: number;
  disable?: boolean;
}

const NewEventSchema = () => {
  const [attributeFields, setAttributeFields] = useState<Field[]>([]);
  const [initValues, setInitValues] = useState<Field[]>();
  const [dimensionFields, setDimensionFields] = useState<Field[]>([]);
  const [isDependencyDrawerOpen, setIsDependencyDrawerOpen] =
    useState<boolean>(false);
  const [isEnrichDrawerOpen, setIsEnrichDrawerOpen] = useState<boolean>(false);
  const [isTemplateShown, setIsTemplateShown] = useState(false);
  const [templateText, setTemplateText] = useState("");
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { name } = useParams<{ name: string }>();
  const [eventId, setEventId] = useState<string | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const { events, eventLoading, currentEvent, loading } = useSelector(
    (state: RootState) => state.event
  );

  useEffect(() => {
    if (currentEvent && name) {
      const attributeData = currentEvent?.attributes?.map(
        (attribute: any, i: number) => {
          return { id: Date.now() + i, disable: true };
        }
      );
      const dimensionData = currentEvent?.dimensions?.map(
        (dimension: any, i: number) => {
          return { id: Date.now() + i, disable: true };
        }
      );

      setAttributeFields(attributeData);
      setDimensionFields(dimensionData);
    }
  }, [currentEvent, dispatch, initValues]);

  useEffect(() => {
    if (!events || (events?.length === 0 && name)) {
      dispatch(getEvents({
        sort:'-updated_at'
      }));
    }
  }, [dispatch, events?.length]);

  useEffect(() => {
    if (name && events?.length > 0 && name) {
      const foundEvent = events.find((event: any) => event.name === name);
      if (foundEvent) {
        setEventId(foundEvent.event_schema_id);
      } else {
        message.error(`No event found with the name "${name}".`);
      }
    }
  }, [name, events]);

  useEffect(() => {
    if (eventId && name) {
      dispatch(getEvent(eventId))
        .unwrap()
        .then((res) => {
          const data = transformValuesReverse(res);
          setInitValues(data);
        })
        .catch((e) => {
          message.error("Failed to fetch event details.");
        });
    }
  }, [eventId, dispatch]);

  const templateShownHandler = () => {
    setIsTemplateShown(true);
  };
  const enrichDrawerHandler = () => {
    setIsEnrichDrawerOpen(!isEnrichDrawerOpen);
  };
  const dependencyDrawerHandler = () => {
    setIsDependencyDrawerOpen(!isDependencyDrawerOpen);
  };

  const handleAddField = (
    fields: Field[],
    setFields: React.Dispatch<React.SetStateAction<Field[]>>
  ) => {
    const newField = { id: Date.now() };
    setFields([...fields, newField]);
  };

  const toggleStatus = async () => {
    try {
      const payload = {
        eventSchemaId: currentEvent?.event_schema_id,
        isActive: currentEvent?.status === "INACTIVE" ? true : false,
      };
      await dispatch(changeEventStatus(payload));
      navigate(`/events/${currentEvent?.name}`);
    } catch (err) {
      const error = err as AxiosError;
      console.log("🚀 ~ file: index.tsx:73 ~ onChange ~ error:", error);
    }
  };

  const transformValuesReverse = (event: any) => {
    const transformedEvent: any = {
      name: event?.name,
      description: event?.description,
    };

    // Iterate over the attributes and add them to the transformedEvent object
    event?.attributes?.forEach((attribute: any, index: number) => {
      transformedEvent[`attribute-${index}`] = attribute.name;
      transformedEvent[`unit-${index}`] = attribute.default_unit || ""; // Use empty string if default_unit is null
    });

    // Iterate over the dimensions and add them to the transformedEvent object
    event?.dimensions?.forEach((dimension: any, index: number) => {
      transformedEvent[`dimensions-${index}`] = dimension[index];
    });

    return transformedEvent;
  };

  const onSubmit = async (type: string) => {
    try {
      const values = await form.validateFields();
      const data: any = transformFormValues(values, name, initValues);
      if (name) {
        data.eventSchemaId = currentEvent?.event_schema_id;
        dispatch(updateEvent(data));
        navigate("/events");
        form.resetFields();
      } else {
        const eventCreated: any = await dispatch(createEvent(data)).unwrap();
        if (type === "publish" && eventCreated) {
          await dispatch(
            changeEventStatus({
              eventSchemaId: eventCreated[0].event_schema_id,
              checked: true,
            })
          );
        }
        navigate("/events");
        form.resetFields();
      }
    } catch (err) {
      const error = err as AxiosError;
      console.log("🚀 ~ file: EditProfile.tsx:22 ~ onFinish ~ error:", error);
    }
  };

  const handleRemoveField = (
    id: number,
    fields: Field[],
    setFields: React.Dispatch<React.SetStateAction<Field[]>>
  ) => {
    setFields(fields.filter((field) => field.id !== id));
  };

  const addAttributeField = useCallback(
    () => handleAddField(attributeFields, setAttributeFields),
    [attributeFields]
  );
  const removeAttributeField = useCallback(
    (id: number) => handleRemoveField(id, attributeFields, setAttributeFields),
    [attributeFields]
  );
  const addDimensionsField = useCallback(
    () => handleAddField(dimensionFields, setDimensionFields),
    [dimensionFields]
  );
  const removeDimensionsField = useCallback(
    (id: number) => handleRemoveField(id, dimensionFields, setDimensionFields),
    [dimensionFields]
  );
  console.log("run", isTemplateShown);

  const renderFields = (
    fields: Field[],
    removeField: (id: number) => void,
    title: string,
    name1: string,
    name2: string,
    isUnitShow = true
  ) => (
    <>
      {fields.map((field, i) => (
        <NewField
          title={title}
          key={field.id}
          id={field.id}
          removeField={removeField}
          isUnitShow={isUnitShow}
          name1={`${name1}-${i}`}
          name2={`${name2}-${i}`}
          disable={field.disable}
        />
      ))}
    </>
  );

  const renderEventSchemaFormFields = () => (
    <>
      <Form.Item
        label="Event Schema Name"
        name="name"
        rules={[{ required: true, message: "Please input Name!" }]}
        className="name"
      >
        <Input placeholder="Event Schema Name" disabled={name ? true : false} />
      </Form.Item>
      <Form.Item
        label="Description"
        name="description"
        rules={[{ required: true, message: "Please input description!" }]}
      >
        <Input.TextArea placeholder="Description" />
      </Form.Item>
    </>
  );

  if (eventLoading) {
    return <PageLoading />;
  }

  if (name && !initValues) {
    return <PageLoading />;
  }

  return (
    <NewEventSchemaContainer>
      {eventLoading && <PageLoading />}
      <PageHeader
        title={`${name ? "Edit" : "Create"} Event Schema`}
        path="/events"
      />
      <div className="body">
        <Form
          layout="vertical"
          form={form}
          initialValues={initValues}
          requiredMark={false}
          className="createEventSchemaForm"
        >
          <NewItemCard
            title="Define Event Schema"
            subtitle="Name your event schema to identify events processed via this schema"
            toolTipText="Learn More"
          >
            {renderEventSchemaFormFields()}
          </NewItemCard>

          <NewItemCard
            title="Build your Schema"
            subtitle="Events ingested to Togai will be processed based on the schemas you set up here"
          >
            <AddItemCard title="ATTRIBUTES" bordered={false}>
              {renderFields(
                attributeFields,
                removeAttributeField,
                "ATTRIBUTES",
                "attribute",
                "unit"
              )}
              <div className="action" onClick={addAttributeField}>
                <PlusOutlined /> <span>Add Attribute</span>
              </div>
            </AddItemCard>
            <AddItemCard title="DIMENSIONS" bordered={false}>
              {renderFields(
                dimensionFields,
                removeDimensionsField,
                "DIMENSIONS",
                "dimensions",
                "",
                false
              )}
              <div className="action" onClick={addDimensionsField}>
                <PlusOutlined /> <span>Add Dimension</span>
              </div>
            </AddItemCard>
          </NewItemCard>

          <NewItemCard
            title="Enriched Values"
            subtitle="End result of an enrichment which gets added to an event."
          >
            <Form layout="vertical" requiredMark={false} className="form">
              <EnrichValue
                title="Dependencies"
                btnText="Add Dependency"
                onClick={dependencyDrawerHandler}
              />
              <EnrichValue
                title="Enrichments"
                btnText="Add Enrichments"
                onClick={enrichDrawerHandler}
              />
              <CustomDrawer
                isOpen={isDependencyDrawerOpen}
                onClose={dependencyDrawerHandler}
                title="Add Dependency"
                submitText="Add Dependency"
              >
                <DependencyDrawerContent />
              </CustomDrawer>
              <CustomDrawer
                isOpen={isEnrichDrawerOpen}
                onClose={enrichDrawerHandler}
                title="Add Enrichment"
                submitText="Add Enrichment"
              >
                <EnrichmentsDrawerContent />
              </CustomDrawer>
            </Form>
          </NewItemCard>
          <NewItemCard
            title="Configurable Event Id (Idempotency Key)"
            subtitle="The template configured will be applied on event payload to autogenerate the event's id for ingesting."
          >
            {isTemplateShown ? (
              <>
                <div className="templateItem">
                  <Form.Item
                    label="Enter the template format"
                    name="format"
                    extra={
                      <>
                        {" "}
                        <InfoCircleOutlined
                          style={{ height: "12px", width: "12px" }}
                        />{" "}
                        <span>Enter @ to choose from the variables.</span>
                      </>
                    }
                    rules={[
                      { required: true, message: "Please input Format First!" },
                    ]}
                    required={false}
                    layout="vertical"
                    style={{ width: "85%" }}
                  >
                    <Input.TextArea
                      placeholder="Enter @ to insert the variables"
                      onChange={(e) => setTemplateText(e.target.value)}
                    ></Input.TextArea>
                  </Form.Item>
                  <div
                    className="deleteIcon"
                    onClick={() => setIsTemplateShown(false)}
                  >
                    <DeleteOutlined />
                  </div>
                </div>
                {templateText.length > 0 && (
                  <Alert
                    style={{
                      backgroundColor: "#F8FAFC",
                      border: "1px solid rgba(0,0,0,0.1)",
                      marginTop: "1rem",
                    }}
                    message={
                      <>
                        <p style={{ margin: "0px" }}>Sample Preview :</p>
                        <p
                          className="query"
                          style={{ margin: "0.3rem auto", fontWeight: "bold" }}
                        >
                          {templateText}
                        </p>
                      </>
                    }
                  />
                )}
              </>
            ) : (
              <div className="configureId" onClick={templateShownHandler}>
                <span>
                  <PlusOutlined style={{ marginRight: "0.5rem" }} />
                  Configure Event Id template
                </span>
              </div>
            )}
          </NewItemCard>
        </Form>
      </div>
      <div className="footerContent">
        <div className="footerActions">
          <CustomButton
            text={
              name
                ? currentEvent?.status === "ACTIVE"
                  ? "Deactivate"
                  : "Activate"
                : "Save As Draft"
            }
            onClick={name ? toggleStatus : () => onSubmit("draft")}
            icon={<RightOutlined />}
            position="end"
            isGrey={true}
          />
          <CustomButton
            text={name ? "Save" : "Publish"}
            onClick={() => onSubmit("publish")}
            icon={<RightOutlined />}
            position="end"
          />
        </div>
      </div>
    </NewEventSchemaContainer>
  );
};

export default NewEventSchema;
