import {
  Card,
  Col,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
} from "antd";
import { useMemo, useState } from "react";
import { StyledRadio } from "../../../../price-plans/NewPricePlan/RateCard/forms/styles";
import { StyledCard } from "../styles";
import { DeleteOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

type ActionCardPropsType = {
  nameKey: number;
  id: number;
  onDelete: (id: number, nameKey: number) => void;
  currencyCode: any;
  debitAmount: any;
  setDebitAmount: any;
};

const ActionCard: React.FC<ActionCardPropsType> = ({
  nameKey,
  id,
  onDelete,
  currencyCode,
  setDebitAmount,
  debitAmount,
}) => {
  const [actionType, setActionType] = useState("INVOICE");
  const {invoices}=useSelector((state:RootState)=>state.billing)

  const actionTypeOptions = useMemo(() => {
    return invoices?.filter((inv: any) => inv.status === 'DUE').map((invoice) => ({
      value: invoice.invoice_id,
      label: invoice.invoice_id,
    }));
  }, [invoices]);

  const actionTypeHandler = (e: RadioChangeEvent) => {
    console.log("►►► ~ actionTypeHandler ~ e:", e);
    setActionType(e.target.value);
  };

  return (
    <StyledCard>
      <Row>
        <Col span={16}>
          <Form.Item label="Action" name={`type-${nameKey}`}>
            <StyledRadio onChange={actionTypeHandler} defaultValue={actionType}>
              <Radio value="INVOICE">Invoice</Radio>
              <Radio value="CREDIT">Credit</Radio>
            </StyledRadio>
          </Form.Item>
          <Form.Item
            label={`Amount (${currencyCode?.currency_code})`}
            name={`amount-${nameKey}`}
          >
            <Input
              type="number"
              placeholder="Amount in USD"
              onChange={(e: any) => {
                setDebitAmount((prev: any) => ({
                  ...prev,
                  [id]: e.target.value,
                }));
              }}
            />
          </Form.Item>
          {actionType === "INVOICE" && (
            <Form.Item
              label="Invoice ID"
              name={`invoice_id-${nameKey}`}
              className="invoice"
              rules={[{ required: true, message: "Field is required!" }]}
            >
              <Select
                options={actionTypeOptions}
                placeholder="Search Invoice ID "
              />
            </Form.Item>
          )}
        </Col>
      </Row>
      <div className="delete" onClick={() => onDelete(id, nameKey)}>
        <DeleteOutlined />
      </div>
    </StyledCard>
  );
};
export default ActionCard;
