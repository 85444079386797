import { Layout, Popconfirm, Tabs } from "antd";
import styled from "styled-components";

// style.ts
export const LayoutStyles = styled.div`
    background: #f1f5f9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 10;
  .top-header {
    /* padding: 10px 16px; */
    display: flex;
    gap: 7px;

  }
  .header{
  padding: .37rem 7rem 0rem;
  background: #fff;
  position: relative;
  z-index: 1000
  }
`;


export const AccountTitleStyles = styled.div`
  max-width: 400px;
  margin: 0;
  font-size: 15px;
  font-weight: 700;
`;

export const BreadcrumbStyles = styled.div`
  margin: 16px 0;
`;

export const CustomPopconfirm = styled(Popconfirm)`
  .ant-popover {
    transform: translate(-50%, -50%) !important;
    top: 50% !important;
    left: 50% !important;
    margin: 0 !important;
  }
  .ant-popover-content {
    width: 50% !important;
  }
`;
export const CustomTabs = styled(Tabs)`
  padding-left: 20px;
  .ant-tabs-nav {
    margin: unset !important;
  }
`;

export const AllAccountsHeader = styled.div`
  .ant-breadcrumb{
    font-size: 12px;
    margin-bottom: 10px;
  }
  .ant-breadcrumb-link:hover{
    background: transparent !important;
    color: rgba(0, 0, 0, 0.45) !important;
  }
  .ant-breadcrumb-separator{
    color: black;
  }
`;
export const HeaderStyles = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  margin-bottom: 1rem;
  position: relative;
  z-index: 1000;

  .leftIcon{
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 10px;
    padding: 5px 10px 3px 10px; 
  }
  .icon{
    height: 12px;
    width: 12px;
    font-weight: 600;
  }
  .accountHeader{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
`;

