import React from "react";
import {
  BranchesOutlined,
  CalendarOutlined,
  CheckCircleFilled,
  DashboardOutlined,
  DownOutlined,
  InfoCircleFilled,
  TagOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Tree } from "antd";
import type { TreeDataNode, TreeProps } from "antd";
import PipelineItem from "./pipelineItem";
import { StyledTree } from "./styles";
import { useLocation } from "react-router-dom";

const Pipeline: React.FC<any> = ({ selectedIngest }) => {
  const onSelect: TreeProps["onSelect"] = (selectedKeys, info) => {
  };
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  const treeData: any[] = [
    {
      title: (
        <PipelineItem
          title="Event ID"
          content={selectedIngest?.billoptim_ingest_event_id}
          startIcon={<BranchesOutlined />}
        />
      ),
      key: "0-0",
      children: [
        {
          title: (
            <PipelineItem
              title="Event Schema"
              content={
                selectedIngest?.event_pipeline_info?.eventSchema?.name ??
                "No event Schema found"
              }
              startIcon={<BranchesOutlined />}
              endIcon={
                selectedIngest?.event_pipeline_info?.eventSchema ? (
                  <CheckCircleFilled style={{ color: "blue" }} />
                ) : (
                  <InfoCircleFilled />
                )
              }
            />
          ),
          key: "0-0-0",
        },
        {
          title: (
            <PipelineItem
              title="Usage Meter"
              content={`${
                selectedIngest?.event_pipeline_info?.usageMeters?.length || 0
              } Usage Meter`}
              startIcon={<DashboardOutlined />}
            />
          ),
          key: "0-0-1",
          children:
            selectedIngest?.event_pipeline_info?.usageMeters?.map(
              (meter: any, index: number) => ({
                title: (
                  <PipelineItem
                    title=""
                    content={meter.name}
                    startIcon={<DashboardOutlined />}
                    onClick={() => {
                      if (meter?.id) {
                        window.open(
                          `${baseUrl}/usage-meter/${meter?.id}`,
                          "_blank"
                        );
                      }
                    }}
                    endIcon={
                      meter ? (
                        <CheckCircleFilled style={{ color: "blue" }} />
                      ) : (
                        <InfoCircleFilled />
                      )
                    }
                  />
                ),
                key: `0-0-1-${index}`,
              })
            ) || [],
        },
        {
          title: (
            <PipelineItem
              title="Price Plan"
              content={`${
                selectedIngest?.event_pipeline_info?.pricePlans?.length || 0
              } Price Plan Matched`}
              startIcon={<TagOutlined />}
            />
          ),
          key: "0-0-2",
          children:
            selectedIngest?.event_pipeline_info?.pricePlans?.map(
              (plan: any, index: number) => ({
                title: (
                  <PipelineItem
                    title=""
                    content={plan?.name || "No Price Plan Matched"}
                    startIcon={<TagOutlined />}
                    onClick={() => {
                      if (plan?.id) {
                        window.open(
                          `${baseUrl}/price-plan/${plan?.id}?__sort__=-updated_at`,
                          "_blank"
                        );
                      }
                    }}
                    endIcon={
                      plan?.name ? (
                        <CheckCircleFilled style={{ color: "blue" }} />
                      ) : (
                        <InfoCircleFilled />
                      )
                    }
                  />
                ),
                key: `0-0-2-${index}`,
              })
            ) || [],
        },
        {
          title: (
            <PipelineItem
              title="Pricing Cycle"
              content={`${
                selectedIngest?.event_pipeline_info?.pricePlans?.length || 0
              } Pricing Cycle Matched`}
              startIcon={<CalendarOutlined />}
            />
          ),
          key: "0-0-3",
          children:
            selectedIngest?.event_pipeline_info?.pricePlans?.map(
              (plan: any, index: any) => ({
                title: (
                  <PipelineItem
                    title=""
                    content={
                      plan
                        ? `Effective From: ${plan.effective_from} - Effective Until: ${plan.effective_until}`
                        : "No Billing Cycle Found"
                    }
                    startIcon={<CalendarOutlined />}
                    endIcon={
                      plan.name ? (
                        <CheckCircleFilled style={{ color: "blue" }} />
                      ) : (
                        <InfoCircleFilled />
                      )
                    }
                  />
                ),
                key: `0-0-3-${index}`,
              })
            ) || [],
        },
        {
          title: (
            <PipelineItem
              title="Account"
              onClick={() => {
                if (
                  selectedIngest?.event_pipeline_info?.account?.id &&
                  selectedIngest?.event_pipeline_info?.customer?.id
                ) {
                  window.open(
                    `${baseUrl}/customers/${selectedIngest?.event_pipeline_info?.customer?.id}/accounts/${selectedIngest?.event_pipeline_info?.account?.id}/details`,
                    "_blank"
                  );
                }
              }}
              content={
                selectedIngest?.event_pipeline_info?.account?.name ??
                "No Account found"
              }
              startIcon={<UserOutlined />}
              endIcon={
                selectedIngest?.event_pipeline_info?.account?.name ? (
                  <CheckCircleFilled style={{ color: "blue" }} />
                ) : (
                  <InfoCircleFilled />
                )
              }
            />
          ),
          key: "0-0-4",
        },
        {
          title: (
            <PipelineItem
              title="Customer"
              content={
                selectedIngest?.event_pipeline_info?.customer?.name ??
                "No Customer found"
              }
              onClick={() => {
                if (
                  
                  selectedIngest?.event_pipeline_info?.customer?.id
                ) {
                  window.open(
                    `${baseUrl}/customer/${selectedIngest?.event_pipeline_info?.customer?.id}/details`,
                    "_blank"
                  );
                }
              }}
              startIcon={<UserOutlined />}
              endIcon={
                selectedIngest?.event_pipeline_info?.customer?.name ? (
                  <CheckCircleFilled style={{ color: "blue" }} />
                ) : (
                  <InfoCircleFilled />
                )
              }
            />
          ),
          key: "0-0-5",
        },
      ],
    },
  ];

  const expandedKeys = treeData.flatMap((node) => [
    node.key,
    ...(node.children?.map((child: any) => child.key) || []),
  ]);
  console.log("►►► ~ expandedKeys:", expandedKeys);

  return (
    <StyledTree
      className="treeDummy"
      showLine
      expandedKeys={expandedKeys}
      selectable={false}
      // onSelect={onSelect}
      treeData={treeData}
      switcherIcon={null}
    />
  );
};

export default Pipeline;
