import {
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Row,
} from "antd";
import { CustomForm } from "./styles";
import { useState } from "react";
import { StyledRadio } from "../../../price-plans/NewPricePlan/RateCard/forms/styles";

interface AliasFormProps {
  form: any;
}

const AliasForm: React.FC<AliasFormProps> = (props) => {
  const { form } = props;
  const [aliasValue, setAliasValue] = useState("name");
  const aliasHandler = ({ target: { value } }: RadioChangeEvent) => {
    setAliasValue(value);
  };
  return (
    <CustomForm
      layout="vertical"
      requiredMark={false}
      initialValues={{ createBy: aliasValue }}
      form={form}
    >
      <Form.Item label="Create alias by">
        <StyledRadio
          defaultValue={aliasValue}
          onChange={aliasHandler}
          value={aliasValue}
        >
          <Radio value={"name"}>Enter Name</Radio>
          <Radio value={"range"}>Enter Range</Radio>
        </StyledRadio>
      </Form.Item>

      {aliasValue === "name" ? (
        <Form.Item
          label="Alias Name"
          name="name"
          rules={[
            { required: true, message: "Please input Alias Name First!" },
          ]}
        >
          <Input size="small" placeholder="Alias Name" type="text" />
        </Form.Item>
      ) : (
        <>
          <Form.Item
            label="Prefix value"
            name="prefix"
            rules={[
              {
                required: true,
                message: "Please input Prefix value First!",
              },
            ]}
          >
            <Input size="small" placeholder="Prefix value" type="text" />
          </Form.Item>

          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Form.Item
                label="Range start value"
                name="startValue"
                rules={[
                  {
                    required: true,
                    message: "Please input Range start value First!",
                  },
                ]}
              >
                <InputNumber placeholder="Start value" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Range end value"
                name="endValue"
                rules={[
                  {
                    required: true,
                    message: "Please input Range end value First!",
                  },
                ]}
              >
                <InputNumber placeholder="End value" />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </CustomForm>
  );
};

export default AliasForm;
