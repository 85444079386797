import { Provider } from "react-redux";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import { RenderRoutes } from "./routes";
import { PersistGate } from "redux-persist/integration/react";
import { persister, store } from "./redux/store";
import "./styles.css";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={"loading..."} persistor={persister}>
        <RenderRoutes />
      </PersistGate>
    </Provider>
  );
}

export default App;
