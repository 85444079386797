import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { DatePicker, Form, Select } from "antd";
import { useCallback, useState } from "react";
import TextedSwitch from "../../../../../components/common/textedSwitch";
import { StyledForm } from "./styles";
import LineItemCard from "./LineItemCard";

interface Field {
  id: number;
}

const InvoiceStatus = [
  { value: "paid", label: "Paid" },
  { value: "due", label: "Due" },
  { value: "draft", label: "Draft" },
];

const CustomInvoiceForm = () => {
  const [status, setStatus] = useState("");
  const [lineItemFields, setLineItemFields] = useState<Field[]>([]);

  const addLineItem = useCallback(
    () =>
      setLineItemFields((prevFields) => [...prevFields, { id: Date.now() }]),
    [lineItemFields]
  );

  const removeLineItem = useCallback(
    (id: number) =>
      setLineItemFields((prevFields) =>
        prevFields.filter((field) => field.id !== id)
      ),
    [lineItemFields]
  );

  const renderFields = (fields: Field[], removeField: (id: number) => void) => {
    return fields.map((field, i) => (
      <div style={{ width: "100%", display: "flex", gap: "10px" }}>
        <LineItemCard nameKey={i} />
        <DeleteOutlined onClick={() => removeField(field.id)} />
      </div>
    ));
  };
  return (
    <StyledForm layout="vertical" requiredMark={false}>
      <div>
        <p>Invoice Line Items</p>
        <LineItemCard nameKey={999} />
        {renderFields(lineItemFields, removeLineItem)}
        <p className="addItemBtn" onClick={addLineItem}>
          <PlusOutlined /> <span>Add Another Line Item</span>
        </p>
        <Form.Item label="Invoice Start Date" name="startDate">
          <DatePicker suffixIcon={null} placeholder="Invoice Start Date" />
        </Form.Item>
        <Form.Item label="Invoice End Date" name="endDate">
          <DatePicker suffixIcon={null} placeholder="Invoice End Date" />
        </Form.Item>
        <Form.Item label="Invoice Status" name="status">
          <Select
            onChange={setStatus}
            options={InvoiceStatus}
            placeholder="Invoice Status"
          />
        </Form.Item>
        <div className="switches">
          <TextedSwitch text="Use Wallet balance To Net Off" />
          <TextedSwitch text="Use Credit balance To Net Off" />
        </div>
      </div>
    </StyledForm>
  );
};

export default CustomInvoiceForm;
