import { Button, Card, Empty, Space, Typography } from "antd";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import CustomButton from "../button";
import { EmptyDataContainer, StyledEmpty } from "./Styles";
import styled from "styled-components";

export const EmptyData: React.FC<{
  description: string;
  btnText?: string;
  onClick?: () => void;
}> = ({ description, btnText, onClick = () => {} }) => {
  return (
    <StyledEmpty>
      <div className="titlePart">
        <div className="iconContainer">
          <ExclamationCircleOutlined
            style={{ fontSize: 24, color: "#2D59C5" }}
          />
        </div>
        <Typography.Title level={4} className="title">
          {description}
        </Typography.Title>
      </div>
      {btnText && (
        <CustomButton
          text={btnText}
          onClick={onClick}
          icon={<PlusOutlined />}
        />
      )}
    </StyledEmpty>
  );
};

export const EmptyDataComponent: React.FC<{
  addButton?: any;
  title?: string;
  description?: string;
  isAddButton?: boolean;
}> = ({ addButton, title, description, isAddButton = true }) => {
  return (
    <EmptyDataContainer>
      <Space direction="vertical" align="center">
        <div className="iconWrapper">
          <ExclamationCircleOutlined
            style={{ fontSize: 24, color: "#2D59C5" }}
          />
        </div>
        <Typography.Title level={4} className="title">
          {title}
        </Typography.Title>
        {description && (
          <Typography.Text className="description">
            {description}
          </Typography.Text>
        )}
        {isAddButton && <>{addButton()}</>}
      </Space>
    </EmptyDataContainer>
  );
};
