import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  Typography,
  Row,
  Col,
  Divider,
  Tag,
  Avatar,
  TableColumnsType,
} from "antd";
import {
  EyeOutlined,
  CopyOutlined,
  ExportOutlined,
  StopOutlined,
  FileDoneOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import BackPageHeader from "../../../../components/common/BackPageHeader";
import { Label, StyledRow } from "../../../billing/detailsPage/styles";
import Calculation from "../../../billing/detailsPage/Calculation";
import UserAvatar from "../../../../components/common/avatar";
import CustomButton from "../../../../components/common/button";
import { StyledCard } from "./styles";
import { StyledTable } from "../../../../components/common/table/styles";
import { copyToClipboard } from "../../../../utils/helper";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
const { Text } = Typography;

interface TransactionDataType {
  key: React.Key;
  transaction_id: string;
  transaction_type: string;
  timestamp: string;
  entity_id: string;
  amount: string;
}

const columns: TableColumnsType<any> = [
  {
    title: "Transaction ID",
    dataIndex: "transaction_id",
  },
  {
    title: "Transaction Type",
    dataIndex: "transaction_type",
  },
  {
    title: "Timestamp",
    dataIndex: "timestamp",
  },
  {
    title: "Entity ID",
    dataIndex: "entity_id",
  },
  {
    title: "Amount",
    dataIndex: "amount",
  },
];

const tempData: TransactionDataType[] = [
  {
    key: "1",
    transaction_id: "cred_txn.23dxQ21bJvb.tS81J",
    transaction_type: "Credited",
    timestamp: "Oct 03, 2024 UTC",
    entity_id: "NA",
    amount: "55 USD",
  },
];
const CreditDetails: React.FC = () => {
  const [isRuleDrawerOpen, setIsRuleDrawerOpen] = useState(false);
  const {viewCreditDetails,customers}=useSelector((state:RootState)=>state.customer)
  const {customerId,accountId}=useParams()
  const drawerHandler = () => {
    setIsRuleDrawerOpen(!isRuleDrawerOpen);
  };
  const navigate = useNavigate();

const customerDetails=useMemo(()=>{
  return customers.find((c)=>c.customer_id?.toString()   ===customerId)
},[viewCreditDetails])

  useEffect(()=>{
    if(!viewCreditDetails)navigate(-1)
  },[viewCreditDetails])

  return (
    <div>
      <BackPageHeader
        text="Back to All Credits in ewrtg-accounts"
        path={`/customers/${customerId}/accounts/${accountId}/credits`}
      />
      <StyledRow gutter={32}>
        <Col span={16}>
          <StyledCard className="detailCard">
            <div className="orderDetailContainer">
              <div className="creditDetailHeader">
                <Avatar size={48} style={{ backgroundColor: "#2853C2" }}>
                  <FileDoneOutlined />
                </Avatar>
                <div>
                  <p>Credit Details</p>
                  <span>{viewCreditDetails?.purpose}</span>
                </div>
              </div>
              <CustomButton
                text="Void Credit"
                type="default"
                icon={<StopOutlined />}
                position="end"
              />
            </div>
            <Row gutter={12} className="cardContainer">
              <Col span={6}>
                <Card className="cardItem">
                  <Label>Start Date</Label>
                  <p>
                    {dayjs(viewCreditDetails?.effectiveFrom).format(
                      "MMM DD YYYY"
                    )}
                  </p>
                </Card>
              </Col>
              <Col span={6}>
                <Card className="cardItem">
                  <Label>Expiry Date</Label>
                  <p>
                    {viewCreditDetails?.effectiveUntil
                      ? dayjs(viewCreditDetails?.effectiveUntil).format(
                          "MMM DD YYYY"
                        )
                      : "	Forever"}
                  </p>
                </Card>
              </Col>
              <Col span={6}>
                <Card className="cardItem">
                  <Label>Creation Date</Label>
                  <p>
                    {dayjs(viewCreditDetails?.createdAt).format("MMM DD YYYY")}
                  </p>
                </Card>
              </Col>
              <Col span={6}>
                <Card className="cardItem">
                  <Label>Priority</Label>
                  <p>{viewCreditDetails?.priority}</p>
                </Card>
              </Col>
            </Row>
          </StyledCard>
          <div>
            <p>Transactions</p>
            <StyledTable
              pagination={false}
              columns={columns}
              dataSource={tempData}
              scroll={{ x: "max-content" }}
            />
          </div>
        </Col>

        <Col span={8}>
          <StyledCard>
            <div className="dueDetails">
              <p>
                Total granted <InfoCircleOutlined />
              </p>
              <div className="item">
                <p>{viewCreditDetails?.creditAmount}</p>
                <span className="currency">
                  {viewCreditDetails?.creditUnit}
                </span>
              </div>
            </div>
            <Divider
              style={{
                margin: 0,
                marginBottom: "0.5rem",
                borderTop: "2px solid #F8F8F8",
              }}
            />
            <div className="dueDetails">
              <p>
                Hold balance <InfoCircleOutlined />
              </p>
              <div className="item">
                <p className="p">{viewCreditDetails?.holdAmount}</p>

                <span className="currency">
                  {viewCreditDetails?.creditUnit}
                </span>
              </div>
            </div>
            <div className="dueDetails">
              <p>
                Closing balance <InfoCircleOutlined />
              </p>
              <div className="item">
                <p className="p">
                  {(
                    (viewCreditDetails?.creditAmount ?? 0) -
                    (viewCreditDetails?.holdAmount ?? 0)
                  ).toFixed(2)}
                </p>

                <span className="currency">
                  {viewCreditDetails?.creditUnit}
                </span>
              </div>
            </div>
          </StyledCard>

          <StyledCard>
            <div className="details">
              <p>Credit Name</p>
              <p className="value">{viewCreditDetails?.purpose}</p>
            </div>
            <div className="details">
              <p>Credit ID</p>
              <p className="value">{viewCreditDetails?.credit_id}</p>
            </div>
            <div className="details" style={{ marginBottom: "0" }}>
              <p>Applies to</p>
              <p className="value">
                {viewCreditDetails?.applicableEntityIds?.value
                  ?.replace(/_/g, " ")
                  .toLowerCase()}
              </p>
            </div>
          </StyledCard>
          <StyledCard style={{ padding: "8px" }}>
            <p>CUSTOMER DETAILS</p>
            <Divider className="detailDivider" />
            <Row align="middle">
              <Col>
                <UserAvatar user={customerDetails} />
              </Col>
              <Col style={{ marginLeft: "10px" }}>
                <Text>{customerDetails?.name}</Text>
                <br />
                <Text type="secondary">{customerDetails?.email}</Text>
              </Col>
            </Row>
            <Divider className="detailDivider" />
            <div>
              <div className="details">
                <p>Customer Id</p>
                <p className="value">
                  {customerDetails?.customer_id}
                  <CopyOutlined
                    className="copy"
                    onClick={() => {
                      copyToClipboard(customerDetails?.customer_id);
                    }}
                  />
                </p>
              </div>
              {/* <div className="details">
                <p>Account</p>
                <p className="value">wrehb</p>
              </div> */}
              <div className="details">
                <p>Account ID</p>
                <p className="value">
                  {accountId}
                  <CopyOutlined
                    className="copy"
                    onClick={() => {
                      copyToClipboard(accountId ?? '');
                    }}
                  />
                </p>
              </div>
            </div>
          </StyledCard>
        </Col>
      </StyledRow>
    </div>
  );
};

export default CreditDetails;
