import { Card } from "antd";
import styled from "styled-components";

export const CreditContainer = styled.div`
    padding: 0.5rem 0;
    .green{
        color: rgb(15 138 107 / 1);
    }
    .nameContainer{
      p{
        font-size: 14px;
        font-weight: 500;
        color: black;
      }
      span{
        font-size: 12px;
        font-weight: 400;
      }
    }
    .dataContainer{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p{
        color: inherit !important;
        font-size: 12px;
        font-weight: 400;
      }
      span{
        font-size: 12px;
        font-weight: 500;
        color: black;
      }
    }
    .multiItemContainer{
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .header{
      display: flex;
      justify-content: center;
      align-items: start;
      gap: 24px;
      margin-bottom: 24px;
    }
    .ant-row{
      margin:0 !important;
      width: 100%;
    }
    .cardItem{
      box-shadow: 0px 2px 8px 0px #cbd5e1 !important;
      display: flex;
      flex-direction: column;
      gap: 8px;
      .ant-card-body{
        padding: 16px;
        
      }
      .title{
        font-size: 10px;
        font-weight: 400;
        text-transform: uppercase;
        color: #334155;
      }
      .subTitle{
        font-size: 16px;
        font-weight: 400;
        color: #334155 !important;

      }
      .subTitle span:first-child{
        margin-right: 4px ;
        font-weight: 500;
      }
    }
`;

export const StyledCard = styled(Card)`
    border-radius: 8px;
    box-shadow: 0px 2px 8px 0px #cbd5e1 !important;
    margin-bottom: 24px;
    .ant-card-body{
      padding: 16px;
    }
    .revenue{
      .item{
        display: flex;
        gap: 0.5rem;
        align-items: center;
        line-height: 31px;
        margin-top: 5px;
        span{
          font-size: 26px ;
          font-weight: 500 ;
        }
        .currency{
          font-size: 16px;
          font-weight: 400;
          margin-right: 1rem;
          color: #64748b;
        }
      }
    } 
    .copy {
       height: 12px;
       width: 12px;
     }
    p {
      margin: 0;
    }
    .orderDetailContainer {
      padding: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .orderDetailHeader {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        h3 {
          margin: 0;
          font-weight: 600;
        }
      }

      .orderDetailItem {
        display: flex;
        gap: 1rem;
        align-items: center;

        p {
          margin: 0;
          font-size: 12px;
        }
     
      }
    }
    .creditDetailHeader{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
      p{
        font-size: 12px;
        font-weight: 500;
        color: #64748b;
      }
      span{
        font-size: 16px;
        font-weight: 500;
      }
    }
    .cardContainer {
     
      background-color: #F1F5F9;
      padding: 1rem;
      margin: 0 !important;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      .cardItem{
        padding: 8px 12px ;
        width: 100%;

        p{
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
    .dueDetails{
      p{
        text-transform: uppercase;
        font-size: 10px ;
        font-weight: 400 ;
        
        .anticon{
          margin-left: 5px;
        }
      }
      .item{
        display: flex;
        gap: 0.5rem;
        align-items: center;
        p{
          font-size: 2rem !important;
          font-weight: 600 !important;
        }
        .p{ 
          font-size: 16px !important;
        }
        .currency{
          font-size: 16px;
          margin-right: 1rem;
          color: #64748b;
        }
      }
    }
    .timing{
      margin-top: 0.5rem;
    }
    .detailDivider{
      margin: 0.7rem 0;
    }
    .details{
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-bottom: 24px;

      p{
        text-align: left;
        font-size : 12px;
        font-weight: 400;
        color: #475569;
      }
      .value{
        
        font-weight: 500;
        font-size: 14px;
        display: flex;
        align-items: center;
        color: black;
      }
      
    }
`;