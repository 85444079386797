import { useNavigate } from "react-router-dom";
import { StyledTable } from "../../../../components/common/table/styles";
import {
  CalendarOutlined,
  DeleteOutlined,
  EditOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Tag } from "antd";
import { StyledRangePicker } from "../../../billing/invoices/styles";
import dayjs from "dayjs";
import { EmptyData } from "../../../../components/common/empty";



const customChargeColumns = [
  {
    title: "Charge Name",
    dataIndex: "charge_name",
    key: "charge_name",
  },
  {
    title: "Charge type",
    dataIndex: "charge_type",
    key: "charge_type",
    render: (charge_type: string) => (
      <Tag
        color={
          charge_type === "PAID"
            ? "green"
            : charge_type === "DUE"
            ? "purple"
            : "red"
        }
      >
        • {charge_type}
      </Tag>
    ),
  },
  {
    title: "Considered for revenue",
    dataIndex: "considered_for_revenue",
    key: "considered_for_revenue",
    render: (considered_for_revenue: string) => (
      <Tag
        color={
          considered_for_revenue === "PAID"
            ? "green"
            : considered_for_revenue === "DUE"
            ? "purple"
            : "red"
        }
      >
        • {considered_for_revenue}
      </Tag>
    ),
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
  },

  {
    dataIndex: "action",
    render: () => (
      <div style={{ display: "flex", gap: "1rem" }}>
        {" "}
        <EditOutlined />
        <DeleteOutlined />
      </div>
    ),
  },
];

const BillingTable: React.FC<{
  billType: "Invoices" | "Orders" | "Custom Charges";
  data: any;
}> = ({ billType, data }) => {
  const navigate = useNavigate();


  const invoicesColumns = [
    {
      title: "Sequence ID",
      dataIndex: "sequence_id",
      key: "sequence_id",
    },
    {
      title: "Billing Cycle",
      dataIndex: "billingCycle",
      key: "billingCycle",
      render: (_: any, record: { startDate: string; endDate: string }) => (
        <StyledRangePicker
          size="small"
          defaultValue={[dayjs(record.startDate), dayjs(record.endDate)]}
          disabled
          suffixIcon={<CalendarOutlined />}
          format="MMM DD, YYYY"
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => (
        <Tag
          color={
            status === "PAID" ? "green" : status === "DUE" ? "purple" : "red"
          }
        >
          • {status}
        </Tag>
      ),
    },
    {
      title: "Created on",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at: any) => {
        return dayjs(created_at).format("MMM DD, YYYY");
      },

    },
    {
      title: "Total Value",
      dataIndex: "total_amount",
      key: "total_amount",
      render: (total_amount: number) => `$${total_amount}`,
    },
    {
      title: "Paid Value",
      dataIndex: "paid_amount",
      key: "paid_amount",
      render: (paid_amount: number) => `$${paid_amount}`,
    },
    {
      dataIndex: "action",
      render: () => <ReloadOutlined />,
    },
  ];

  return data.length === 0 ? (
    <EmptyData description={`No ${billType} Found`} />
  ) : (
    <StyledTable
      pagination={false}
      columns={billType === "Invoices" ? invoicesColumns : customChargeColumns}
      dataSource={data}
      scroll={{ x: "max-content" }}
      onRow={(record: any) => {
        return {
          onClick: () => {
            navigate(`/billing/${record.invoice_id}`);
          },
        };
      }}
    />
  );
};

export default BillingTable;
