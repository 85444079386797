import React, { ReactNode, useState } from "react";
import { Button } from "antd";
import { FilterPanel, StyledButton } from "./styles";
import CustomButton from "../button";
import { FilterOutlined } from "@ant-design/icons";

const FilterDropdown: React.FC<{
  children: ReactNode;
  onSubmitFilter?: () => void;
}> = ({ children, onSubmitFilter }) => {
  const [visible, setVisible] = useState(false);
  const toggleVisibility = () => {
    setVisible(!visible);
  };

  return (
    <div style={{ position: "relative" }}>
      <StyledButton
        icon={<FilterOutlined className="icon" />}
        iconPosition="end"
        type="default"
        onClick={toggleVisibility}
      >
        <span className="btnText">Filter</span>
      </StyledButton>

      <FilterPanel visible={visible}>
        <div className="action">
          <div onClick={onSubmitFilter}>
            <Button
              type="link"
              onClick={() => {
                setVisible(!visible);
              }}
            >
              Apply
            </Button>
          </div>
        </div>
        <div className="childrenContainer">{children}</div>
      </FilterPanel>
    </div>
  );
};

export default FilterDropdown;
