import { Card } from "antd";
import styled from "styled-components";

export const WalletContainer = styled.div`
    padding: 0.5rem 0;
    .header{
      display: flex;
      justify-content: center;
      align-items: start;
      gap: 24px;
      margin-bottom: 24px;
        .ant-row{
          width: 100%;
        }
    }
    .green{
        color: rgb(15 138 107 / 1);
    }
    .cardItem{
      box-shadow: 0px 2px 8px 0px #cbd5e1 !important;
      display: flex;
      flex-direction: column;
      gap: 8px;
      .ant-card-body{
        padding: 16px;
        
      }
      .title{
        font-size: 10px;
        font-weight: 400;
        text-transform: uppercase;
        color: #334155;
      }
      .subTitle{
        font-size: 16px;
        font-weight: 400;
        color: #334155 !important;

      }
      .subTitle span:first-child{
        margin-right: 4px ;
        font-weight: 500;
      }
    }
    .tableHeader{

      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;

      .actions{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        .ant-form-item{
          margin-bottom: 0 !important;
        }
        .type{
          margin-bottom: 16px !important;
        }
      }
      .detail{
        font-size: 14px;
        font-weight: 500;
        p{
          font-weight: 400;
          color: #64748b;
          margin: 0;
        }
      }
    }
`;

export const StyledCard = styled(Card)`
  border: 1px solid #cbd5e1 !important;
  position: relative;
  margin-bottom: 1rem;
  .ant-card-body{
    padding: 16px;
  }
  .invoice{
    margin-bottom: 0 !important;
  }
  .delete{
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 12px;
    padding: 4px 8px;
    cursor: pointer;
   
  }
`;

export const UsePaymentContainer = styled.div`
  padding: 1.3rem 0;
  .add-action{
    font-size: 12px;
    font-weight: 500;
    color: #475569;
    margin-top: 1rem;
    cursor: pointer;
  }
  .availableAmount{
    background-color: #F0F6FE;
    padding: 8px 16px;
    border-radius: 12px;
    margin-bottom: 16px;
    p{
      margin: 0;
      font-size: 12px;
      font-weight: 400;
      color: #475569;
    }
    .amount{
     color: #334155;
      font-size: 12px;
      font-weight: 500;
      margin-left: 2rem;
    }
  }
`;

export const PaymentContainer = styled.div`
  padding: 1.3rem ;
  display:flex;
  flex-direction: column !important ;
  gap: .6rem;
  .item{
    display: flex;
    align-items: center;
    /* gap: 50px; */
    justify-content: space-between;
  width: 200px;
  }
`;