import { Button } from "antd";
import styled from "styled-components";

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .info-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    h3{
      margin-bottom: 0 !important;
    }
  }
  .description {
    padding: 1.5rem;
    box-shadow: 0px 0px 5px -2px;
    border-radius: 20px;
  }
  
`;

export const BillingInfoContainer = styled.div`
  flex-direction: column;
  gap: 15px;
  display: flex;
  padding: 20px;
  .billing-info-item {
    flex-direction: column;
    gap: 2px;
    display: flex;

  

    .custom-field {
      font-size: 16px;
    }
  }
  .label{
    color: #334155;
    font-size: 12px;
    font-weight: 400;
  }
  .value{
    color: #1e293b;
    font-size: 16px;
    font-weight: 400;
  }
`;

export const StyledButton = styled(Button)`
    height: 33px;
    width: 100%;
    box-shadow: none;
    margin-top: 3px;
    svg{
      margin-right:0;
      font-size: 12px;
    }
    .icon{
    }
`;

export const StyledDiv = styled.div`
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  color:#475569;
   display: flex;
    justify-content: left;
    align-items: center;
    gap: 8px;
`;