import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import api from "../../../api/apiClient";
import { message } from "antd";

interface Credentials {
  email: string;
  password: string;
}

export const getUser = createAsyncThunk(
  'auth/getUser',
  async (_, { rejectWithValue }) => {
    try {
      const { data: user } = await api.get('/profile')
      console.log("⏩ ~ user.data:", user.data)
      return user.data;
    } catch (error: any) {
      console.log("🚀 ~ file: authThunk.tsx:24 ~ error:", error)
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);
