import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { authReducer } from "./feature/auth/authSlice";
import { adminCenterReducer } from "./feature/adminCenter/adminCenterSlice";
import { customerReducer } from "./feature/customer/customerSlice";
import PricePlan from "../pages/price-plans";
import { PricePlanSliceReducer } from "./feature/pricePlan/pricePlanSlice";
import { eventReducer } from "./feature/events/eventSlice";
import { billableReducer } from "./feature/billableItems/billableSlice";
import { billingReducer } from "./feature/billing/billingSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [],
};

const rootReducer = combineReducers({
  auth: authReducer,
  adminCenter: adminCenterReducer,
  customer: customerReducer,
  pricePlan: PricePlanSliceReducer,
  event: eventReducer,
  billable: billableReducer,
  billing:billingReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    }),
});

export const persister = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
