import { Button, Select } from "antd";
import styled from "styled-components";

export const StyledButton = styled(Button)`
  height: 30px !important;
  box-shadow: none;
  padding: 8px 12px;

  .btnText{
    font-size: 14px;
    font-weight: 400;
    color: #64748b;
  }
  svg{
    height: 11px;
    width: 11px;
    margin-left: 1.5rem;
    margin-right: 0;
  }
`;


export const FilterPanel = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 60px;
  right: 0;
  width: 300px;
  border-radius: 10px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  display: ${(props) => (props.visible ? "block" : "none")};
  z-index: 999;

  .action{
    display: flex ;
    justify-content: end ;
    margin-bottom: 10px ;
    background: #F8FAFC ;
    border-top-left-radius: 10px ;
    border-top-right-radius: 10px ;
  }
  .childrenContainer{
    padding: 20px;
    padding-top: 0;
  
  }
`;



export const StyledSelect = styled(Select)`
  width: 200px;
  height: 30px;
`;

