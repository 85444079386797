import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { PlanModal } from "../RateCard/Styles";
import CustomButton from "../../../../components/common/button";
import { CustomForm } from "../../../admin-center/aliasManagement/form/styles";
import React, { KeyboardEvent, useEffect, useState } from "react";
import { AutoComplete, Form, Input, Radio, Select } from "antd";
import Extra from "../../../../components/common/extra";
import {
  addBillingPricingRules,
  setPlanModalDetail,
} from "../../../../redux/feature/pricePlan/pricePlanSlice";
import { StyledRadio } from "../RateCard/forms/styles";
interface Option {
  value: string;
}
const { TextArea } = Input;
const EditPricingRule: React.FC<any> = ({
  editRule,
  setPricingRules,
  pricingRules,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const { billingDetails, planModalDetail } = useSelector(
    (state: any) => state.pricePlan
  );

  useEffect(() => {
    if (editRule) {
      form.setFieldsValue(editRule);
    } else {
      form.resetFields();
    }
  }, [editRule, form, planModalDetail]);

  const [value, setValue] = useState<string>("");
  const [options, setOptions] = useState<Option[]>([]);
  const [actionTypeValue, setActionTypeValue] = useState("update");

  const handleKeyDown = (e: KeyboardEvent) => {
    console.log("►►► ~ handleKeyDown ~ e:", e);
    const currentValue = (e.target as HTMLTextAreaElement).value;
    const lastChar = currentValue[currentValue.length - 1];
    console.log("►►► ~ handleKeyDown ~ lastChar:", lastChar);

    if (lastChar === "@") {
      setOptions([
        { value: "revenue.Rate_Card_Total​" },
        { value: "invoice.Start Date​" },
        { value: "invoice.End Date​" },
        { value: "invoice.Invoice Date​" },
        { value: "invoice.Due Date​" },
      ]);
    } else if (lastChar === "#") {
      setOptions([
        { value: "date()" },
        { value: "date.add()" },
        { value: "date.sub()" },
        { value: "duration.between()​" },
        { value: "duration.of()​" },
        { value: "duration.as()​" },
      ]);
    } else {
      setOptions([]);
    }
  };

  const actionTypeChange = (e: any) => {
    console.log("►►► ~ actionTypeChange ~ e:", e);
    setActionTypeValue(e);
  };
  const handleOnSave = async () => {
    try {
      const values = await form.validateFields();
      console.log("►►► ~ handleOnSave ~ values:", values);
      setPricingRules([...pricingRules, values]);
      dispatch(addBillingPricingRules(values));
      dispatch(setPlanModalDetail(""));
      form.resetFields();
    } catch (error) {
      console.log("►►► ~ handleOnSave ~ error:", error);
    }
  };

  const handleOnClose = () => {
    dispatch(setPlanModalDetail(""));
  };

  return (
    <PlanModal
      centered
      title={
        <div className="planModalTitle">
          <div className="title">Add Pricing Rule</div>
        </div>
      }
      open={planModalDetail === "Pricing Rule"}
      closeIcon={null}
      onCancel={handleOnClose}
      width={700}
      footer={[
        <div className="footer">
          <CustomButton
            text="Cancel"
            onClick={handleOnClose}
            icon={false}
            type={"default"}
          />
          <CustomButton
            type="primary"
            text="Save Rule"
            onClick={handleOnSave}
            icon={false}
          />
        </div>,
      ]}
    >
      <div className="modal-body">
        <div className="PlanModalBody">
          <CustomForm
            form={form}
            initialValues={{
              actionType: "update",
              invoiceTiming: "IN_ARREARS",
            }}
            layout="vertical"
          >
            <Form.Item
              label="Pricing Rule Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input the Pricing Rule Name!",
                },
              ]}
            >
              <Input placeholder="Pricing Rule Name" />
            </Form.Item>
            <Form.Item
              label="Invoice Association"
              name="invoiceTiming"
              extra={
                <Extra text="Fixed Fee will be applied to the current invoice. Proration is supported only for this mode." />
              }
            >
              <StyledRadio defaultValue={"IN_ARREARS"}>
                <Radio value="IN_ARREARS">In Arrears</Radio>
                <Radio value="IN_ADVANCE">In Advance</Radio>
              </StyledRadio>
            </Form.Item>
            <Form.Item
              label="Condition for the rule to apply"
              name="condition"
              extra={
                <Extra text="Condition must return a boolean value. Enter @ to choose from the variables. Enter # to use special functions, refer to doc for more information." />
              }
            >
              <AutoComplete
                options={options}
                value={value}
                onChange={setValue}
                onKeyUp={handleKeyDown}
                style={{ width: "100%" }}
              >
                <TextArea
                  placeholder="Enter @,# to insert the variables"
                  rows={3}
                  onChange={(e) => setValue(e.target.value)}
                  value={value}
                />
              </AutoComplete>
            </Form.Item>
            <Form.Item
              label="Computation"
              name="computation"
              extra={
                <Extra text="This is the computation logic to be applied. Computation must return a numeric value. Enter @ to choose from the variables.Enter # to use special functions, refer to doc for more information." />
              }
            >
              <AutoComplete
                options={options}
                value={value}
                onChange={setValue}
                onKeyUp={handleKeyDown}
                style={{ width: "100%" }}
              >
                <TextArea
                  placeholder="Enter @,# to insert the variables"
                  rows={3}
                  onChange={(e) => setValue(e.target.value)}
                  value={value}
                />
              </AutoComplete>
            </Form.Item>
            <Form.Item label="Action Type" name="actionType">
              <Select
                placeholder="Update existing Line Item"
                onChange={actionTypeChange}
                defaultValue={actionTypeValue}
              >
                <Select.Option value="update">
                  Update existing Line Item
                </Select.Option>
                <Select.Option value="add">Add new Line Item</Select.Option>
              </Select>
            </Form.Item>
            {actionTypeValue === "update" && (
              <Form.Item
                label="Apply on"
                name="applyOn"
                rules={[
                  {
                    required: true,
                    message: "Please input the Pricing Rule Name!",
                  },
                ]}
                extra={
                  <Extra text="This is the line item on which the computation will be applied on the invoice" />
                }
              >
                <Select placeholder="Apply on">
                  <Select.Option value="line1">
                    revenue.Fixed_Fee_Card.test
                  </Select.Option>
                  <Select.Option value="line2">
                    revenue.Rate_Card_Total
                  </Select.Option>
                </Select>
              </Form.Item>
            )}
            <Form.Item
              label="Description"
              name="description"
              extra={
                <Extra text="This will be the description of the line item added in the invoice." />
              }
              rules={[
                {
                  required: true,
                  message: "Please input the Description!",
                },
              ]}
            >
              <Input placeholder="Description" />
            </Form.Item>
          </CustomForm>
        </div>
      </div>
    </PlanModal>
  );
};

export default EditPricingRule;
