import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  setIsEditAccount,
  setIsEditCustomer,
  setIsNewCustomer,
} from "../../../redux/feature/customer/customerSlice";
import api from "../../../api/apiClient";
import CustomDrawer from "../../../components/common/drawer";
import { Form, Steps } from "antd";
import NewCustomerForm from "../NewCustomerForm";
import {
  createCustomer,
  createCustomerAccount,
  editCustomer,
  editCustomerAccount,
  getAllCustomer,
  getAllCustomerAccounts,
  getCustomerById,
} from "../../../redux/feature/customer/customerAsyncThunk";

const EditCustomer = () => {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [customerDetails, setCustomerDetails] = useState<any>();
  const dispatch = useDispatch<AppDispatch>();
  const {
    isNewCustomer,
    viewCustomerDetails,
    isEditCustomer,
    isEditAccount,
    viewAccountDetails,
    loading
  } = useSelector((state: RootState) => state.customer);

  const { AccountCurrency } = useSelector(
    (state: any) => state.adminCenter
  );
  const currenciesOptions = useMemo(() => {
    return AccountCurrency.find((currency: any)=>currency.is_base)
  }, [AccountCurrency]);

  useEffect(() => {
    const initialValues = {
      customer_account_id: viewAccountDetails.customer_account_id,
      account_name: viewAccountDetails.account_name,
      additional_currency_id: viewAccountDetails.additional_currency_id,
      net_term_days: viewAccountDetails?.net_term_days,
      billoptim_customer_account_id: viewAccountDetails.billoptim_customer_id,
      email: viewAccountDetails.account_email,
      tags:
        typeof viewAccountDetails?.tags === "string"
          ? JSON.parse(viewAccountDetails.tags)
          : viewAccountDetails?.tags || [],
      phone: viewAccountDetails.account_phone,
      address_1: viewAccountDetails.account_address_1,
      address_2: viewAccountDetails.account_address_2,
      city: viewAccountDetails.account_city,
      postal_code: viewAccountDetails?.account_postal_code,
      state: viewAccountDetails?.account_state,
      country_id: viewAccountDetails?.country_id,
    };
    if (isEditCustomer) {
      form.setFieldsValue(viewCustomerDetails);
    }
    if (isEditAccount) {
      form.setFieldsValue({
        phone: viewCustomerDetails?.phone,
        address_1: viewCustomerDetails?.address_1,
        address_2: viewCustomerDetails?.address_2,
        city: viewCustomerDetails?.city,
        postal_code: viewCustomerDetails?.postal_code,
        state: viewCustomerDetails?.state,
        country_id: viewCustomerDetails?.country_id,
      });
    }
    if (isEditAccount && viewAccountDetails) {
      form.setFieldsValue(initialValues);
    }
  }, [isEditCustomer, isEditAccount, viewCustomerDetails]);
  const handleDrawerClosed = () => {
    dispatch(setIsNewCustomer(false));
    dispatch(setIsEditCustomer(false));
    dispatch(setIsEditAccount(false));

    form.resetFields();
    setCurrentStep(0);
  };

  const handleStepChange = async (e: number) => {
    try {
      if (currentStep === 0) {
       const values=  await form.validateFields();
       form.setFieldsValue({ account_name: `${values.name}-account`,
        billoptim_customer_account_id:values?.billoptim_customer_id,
        additional_currency_id:currenciesOptions.additional_currency_id
        });
      }
      setCurrentStep(e);
    } catch (error) {
      console.log("►►► ~ handleStepChange ~ error:", error);
    }
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      if (currentStep === 0) {
        form.setFieldsValue({ account_name: `${values.name}-account`,
         billoptim_customer_account_id:values?.billoptim_customer_id,
         additional_currency_id:currenciesOptions.additional_currency_id
         });
       }
      if (isEditCustomer) {
        const payload = {
          customer_id: viewCustomerDetails.customer_id,
          name: values.name,
          email: values.email,
          phoneNumber: values.phone,
          line1: values.address_1,
          line2: values.address_2,
          postalCode: values.postal_code,
          city: values.city,
          state: values.state,
          country_id: values.country_id,
        };
        await dispatch(editCustomer(payload));
        await dispatch(getCustomerById({customer_id:viewCustomerDetails.customer_id}));
        form.resetFields()
      } else if (isEditAccount && viewAccountDetails) {
        dispatch(
          await editCustomerAccount({
            ...values,
            customer_id: viewAccountDetails?.customer_id,
            customer_account_id: viewAccountDetails?.customer_account_id,
          })
        );
        form.resetFields()
      } else if (isEditAccount) {
        await dispatch(
          createCustomerAccount({
            account: {
              ...values,
              customer_id: viewCustomerDetails.customer_id,
              account_id: values.billoptim_customer_account_id,
            },
          })
        );
        await dispatch(getCustomerById({customer_id:viewCustomerDetails.customer_id}));
        form.resetFields()
      } else {
        setCustomerDetails((prevVal: any) => ({
          ...prevVal,
          ...values,
        }));
        setCurrentStep(1);
        if (currentStep !== 0) {
          const payload = {
            customer: {
              name: customerDetails.name,
              email: customerDetails.email,
              phone: customerDetails.phone,
              address_1: customerDetails.address_1,
              address_2: customerDetails.address_2,
              city: customerDetails.city,
              postal_code: customerDetails.postal_code,
              state: customerDetails.state,
              country_id: customerDetails.country_id,
              billoptim_customer_id: customerDetails.billoptim_customer_id,
            },
            account: {
              account_name: values.account_name,
              billoptim_customer_account_id:
                values.billoptim_customer_account_id,
              email: values.email,
              address_1: values.address_1,
              address_2: values.address_2,
              city: values.city,
              postal_code: values.postal_code,
              state: values.state,
              additional_currency_id: values.additional_currency_id,
              tags: values.tags,
              net_term_days: values.net_term_days,
            },
          };
          await dispatch(createCustomer(payload));
          await dispatch(getAllCustomer({ sort: "-updated_at" }));
          await dispatch(getAllCustomerAccounts({ sort: "-updated_at" }));
          form.resetFields()
          setCurrentStep(0);

        }
      }
      
    } catch (error) {
      console.log("►►► ~ handleSubmit ~ error:", error);
    }
  };
  return (
    <CustomDrawer
      title={
        isEditCustomer
          ? "Edit Customer Details"
          : isEditAccount && viewAccountDetails
          ? "Edit Account Details"
          : isEditAccount
          ? "Add New Account"
          : "Add New Customer"
      }
      isOpen={isNewCustomer || isEditCustomer || isEditAccount}
      onClose={handleDrawerClosed}
      submitText={currentStep === 0 ? "Proceed" : "Create Customer"}
      onFinish={handleSubmit}
      loading={loading}
    >
      <div style={{ padding: "20px" }}>
        {!(isEditCustomer || isEditAccount) && (
          <Steps
            current={currentStep}
            items={[
              {
                title: "Customer Details",
              },
              {
                title: "Account Details",
              },
            ]}
            onChange={handleStepChange}
          />
        )}
        <NewCustomerForm form={form} currentStep={currentStep} />
      </div>
    </CustomDrawer>
  );
};

export default EditCustomer;
