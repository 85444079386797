import React, { useEffect, useMemo, useState } from "react";
import { Container } from "../../viewCustomer/customerDetails/Styles";
import { Button, Card, Col, Row, Tag } from "antd";
import { EmptyDataComponent } from "../../../../components/common/empty";
import { EditFilled, RightOutlined } from "@ant-design/icons";
import CustomButton from "../../../../components/common/button";
import { CustomDivider } from "../../../admin-center/userProfile/style";
import { Typography, Divider, Tooltip } from "antd";
import { CopyOutlined, EditOutlined } from "@ant-design/icons";
import { AccDetails, ContentStyles, CopyIcon } from "./Styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PricingScheduleCard from "./ViewSummary";
import { getAccountById } from "../../../../redux/feature/customer/customerAsyncThunk";
import { AppDispatch, RootState } from "../../../../redux/store";
import { copyToClipboard } from "../../../../utils/helper";
import { StyledCard } from "../../../billing/detailsPage/styles";
import PricePlanSchedule from "../PricePlanSchedule";
import ViewSchedule from "../PricePlanSchedule/ViewSchedule";
import ScheduleCard from "../PricePlanSchedule/ScheduleCard";
import { getAttachPricePlan } from "../../../../redux/feature/pricePlan/pricePlanThunk";
import Segment from "../../../../components/common/segmented";
import SearchInput from "../../../../components/common/search";
import FilterOption from "../../../../components/common/filter/FilterOption";
import PricePlanDetailsTable from "../../../price-plans/NewPricePlan/PricePlanTable";
import InvoiceTemplate from "../../../billing/detailsPage/invoiceGroupDetail/InvoiceTemplate";

const { Text, Title, Paragraph } = Typography;
const options = [
  {
    value: "-rateCardDetails.displayName",
    label: "Rate Card Name Descending",
  },
  {
    value: "2BrateCardDetails.displayName",
    label: "Rate Card Name Ascending",
  },
  {
    value: "-type",
    label: "Rate Card Type Descending",
  },
  {
    value: "2Btype",
    label: "Rate Card Type Ascending",
  },
];
const Summary: React.FC<any> = ({
  baseCurrencySymbol,
  viewAccountDetails,
  filter,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { accountId } = useParams();
  const { attachPricePlan, pricePlans } = useSelector(
    (state: RootState) => state.pricePlan
  );
  const [type, setType] = useState("Rate Cards");
  const [isEditTemplateModalOpen, setIsEditTemplateModalOpen] = useState(false);
  const editTemplateHandler = () => {
    setIsEditTemplateModalOpen(!isEditTemplateModalOpen);
  };

  const { AccountCurrency } = useSelector(
    (state: RootState) => state?.adminCenter
  );
  const currency = useMemo(() => {
    return (
      AccountCurrency?.find((item: any) => item.is_base === true)
      ?.currency_code || ''
    );
  }, [AccountCurrency]);

  useEffect(() => {
    if (accountId) {
       dispatch(getAttachPricePlan(accountId));
    }
  }, [ accountId]);

  const attachPricePlanData = useMemo(() => {
    return attachPricePlan.length > 0 ? attachPricePlan[0] : null;
  }, [attachPricePlan, accountId]);

  const filteredData = useMemo(() => {
    return pricePlans.find(
      (item: any) => item?.pricePlanId === attachPricePlanData?.price_plan_id
    );
  }, [attachPricePlanData]);

  const AddNewPlan = () => (
    <CustomButton
      type="primary"
      icon={<RightOutlined />}
      position="end"
      onClick={() => {
        navigate(
          `/customers/${viewAccountDetails.customer_id}/accounts/${viewAccountDetails.customer_account_id}/attach-price-plan`
        );
      }}
      text="Attach Price Plan"
    />
  );

  return (
    <Container>
      <Row gutter={24}>
        <Col span={16}>
          {attachPricePlan.length ? (
            <>
              <ScheduleCard
                attachPricePlan={[attachPricePlanData]}
                isSummary={true}
                onPlanClick={() => {}}
              />
              <div style={{ margin: "32px 0 24px 0" }}>
                <Segment
                  options={["Rate Cards", "Pricing Rules"]}
                  value={type}
                  onChange={setType}
                  isWhite={true}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <SearchInput width="300px" />
                <FilterOption
                  options={options}
                  defaultValue="-rateCardDetails.displayName"
                />
              </div>
              <PricePlanDetailsTable
                isOtherChargesShown={false}
                rateCards={filteredData}
                isAction={false}
                currency={currency}
                isNew={false}
              />
            </>
          ) : (
            <EmptyDataComponent
              title="No Price Plan Attached"
              description="Please attach a price plan to this account to initiate billing."
              addButton={AddNewPlan}
            />
          )}
        </Col>
        <Col span={8}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              borderBottom: "1px solid rgb(234, 88, 12);",
            }}
          >
            <StyledCard>
              <div className="revenue">
                <p>Monthly Revenue</p>
                <div className="item">
                  <span>0</span>
                  <span className="currency">{currency}</span>
                </div>
              </div>
            </StyledCard>
            <AccDetails>
              <div>
                <p>Account Name</p>
                <h5>{viewAccountDetails?.account_name} </h5>
              </div>
              <div>
                <p>Account ID</p>
                <h5>
                  {viewAccountDetails?.customer_account_id}{" "}
                  <CopyOutlined className="icon"  onClick={()=>{
                    copyToClipboard(viewAccountDetails?.customer_account_id)
                  }}/>{" "}
                </h5>
              </div>
              <div>
                <p>Customer ID</p>
                <h5 className="dummy">
                  {viewAccountDetails?.customer_id}{" "}
                  <CopyOutlined className="icon"  onClick={()=>{
                    copyToClipboard(viewAccountDetails?.customer_id)

                  }}/>{" "}
                </h5>
              </div>
              <div>
                <p>Usage MTD</p>
                <h5>-</h5>
              </div>
              <div>
                <p>Currency</p>
                <h5>{baseCurrencySymbol}</h5>
              </div>
              <div>
                <p>Primary Email</p>
                <h5>
                  {viewAccountDetails?.account_email}{" "}
                  <CopyOutlined className="icon" onClick={()=>{
                    copyToClipboard(viewAccountDetails?.account_email)
                    
                  }} />{" "}
                </h5>
              </div>
              <div>
                <p>Address</p>
                <h5>
                  {viewAccountDetails?.account_address_1}
                  {","}
                  {viewAccountDetails?.account_address_2}
                </h5>
              </div>
              <div className="rightContentCardItem">
                <p>Tags</p>
                <div style={{ marginBottom: "1rem" }}>
                  {Array.isArray(viewAccountDetails?.account?.tags) ? (
                    viewAccountDetails?.tags?.map(
                      (tag: string, index: number) => (
                        <Tag key={index} color="magenta">
                          {tag}
                        </Tag>
                      )
                    )
                  ) : typeof viewAccountDetails?.tags === "string" ? (
                    JSON.parse(viewAccountDetails.tags).map(
                      (tag: string, index: number) => (
                        <Tag key={index} color="magenta">
                          {tag}
                        </Tag>
                      )
                    )
                  ) : (
                    <Text>No tags available</Text>
                  )}
                </div>
              </div>
              <div>
                <p>Invoice Template</p>
                <h5 onClick={editTemplateHandler} className="edit">
                  Default Template <EditFilled />
                </h5>
              </div>
              <Divider dashed className="divider" />
              <div className="timeLine">
                <p>
                  Created <span>{viewAccountDetails?.created_at}</span>
                </p>
                <p>
                  Last Updated <span>{viewAccountDetails?.updated_at}</span>
                </p>
              </div>
            </AccDetails>
          </div>
        </Col>
        <InvoiceTemplate
          isOpen={isEditTemplateModalOpen}
          onClose={editTemplateHandler}
          modalFor="Account"
        />
      </Row>
    </Container>
  );
};

export default Summary;
