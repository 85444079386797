// src/features/auth/authSlice.ts
import { createSlice } from "@reduxjs/toolkit";
import { getInvoiceById, getInvoices } from "./billingThunk";

export interface BillingState {
    invoices:any[],
    loading:boolean ,
    selectedInvoice: any
}

const initialState: BillingState = {
    invoices: [],
    loading: false,
    selectedInvoice:''

};

const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    removeSelectedInvoice:(state)=>{
      state.selectedInvoice=''
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInvoices.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getInvoices.fulfilled, (state, action) => {
        state.invoices = action.payload;
        state.loading = false;

      })
      .addCase(getInvoiceById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getInvoiceById.fulfilled, (state, action) => {
        state.selectedInvoice = action.payload;
        state.loading = false;

      })
    }
  
});

export const {
  removeSelectedInvoice
} = billingSlice.actions;

export const billingReducer = billingSlice.reducer;
