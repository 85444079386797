import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import Modal from "../../../components/common/modal";
import ModalHeaderTitle from "../../../components/common/modalHeaderTitle";
import { AddAccountModalContainer } from "./styles";
import { Table } from "antd";
import { ReactNode, useState } from "react";
import { StyledTable } from "../../../components/common/table/styles";
import SearchInput from "../../../components/common/search";

type dataType = {
  key: string;
  accId: string;
  accName: string;
  custId: string;
  pricePlan: string;
  currency: string;
};

type AddAccountModalPropsType = {
  isOpen: boolean;
  onClose: () => void;
  modalTitle: ReactNode;
  submitText: string;
};

const columns = [
  {
    title: "Account ID",
    dataIndex: "accId",
    key: "accId",
  },
  {
    title: "Account Name",
    dataIndex: "accName",
    key: "accName",
  },
  {
    title: "Customer ID",
    dataIndex: "custId",
    key: "custId",
  },
  {
    title: "Price Plan",
    dataIndex: "pricePlan",
    key: "pricePlan",
  },
  {
    title: "Currency",
    dataIndex: "currency",
    key: "currency",
  },
];

const data: dataType[] = [
  {
    key: "1",
    accId: "123",
    accName: "testing",
    custId: "123",
    pricePlan: "-",
    currency: "USD",
  },
  {
    key: "2",
    accId: "123",
    accName: "testing",
    custId: "123",
    pricePlan: "-",
    currency: "USD",
  },
];
const AddAccountModal: React.FC<AddAccountModalPropsType> = ({
  isOpen,
  onClose,
  modalTitle,
  submitText,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Modal
      title={modalTitle}
      isOpen={isOpen}
      onClose={onClose}
      width="auto"
      successBtnTitle={submitText}
    >
      <AddAccountModalContainer>
        <SearchInput
        // value={searchTerm}
        // onChange={(e) => setSearchTerm(e.target.value)}
        />
        <StyledTable
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          pagination={false}
        />

        <p className="selectedItems">{selectedRowKeys.length} items Selected</p>
      </AddAccountModalContainer>
    </Modal>
  );
};

export default AddAccountModal;
