import { Form, Input, Radio, Select } from "antd";
import Extra from "../../../../components/common/extra";
import { StyledRadio } from "../../../price-plans/NewPricePlan/RateCard/forms/styles";

type NamedLicenseFieldsPropsType = {
  addOns: any;
};

const NamedLicenseFields: React.FC<NamedLicenseFieldsPropsType> = ({
  addOns,
}) => {
  return (
    <>
      <Form.Item
        label="License Addon"
        name="license_id"
        rules={[
          {
            required: true,
            message: "Please Select License Addon First!",
          },
        ]}
      >
        <Select size="large" placeholder="License Addon">
          {(addOns ?? []).map((addon: any) => {
            return (
              <Select.Option key={addon?.addOnId} value={addon?.addOnId}>
                {addon?.name}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Please input Name First!" }]}
      >
        <Input placeholder="Name" type="text" />
      </Form.Item>
      <Form.Item
        label="Action"
        name="action"
        extra={<Extra text="You can either add or remove license" />}
      >
        <StyledRadio defaultValue={"1"}>
          <Radio value={"1"}>Add</Radio>
          <Radio value={"-1"}>Remove</Radio>
        </StyledRadio>
      </Form.Item>
    </>
  );
};

export default NamedLicenseFields;
