import { Modal } from "antd";
import styled from "styled-components";

export const RateCardHeader = styled.div`
  border-bottom: 1px solid #cbd5e1;
  padding: 0.7rem 1.5rem;
  display: flex;
  justify-content: space-between;
  .title {
    font-size: 12px;
  }
  .rateCardLeftHeader {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
  }
  .addRateCard {
    display: flex;
    gap: 5px;
    font-weight: 600;
    font-size: 13px;
  }
  .addRateCard:hover {
    text-decoration: underline;
  }
`;

export const PlanModal = styled(Modal)`
  .modal-body {
    max-height: 500px;
    min-height: 200px;
    overflow-y: auto;  
  }
  .ant-modal-footer {
    margin-top: unset !important;
  }
  .ant-modal-header {
    margin-bottom: unset !important;
  }
  .ant-modal-content {
    padding: unset !important;
  }
  .planModalTitle {
    background-color: #f1f5f9;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .title {
      font-size: 16px;
      line-height: 19px;
      font-weight: 500;
    }
    .description {
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      word-break: break-word;
      color: #64748b;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    padding: 24px;
    background-color: #f1f5f9;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .footerStepAction{
      display: flex;
      gap: 1rem;
    }
  }
  .PlanModalBody {
    padding: 20px 24px 0 24px;
  }
  .steps{
    width: 50%;
  }
  .steps .ant-steps-item-title::after {
    /* width: 1rem; */
    border: 1px solid #ABB5C2;

  }
`;


