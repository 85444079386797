import React, { useEffect, useMemo, useState } from "react";
import {
  SearchOutlined,
  InfoCircleOutlined,
  CopyOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Card, Col, Input, Row, Table, Typography, message } from "antd";
import {
  ContentStyles,
  Container,
  Header,
  CardContainer,
  CopyIcon,
} from "./Styles"; // Adjust the path if necessary
import { CustomDivider } from "../../../admin-center/userProfile/style";
import { useDispatch, useSelector } from "react-redux";
import { copyToClipboard } from "../../../../utils/helper";
import { StyledTable } from "../../../../components/common/table/styles";
import { StyledCard } from "../../../billing/detailsPage/styles";
import { getCustomerById } from "../../../../redux/feature/customer/customerAsyncThunk";
import { AppDispatch } from "../../../../redux/store";
import { useNavigate, useParams } from "react-router-dom";

const { Title, Paragraph } = Typography;

const CustomerDetails: React.FC<any> = ({ viewCustomerDetails }) => {
  const { AccountCurrency, currencies } = useSelector(
    (state: any) => state.adminCenter
  );
  const dispatch = useDispatch<AppDispatch>();
  const param = useParams();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");
  const baseCurrencySymbol = useMemo(() => {
    const baseCurrencyId = AccountCurrency.find(
      ({ is_base }: { is_base: boolean }) => is_base
    )?.currency_id;

    const baseCurrency = currencies.find(
      ({ currency_id }: { currency_id: number }) =>
        currency_id === baseCurrencyId
    );

    return baseCurrency ? baseCurrency.currency_symbol : undefined;
  }, [AccountCurrency]);

  useEffect(() => {
    dispatch(
      getCustomerById({
        search: searchValue,
        customer_id: param?.customerId,
      })
    );
  }, [searchValue]);

  const customerAccountDataSource = useMemo(() => {
    return viewCustomerDetails?.customerAccount?.map((customerAc: any) => ({
      key: customerAc.customer_account_id,
      accountName: customerAc.account_name,
      customerId: customerAc.customer_id,
      accountId: customerAc.customer_account_id,
    }));
  }, [viewCustomerDetails]);

  const columns = [
    {
      title: "Account Name",
      dataIndex: "accountName",
      key: "accountName",
      render: (_: any, record: any) => {
        // const accountName = customerAccounts.filter((ac)=>ac.);
        return (
          <div>
            <div>
              <b>{record.accountName}</b>
            </div>
            <div>{record.customerId}</div>
          </div>
        );
      },
    },
    {
      title: "Pricing Plan",
      dataIndex: "pricingPlan",
      key: "pricingPlan",
      render: () => <div>-</div>,
    },
    {
      title: "Billed to-date",
      dataIndex: "billed",
      key: "billed",
      render: () => <div>$0.00</div>,
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text: any, record: any) => <RightOutlined />,
    },
  ];

  return (
    <Container>
      <Row gutter={24}>
        <Col span={16}>
          <Header>
            <Title level={4}>
              Total Accounts ({customerAccountDataSource?.length ?? '0'})
            </Title>
            <Input
              placeholder="Search"
              prefix={<SearchOutlined />}
              suffix={<InfoCircleOutlined />}
              style={{ width: "200px", height: "30px", alignItems: "center" }}
              onChange={(e: any) => {
                setSearchValue(e.target.value);
              }}
            />
          </Header>
          <StyledTable
            dataSource={customerAccountDataSource}
            columns={columns}
            pagination={false}
            onRow={(record: any) => {
              return {
                onClick: () => {
                  navigate(
                    `/customers/${record?.customerId}/accounts/${record?.accountId}/details`
                  );
                },
              };
            }}
          />
        </Col>
        <Col span={8}>
          <CardContainer>
            <StyledCard>
              <div>Monthly Revenue Across Accounts</div>
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <div>
                  <b>0</b> <span>{baseCurrencySymbol}</span>
                </div>
              </div>
            </StyledCard>

            <StyledCard>
              <ContentStyles>
                <div>Customer Portal Link</div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <Paragraph
                    ellipsis
                    style={{ maxWidth: "200px", marginBottom: 0 }}
                  >
                    http://localhost:3000/customer/12/details
                  </Paragraph>
                  <CopyIcon
                    onClick={() =>
                      copyToClipboard(
                        "http://localhost:3000/customer/12/details"
                      )
                    }
                  />
                </div>
              </ContentStyles>
              <CustomDivider />
              <ContentStyles>
                <div>Customer Name</div>
                <div>{viewCustomerDetails?.name}</div>
              </ContentStyles>
              <CustomDivider />
              <ContentStyles>
                <div>E-mail ID</div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <div>{viewCustomerDetails?.email}</div>
                  <CopyIcon
                    onClick={() => copyToClipboard(viewCustomerDetails?.email)}
                  />
                </div>
              </ContentStyles>
              <CustomDivider />
              <ContentStyles>
                <div>Customer ID</div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <div>{viewCustomerDetails?.customer_id}</div>
                  <CopyIcon
                    onClick={() =>
                      copyToClipboard(viewCustomerDetails?.customer_id)
                    }
                  />
                </div>
              </ContentStyles>
              <CustomDivider />
              <ContentStyles>
                <div>Total Accounts</div>
                <div>{customerAccountDataSource?.length ?? '0'}</div>
                <CustomDivider />
                <div>Address</div>
                <div>
                  {viewCustomerDetails?.address_1}
                  {","}
                  {viewCustomerDetails?.address_2}
                </div>
                <CustomDivider />
                <div>
                  <div>Created</div>
                  <div>{viewCustomerDetails?.created_at}</div>
                  <br />
                  <div>Last Updated</div>
                  <div>{viewCustomerDetails?.updated_at}</div>
                </div>
              </ContentStyles>
            </StyledCard>
          </CardContainer>
        </Col>
      </Row>
    </Container>
  );
};

export default CustomerDetails;
