import { Switch } from "antd";
import { TextedSwitchContainer } from "./styles";

type TextedSwitchPropType = {
  text: string;
  onChange?: () => void;
  checked?: boolean;
  width?: string;
  value?: any;
};

const TextedSwitch: React.FC<TextedSwitchPropType> = ({
  text,
  onChange,
  checked = false,
  width = "320px",
  value,
}) => {
  return (
    <TextedSwitchContainer checked={checked} width={width}>
      <p>{text}</p>
      <Switch
        checked={checked}
        size="small"
        onChange={onChange}
        value={value}
      />
    </TextedSwitchContainer>
  );
};

export default TextedSwitch;
