import { Card } from "antd";
import styled from "styled-components";

export const PricingLogCard = styled(Card)`
  border: 1px solid #E2E8F0;
  position: relative;
  

  .ant-card-body{
    padding: 1rem;
    padding-bottom: 0.5rem;
  }
  .cardNo{
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.4rem 0.7rem;
    background-color:#E2E8F0;
    color: black;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    font-size: 13px;
    font-weight: 600;
  }
  .cardItem{
    display: flex;
    gap: 10px;
    p{
      margin-top: 0;
      font-weight:600;
    }
  }
  .icon{
    height: 12px;
    width: 12px;
    margin-right: 5px;
  }
  .toggle{
    margin-bottom: 0;
    color: black;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
  }
  .computation{
    margin-bottom: 1rem;
    h4{
      margin-top: 0;
      font-size: 14px;
      font-weight: 600;
    }
    .computationItem{
      font-size: 12px;
      border: 1px solid #E2E8F0;
      border-radius: 8px;
    }
    .header{
      margin: 0;
      padding: 0.5rem 1rem;
      border-bottom: 1px solid #E2E8F0;
      
    }
    .data{
      margin: 0;
      padding: 0.5rem 1rem;
    }
    .evolution{
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 1rem;
    
      .computationItem{
        width: 75%;
      }
    }
    .ant-tag{
      padding: 0.3rem 0.5rem;
    }
  }

`;