import { Form, Input, Select } from "antd";
import { useMemo, useState } from "react";

type RowFieldPropsType = {
  events: any;
};

const RowField: React.FC<RowFieldPropsType> = ({ events }) => {
  const [eventSchema, setEventSchema] = useState<string>("");

  const selectedEvent = useMemo(
    () => events?.find((event: any) => event.event_schema_id === eventSchema),
    [events, eventSchema]
  );

  return (
    <>
      <Form.Item
        label="Event Schema Name"
        name="eventSchemaId"
        rules={[
          {
            required: true,
            message: "Please Select Event Schema Name First!",
          },
        ]}
      >
        <Select
          placeholder="Event Schema Name"
          onChange={setEventSchema}
        >
          {(events ?? []).map((event: any) => (
            <Select.Option key={event?.event_schema_id} value={event?.event_schema_id}>
              {event?.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {eventSchema && selectedEvent && (
        <>
          {selectedEvent?.attributes.length ? (
            <>
              <p className="eventSubTitle">
                Attributes <span>(Numeric Values only)</span>
              </p>
              {selectedEvent.attributes.map((atb: any) => (
                <Form.Item
                  key={atb.name}
                  label={atb.name}
                  name={atb.name}
                  rules={[{ required: true, message: `${atb.name} is required` }]}
                >
                  <Input placeholder={atb.name} type="number" />
                </Form.Item>
              ))}
            </>
          ):(
            <></>
          )}

          {selectedEvent?.dimensions.length ? (
            <>
              <p className="eventSubTitle">Dimensions</p>
              {selectedEvent.dimensions.map((dm: any) => (
                <Form.Item
                  key={dm}
                  label={dm}
                  name={dm}
                  rules={[{ required: true, message: `${dm} is required` }]}
                >
                  <Input placeholder={dm} type="text" />
                </Form.Item>
              ))}
            </>
          ):(
            <></>
          )}
        </>
      )}
    </>
  );
};

export default RowField;
