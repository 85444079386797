import styled from "styled-components";

export const PerUnitCalculationFilterContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  .icon{
    border: 1px solid grey;
    background-color: white;
    border-radius: 10px;
    padding: 0.2rem 0.5rem  ;
    cursor: pointer;
  }
`;

