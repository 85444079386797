import React, { useEffect, useMemo, useState } from "react";
import {
  CaretDownOutlined,
  CopyOutlined,
  DeleteOutlined,
  DeleteRowOutlined,
  DownOutlined,
  InfoCircleOutlined,
  LeftOutlined,
  SearchOutlined,
  TagOutlined,
} from "@ant-design/icons";
import CustomButton from "../../../components/common/button";
import { PlanCard, ViewPlanContainer, ViewPricePlanHeader } from "./Styles";

import {
  Card,
  Tag,
  Switch,
  Button,
  Select,
  Tooltip,
  Divider,
  Row,
  Col,
  Segmented,
  Typography,
} from "antd";
import {
  ReloadOutlined,
  LockOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { ScheduleItemWrapper } from "../../customer/viewAccount/PricePlanSchedule/AttachPricePlan/SchedulePreview/Styles";
import { ScheduleData } from "../../customer/viewAccount/PricePlanSchedule/Styles";
import {
  ContentStyles,
  CopyIcon,
} from "../../customer/viewCustomer/customerDetails/Styles";
import { SearchInput } from "../../customer/table/Styles";
import PricePlanDetailsTable from "../NewPricePlan/PricePlanTable";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { removeSinglePricePlanData } from "../../../redux/feature/pricePlan/pricePlanSlice";
import { EmptyDataComponent } from "../../../components/common/empty";
import Modal from "../../../components/common/modal";
import {
  activatePricePlan,
  deletePricePlan,
  getSinglePricePlan,
} from "../../../redux/feature/pricePlan/pricePlanThunk";
import { AxiosError } from "axios";
import PlanRateCardTable from "./PlanRateCardTable";
import { Container } from "../NewPricePlan/BillingDetails/Styles";
import PageLoading from "../../../components/Loader";
import { copyToClipboard } from "../../../utils/helper";
import { setFilter } from "../../../redux/feature/customer/customerSlice";

const { Option } = Select;
const { Text } = Typography;

const ViewPricePlan: React.FC<any> = () => {
  const { singlePricePlan } = useSelector(
    (state: RootState) => state.pricePlan
  );
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  console.log("data-------> ", singlePricePlan);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getSinglePricePlan(id));
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(removeSinglePricePlanData(null));
    };
  }, [dispatch]);

  const { creditGrantCount, fixedCount } = useMemo(() => {
    const creditGrantCount = singlePricePlan
      ? singlePricePlan?.rateCards?.filter(
          (item: any) => item.type === "CREDIT_GRANT"
        ).length
      : 0;

    const fixedCount = singlePricePlan
      ? singlePricePlan?.rateCards?.filter(
          (item: any) => item.type === "FIXED_FEE"
        ).length
      : 0;

    return { creditGrantCount, fixedCount };
  }, [singlePricePlan]);

  const onChange = (checked: boolean) => {
    try {
      dispatch(activatePricePlan(singlePricePlan.pricePlanId));
    } catch (err) {
      const error = err as AxiosError;
      console.log("🚀 ~ file: index.tsx:73 ~ onChange ~ error:", error);
    }
  };

  return singlePricePlan ? (
    <ViewPlanContainer>
      <ViewPricePlanHeader>
        <div className="headerStyles">
          <CustomButton
            icon={<LeftOutlined />}
            onClick={() => {
              dispatch(removeSinglePricePlanData(null));
              dispatch(setFilter("-updated_at"));

              navigate("/price-plan");
            }}
            text="Back"
            border={false}
            type="default"
          />
          <div className="header-btn">
            <CustomButton
              icon={<CopyOutlined />}
              onClick={() => {}}
              text="Clone"
              border={false}
              type="default"
            />
            <CustomButton
              icon={<DeleteRowOutlined />}
              onClick={() => {
                setIsDeleteModal(true);
              }}
              text="Archive"
              border={false}
              type="default"
            />
            <CustomButton
              icon={<DownOutlined />}
              onClick={() => {}}
              text="Download XLSX"
              border={false}
              type="default"
            />
          </div>
        </div>
      </ViewPricePlanHeader>
      <div style={{ padding: "0 7rem" }}>
        <PlanCard>
          <div className="card-header ">
            <div className="tooltip-container">
              <Tooltip title="Price Plan">
                <span className="icon-span">
                  <TagOutlined
                    style={{
                      fontSize: "30px",
                      rotate: "-87deg",
                      color: "white",
                    }}
                  />
                </span>
              </Tooltip>
              <div>
                <h5 style={{ margin: "0" }}>Price Plan Details</h5>
                <div className="plan_name">{singlePricePlan?.name}</div>
                <div className="status">
                  <Tag color="green">{singlePricePlan?.status}</Tag>
                  <Tag icon={<ReloadOutlined />} color="orange">
                    {singlePricePlan?.type === "PURCHASE"
                      ? "One-Time"
                      : "Recurring "}
                  </Tag>
                </div>
              </div>
            </div>
            <div className="card-body-right-side">
              <div className="content">
                <div>Toggle Status</div>
                <Tooltip title=" Price plan cannot be Deactivated">
                  <div style={{ display: "flex", gap: "10px" }}>
                    <LockOutlined />
                    <Switch
                      disabled={singlePricePlan.status === "ACTIVE"}
                      checked={singlePricePlan.status === "ACTIVE"}
                      onChange={onChange}
                    />
                  </div>
                </Tooltip>
              </div>

              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <Select defaultValue="v1 (latest)" style={{ width: 150 }}>
                  <Option value="v1">v1 (latest)</Option>
                  <Option value="v2">v2</Option>
                </Select>
                <CustomButton
                  type="default"
                  text="Add Version"
                  icon={<PlusOutlined />}
                  position="start"
                  onClick={() => {}}
                />
              </div>
            </div>
          </div>
        </PlanCard>
        <Row gutter={24}>
          <PlanRateCardTable
            selectedPricePlan={singlePricePlan.pricePlanId}
            isAddCurrency={true}
            isAddRoutes={true}
          />
          <Col span={6}>
            <PlanCard>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <ContentStyles>
                  <div>Price Plan ID</div>
                  <b>
                    {singlePricePlan.pricePlanId}
                    <CopyIcon
                      onClick={() =>
                        copyToClipboard(singlePricePlan.pricePlanId)
                      }
                    />
                  </b>
                </ContentStyles>
                {singlePricePlan?.type === "BILLING" ? (
                  <>
                    <ContentStyles>
                      <div>Pricing Cycle</div>
                      <b>{singlePricePlan?.pricingCycleInterval}</b>
                    </ContentStyles>
                    <ContentStyles>
                      <div>Pricing Cycle Type</div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <div>
                          <b>
                            {singlePricePlan?.anniversaryCycle
                              ? "Anniversary Cycle"
                              : `${singlePricePlan?.pricingCycleStartOffsetDay} of every ${singlePricePlan?.pricingCycleInterval}`}
                          </b>
                        </div>
                      </div>
                    </ContentStyles>
                    <ContentStyles>
                      <div>Grace Period</div>
                      {singlePricePlan?.gracePeriod ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <b>{singlePricePlan?.gracePeriod}</b>
                          <CopyIcon
                            onClick={() =>
                              copyToClipboard(singlePricePlan?.gracePeriod)
                            }
                          />
                        </div>
                      ) : (
                        <>-</>
                      )}
                    </ContentStyles>
                    <ContentStyles>
                      <div>Total Associated Accounts</div>
                      <b>0</b>
                    </ContentStyles>
                    <ContentStyles>
                      <div>Associated Accounts with Overrides</div>
                      <b>0</b>
                    </ContentStyles>
                    <ContentStyles>
                      <div>Mid-cycle pricing changes</div>
                      <b>-</b>
                    </ContentStyles>
                    <ContentStyles>
                      <div>View real-time revenue analytic</div>
                      <b>-</b>
                    </ContentStyles>
                  </>
                ) : (
                  <>
                    <ContentStyles>
                      <div>Entitlement Features</div>
                      <b>0</b>
                    </ContentStyles>
                    <ContentStyles>
                      <div>Credit Grants</div>
                      <b>{creditGrantCount}</b>
                    </ContentStyles>
                    <ContentStyles>
                      <div>Fixed Fees</div>
                      <b>{fixedCount}</b>
                    </ContentStyles>
                  </>
                )}

                <ContentStyles>
                  <div>
                    <div>Last Updated</div>
                    <b>{singlePricePlan?.updatedAt}</b>
                  </div>
                </ContentStyles>
                <ContentStyles>
                  <div>
                    <div>Created</div>
                    <b>{singlePricePlan?.createdAt}</b>
                  </div>
                </ContentStyles>
              </div>
            </PlanCard>
          </Col>
        </Row>
      </div>
      <Modal
        title="Archive Price Plan?"
        isOpen={isDeleteModal}
        onSubmit={() => {
          dispatch(deletePricePlan(singlePricePlan.pricePlanId));
          navigate("/price-plan");
        }}
        onClose={() => setIsDeleteModal(false)}
        successBtnTitle="Archive"
        icon={<DeleteOutlined />}
      >
        <div style={{ padding: "2rem 0px" }}>
          <Text>
            The Price Plan will no longer be available for association with any
            accounts. The action cannot be undone.
          </Text>
        </div>
      </Modal>
    </ViewPlanContainer>
  ) : (
    <PageLoading />
  );
};

export default ViewPricePlan;
