import { Segmented, SegmentedProps } from "antd";
import styled from "styled-components";

interface StyledSegmentedProps extends SegmentedProps {
  isWhite?: boolean;
}

export const StyledSegmented = styled(Segmented) <StyledSegmentedProps>`
    height: 35px;
   border:1px solid #cbd5e1 !important ;
   background-color: ${(props) => props.isWhite ? "#F8FAFC" : "white"};
   padding: 4px !important;
   border-radius: 8px !important;

  .ant-segmented-item-selected {
    color: ${(props) => props.isWhite ? "black" : "white"};
    background-color: ${(props) => props.isWhite ? "white" : "#475569"};
  }
  .ant-segmented-item-label{
    font-size:12px;
    font-weight: 400;
    padding: 6px 8px !important;
    line-height: initial;
  }
  .ant-segmented-group{
    height: 100% !important;
  }

`;