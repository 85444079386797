import { ReactNode } from "react";
import { StyledHeaderDiv } from "./styles";

const ModalHeaderTitle: React.FC<{
  title: string;
  subtitle: string | ReactNode;
}> = ({ title, subtitle }) => {
  return (
    <StyledHeaderDiv>
      <p className="headerTitle">{title}</p>
      <p className="headerSubTitle">{subtitle}</p>
    </StyledHeaderDiv>
  );
};

export default ModalHeaderTitle;
