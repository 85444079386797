import { CopyOutlined } from "@ant-design/icons";
import { Card } from "antd";
import styled from "styled-components";

export const ContentStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
export const CopyIcon = styled(CopyOutlined)`
  cursor: pointer;
  color: #1890ff;
  &:hover {
    color: #40a9ff;
  }
`;
export const AccDetails = styled(Card)`
  .ant-card-body{
    display: flex;
    border-radius: 12px;
    flex-direction: column;
    box-shadow: 0px 2px 8px 0px #cbd5e1 !important;
       p{
        color: #64748b;
        font-size: 12px;
        font-weight: 400;
        margin:0;
        margin-bottom: 5px;
      }
      h5{
        margin: 0;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        .icon{
          margin-left: 5px;
          height: 12px;
          width: 12px;
        }
      }
      .edit{
        cursor: pointer;
      }
      .divider{
        margin: 0;
        margin-bottom: 0.7rem;
        border-top: 1px dashed black;
      }
      .timeLine{
        p{
          color: #334155;
        }
        span{
          margin-left: 5px;
        }
      }
  }
  .mainRow{
    width: 100%;
    background-color: #F8FAFC;
    border-radius: 10px;
    padding: 10px;
    
    .mainColumn{
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

  .innerRow{
    padding:0;
  }

  }
  .ant-col{
    h6{
      margin: 0;
      color:#6476A8;
      font-size: 13px;
    } 
    p{
      margin-bottom: 0;
      font-size: 15px;
      margin-top: 0;
    }
  }
`;
