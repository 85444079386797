import { Col, Tag } from "antd";
import { StyledRow } from "./styles";
import React from "react";
const CommonExpandItem: React.FC<{ record: any; currency?: any }> = ({
  record,
  currency,
}) => {
  console.log("►►► ~ record:", record);
  return (
    <>
      <StyledRow>
        <Col span={6} className="header">
          <span>Rate Card Configuration</span>
        </Col>
        <Col span={18}>
          <div className="configuration">
            {record?.map((item: any) => {
              return (
                <>
                  {item?.cardType === "CREDIT_GRANT" ||
                    item?.cardType === "Credit Grant Fee" ? (
                    <>
                      <div className="configurationItem">
                        <span>Billable ID</span>
                        <span className="id">
                          {item.id ?? item?.rateCardId}
                        </span>
                      </div>
                      <div className="configurationItem">
                        <span>Invoice Timing</span>
                        <span className="id">
                          <Tag>
                            {item.invoiceTiming === "IN_ADVANCE"
                              ? "In Advance "
                              : "In Arrears"}
                          </Tag>
                        </span>
                      </div>
                      <div className="configurationItem">
                        <span>Applicable Entities</span>
                        <span className="id">
                          {item?.grantDetails?.applicableEntityIds[0] ===
                            "ADD_ONS_AND_FEATURES"
                            ? "All Usage Meters, Add-Ons & Features"
                            : item?.grantDetails?.applicableEntityIds[0] ===
                              "ALL_USAGE_METERS"
                              ? "All Usage Meters"
                              : item?.grantDetails?.applicableEntityIds[0] ===
                                "ALL_FIXED_FEES"
                                ? "All Fixed Fees"
                                : item?.grantDetails?.applicableEntityIds[0] ===
                                  "ALL_LICENSE_FEES"
                                  ? "All License Fees"
                                  : item?.grantDetails?.applicableEntityIds[0] ===
                                    "ALL_FEATURES"
                                    ? "All Features"
                                    : item?.grantDetails?.applicableEntityIds[0] ===
                                      "ALL_CREDIT_GRANT_FEES"
                                      ? "All Credit Grant Fees"
                                      : item?.grantDetails?.applicableEntityIds[0] ===
                                        "ALL_CREDIT_GRANT_FEES_EXCLUDING_SELF"
                                        ? "All Credit Grant Fees Excluding Self"
                                        : item?.grantDetails?.applicableEntityIds[0] ===
                                          "SPECIFIC_USAGE_METERS_ADD_ONS_AND_FEATURES"
                                          ? "Specific Usage Meters, Add-Ons & Features"
                                          : ""}
                        </span>
                      </div>
                      <div className="configurationItem">
                        <span>Priority</span>
                        <span className="id">
                          {item?.grantDetails?.priority ?? "-"}
                        </span>
                      </div>
                      <div className="configurationItem">
                        <span>Recurrence Type</span>
                        <span className="id">
                          <Tag>
                            {item.recurringRateCard?.type ?? item?.type}
                          </Tag>
                        </span>
                      </div>
                    </>
                  ) : item?.cardType === "FIXED_FEE" ||
                    item?.cardType === "Fixed Fee" ? (
                    <>
                      <div className="configurationItem">
                        <span>Billable ID</span>
                        <span className="id">{item.id || item.rateCardId}</span>
                      </div>
                      <div className="configurationItem">
                        <span>Invoice Timing</span>
                        <span className="id">
                          <Tag>
                            {" "}
                            {item.invoiceTiming === "IN_ADVANCE"
                              ? "In Advance "
                              : "In Arrears"}
                          </Tag>
                        </span>
                      </div>
                      <div className="configurationItem">
                        <span>Proration</span>
                        <span className="id">
                          {item.enableProration ? (
                            <Tag>Enable</Tag>
                          ) : (
                            <Tag>Disable</Tag>
                          )}
                        </span>
                      </div>
                      <div className="configurationItem">
                        <span>Recurrence Type</span>
                        <span className="id">
                          <Tag>{item?.type}</Tag>
                        </span>
                      </div>
                      {item?.recurrenceConfig && (
                        <>
                          <div className="configurationItem">
                            <span>Repeat every nth cycle</span>
                            <span className="id">
                              {item?.recurrenceConfig?.interval ?? "-"}
                            </span>
                          </div>
                          <div className="configurationItem">
                            <span>Start from nth cycle</span>
                            <span className="id">
                              {item?.recurrenceConfig?.offset ?? "-"}
                            </span>
                          </div>
                        </>
                      )}
                    </>
                  ) : item?.cardType === "LICENSE" ||
                    item?.cardType === "License Fee" ? (
                    <>
                      <div className="configurationItem">
                        <span>Billable ID</span>
                        <span className="id">{item.rateCardId}</span>
                      </div>
                      <div className="configurationItem">
                        <span>Invoice Timing</span>
                        <span className="id">
                          <Tag>
                            {" "}
                            {item.invoiceTiming === "IN_ADVANCE"
                              ? "In Advance "
                              : "In Arrears"}
                          </Tag>
                        </span>
                      </div>
                      <div className="configurationItem">
                        <span>Applicable Entities</span>
                        <span className="id">
                          <Tag>{item.invoiceTiming}</Tag>
                        </span>
                      </div>
                      <div className="configurationItem">
                        <span>Recurrence Type</span>
                        <span className="id">
                          <Tag>{item.recurringRateCard?.type}</Tag>
                        </span>
                      </div>
                    </>
                  ) : item?.cardType === "USAGE_BASED_FEE" ||
                    item.cardType === "Usage Based Fee" ? (
                    <>
                      <div className="configurationItem">
                        <span>Usage Meter ID</span>
                        <span className="id">
                          {item.id || item.usageMeterId}
                        </span>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
          </div>
        </Col>
      </StyledRow>

      <StyledRow>
        <Col span={6} className="header">
          <span>Price Configuration</span>
        </Col>
        <Col
          span={18}
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          {record?.map((item: any, recordIndex: number) => {
            if (
              item?.cardType === "USAGE_BASED_FEE" ||
              item?.cardType === "Usage Based Fee" ||
              item?.cardType === "LICENSE"
            ) {
              return (
                <React.Fragment key={recordIndex}>
                  {item?.ratePlan?.slabs?.map(
                    (slab: any, slabIndex: number) => {
                      console.log("►►► ~ {record.map ~ slab:", slab);
                      const nextSlab = item?.ratePlan?.slabs[slabIndex + 1];
                      const index = item?.rateValues?.findIndex((item: any) => {
                        return item?.currency === currency;
                      });
                      const rateValue = item?.rateValues.length
                        ? item?.rateValues[index !== -1 ? index : 0]
                        : {};

                      return (
                        <div key={slabIndex}>
                          <div className="configuration priceConfiguration">
                            <div className="priceItem left">
                              <span>
                                {slab?.startAfter} -{" "}
                                {nextSlab
                                  ? nextSlab?.startAfter
                                  : "Infinity (∞)"}
                              </span>
                              <span>
                                {slab?.priceType}
                                {slab?.priceType === "package"
                                  ? `-${slab?.slabConfig?.packageSize}`
                                  : ""}
                              </span>
                            </div>
                            <div className="priceItem right">
                              {rateValue?.slabRates[slabIndex]?.rate && (
                                <span>
                                  <span className="currency">
                                    {rateValue?.currency}{" "}
                                  </span>
                                  {rateValue?.slabRates[slabIndex]?.rate}
                                </span>
                              )}
                              {(rateValue?.slabRates[slabIndex]?.slabRateConfig
                                ?.minimumRate ||
                                rateValue?.slabRates[slabIndex]?.slabRateConfig
                                  ?.maximumRate) && (
                                <span>
                                  Min:{" "}
                                  {
                                    rateValue?.slabRates[slabIndex]
                                      ?.slabRateConfig?.minimumRate
                                  }{" "}
                                  Max:{" "}
                                  {
                                    rateValue?.slabRates[slabIndex]
                                      ?.slabRateConfig?.maximumRate
                                  }
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                  {item?.rateValues.length &&
                  item?.rateValues[0]?.rateConfig ? (
                    (() => {
                      const index = item?.rateValues?.findIndex(
                        (rateValue: any) => rateValue?.currency === currency
                      );
                      const rateValuesData =
                        item?.rateValues[index !== -1 ? index : 0] || {};

                      return (
                        <React.Fragment key={index}>
                          {rateValuesData?.rateConfig?.minimumRate && (
                            <div className="configuration priceConfiguration">
                              <div className="priceItem left minMax">
                                <span>Minimum Price</span>
                              </div>
                              <div className="priceItem right">
                                <span>
                                  <span className="currency">
                                    {rateValuesData?.currency}{" "}
                                  </span>
                                  {rateValuesData?.rateConfig?.minimumRate}
                                </span>
                              </div>
                            </div>
                          )}
                          {rateValuesData?.rateConfig?.maximumRate && (
                            <div className="configuration priceConfiguration">
                              <div className="priceItem left minMax">
                                <span>Maximum Price</span>
                              </div>
                              <div className="priceItem right ">
                                <span>
                                  <span className="currency">
                                    {rateValuesData?.currency}{" "}
                                  </span>
                                  {rateValuesData?.rateConfig?.maximumRate}
                                </span>
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      );
                    })()
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              );
            } else if (
              item?.cardType === "CREDIT_GRANT" ||
              item?.cardType === "Credit Grant Fee"
            ) {
              const index =
                item?.rateDetails?.currencySlabRateDetails?.findIndex(
                  (item: any) => {
                    return item.currency === currency;
                  }
                );
              const creditGrantData =
                item?.rateDetails?.currencySlabRateDetails[
                  index !== -1 ? index : 0
                ];

              return (
                <div>
                  <div
                    className="configuration priceConfiguration"
                    style={{ marginBottom: "1rem" }}
                  >
                    <div className="priceItem left">
                      <span>Price</span>
                    </div>
                    <div className="priceItem right">
                      <span>
                        <span className="currency">
                          {creditGrantData?.currency}{" "}
                        </span>
                        {creditGrantData?.slabDetails?.[0]?.rate}
                      </span>
                    </div>
                  </div>
                  <div className="configuration priceConfiguration">
                    <div className="priceItem left">
                      <span>Credit Grants</span>
                    </div>
                    <div className="priceItem right">
                      <span>
                        <span className="currency">
                          {creditGrantData?.currency}{" "}
                        </span>
                        {creditGrantData?.creditAmount}
                      </span>
                    </div>
                  </div>
                </div>
              );
            } else {
              const index = item?.rateValues?.findIndex((item: any) => {
                return item?.currency === currency;
              });
              const rateValuesData =
                item?.rateValues[index !== -1 ? index : 0] || {};
              return (
                <div>
                  <div className="configuration priceConfiguration">
                    <div className="priceItem left">
                      <span>Price</span>
                    </div>
                    <div className="priceItem right">
                      <span>
                        <span className="currency">
                          {rateValuesData?.currency}{" "}
                        </span>
                        {rateValuesData?.rate}
                      </span>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </Col>
      </StyledRow>
    </>
  );
};
export default CommonExpandItem;
