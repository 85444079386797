import { Button } from "antd";
import styled from "styled-components";

export const StyledButton = styled(Button)`
    padding: 8px 12px;
    border-Radius: 8px;
    line-height: 33px !important;
     svg{
        margin-right: 0 !important;
    }
`;