import styled from "styled-components";
import { Card } from "antd";

export const ScheduleCycleCard = styled(Card)`
    background-color: #f8fafc;

  .card_body {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 14px;
    line-height: 1.5;
  }

  b {
    font-weight: bold;
  }
`;

export const ScheduleVersion = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  font-size: 14px;
  font-weight: 600;
  gap: 5px;

  span {
    display: inline-block;
  }
`;

export const ScheduleData = styled.div`
      border: 1px solid #cbd5e1;
      padding: 8px 10px;
      border-radius: 12px;
      color:#334455 ;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      svg {
        font-size: 12px; 
        text-align : center;
      }
      p{
        line-height: 12px;
      }

  
`;

export const ScheduleContainer = styled.div`
  .schedule-container {
    padding: 16px;
  }

  .schedule-card {
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  .schedule-title {
    font-size: 18px;
    font-weight: bold;
  }

  .view-schedules {
    margin-bottom: 16px;
    font-size: 14px;
    color: #1890ff;
  }
`;
