import { Form, Select } from "antd";
import { useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { PerUnitCalculationFilterContainer } from "./styles";

export const operatorData = [
  { label: "Sum (+)", value: "+" },
  { label: "Subtract (-)", value: "-" },
  { label: "Multiply (*)", value: "*" },
  { label: "Divide (/)", value: "/" },
  { label: "Modulus (%)", value: "%" },
];

const PerUnitCalculationFilter: React.FC<{
  remove: (id: number) => void;
  id: number;
  attributeOption: any;
  name1: string;
  name2: string;
  onChange: () => void;
}> = ({ remove, id, attributeOption, name1, name2, onChange }) => {
  return (
    <div>
      <PerUnitCalculationFilterContainer>
        <Form.Item
          label="Operator"
          name={name1}
          required={false}
          className="flexItem"
          rules={[
            {
              required: true,
              message: `Please select operator First!`,
            },
          ]}
        >
          <Select
            placeholder="Operator"
            onChange={onChange}
            options={operatorData}
          />
        </Form.Item>
        <Form.Item
          label="Choose Attribute/Constant"
          name={name2}
          required={false}
          rules={[
            {
              required: true,
              message: `Please choose Attribute/Constant First!`,
            },
          ]}
        >
          <Select
            placeholder="Choose Attribute/Constant"
            options={attributeOption}
            onChange={onChange}
          />
        </Form.Item>
        <div className="icon" onClick={() => remove(id)}>
          <DeleteOutlined />
        </div>
      </PerUnitCalculationFilterContainer>
    </div>
  );
};

export default PerUnitCalculationFilter;
