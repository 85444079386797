import { Card, Dropdown, Table, Tag } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import CommonExpandItem from "../RateCard/expandItems/CommonExpandItem";
import {
  deleteOverRideRate,
  removeBillingRateCard,
  setOverRideRateCards,
  setPlanModalDetail,
} from "../../../../redux/feature/pricePlan/pricePlanSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { DownOutlined, MoreOutlined, RightOutlined, UpOutlined } from "@ant-design/icons";
import {
  reverseTransformRateCard,
  transformPricePlanPayload,
} from "../../../../utils/helper";
import { StyledTable } from "../../../../components/common/table/styles";
import styled from "styled-components";

type PricePlanDetailsKeys =
  | "creditGrantRateCards"
  | "fixedFeeRateCards"
  | "licenseRateCards"
  | "usageRateCards"
  | "entitlementOverageRateCards";

const PricePlanDetailsTable: React.FC<any> = ({
  pagination,
  setEditCardData,
  setRateCards,
  rateCards,
  isAttachPricePlan,
  isAction = true,
  searchTerm = "",
  isOtherChargesShown = true,
  currency,
  isNew,
}) => {
  console.log("⏩ ~ currency:", currency);
  console.log("►►► ~ rateCards11111111111111111:", rateCards);
  const dispatch = useDispatch<AppDispatch>();
  const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);

  const { pricePlans, isOverridePlan, billingDetails, singlePricePlan } =
    useSelector((state: RootState) => state.pricePlan);
  const { filter } = useSelector((state: any) => state.customer);

  const handleCardRemove = (record: any) => {
    const data = rateCards?.rateCards.filter(
      (card: any) => card.id !== record.id
    );
    dispatch(removeBillingRateCard(record?.id));
    dispatch(deleteOverRideRate(record));
    setRateCards(data);
  };

  useEffect(() => {
    if (isOverridePlan) {
      console.log("►►► ~ useEffect ~ isOverridePlan:", isOverridePlan);
      dispatch(
        setOverRideRateCards(
          singlePricePlan.rateCards.map((rateCard: any) => ({
            ...rateCard,
            action: "update",
          }))
        )
      );
    } else {
      dispatch(setOverRideRateCards([]));
    }
  }, [isOverridePlan]);

  //   const rateCardDataSource = useMemo(() => {
  //     let pricePlanData = [];

  //     if (selectedPricePlan) {
  //       const data = pricePlans
  //       ?.find((p: any) => p.pricePlanId === selectedPricePlan);
  //       const pricePlanDetails = reverseTransformPricePlanPayload(data)
  //       pricePlanData = pricePlanDetails?.rateCards || [];
  //     }  else {
  //       pricePlanData = rateCards.map((rate: any) => rate);
  //     }

  //     // Filter based on the search term
  //     if (searchTerm) {
  //       pricePlanData = pricePlanData.filter((item: any) =>
  //         item.displayName.toLowerCase().includes(searchTerm.toLowerCase())
  //     );
  //   }

  //   return pricePlanData = pricePlanData.map((item: any) => ({
  //     ...item,
  //     key: item.id, // Assuming item.id is the unique identifier
  // }));;
  // }, [selectedPricePlan, pricePlans, rateCards, searchTerm]);

  const { pricePlanData, otherCharges } = useMemo(() => {
    let pricePlanData: any[] = [];
    let otherCharges: any[] = [];
    const { rateCards: rateCardsData, ...rest } = rateCards || {};

    const filterCharges = (rate: any) =>
      (rate.cardType === "CREDIT_GRANT" ? otherCharges : pricePlanData).push({
        ...rate,
        pricePlanMetadata: rest,
      });

    // const rates = selectedPricePlan
    //   ? reverseTransformPricePlanPayload(
    //       pricePlans?.find((p: any) => p.pricePlanId === selectedPricePlan)
    //     )?.rateCards
    //   : rateCards;
    const rates = rateCards?.rateCards ?? rateCards;

    rates?.forEach(filterCharges);

    const filterBySearchTerm = (item: any) =>
      item?.displayName?.toLowerCase().includes(searchTerm.toLowerCase());

    const applyFilterAndSort = (filtered: any[]) => {
      return filtered
        .filter(filterBySearchTerm)
        .map((item, index: any) => ({ ...item, key: item.rateCardId || index }))
        .sort((a: any, b: any) => {
          switch (filter) {
            case "-rateCardDetails.displayName":
              return b.displayName.localeCompare(a.displayName);
            case "2BrateCardDetails.displayName":
              return a.displayName.localeCompare(b.displayName);
            case "-type":
              return b.cardType.localeCompare(a.cardType);
            case "2Btype":
              return a.cardType.localeCompare(b.cardType);
            default:
              return 0;
          }
        });
    };

    return {
      pricePlanData: applyFilterAndSort(pricePlanData),
      otherCharges: applyFilterAndSort(otherCharges),
    };
  }, [pricePlans, rateCards, searchTerm, filter]);

  const handleExpand = (expanded: boolean, record: any) => {
    if (expanded) {
      setExpandedRowKeys((prevKeys) => [...prevKeys, record.key]);
    } else {
      setExpandedRowKeys((prevKeys) => prevKeys.filter((key) => key !== record.key));
    }
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "displayName",
    },
    {
      title: "Type",
      dataIndex: "cardType",
      render: (_: any, record: any) => {
        return <Tag color="green"> {record?.cardType}</Tag>;
      },
    },
    {
      title: "Tag",
      dataIndex: "tag",
      render: (_: any, record: any) => {
        return record?.tag ? <Tag color="geekblue"> • {record?.tag}</Tag> : "-";
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (_: any, record: any) => {
        console.log("⏩ ~ record:", record);

        let priceDisplay = "";
        let displayUnit = "";

        if (isNew) {
          if (
            record?.cardType === "USAGE_BASED_FEE" ||
            record?.cardType === "LICENSE"
          ) {
            priceDisplay = record[`${currency}-price-0`]
              ? `${currency} ${record[`${currency}-price-0`]} `
              : "-";
          } else {
            priceDisplay = record[`${currency}-Price`]
              ? ` ${currency} ${record[`${currency}-Price`]}`
              : "-";
          }
          if (record["pricingType-0"]) {
            displayUnit =
              record.pricingModal === "STANDARD"
                ? record["pricingType-0"] === "unit"
                  ? "Per Unit"
                  : record["pricingType-0"].charAt(0).toUpperCase() +
                    record["pricingType-0"].slice(1)
                : ` ${
                    record["pricingType-0"].charAt(0).toUpperCase() +
                    record["pricingType-0"].slice(1)
                  }
                  for first ${record["lastUnit-0"]}`;
          }

          return (
            <StyledDiv>
              <p>{priceDisplay}</p>
              <span>{displayUnit}</span>
            </StyledDiv>
          );
        } else {
          const rateValue = record?.rateValues
            ? record?.rateValues?.find(
                (item: any) => item.currency === currency
              ) || { slabRates: [], rate: 0, currency: currency }
            : record?.rateDetails?.currencySlabRateDetails.find(
                (item: any) => item.currency === currency
              ) || { slabRates: [], rate: 0, currency: currency };

          if (rateValue?.slabRates?.length) {
            const slabRate = rateValue.slabRates.find(
              (item: any) => item.order === 1
            );
            if (slabRate) {
              priceDisplay = `${rateValue.currency ?? ""} ${slabRate.rate}`;
              displayUnit =
                record?.ratePlan?.slabs?.length &&
                record.ratePlan.slabs.length > 1
                  ? `Flat for first ${
                      record?.ratePlan?.slabs[1]?.startAfter ?? 0
                    }`
                  : record.type === "LICENSE"
                  ? "Flat License"
                  : "Flat";
            }
          } else if (rateValue?.slabDetails?.length) {
            const slabRate = rateValue?.slabDetails?.[0];
            if (slabRate) {
              priceDisplay = `${rateValue.currency ?? ""} ${slabRate.rate}`;
              displayUnit = slabRate?.rate
                ? ` ${slabRate?.rate ?? 0} Credits`
                : "";
            }
          } else if (rateValue?.rate) {
            priceDisplay = `${rateValue.currency ?? ""} ${rateValue.rate}`;
          }

          return (
            <StyledDiv>
              <p>{priceDisplay}</p>
              <span>{displayUnit}</span>
            </StyledDiv>
          );
        }
      },
    },

    {
      title: "",
      dataIndex: "action",
      render: (_: any, record: any) => {
        console.log("►►► ~ record:", record);

        const items = [
          {
            key: "1",
            label: (
              <span>
                {isOverridePlan ? "Override Rate Card" : "Edit Rate Card"}
              </span>
            ),
            onClick: () => {
              dispatch(setPlanModalDetail(record?.cardType));
              if (isOverridePlan) {
                const { planMetadata, ...rest } = record;
                const data = reverseTransformRateCard(rest);
                setEditCardData(data);
                return;
              }
              setEditCardData(record);
            },
          },
          {
            key: "2",
            label: <span>Delete Rate Card</span>,
            onClick: () => handleCardRemove(record),
          },
          {
            key: "3",
            label: <span>Clone Rate Card</span>,
            onClick: () => {
              // dispatch(setPlanModalDetail(record?.cardType));
              // setEditCardData(record);
            },
          },
        ];
        if (
          isAction &&
          ((isAttachPricePlan && isOverridePlan) || !isAttachPricePlan)
        ) {
          return (
            <Dropdown placement="bottom" menu={{ items }}>
              <MoreOutlined />
            </Dropdown>
          );
        }
      },
    },
  ];
  return (
    <div>
      {pricePlanData.length > 0 && (
        <StyledTable
          pagination={pagination}
          size="middle"
          columns={columns}
          dataSource={pricePlanData}
          expandable={{
            expandedRowKeys,
            onExpand: handleExpand,
            expandIcon: ({ expanded, onExpand, record }: any) =>
              // record.children ? (
              expanded ? (
                <DownOutlined
                  onClick={(e) => onExpand(record, e)}
                  style={{ height: "12px", width: "12px" }}
                />
              ) : (
                <RightOutlined
                  onClick={(e) => onExpand(record, e)}
                  style={{ height: "12px", width: "12px" }}
                />
              ),
            // ) : null,
            expandedRowRender: (record: any) => {
              const { pricePlanMetadata, ...rest } = record; // Destructure the record

              const rateCards = isNew
                ? (
                    [
                      "creditGrantRateCards",
                      "fixedFeeRateCards",
                      "licenseRateCards",
                      "usageRateCards",
                      "entitlementOverageRateCards",
                    ] as PricePlanDetailsKeys[]
                  ).flatMap(
                    (key) =>
                      transformPricePlanPayload({
                        ...pricePlanMetadata,
                        rateCards: [rest],
                      })?.pricePlanDetails?.[key] || []
                  )
                : [record];

              return (
                <CommonExpandItem record={rateCards} currency={currency} />
              );
            },
          }}
        />
      )}
      {isOtherChargesShown && otherCharges.length > 0 && (
        <div>
          <h3 style={{ color: "#64748b", fontSize: "12px", fontWeight: "400" }}>
            {" "}
            OTHER CHARGES
          </h3>
          <StyledTable
            pagination={pagination}
            size="middle"
            columns={columns}
            dataSource={otherCharges}
            expandable={{
              expandedRowKeys,
              onExpand: handleExpand,
              expandIcon: ({ expanded, onExpand, record }: any) =>
                expanded ? (
                  <DownOutlined         
                             onClick={(e) => onExpand(record, e)}
                    style={{ height: "12px", width: "12px" }}
                  />
                ) : (
                  <RightOutlined
                    onClick={(e) => onExpand(record, e)}
                    style={{ height: "12px", width: "12px" }}
                  />
                ),
              expandedRowRender: (record: any) => {
                const { pricePlanMetadata, ...rest } = record; // Destructure the record

                const rateCards = isNew
                  ? (
                      [
                        "creditGrantRateCards",
                        "fixedFeeRateCards",
                        "licenseRateCards",
                        "usageRateCards",
                        "entitlementOverageRateCards",
                      ] as PricePlanDetailsKeys[]
                    ).flatMap(
                      (key) =>
                        transformPricePlanPayload({
                          ...pricePlanMetadata,
                          rateCards: [rest],
                        })?.pricePlanDetails?.[key] || []
                    )
                  : [record];

                return (
                  <CommonExpandItem record={rateCards} currency={currency} />
                );
              },
            }}
          />
        </div>
      )}
    </div>
  );
};

export default PricePlanDetailsTable;

const StyledDiv = styled.div`
  /* display: flex;
  flex-direction: "column"; */
  /* text-align: center; */
  p {
    font-weight: 500;
    font-size: 12px !important;
  }
  span {
    font-weight: 400;
    font-size: 12px !important;
  }
`;
