import React, { useMemo, useState } from "react";
import { Alert, Form, Input, InputNumber, Select, Space, Tooltip, Typography } from "antd";
import { CustomCheckbox, FormContainer } from "./Styles";
import { useSelector } from "react-redux";
import Extra from "../../components/common/extra";

const { Option } = Select;
const { Text } = Typography;

const NewCustomerForm: React.FC<any> = ({ form, currentStep }) => {
  const { country, AccountCurrency, currencies } = useSelector(
    (state: any) => state.adminCenter
  );
  const { isEditCustomer, isEditAccount, viewAccountDetails } = useSelector(
    (state: any) => state.customer
  );
  const [isInheritAddress, setIsInheritAddress] = useState<boolean>(true);
  Form.useWatch([], form);
  const rules = useMemo(() => {
    return [{ required: true, message: "Field is required!" }];
  }, []);

  const countryOptions = useMemo(() => {
    return country.map((country: any) => (
      <Option value={country.country_id} key={country.country_id}>
        {country.country_name}
      </Option>
    ));
  }, [country]);

  const currenciesOptions = useMemo(() => {
    return AccountCurrency.map((currency: any) => (
      <Option
        value={currency.additional_currency_id}
        key={currency.currency_id}
      >
        {currency.currency_code}-{currency.currency_name}
      </Option>
    ));
  }, [AccountCurrency, currencies]);

  const handleCheckboxChange = (e: any) => {
    setIsInheritAddress(e.target.checked);
  };

  const addressDetails = (isInheritAddress: boolean) => (
    <>
      <Form.Item label="Phone Number" name="phone" rules={rules}>
        <Input placeholder="Phone Number" disabled={isInheritAddress} />
      </Form.Item>
      <Form.Item label="Billing Address Line 1" name="address_1" rules={rules}>
        <Input
          placeholder="Billing Address Line 1"
          disabled={isInheritAddress}
        />
      </Form.Item>
      <Form.Item label="Billing Address Line 2" name="address_2">
        <Input
          placeholder="Billing Address Line 2"
          disabled={isInheritAddress}
        />
      </Form.Item>
      <Form.Item label="City" name="city" rules={rules}>
        <Input placeholder="City" disabled={isInheritAddress} />
      </Form.Item>
      <Form.Item label="Postal Code" name="postal_code" rules={rules}>
        <Input placeholder="Postal Code" disabled={isInheritAddress} />
      </Form.Item>
      <Form.Item label="State" name="state">
        <Input placeholder="State" disabled={isInheritAddress} />
      </Form.Item>
      <Form.Item label="Country" name="country_id" rules={rules}>
        <Select
          filterOption={(input: any, option: any) =>
            option?.children.toLowerCase().includes(input.toLowerCase())
          }
          placeholder="Select a country"
          showSearch
          disabled={isInheritAddress}
        >
          {countryOptions || <Option disabled>Loading countries...</Option>}
        </Select>
      </Form.Item>
    </>
  );
  const accountDetails = () => (
    <>
      <Form.Item label="Account Name" name="account_name" rules={rules}>
        <Input placeholder="Account Name" />
      </Form.Item>
      <Form.Item
        label="Currency"
        name="additional_currency_id"
        rules={rules}
        extra={
          <Extra text="Account level currency cannot be changed once set." />
        }
      >
        <Select
          placeholder="Select currency"
          showSearch
          disabled={isEditAccount && viewAccountDetails}
        >
          {currenciesOptions}
        </Select>
      </Form.Item>
      <Form.Item
        label="Enter Net Term Days"
        name="net_term_days"
        extra={
          <Extra
            text={
              <Text type="secondary">
                Organization's default setting for net term days will be
                applied, if this is empty.{" "}
                <a href="#" target="_blank" rel="noopener noreferrer">
                  Click here
                </a>{" "}
                to configure the organization setting.
              </Text>
            }
          />
        }
      >
        <Input placeholder="Enter Net Term Days" />
      </Form.Item>

      <Form.Item
        label="Account ID"
        name="billoptim_customer_account_id"
        rules={rules}
      >
        <Input placeholder="Account ID"  type="number"/>
      </Form.Item>
      <Form.Item label="Primary Email" name="email" rules={rules}>
        <Input placeholder="Primary Email" />
      </Form.Item>
      <Form.Item label="Enter tags for the account" name="tags" >
        <Select
          mode="tags"
          placeholder="Enter tags for the account"
          style={{ width: "100%" }}
        ></Select>
      </Form.Item>
      {!(isEditAccount && viewAccountDetails) && (
        <Form.Item label="Account Billing Address" name={"is_inherit_address"}>
          <CustomCheckbox
            onChange={handleCheckboxChange}
            checked={isInheritAddress}
          >
            Inherit Address from Customer
          </CustomCheckbox>
        </Form.Item>
      )}
      {addressDetails((isEditAccount && viewAccountDetails) ? false : isInheritAddress)}
      {!isEditAccount && (
        <div
          style={{
            marginBottom: "16px",
          }}
        >
          <Space direction="vertical" style={{ width: "100%" }}>
            <Alert
              message="A default account needs to be created for every customer. We've prefilled the following details, you can edit them before it is created."
              type="warning"
              showIcon
            />
          </Space>
        </div>
      )}
    </>
  );
  return (
    <FormContainer>
      <Form layout="vertical" form={form}>
        {isEditCustomer ? (
          <>
            <Form.Item label="Customer Name" name="name" rules={rules}>
              <Input placeholder="Customer Name" />
            </Form.Item>
            <Form.Item label="Email ID" name="email" rules={rules}>
              <Input placeholder="Email ID" />
            </Form.Item>
            <Form.Item
              label="Customer ID"
              name="billoptim_customer_id"
              rules={rules}
            >
              <Input placeholder="Customer ID" type="number" />
            </Form.Item>
            {addressDetails(false)}
          </>
        ) : isEditAccount ? (
          accountDetails()
        ) : (
          <>
            {currentStep === 0 && (
              <>
                <Form.Item label="Customer Name" name="name" rules={rules}>
                  <Input placeholder="Customer Name" />
                </Form.Item>
                <Form.Item label="Email ID" name="email" rules={rules}>
                  <Input placeholder="Email ID" />
                </Form.Item>
                <Form.Item
                  label="Customer ID"
                  name="billoptim_customer_id"
                  rules={rules}
                >
                  <Input placeholder="Customer ID"  type="number"/>
                </Form.Item>
                {addressDetails(false)}
              </>
            )}
            {currentStep === 1 && accountDetails()}
          </>
        )}
      </Form>
    </FormContainer>
  );
};

export default NewCustomerForm;
