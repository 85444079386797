// src/features/auth/authSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getUser } from "./authThunk";

interface User {
  name: string;
  company_name: string;
  timezone: string;
  email: string;
  created_at: string;
  user_id: any;
}
export interface AuthState {
  user: User | null;
  loading: boolean;
  error: string | null;
  activeUsers?: any[];
  invitedUsers?: any[];
  userEmail: string | undefined;
}

const initialState: AuthState = {
  user: null,
  loading: false,
  error: null,
  activeUsers: [],
  userEmail: "",
  invitedUsers: [],
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
    },
    updateUserField: (
      state,
      action: PayloadAction<{ field: keyof User; value: string }>
    ) => {
      if (state.user) {
        state.user[action.payload.field] = action.payload.value;
      }
    },
    setActiveUsers: (state, action) => {
      state.activeUsers = action.payload;
    },
    setInvitedUsers: (state, action) => {
      state.invitedUsers = action.payload;
    },
    updateUserRole: (state, action) => {
      if (state.activeUsers && action.payload.user_id) {
        const userIndex = state.activeUsers.findIndex(
          (user) => user.user_id === action.payload.user_id
        );
        if (userIndex !== -1) {
          state.activeUsers[userIndex].account_roles =
            action.payload.account_roles;
        }
      }
    },
    setUserEmail: (state, action: PayloadAction<string | undefined>) => {
      state.userEmail = action.payload;
    },
    addUser: (state, action) => {
      state.user = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(getUser.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  logout,
  updateUserField,
  setActiveUsers,
  setInvitedUsers,
  updateUserRole,
  setUserEmail,
  addUser
} = authSlice.actions;

export const authReducer = authSlice.reducer;
