import styled from "styled-components";



export const PricingRulesHeader = styled.div`
  border-bottom: 1px solid #cbd5e1;
  padding: 0.7rem 1.5rem; 
  display: flex;
  justify-content: space-between;
  .title{
    font-size: 12px;
  }
  .rateCardLeftHeader {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  .addRateCard {
    display: flex;
    gap: 5px;
    font-weight: 600;
    font-size: 13px;
  }
  .addRateCard:hover {
    text-decoration: underline;
  }
`;