import styled from "styled-components";

export const DetailsContainer = styled.div`
  p,
  h5,
  h6 {
    margin: 0;
  }
  .detailBody {
    background-color: #f1f5f9;
    padding: 1rem 7rem;
  }
  .userDetailCard {
    box-shadow: 0px 2px 8px 0px #cbd5e1 !important;
  }
  .leftSide {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .segment {
      background: transparent;
      border: 1px solid rgba(0, 0, 0, 0.1) !important ;
      padding: 0.3rem !important;
      width: fit-content;

      .ant-segmented-item-label {
        font-size: 0.8rem;
        font-weight: 500;
      }
    }

    .detailContent {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .ant-card-body {
      display: flex;
      justify-content: space-between;
      align-items: start;
      padding: 1rem;

      .userProfile {
        width: 50%;
        display: flex;
        align-items: center;
        gap: 1rem;
      }
      .toggleContainer {
        width: 50%;
        text-align: right;
        align-items: start;

        p {
          font-size: 0.6rem;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.5);
          margin-bottom: 0.5rem;
        }

        .toggle {
          display: flex;
          gap: 1rem;
          justify-content: end;
        }
      }
      .profileDescription {
        p {
          font-size: 0.8rem;
          color: rgba(0, 0, 0, 0.5);
          font-weight: 600;
        }
        h5 {
          font-weight: 600;
          font-size: 1.1rem;
          margin-bottom: 0.2rem;
        }
      }
    }
  }
  .rightSide {
    .ant-card-body {
      padding: 12px 0;
    }
    .rightContentCardItem {
      padding: 12px 24px;
      p {
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 2px;
      }
      h5 {
        font-size: 14px;
      }
      .copyIcon {
        margin-left: 0.5rem;
        height: 12px;
        width: 12px;
      }
      .rightIcon {
        margin-left: 0.5rem;
      }
      .dummy {
        display: flex;
        align-items: center;
      }
    }
    .divider {
      margin: 0 24px;
    }
  }
`;
