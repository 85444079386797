import { Modal } from "antd";
import styled from "styled-components";

export const StyledModal = styled(Modal)`

.ant-modal-footer{
  display: flex;
  justify-content: center;
  align-items: center;
    .ant-btn{
      width: 50%;
    }
    .ant-btn-primary{
      background-color: black;
    }
}

.modalContent{
  display: flex;
  flex-direction: column;
  align-items:center ;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 1.5rem;

    .icon{
        border-radius: 50%;
        padding: 1rem;
        background: #FBE9E9;
        color: red;
    }
    p{
      margin: 0;
      text-align: center;
      font-size: 14px;
      font-weight: 400px;
    }
    h3{
      margin: 0;
      font-size: 16px;
      font-weight: 500px;
    }
}


`;