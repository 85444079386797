import React from "react";
import {
  Bullet,
  DateText,
  DateWrapper,
  DetailsGrid,
  Icon,
  ScheduleItemWrapper,
  ScheduleTitle,
  VerticalLine,
} from "./Styles";
import { Card } from "antd";
import dayjs from "dayjs";
import { ScheduleData } from "../../Styles";
import { ArrowDownOutlined, CalendarOutlined } from "@ant-design/icons";
import moment from "moment";

const ScheduleItem: React.FC<any> = ({ item }) => {


  return (
    <ScheduleItemWrapper>
      <div>
        <div
          className="card-body"
          style={{
            background: `${item.active ? "#EEF2FF" : ""}`,
            borderRadius: `${item.active ? "10px" : ""}`,
          }}
        >
          <div className="heading">
            <div className="dots"></div>
            <div>{item.pricePlanName}</div>
          </div>
          <div className="content">
            <div className="divider"></div>
            <div className="content-text">
              <div>
                {" "}
                <ScheduleData>
                  <CalendarOutlined />
                  <span>{moment(item.effectiveFrom).local().format('YYYY-MM-DD')}</span>
                  <ArrowDownOutlined rotate={270} />
                  <span>{moment(item.effectiveUntil).local().format('YYYY-MM-DD')}</span>
                </ScheduleData>
              </div>
              <div>
                <div className="price-cycle">
                  <div>Pricing Cycle</div>
                  <div>{item.pricingCycleInterval}</div>
                </div>
                <div className="price-cycle">
                  <div>Pricing Cycle Start Month</div>
                  <div>
                    {item.pricingCycleInterval === "MONTHLY" ||
                    item.pricingCycleInterval === "WEEKLY"
                      ? "-"
                      : item.pricingCycleStartMonth}
                  </div>
                </div>
                <div className="price-cycle">
                  <div>{item.pricingCycleInterval === 'WEEKLY' ?"Pricing Cycle Day":'Pricing Cycle Date' }</div>
                  <div>{item.pricingCycleStartDay}</div>
                </div>
                <div className="price-cycle">
                  <div>Grace Period (Days)</div>
                  <div>{item.gracePeriod}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ScheduleItemWrapper>
  );
};

export default ScheduleItem;
