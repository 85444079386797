import { Alert, Form, Input, Radio, RadioChangeEvent, Select } from "antd";
import { EnrichmentForm } from "./styles";
import { useState } from "react";
import { Editor } from "@monaco-editor/react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { StyledRadio } from "../../../price-plans/NewPricePlan/RateCard/forms/styles";

const EnrichmentsDrawerContent = () => {
  const [fieldType, setFieldType] = useState("attribute");
  const [enrichmentType, setEnrichmentType] = useState("j-logic");
  const [dependencyName, setDependencyName] = useState<string | null>(null);
  const [code, setCode] = useState("");

  const handleEnrichmentTypeChange = (value: string) => {
    setEnrichmentType(value);
  };
  const handleDependencyNameChange = (value: string) => {
    setDependencyName(value);
  };
  const handleEditorChange = (value: string | undefined) => {
    if (value !== undefined) {
      setCode(value);
    }
  };
  const fieldTypeHandler = ({ target: { value } }: RadioChangeEvent) => {
    setFieldType(value);
  };

  const enrichmentTypeData = [
    { value: "value", label: "Value" },
    { value: "j-logic", label: "Json Logic" },
    { value: "dependency", label: "Value from dependency" },
  ];

  return (
    <EnrichmentForm layout="vertical" requiredMark={false}>
      <Form.Item
        label="Enrichment name"
        name="enrichment"
        rules={[
          { required: true, message: "Please input Enrichment name First!" },
        ]}
      >
        <Input size="small" placeholder="Enrichment name" type="text" />
      </Form.Item>
      <Form.Item
        label="Field type"
        name="fieldType"
        rules={[
          { required: true, message: "Please select  Field type First!" },
        ]}
      >
        <StyledRadio
          defaultValue={fieldType}
          onChange={fieldTypeHandler}
          value={fieldType}
        >
          <Radio value={"attribute"}>Attribute</Radio>
          <Radio value={"dimension"}>Dimension</Radio>
        </StyledRadio>
      </Form.Item>
      <Form.Item
        label="Type"
        name="type"
        rules={[{ required: true, message: "Please Select Type First!" }]}
      >
        <Select
          onChange={handleEnrichmentTypeChange}
          options={enrichmentTypeData}
          defaultValue={enrichmentType}
        />
      </Form.Item>
      {enrichmentType === "j-logic" ? (
        <Form.Item label="JSON VALUE">
          <div
            style={{
              borderRadius: "8px",
              overflow: "hidden",
              border: "1px solid #d9d9d9",
            }}
          >
            <Editor
              language="json"
              height={200}
              value={code}
              onChange={handleEditorChange}
              options={{
                lineNumbers: "on",
                roundedSelection: false,
                scrollBeyondLastLine: false,
                theme: "vs-light",
              }}
            />
          </div>
        </Form.Item>
      ) : enrichmentType === "dependency" ? (
        <Form.Item
          label="Dependency name"
          name="dependencyName"
          rules={[
            { required: true, message: "Please input Dependency name First!" },
          ]}
        >
          <Select
            onChange={handleDependencyNameChange}
            // options={DependencyNameData}
            placeholder="Dependency Name"
            notFoundContent={"No Data"}
          />
        </Form.Item>
      ) : (
        <Form.Item
          label="Enter value"
          name="value"
          rules={[{ required: true, message: "Please input value First!" }]}
        >
          <Input placeholder="Enter value" type="text" />
        </Form.Item>
      )}
      <Form.Item
        label="Order"
        name="order"
        rules={[{ required: true, message: "Please input Order value First!" }]}
        initialValue={1}
      >
        <Input type="number" min={1} max={100} />
      </Form.Item>
      <Form.Item>
        <Alert
          message={
            <>
              <h6 style={{ margin: 0 }}>What’s an Enrichment?</h6>
              <p style={{ margin: 0 }}>
                Add additional attributes/dimensions to an event by defining
                custom logic
              </p>
            </>
          }
          type="warning"
          icon={<InfoCircleOutlined style={{ color: "#2853C2" }} />}
          showIcon
          style={{
            backgroundColor: "#F8FAFC",
            border: "none",
          }}
        />
      </Form.Item>
    </EnrichmentForm>
  );
};

export default EnrichmentsDrawerContent;
