import { DatePicker, Dropdown, Form, Menu, Select, Tag } from "antd";
import { StreamContainer, StyledTab } from "./styles";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PlusOutlined, RightOutlined } from "@ant-design/icons";
import { EmptyData } from "../../../components/common/empty";
import Segment from "../../../components/common/segmented";
import Modal from "../../../components/common/modal";
import IngestEventForm from "./modalForm";
import CustomDrawer from "../../../components/common/drawer";
import DrawerHeader from "./eventDetails/DrawerHeader";
import Properties from "./eventDetails/Properties";
import Pipeline from "./eventDetails/pipeline";
import JsonPayload from "./eventDetails/jsonPayload";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  createIngest,
  getIngest,
  getIngestById,
} from "../../../redux/feature/events/eventThunk";
import { StyledTable } from "../../../components/common/table/styles";
import { render } from "@testing-library/react";
import { SearchInput } from "../../customer/table/Styles";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../../components/common/button";
import FilterDropdown from "../../../components/common/filter";
import PageLoading from "../../../components/Loader";
import {
  emptySelectedIngest,
  setIngestValue,
} from "../../../redux/feature/events/eventSlice";
import FilterOption from "../../../components/common/filter/FilterOption";
import { timeAgo } from "../../../utils/helper";

const { RangePicker } = DatePicker;
type TempEventDataType = {
  eid: string;
  schema: string;
  status: string;
  cid: string;
  accId: string;
};

const Stream = () => {
  const [event, setEvent] = useState<string>("Raw Events");
  const [selectedEvent, setSelectedEvent] = useState<{ [key: string]: any }>(
    {}
  );
  
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [form] = Form.useForm();
  const [filterForm] = Form.useForm();
  const location = useLocation();

  const [filterEvent, setFilterEvent] = useState({
    customerAccountId: "",
    statue: "",
    EventId: "",
    startDate: "",
    endDate: "",
    eventSchemaId: "",
  });
  const {
    events,
    loading,
    ingest,
    isModalLoading,
    ingestEventValue,
    selectedIngest,
  } = useSelector((state: RootState) => state.event);
  const navigate = useNavigate();
  const { filter } = useSelector((state: RootState) => state.customer);

  const dispatch = useDispatch<AppDispatch>();
  const drawerHandler = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const handleMenuClick = (e: any) => {
    dispatch(setIngestValue(e.key));
  };
  const modalHandler = () => {
    dispatch(setIngestValue(""));

    form.resetFields();
  };
  const menuItems: any = {
    "1": { label: "Raw Event" },
    "2": { label: "Usage Event" },
    "3": { label: "Named License Entry" },
  };


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("event-type", "billOptim-event");
 
    navigate({
      pathname: location.pathname,
      search: `?${searchParams.toString()}`,
    });
  }, [navigate]);


  

  const menu = (
    <Menu onClick={handleMenuClick}>
      {Object.keys(menuItems).map((key) => (
        <Menu.Item key={key} icon={menuItems[key].icon}>
          <span>{menuItems[key].label}</span>
        </Menu.Item>
      ))}
    </Menu>
  );
  const ingestEventDropdown = () => {
    return (
      <Dropdown dropdownRender={() => menu} trigger={["hover"]}>
        <div className="ingestEvent">
          <span>Ingest Event</span>
          <PlusOutlined className="icon" />
        </div>
      </Dropdown>
    );
  };

  const onSubmit = async () => {
    try {
      const data = await form.validateFields();

      const attributes = data.eventSchemaId
        ? events
            ?.find((event: any) => event.event_schema_id === data.eventSchemaId)
            ?.attributes?.map((atb: any) => ({
              name: atb.name,
              value: data[atb.name],
            }))
        : data.usage
        ? [
            {
              name: " usage",
              value: data.usage,
            },
          ]
        : // ? { [data["usage"]]: data.usage }
          { name: data["action"], value: data.value };

      const dimensions = data.eventSchemaId
        ? events
            ?.find((event: any) => event.event_schema_id === data.eventSchemaId)
            ?.dimensions?.reduce((acc: Record<string, any>, dm: string) => {
              acc[dm] = data[dm];
              return acc;
            }, {})
        : data.usage_meter_id
        ? { usage_meter_id: data.usage_meter_id }
        : { license_id: data.license_id, [data["name"]]: data.name };

      const payload = {
        billoptimIngestEventId: data.eventId,
        timestamp: moment(data.timestamp).format("YYYY-MM-DD HH:mm:ss"),
        accountId: data.accountId,
        eventSchemaId:
          ingestEventValue === "2" ? "billOptim-usage" : data.eventSchemaId,
        dimensions,
        attributes,
      };
      await dispatch(createIngest({ event: payload }));
      const params = new URLSearchParams(location.search);
      const eventType = params.get('event-type');
      await dispatch(
        getIngest({
          schema_name: eventType ==='billOptim-event' ? 'billOptim-row':eventType,
          sort: filter ==='-updated_at'? "-timestamp": filter.startsWith("2B") ? `${filter.slice(2)}` : filter
        })
      );
      form.resetFields();
    } catch (error) {
      console.log("►►► ~ onSubmit ~ error:", error);
    }
  };

  useEffect(() => {
    let schemaName = "billOptim-row";
    if (event === "Raw Events") {
      schemaName = "billOptim-row";
    }
    if (event === "Usage Events") {
      schemaName = "billOptim-usage";
    }
    if (event === "License Events") {
      schemaName = "billOptim-named-license";
    }
    dispatch(
      getIngest({
        schema_name: schemaName,
        sort: filter ==='-updated_at'? "-timestamp": filter.startsWith("2B") ? `${filter.slice(2)}` : filter,
        customer_account_id: filterEvent.customerAccountId,
        status: filterEvent.statue,
        timestamp_lte: filterEvent.startDate,
        timestamp_gte: filterEvent.endDate,
        event_schema_id: filterEvent.eventSchemaId,
      })
    );
  }, [event, filter, filterEvent]);

  const eventStreamData = useMemo(
    () => ingest?.map((item: any) => item),
    [ingest]
  );
  const onRowClick = (record: any) => {
    dispatch(getIngestById(record.billoptimIngestEventId));
  };


  const onSubmitFilter = async () => {
    try {
      const values = await filterForm.validateFields();

      // Assuming Source_time is the key for your time range
      const range = values.Source_time || [null, null];

      setFilterEvent({
        customerAccountId: values.customerAccountId,
        statue: values.statue,
        EventId: values.EventId,
        startDate: range[0]?.format("YYYY-MM-DD") || "",
        endDate: range[1]?.format("YYYY-MM-DD") || "",
        eventSchemaId: values.EventId,
      });

      const queryParams = new URLSearchParams(location.search);

      // Setting query parameters
      if (values.statue) queryParams.set("ingestion_status", values.statue);
      if (values.customerAccountId)
        queryParams.set("account_id", values.customerAccountId);

      if (values.customerAccountId)
        queryParams.set("account_id", values.customerAccountId);

      // Use the correct range variable
      if (range[0]?.format("YYYY-MM-DD")) {
        queryParams.set(
          "event_source_time[get]",
          range[0].format("YYYY-MM-DD")
        );
        queryParams.set(
          "event_source_time[get]",
          range[0].format("YYYY-MM-DD")
        );
      }
      if (range[1]?.format("YYYY-MM-DD")) {
        queryParams.set(
          "event_source_time[lte]",
          range[1].format("YYYY-MM-DD")
        );
        queryParams.set(
          "event_source_time[lte]",
          range[1].format("YYYY-MM-DD")
        );
      }

      if (values.EventId) queryParams.set("schema_name", values.EventId);

      // Navigate with query parameters
      navigate(`/events?${queryParams}`);
    } catch (error) {
      console.error("Error submitting filter:", error); // Log the error for debugging
    }
  };

  const RawColumns = useMemo(
    () => [
      {
        title: "Event ID",
        dataIndex: "billoptimIngestEventId",
        key: "billoptimIngestEventId",
        render: (text:any,{createdAt}:any) => {
          return <div>
            <div><b>{text}</b></div>
            <div style={{fontSize:'13px'}}>{timeAgo(createdAt)}</div>
          </div>;
        },
      },
      ...(event === "Raw Events"
        ? [
            {
              title: "Event Schema",
              dataIndex: "eventSchemaId",
              key: "eventSchemaId",
            },
          ]
        : []),
      {
        title: "Status",
        dataIndex: "eventStatus",
        key: "eventStatus",
        render: (eventStatus: any) => {
          return (
            <Tag color={eventStatus === "PROCESSED" ? "green" : "red"}>
              {eventStatus}
            </Tag>
          );
        },
      },
      {
        title: "Customer ID",
        dataIndex: "customerId",
        key: "customerId",
      },
      {
        title: "Account ID",
        dataIndex: "customerAccountId",
        key: "customerAccountId",
      },
      {
        dataIndex: "Action",
        render: () => <RightOutlined />,
      },
    ],
    [event, ingest]
  );

  const eventDetailsItems = useMemo(
    () => [
      {
        label: `Properties`,
        key: "1",
        children: <Properties propertyData={selectedIngest} />,
      },
      ...(selectedIngest?.event_status !== "UNPROCESSED"
        ? [
            {
              label: `Pipeline`,
              key: "2",
              children: <Pipeline selectedIngest={selectedIngest} />,
            },
          ]
        : []),
      {
        label: `JSON Payload`,
        key: "3",
        children: <JsonPayload selectedIngest={selectedIngest} />,
      },
    ],
    [selectedIngest]
  );

  const options = [
    {
      value: "-timestamp",
      label: "Source Time Descending",
    },
    {
      value: "2Btimestamp",
      label: "Source Time Ascending",
    },
    {
      value: "-billoptim_ingest_event_id",
      label: "Event ID Descending",
    },
    {
      value: "2Bbilloptim_ingest_event_id",
      label: "Event ID Ascending",
    },
    {
      value: "-customer_account_id",
      label: "Account Id Descending",
    },
    {
      value: "2Bcustomer_account_id",
      label: "Account Id Ascending",
    },
  ];

 

  
  return  (
    <StreamContainer>
      <div className="header">
        <Segment
          options={["Raw Events", "Usage Events", "License Events"]}
          value={event}
          onChange={(e: any) => {
            setEvent(e);
            const searchParams = new URLSearchParams(location.search);
            if (e === "Raw Events") {
              searchParams.set("event-type", "billOptim-event");
            }
            if (e === "Usage Events") {
              searchParams.set("event-type", "billOptim-usage");
            }
            if (e === "License Events") {
              searchParams.set("event-type", "billOptim-named-license");
            }
            navigate({
              pathname: location.pathname,
              search: `?${searchParams.toString()}`,
            });
          }}
        />
        <div style={{ display: "flex", gap: "10px" }}>
          {filterEvent.customerAccountId ||
          filterEvent.statue ||
          filterEvent.EventId ||
          filterEvent.endDate ||
          filterEvent.startDate ? (
            <CustomButton
              text="Clear filter"
              type="link"
              icon={false}
              onClick={() => {
                filterForm.resetFields();
                setFilterEvent({
                  customerAccountId: "",
                  statue: "",
                  EventId: "",
                  startDate: "",
                  endDate: "",
                  eventSchemaId: "",
                });
                navigate(`/events`);
              }}
            />
          ) : (
            <></>
          )}
          <FilterDropdown onSubmitFilter={onSubmitFilter}>
            <Form layout="vertical" form={filterForm}>
              <Form.Item className="type" name="statue" label="Status">
                <Select placeholder="Status">
                  <Select.Option value="PROCESSED">Processed</Select.Option>
                  <Select.Option value="UNPROCESSED">Unprocessed</Select.Option>
                  <Select.Option value="INPROGRESS">Inprogress</Select.Option>

                  <Select.Option value="REVERTED">Reverted</Select.Option>
                  <Select.Option value="UNKNOWN">Unknown</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                className="type"
                name="Source_time"
                label="Source Time"
              >
                <RangePicker />
              </Form.Item>
              <Form.Item name="customerAccountId" label="Account Id">
                <Select placeholder="Account id" mode="multiple">
                  {Array.from(
                    new Set(
                      eventStreamData?.map(
                        (data: any) => data.customerAccountId
                      )
                    )
                  ).map((uniqueId: any) => {
                    return (
                      <Select.Option key={uniqueId} value={uniqueId}>
                        {uniqueId}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {event === "Raw Events" ? (
                <Form.Item name="EventId" label="Event Schema">
                  <Select placeholder="Event Schema" mode="multiple">
                    {Array.from(
                      new Set(
                        eventStreamData
                          ?.filter(
                            (data: any) =>
                              data.eventSchemaId !==
                                "billOptim-named-license" &&
                              data.eventSchemaId !== "billOptim-usage"
                          )
                          .map((data: any) => data.eventSchemaId)
                      )
                    ).map((uniqueId: any) => {
                      return (
                        <Select.Option key={uniqueId} value={uniqueId}>
                          {uniqueId}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              ) : (
                <></>
              )}
            </Form>
          </FilterDropdown>
          <FilterOption defaultValue="-timestamp" options={options} />{" "}
        {ingestEventDropdown()}
        </div>

      </div>
      <div className="body">
        {!eventStreamData.length ? (
          <EmptyData description="No Data Found" />
        ) : (
          <StyledTable
            columns={RawColumns}
            loading={loading}
            dataSource={eventStreamData}
            pagination={false}
            onRow={(record: any) => ({
              onClick: () => {
                onRowClick(record);
              },
            })}
          />
        )}
      </div>
      <Modal
        title={
          ingestEventValue === "1"
            ? "Ingest Raw Event"
            : ingestEventValue === "2"
            ? "Ingest Usage Event"
            : "Ingest Named License Entry"
        }
        isOpen={ingestEventValue}
        onClose={modalHandler}
        onSubmit={onSubmit}
        loading={isModalLoading}
      >
        <div className="dummy">
          <IngestEventForm
            form={form}
            contentKey={ingestEventValue}
            events={events}
          />
        </div>
      </Modal>
      {selectedIngest && (
        <CustomDrawer
          title={<DrawerHeader selectedIngest={selectedIngest} />}
          isOpen={true}
          onClose={() => {
            dispatch(emptySelectedIngest(null));
          }}
          isActionButtonsShown={false}
          width={500}
          isStyledHeader={true}
        >
          <div className="drawer-content">
            <StyledTab className="tabs" items={eventDetailsItems} />
          </div>{" "}
        </CustomDrawer>
      )}
    </StreamContainer>
  );
};

export default Stream;
