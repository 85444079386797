import { Form, Input, Select } from "antd";
import { useState } from "react";
import { StyledForm } from "./styles";
import { AddItemCard } from "../../../events/schemas/newEventSchema/styles";
import { DeleteOutlined } from "@ant-design/icons";

type NewFieldPropType = {
  id: number;
  removeField: (obj: any) => void;
  title: string;
  isUnitShow?: boolean;
  attributeDimension?:any
  name1 : string
  name2 : string
  name3 : string
};

export const operatorData = [
  { label: "equals (==)", value: "==" },
  { label: "not equals (!=)", value: "!=" },
  { label: "greater than (>)", value: ">" },
  { label: "greater than or equals (>=)", value: ">=" },
  { label: "less than (<)", value: "<" },
  { label: "less than or equals (<=)", value: "<=" },
  { label: "in", value: "in" },
];
const FilterConditionsForm: React.FC<NewFieldPropType> = ({
  id,
  removeField,
  title,
  name1,
  name2,
  name3,
  attributeDimension,
}) => {
  const [chooseCondition, setChooseCondition] = useState("");
  const [option, setOption] = useState("");
  return (
    <AddItemCard
      bordered={false}
      title="Filter Condition"
      extra={<DeleteOutlined onClick={() => removeField(id)} />}
    >
      <StyledForm >
        <Form.Item
          label="Choose Attribute/Dimension"
          name={name1}
          required={false}
          rules={[
            {
              required: true,
              message: `Please input  ${title} First!`,
            },
          ]}
        >
          <Select
            placeholder="Choose Attribute/Dimension"
            options={attributeDimension}
            onChange={setChooseCondition}
          />
        </Form.Item>
        <div className="flexible">
          <Form.Item
            label="Operator"
            name={name2}
            required={false}
            className="flexItem"
            rules={[
              {
                required: true,
                message: `Please input  ${title} First!`,
              },
            ]}
          >
            <Select
              placeholder="Operator"
              onChange={setOption}
              options={operatorData}
            />
          </Form.Item>

          <Form.Item
            label="Value"
            name={name3}
            required={false}
            className="flexItem"
            rules={[
              {
                required: true,
                message: "Please input  unit First!",
              },
            ]}
          >
            <Input
              placeholder="Value"
              type="text"
              style={{ height: "initial" }}
            />
          </Form.Item>
        </div>
      </StyledForm>
    </AddItemCard>
  );
};

export default FilterConditionsForm;
