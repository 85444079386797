import { Card, Row } from "antd";
import styled from "styled-components";

export const StyledCard = styled(Card)`
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px #cbd5e1 !important;
  margin-bottom: 24px;
  .ant-card-body {
    padding: 16px;
  }
  .revenue {
    .item {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      line-height: 31px;
      margin-top: 5px;
      span {
        font-size: 26px;
        font-weight: 500;
      }
      .currency {
        font-size: 16px;
        font-weight: 400;
        margin-right: 1rem;
        color: #64748b;
      }
    }
  }
  .copy {
    height: 12px;
    width: 12px;
  }
  p {
    margin: 0;
  }
  .orderDetailContainer {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .orderDetailHeader {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      h3 {
        margin: 0;
        font-weight: 600;
      }
    }

    .orderDetailItem {
      display: flex;
      gap: 1rem;
      align-items: center;

      p {
        margin: 0;
        font-size: 12px;
      }
    }
  }
  .creditDetailHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    p {
      font-size: 12px;
      font-weight: 500;
      color: #64748b;
    }
    span {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .cardContainer {
    background-color: #f1f5f9;
    padding: 1rem;
    margin: 0 !important;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .cardItem {
      padding: 8px 12px;
      width: 100%;

      p {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
  .dueDetails {
    .item {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      p {
        font-size: 2rem !important;
        font-weight: 600 !important;
      }
      .currency {
        font-size: 16px;
        margin-right: 1rem;
      }
    }
  }
  .timing {
    margin-top: 0.5rem;
  }
  .detailDivider {
    margin: 0.7rem 0;
  }
  .details {
    display: flex;
    padding: 0.5rem 0;

    p {
      width: 50%;
      text-align: left;
      font-size: 13px;
    }
    .value {
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .value-link {
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 10px;
      color: black;
    }
  }
`;

export const StyledRow = styled(Row)`
  margin: 0 3rem !important;
  padding: 16px;
  .detailCard {
    .ant-card-body {
      padding: 0 !important;
    }
  }
`;

export const TableContainer = styled.div`
  margin-top: 16px;
`;

export const Label = styled.p`
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #8c8c8c;
`;

export const CalculationContainer = styled.div<any>`
  .ant-table-cell-with-append {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
  }
  .ant-card-body {
    padding: 8px;
  }

  .expandIcon {
    font-weight: bold;
    svg {
      height: 9px;
      width: 12px;
      margin-right: 5px;
    }
  }
  .dropDownBtn {
    box-shadow: none;
  }
  .dropDownBtn:hover {
    background-color: transparent !important;
  }
  .customName {
    display: flex;
    align-items: center;
    gap: 10px;
    .icon {
      height: 12px;
      width: 12px;
    }
  }
  .usage_name {
    display: flex;
    gap: 10px;
  }
  .name {
    p {
      color: #475569;
      font-size: 12px;
      font-weight: 400;
    }

    .icon {
      height: 12px;
      width: 12px;
      margin-left: 5px;
    }
  }
  .card {
    .ant-table-wrapper .ant-table-tbody > tr > td {
      padding: 16px 8px 12px 8px;
    }

    .ant-table-thead > tr > th {
      border-bottom: none;
      padding: 8px 16px;
      color: #475569;
      font-size: 12px;
      line-height: 14px;
    }
    .ant-table-thead > tr :first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    .ant-table-thead > tr :last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    .ant-table-cell::before {
      display: none;
    }
    .ant-table-cell {
      border-bottom: none;
    }
    .ant-table-cell-with-append {
      font-size: 12px;
      font-weight: 400;
      color: #475569;
    }

    .total-row {
      font-weight: 600;
      pointer-events: none;
      & td {
        border-bottom: none;
        padding: 8px 16px !important ;
        color: #475569;
        font-size: 12px;
        line-height: 14px;
        background-color: #e5f4f0;
      }
      td:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      td:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
    .sub-total-row {
      font-weight: 600;
      pointer-events: none;
      td {
        border-top: 1px solid #e2e8f0 !important;
        border-bottom: 1px solid #e2e8f0 !important;
        padding: 10px 16px !important ;
        color: #475569;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        background-color: #f1f5f9;
        text-transform: capitalize;
      }
      td:first-child,
      .child-row-class > td:first-child {
        border-top-left-radius: ${(props) =>
          !props.isNestedTable ? "10px" : "0 !important"};

        border-bottom-left-radius: 10px;
        border-left: 1px solid #e2e8f0 !important;
      }

      td:last-child,
      .child-row-class > td:last-child {
        border-top-right-radius: ${(props) =>
          !props.isNestedTable ? "10px" : "0 !important" };

        border-bottom-right-radius: 10px;
        border-right: 1px solid #e2e8f0 !important;
      }
    }
    .child-row-class .child-nest-row-class {
      font-size: 12px !important;
      font-weight: 400 !important;
      color: #475569;
    }
    .child-nest-row-class > td:first-child {
      border-left: 1px solid #e2e8f0 !important;
    }
    .child-nest-row-class > td:last-child {
      border-right: 1px solid #e2e8f0 !important;
    }
    .child-row-class > td {
      border-top: 1px solid #e2e8f0 !important;
    }
    .child-row-class > td:first-child {
      border-top-left-radius: 10px;
      border-left: 1px solid #e2e8f0 !important;
    }
    .child-row-class > td:last-child {
      border-top-right-radius: 10px;
      border-right: 1px solid #e2e8f0 !important;
    }
  }
`;
