  import React, { Children, useEffect, useMemo, useState } from "react";
  import {
    Layout,
    Button,
    Breadcrumb,
    Typography,
    Avatar,
    Space,
    Col,
    Row,
    Popconfirm,
    Tabs,
    Table,
    Input,
    Card,
    Divider,
    Tag,
    Spin,
  } from "antd";
  import {
    CaretDownOutlined,
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    LeftOutlined,
    RightOutlined,
  } from "@ant-design/icons";
  import {
    AccountTitleStyles,
    AllAccountsHeader,
    CustomPopconfirm,
    CustomTabs,
    HeaderStyles,
    LayoutStyles,
  } from "./Styles";
  import { useDispatch, useSelector } from "react-redux";
  import CustomButton from "../../../components/common/button";
  import { AppDispatch, RootState } from "../../../redux/store";
  import {
    setIsEditAccount,
    setIsEditCustomer,
    setViewAccountDetails,
  } from "../../../redux/feature/customer/customerSlice";
  import {
    deleteCustomerAccount,
    getAccountById,
  } from "../../../redux/feature/customer/customerAsyncThunk";
  import { useLocation, useNavigate, useParams } from "react-router-dom";
  import EditCustomer from "../viewCustomer/EditCustomer";
  import Summary from "./summary";
  import Modal from "../../../components/common/modal";
  import PricePlanSchedule from "./PricePlanSchedule";
  import PageLayout from "../../../components/common/pageLayout";
  import { EmptyData } from "../../../components/common/empty";
  import { TitleStyles } from "../viewCustomer/styles";
  import Credits from "./credits";
  import Wallet from "./wallet";
  import Billing from "./billing";

  const { Text } = Typography;

  const ViewAccount = () => {
    const { viewCustomerDetails, filter } = useSelector(
      (state: any) => state.customer
    );

    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { accountId } = useParams();

    const { viewAccountDetails, loading } = useSelector(
      (state: RootState) => state.customer
    );
    console.log("►►► ~ ViewAccount ~ viewAccountDetails:", viewAccountDetails);
    const { AccountCurrency, currencies } = useSelector(
      (state: any) => state.adminCenter
    );
    const [isDeleteModal, setIsDeleteModal] = useState(false);

    const location = useLocation();

    const handleConfirm = () => {
      dispatch(deleteCustomerAccount(viewAccountDetails.customer_account_id));
      navigate(`/accounts?__sort__=${filter}`);
    };

    useEffect(() => {
      if (!viewAccountDetails) {
        dispatch(getAccountById(accountId));
      }
    }, [accountId]);

    const baseCurrencySymbol = useMemo(() => {
      const baseCurrencyId = AccountCurrency?.find(
        ({ is_base }: { is_base: boolean }) => is_base
      )?.currency_id;

      const baseCurrency = currencies?.find(
        ({ currency_id }: { currency_id: number }) =>
          currency_id === baseCurrencyId
      );

      return baseCurrency ? baseCurrency.currency_symbol : undefined;
    }, [AccountCurrency]);

    const currentTabKey = useMemo(() => {
      switch (location.pathname) {
        case `/customers/${viewAccountDetails?.customer_id}/accounts/${viewAccountDetails?.customer_account_id}/details`:
          return "1";
        case `/customers/${viewAccountDetails?.customer_id}/accounts/${viewAccountDetails?.customer_account_id}/price-plan-schedule`:
          return "5";
        case `/customers/${viewAccountDetails?.customer_id}/accounts/${viewAccountDetails?.customer_account_id}/credits`:
          return "6";
        case `/customers/${viewAccountDetails?.customer_id}/accounts/${viewAccountDetails?.customer_account_id}/billing`:
          return "7";
        case `/customers/${viewAccountDetails?.customer_id}/accounts/${viewAccountDetails?.customer_account_id}/wallet`:
          return "8";
        case `/accounts`:
          return "2";
        default:
          return "1"; // Default to tab "1" if none matches
      }
    }, [location.pathname, viewAccountDetails]);

    const handleTabChange = (key: string) => {
      switch (key) {
        case "1":
          navigate(
            `/customers/${viewAccountDetails?.customer_id}/accounts/${viewAccountDetails?.customer_account_id}/details`
          );
          break;
        case "2":
          navigate("/accounts");
          break;
        case "5":
          navigate(
            `/customers/${viewAccountDetails?.customer_id}/accounts/${viewAccountDetails?.customer_account_id}/price-plan-schedule`
          );
          break;
        case "6":
          navigate(
            `/customers/${viewAccountDetails?.customer_id}/accounts/${viewAccountDetails?.customer_account_id}/credits`
          );
          break;
        case "7":
          navigate(
            `/customers/${viewAccountDetails?.customer_id}/accounts/${viewAccountDetails?.customer_account_id}/billing`
          );
          break;
        case "8":
          navigate(
            `/customers/${viewAccountDetails?.customer_id}/accounts/${viewAccountDetails?.customer_account_id}/wallet`
          );
          break;
        default:
          break;
      }
    };

  const items = useMemo(() => {
    return [
      {
        label: `Summary`,
        key: "1",
        children: loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "50vh",
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <Summary
            baseCurrencySymbol={baseCurrencySymbol}
            viewAccountDetails={viewAccountDetails}
            filter={filter}
          />
        ),
      },
      // {
      //   label: `Entitlements`,
      //   key: "2",
      // },
      // {
      //   label: `License Entries`,
      //   key: "3",
      // },
      // {
      //   label: `Purchases`,
      //   key: "4",
      // },
      {
        label: `Price Plan Schedule`,
        key: "5",
        children: loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "50vh",
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <PricePlanSchedule />
        ),
      },
      {
        label: `Credits`,
        key: "6",
        children: <Credits />,
      },
      {
        label: `Billing`,
        key: "7",
        children: <Billing />,
      },
      {
        label: `Wallet`,
        key: "8",
        children: <Wallet />,
      },
      // {
      //   label: `Alias`,
      //   key: "9",
      // },
      // {
      //   label: `Settings`,
      //   key: "10",
      // },
    ];
  }, [viewAccountDetails, currentTabKey]);

    const header = (
      <AllAccountsHeader>
        <Breadcrumb
          separator=">"
          items={[
            {
              title: "All Customers",
              href: "/customers",
            },
            {
              title: viewAccountDetails?.customer_name,
              href: `/customers/${viewAccountDetails?.customer_id}/details`,
            },
            {
              title: viewAccountDetails?.account_name,
            },
          ]}
        />
        <HeaderStyles>
          <div
            className="leftIcon"
            onClick={() => {
              navigate("/accounts");
              dispatch(setViewAccountDetails(""));
            }}
            style={{ cursor: "pointer" }}
          >
            <LeftOutlined className="icon" />
          </div>
          <div className="accountHeader">
            <Space size="middle" align="center" style={{ marginLeft: 16 }}>
              <TitleStyles>{viewAccountDetails?.account_name}</TitleStyles>
              <Tag>{baseCurrencySymbol}</Tag>
            </Space>
            <CustomButton
              type="default"
              isGrey={true}
              text="Edit"
              onClick={() => {
                dispatch(setIsEditAccount(true));
              }}
              isShowIcon={true}
              icon={<EditOutlined />}
            />
          </div>
        </HeaderStyles>
      </AllAccountsHeader>
    );

    return (
      <>
        <PageLayout
          items={items}
          isCustomExtraContentShown={true}
          tabBarExtraContent={header}
          handleTabChange={handleTabChange}
          activeKey={currentTabKey}
        />
        <EditCustomer />
      </>
    );
  };

  export default ViewAccount;
