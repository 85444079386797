import { Tabs } from "antd";
import styled from "styled-components";

export const AdminCenterTabs = styled(Tabs)`

.ant-tabs-nav{
    box-shadow: 0px 2px 8px 0px #cbd5e1 !important;
    border-radius: 12px;
    background-color: white;
    height: 76vh;
}
.ant-tabs-nav-list{
    padding: 0.8rem 1rem ;
    box-shadow: 0px 2px 8px 0px #CBD5E1 !important;
    width: 220px;
}
.ant-tabs-tab{
    margin-top:8px !important;
    padding: 8px 16px !important;
}
.ant-tabs-content-holder{
    border-left: none !important;
}
.ant-tabs-tab-active , .ant-tabs-tab:hover {
    border-left: 2px solid #2847A5;
    background: #F8FAFC;

    .ant-tabs-tab-btn{
        color: #334155 !important;
        font-weight: 500;
    }
}
 .ant-tabs-ink-bar {
    display: none !important;
  }
  .ant-tabs-tabpane{
    padding-left: 32px !important;
  }
`

export const TabHeader = styled.h3`
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
`

export const AdminCenterContainer = styled.div`
margin: 32px 5rem 24px 5rem;

.header{
    font-size: 26px;
    line-height: 31px;
    font-weight: 400;
    margin-bottom: 24px;

}

`