import { Form } from "antd";
import styled from "styled-components";

export const CustomForm = styled(Form)`
/* padding-top:  20px; */
& .rangeContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
 .ant-input-number{
  width: 100%;
 }
.anniversaryCycle{
  display: flex;
  justify-content: space-between;
  border: 1px solid black;
}

 .ant-radio-wrapper{
    border:1px solid rgba(0,0,0,0.1);
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
  }

  .ant-radio-wrapper-checked{
    border:1px solid #8CA4E0;
    background-color: #F0F6FE;
  }
`;