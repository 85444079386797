import styled from "styled-components";


export const TextedSwitchContainer = styled.div<{ checked: boolean, width: string }>`
    display: flex;
    padding: 0px 12px;
    border: ${(props) => props.checked ? "2px solid #336BDD" : "2px solid #d9d9d9"};
    background-color: ${(props) => props.checked ? "#F0F6FE" : "white"};
    width: ${(props) => props.width};
    border-radius: 8px;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    height: 33px;
    cursor: pointer;
    .switch{
      width: 100%;
      padding: 0px 12px;
      display: flex;
      justify-content: space-between;
    }
    p{
      font-size: 14px;
      font-weight: 400;
      margin: 0;
    }
`;