import styled from "styled-components";

export const StyledDiv = styled.div`
    border:1px solid lightgray;
    border-radius: 10px;
    padding: 7px;

     .thead{
    background-color:#F8FAFC ;
    border-radius: 10px;
    padding: 3px 10px;
    font-size: 10px;
    margin-bottom: 7px;

    .ant-col{
      font-size: 13px;
      display: flex;
      align-items: center;
    }
  }
  .tbody{
     padding: 0.8rem 10px;
    font-size: 10px;

    .ant-col{
        font-size: 13px;
        display: flex;
        align-items: center;
      }
      .value{
        font-weight: 600;
      }
  }
  .tbody:hover{
    background-color: #F8FAFC;
  }
`;