import { InfoCircleOutlined } from "@ant-design/icons";
import { Col, Row, Tooltip } from "antd";
import { StyledCard } from "./styles";

type NewItemCardPropsType = {
  title: string;
  subtitle: string;
  toolTipText?: string;
  children: React.ReactNode;
};

const NewItemCard: React.FC<NewItemCardPropsType> = ({
  title,
  subtitle,
  toolTipText,
  children,
}) => {
  return (
    <StyledCard>
      <Row gutter={[64, 16]}>
        <Col span={5}>
          <div className="title">
            <h3>{title}</h3>
            {toolTipText && (
              <Tooltip placement="bottom" title={toolTipText}>
                <InfoCircleOutlined />
              </Tooltip>
            )}
          </div>
          <div>
            <p className="subtitle">{subtitle}</p>
          </div>
        </Col>
        <Col span={10}>{children}</Col>
      </Row>
    </StyledCard>
  );
};

export default NewItemCard;
