import styled from "styled-components";

export const PageLayoutContainer = styled.div`
.ant-tabs{
  height: 100vh;
}
  & .ant-tabs-nav {
    position: sticky;
    top: 0px;
    z-index: 999;
     padding: 1.5rem 5rem;
     padding-bottom: 0;
    background-color: white;
    display: flex;
    flex-direction: column-reverse;
    align-items: baseline;
    box-shadow: 0px 2px 8px 0px #cbd5e1 !important;
    margin-bottom: 0;
  }
  & .ant-tabs-content-holder {
    margin: 0 5rem;
    overflow-y: scroll;
    scrollbar-width: none;
    padding-top: 1rem;
  }
  .ant-tabs-ink-bar {
    background-color: #2847A5 !important;
    height: 1.5px !important;
  }
  .ant-tabs-tab-btn {
    color: #334155 !important;
  }
  .ant-tabs-extra-content{
    width: 100%;
    
  }
  .ant-tabs-nav-operations{
    display: none !important;
  }
  .ant-tabs-nav-list{
    transform: none !important;
  }
  .ant-tabs-nav-wrap::before{
    box-shadow: none !important;
  }
  .ant-tabs-nav-wrap::after{
    box-shadow: none !important;
  }
  `;

export const StyledDiv = styled.div`
  display: flex;
  margin-bottom: 1rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 33px;

    .title{
      font-size: 16px;
      font-weight: 500;
    }
    
`;
