import {
  Avatar,
  Card,
  Col,
  Divider,
  Form,
  Row,
  Segmented,
  Select,
  Table,
  Tag,
} from "antd";
import PageHeader from "../../../../components/common/pageHeader";
import {
  EditFilled,
  EditOutlined,
  InfoCircleOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useState } from "react";

import { InvoiceGroupDetailsContainer } from "./styles";
import CustomButton from "../../../../components/common/button";
import { EmptyData } from "../../../../components/common/empty";
import Modal from "../../../../components/common/modal";
import ModalHeaderTitle from "../../../../components/common/modalHeaderTitle";
import { AddAccountModalContainer } from "../../invoiceGroup/styles";
import { SearchInput } from "../../../customer/table/Styles";
import AddAccountModal from "../../invoiceGroup/AddAccountModal";
import CustomDrawer from "../../../../components/common/drawer";
import NewSettingForm from "../../../admin-center/setting/form";
import SettingTable, {
  SettingTableType,
} from "../../../admin-center/setting/table";
import CustomInvoiceForm from "./customInvoiceForm";
import CustomInvoiceTable, {
  CustomInvoiceTableType,
} from "./customInvoiceTable";
import InvoiceTemplate from "./InvoiceTemplate";
import BackPageHeader from "../../../../components/common/BackPageHeader";

const dummyTemplate = [
  { value: "perUnit", label: "Per Unit" },
  { value: "Flat", label: "Flat" },
];

const data: SettingTableType[] = [
  {
    key: "1",
    name: "kin",
    id: "123456",
    namespace: "User",
    type: "Numeric",
    value: "21",
  },
  {
    key: "1",
    name: "kin",
    id: "123456",
    namespace: "User",
    type: "String",
    value: "21",
  },
  {
    key: "1",
    name: "kin",
    id: "123456",
    namespace: "Togai",
    type: "String",
    value: "21",
  },
];

const customInvoiceDummyData: CustomInvoiceTableType[] = [
  {
    key: 1,
    seqId: "INVOICE-5",
    refId: "inv.23bDMeNJS4g.VvpTx",
    status: "Draft",
    date: "25 Sep, 2024 00:00 UTC",
    totalValue: "144 USD",
  },
  {
    key: 2,
    seqId: "INVOICE-5",
    refId: "inv.23bDMeNJS4g.VvpTx",
    status: "Due",
    date: "25 Sep, 2024 00:00 UTC",
    totalValue: "144 USD",
  },
  {
    key: 3,
    seqId: "INVOICE-5",
    refId: "inv.23bDMeNJS4g.VvpTx",
    status: "Paid",
    date: "25 Sep, 2024 00:00 UTC",
    totalValue: "144 USD",
  },
];
const InvoiceGroupDetail = () => {
  const [detailType, setDetailType] = useState<"Invoices" | "Settings">(
    "Invoices"
  );
  const [isSettingDrawerOpen, setIsSettingDrawerOpen] = useState(false);
  const drawerHandler = () => {
    setIsSettingDrawerOpen(!isSettingDrawerOpen);
  };
  const [template, setTemplate] = useState("");
  const [isManageAccModalOpen, setIsManageAccModalOpen] = useState(false);
  const manageAccModalHandler = () => {
    setIsManageAccModalOpen(!isManageAccModalOpen);
  };
  const [isCustomInvoiceModalOpen, setIsCustomInvoiceModalOpen] =
    useState(false);
  const customInvoiceModalHandler = () => {
    setIsCustomInvoiceModalOpen(!isCustomInvoiceModalOpen);
  };
  const [isEditTemplateModalOpen, setIsEditTemplateModalOpen] = useState(false);
  const editTemplateHandler = () => {
    setIsEditTemplateModalOpen(!isEditTemplateModalOpen);
  };
  return (
    <InvoiceGroupDetailsContainer>
      <BackPageHeader
        actions={[
          {
            label: "Manage Accounts",
            icon: <EditOutlined />,
            onClick: manageAccModalHandler,
          },
        ]}
        path="/billing"
      />
      <div className="detailBody">
        <Row gutter={32}>
          <Col span={16} className="leftSide">
            <Card className="billDetailCard">
              <div className="userProfile">
                <div>
                  <Avatar size={50} icon={<UserOutlined />} />
                </div>
                <div className="profileDescription">
                  <p>Usage Meter Details</p>
                  <h5>saq</h5>
                  <Tag color="green">• Active</Tag>
                </div>
              </div>
            </Card>
            <Segmented
              className="segment"
              options={["Invoices", "Settings"]}
              value={detailType}
              onChange={setDetailType}
            />
            {detailType === "Invoices" ? (
              <div>
                <div className="invoicesHeader">
                  <span className="text">Invoice list</span>
                  <CustomButton
                    text="Create Custom Button"
                    onClick={customInvoiceModalHandler}
                  />
                </div>
                <CustomInvoiceTable data={customInvoiceDummyData} />
              </div>
            ) : (
              <div>
                <div className=" invoicesHeader settingHeader">
                  <CustomButton text="New Setting" onClick={drawerHandler} />
                </div>
                <SettingTable
                  data={data}
                  emptyDescription="Create your first Invoice Group Setting"
                  emptyBtnOnClick={drawerHandler}
                />
              </div>
            )}
          </Col>
          <Col span={8} className="rightSide">
            <Card>
              <div className="rightContentCardItem">
                <p>Group Name</p>
                <h5>rth</h5>
              </div>
              <div className="rightContentCardItem">
                <p>Net Term DaeditTemplateHandlerys</p>
                <h5>34</h5>
              </div>
              <div className="rightContentCardItem">
                <p>Accounts Associated</p>
                <h5 className="dummy">1</h5>
              </div>
              <div className="rightContentCardItem">
                <p>Invoice Template</p>
                <h5 onClick={editTemplateHandler} className="edit">
                  Default Template <EditFilled />
                </h5>
              </div>
              <Divider dashed className="divider" />
              <div className="rightContentCardItem">
                <p>Created 11 Sep, 2024 09:29 UTC</p>
                <p>Last Updated 14 days ago</p>
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      {/* create custom invoice drawer  */}
      <Modal
        title="Create Custom Invoice"
        isOpen={isCustomInvoiceModalOpen}
        onClose={customInvoiceModalHandler}
      >
        <CustomInvoiceForm />
      </Modal>

      {/* edit template modal */}
      <InvoiceTemplate
        isOpen={isEditTemplateModalOpen}
        onClose={editTemplateHandler}
      />

      {/* manage account modal */}
      <AddAccountModal
        isOpen={isManageAccModalOpen}
        onClose={manageAccModalHandler}
        modalTitle={
          <ModalHeaderTitle
            title="Manage Accounts of Invoice Group"
            subtitle="You can add multiple accounts to an invoice group across multiple customers with the same currency."
          />
        }
        submitText="Update Accounts"
      />

      {/* new setting drawer */}
      <CustomDrawer
        isOpen={isSettingDrawerOpen}
        onClose={drawerHandler}
        title="New Setting"
        submitText="Add Setting"
      >
        <NewSettingForm />
      </CustomDrawer>
    </InvoiceGroupDetailsContainer>
  );
};

export default InvoiceGroupDetail;
