import { Form } from "antd";
import styled from "styled-components";

export const DependencyForm = styled(Form)`
padding: 20px;
.queryAlert{
  background-color: #F8FAFC;
  border: 1px dashed rgba(0,0,0,0.1),
}
.desctiptionAlert{
   background-color: #F8FAFC;
  border: none
}
.query{
   margin: 0.3rem auto;
  font-weight: bold;
}
.descriptionHeader{
  margin: 0;
}
.descriptionBody{
  margin:0
}
`;

export const EnrichmentForm = styled(Form)`
padding: 24px;

 .ant-radio-wrapper{
    border:1px solid rgba(0,0,0,0.1);
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
  }

  .ant-radio-wrapper-checked{
    border:1px solid #8CA4E0;
    background-color: #F0F6FE;
  }
`;
