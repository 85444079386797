import React from "react";
import { StyledSelect } from "./styles";
import { setFilter } from "../../../redux/feature/customer/customerSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { Select } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

const FilterOption: React.FC<any> = ({ defaultValue = null, options }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();

  const handleOnChange = (e: any) => {
    dispatch(setFilter(e));
    const searchParams = new URLSearchParams(location.search);

    if (e) {
      searchParams.set("__sort__", e);  // Properly update the __sort__ parameter
    } else {
      searchParams.delete("__sort__");  // Remove if not needed
    }
  
    // Ensure the navigate updates only the intended query parameters
    navigate({
      pathname: location.pathname,
      search: `?${searchParams.toString()}`,
    });
  };

  return (
    <StyledSelect
      defaultValue={defaultValue}
      onChange={handleOnChange}
      placeholder={"Short by"}
    >
      {options.map((option: any) => {
        return (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        );
      })}
    </StyledSelect>
  );
};

export default FilterOption;
