import { useEffect, useMemo, useState } from "react";
import Segment from "../../../components/common/segmented";
import { InvoicesContainer } from "./styles";
import CustomButton from "../../../components/common/button";
import InvoiceTable from "./table";
import AlertModal from "../../../components/common/alertModal";
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { DatePicker, Form, Select } from "antd";
import FilterDropdown from "../../../components/common/filter";
import FilterOption from "../../../components/common/filter/FilterOption";
import { PlanCard } from "../../price-plans/ViewPricePlan/Styles";
import SearchInput from "../../../components/common/search";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { getInvoices } from "../../../redux/feature/billing/billingThunk";
const { RangePicker } = DatePicker;

const Invoices: React.FC<any> = ({ invoices }) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const { filter } = useSelector((state: RootState) => state.customer);
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [form] = Form.useForm();
  const [filterInvoice, setFilterInvoice] = useState({
    customerAccountId: "",
    status: "",
    pricePlan: "",
    startDate: "",
    endDate: "",
  });

  const [invoiceType, setInvoiceType] = useState<
    "Historic Invoices" | "Ongoing Invoices" | "Ready To Due Invoices"
  >("Historic Invoices");
  const [isDueInvoiceOpen, setIsDueInvoiceOpen] = useState(false);
  const dueInvoiceHandler = () => {
    setIsDueInvoiceOpen(!isDueInvoiceOpen);
  };

  const rules = useMemo(() => {
    return [{ required: true, message: "Field is required!" }];
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    params.set(
      "invoice-type",
      invoiceType === "Historic Invoices"
        ? "history"
        : invoiceType === "Ongoing Invoices"
        ? "ongoing"
        : "ready-to-due"
    );
    params.set('__sort__', filter)

    navigate(`?${params.toString()}`, { replace: true });

    
    dispatch(getInvoices({
      sort: filter.startsWith("2B") ? `${filter.slice(2)}` : filter,
      // search:searchValue,
      // status: filterInvoice.status,
      // startDate: filterInvoice.startDate,
      // endDate: filterInvoice.endDate,
      // customerAccountId: filterInvoice.customerAccountId,
    }))
  }, [invoiceType, navigate,filter,searchValue]);

  const options = [
    {
      value: "-created_at",
      label: "Created At Descending",
    },
    {
      value: "2Bcreated_at",
      label: "Created At Ascending",
    },
    {
      value: "-id",
      label: "ID Descending",
    },
    {
      value: "2Bid",
      label: "ID Ascending",
    },
  ];

  const onSubmitFilter = async () => {
    try {
      const values = await form.validateFields();

      // Assuming Source_time is the key for your time range
      const range = values.range || [null, null];

      setFilterInvoice({
        customerAccountId: values?.customerAccountId,
        status: values?.status,
        pricePlan: values?.pricePlan,
        startDate: range[0]?.format("YYYY-MM-DD") || "",
        endDate: range[1]?.format("YYYY-MM-DD") || "",
      });

      // Setting query parameters
      if (values?.status) searchParams.set("status", values.status);
      if (values?.customerAccountId)
        searchParams.set("owner_id", values?.customerAccountId);

      // Use the correct range variable
      if (range[0]?.format("YYYY-MM-DD")) {
        searchParams.set("invoice_date_get", range[0].format("YYYY-MM-DD"));
      }
      if (range[1]?.format("YYYY-MM-DD")) {
        searchParams.set("invoice_date_lte", range[1].format("YYYY-MM-DD"));
      }

      // if (values.pricePlan) searchParams.set("schema_name", values.pricePlan);

      navigate({
        pathname: location.pathname,
        search: `?${searchParams.toString()}`,
      });
    } catch (error) {
      console.error("Error submitting filter:", error); // Log the error for debugging
    }
  };

  return (
    <InvoicesContainer>
      <div className="header">
        <Segment
          options={[
            "Historic Invoices",
            "Ongoing Invoices",
            "Ready To Due Invoices",
          ]}
          value={invoiceType}
          onChange={setInvoiceType}
        />
        <div className="filter">
          {filterInvoice?.customerAccountId ||
          filterInvoice?.status ||
          filterInvoice?.startDate ||
          filterInvoice?.endDate ? (
            <CustomButton
              text="Clear filter"
              type="link"
              icon={false}
              onClick={() => {
                form.resetFields();
                setFilterInvoice({
                  customerAccountId: "",
                  status: "",
                  pricePlan: "",
                  startDate: "",
                  endDate: "",
                });
                searchParams.set("?__sort__=", filter);

                navigate({
                  pathname: location.pathname,
                  search: `?${searchParams.toString()}`,
                });
              }}
            />
          ) : (
            <></>
          )}
          <FilterDropdown onSubmitFilter={onSubmitFilter}>
            <Form layout="vertical" requiredMark={false} form={form}>
            {invoiceType === 'Historic Invoices' ? (

              <Form.Item label="Status" name="status">
                <Select
                  placeholder="Status"
                  style={{ width: "100%", marginBottom: "10px" }}
                  mode="multiple"
                >
                  <Select.Option value="PAID">Paid</Select.Option>
                  <Select.Option value="PARTIALLY_PAID">
                    Partially Paid
                  </Select.Option>
                  <Select.Option value="DUE">Due/Overdue</Select.Option>
                  <Select.Option value="VOID">Void</Select.Option>
                  <Select.Option value="REFUND_INITIATED">
                    Refund Initiated
                  </Select.Option>
                  <Select.Option value="REFUND_COMPLETED">
                    Refund Complete
                  </Select.Option>
                </Select>
              </Form.Item>
            ):(
              <></>
            )}
              <Form.Item
                label="Invoice Date ( End Date + Grace Period )"
                name="range"
              >
                <RangePicker
                  style={{ width: "100%", marginBottom: "10px" }}
                  placeholder={["Start date", "End date"]}
                />
              </Form.Item>
              <Form.Item label="Account Id" name="customerAccountId">
                <Select
                  placeholder="Account Id"
                  style={{ width: "100%", marginBottom: "10px" }}
                  mode="multiple"
                >
                  {[
                    ...Array.from(
                      new Map(
                        invoices?.map((i: any) => [i.customer_account_id, i])
                      ).values()
                    ),
                  ].map((invoice: any) => (
                    <Select.Option
                      key={invoice.customer_account_id}
                      value={invoice.customer_account_id}
                    >
                      {invoice.customer_account_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {/* <Form.Item label="Price-Plan" name="pricePlan" rules={rules}>
                {invoices.map((i: any) => {
                  <Select.Option value={i.price_plan_attach_id}>
                    {i.price_plan_attach_id}
                  </Select.Option>;
                })}
              </Form.Item> */}
            </Form>
          </FilterDropdown>
          <SearchInput
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              if (e.target.value) {
                searchParams.set("__search__", searchValue);
              } else {
                searchParams.delete("__search__"); // Remove it if not needed
              }
              navigate({
                pathname: location.pathname,
                search: `?${searchParams.toString()}`,
              });
            }}
          />
          <FilterOption defaultValue="-created_at" options={options} />{" "}
        </div>
      </div>
      {invoiceType === "Ready To Due Invoices" && (
        <div className="dueInvoice">
          <CustomButton
            text="Mark Filtered Invoices as Due"
            icon={null}
            onClick={dueInvoiceHandler}
          />
        </div>
      )}
      <InvoiceTable
        type={invoiceType}
        searchValue={searchValue}
        filterInvoice={filterInvoice}
      />

      <AlertModal
        open={isDueInvoiceOpen}
        close={dueInvoiceHandler}
        title="Mark invoices as due"
        subtitle="This Action will initiate a bill run job to mark filtered invoices as due."
      />
    </InvoicesContainer>
  );
};

export default Invoices;
