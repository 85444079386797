import { Card, Radio } from "antd";
import styled from "styled-components";

export const FixedFeeFormContainer = styled.div`

`;
export const StyledRadio = styled(Radio.Group)`
  .ant-radio-wrapper{
    border:1px solid rgba(0,0,0,0.1);
    padding: 0.3rem 0.5rem !important;
    border-radius: 0.5rem;
  }
  .ant-radio-wrapper-checked{
    border:1px solid #2853c2;
    background-color: #F0F6FE;
  }
  .ant-radio-wrapper-checked .ant-radio-inner{
    border-color: #2853c2;
    background-color: #2853c2;
  }
`;

export const NestedCard = styled(Card) <any>`
  background-color: #f1f5f9;
  max-width: ${(props) => props.maxWidth || "670px"};
  margin-bottom: 1rem;

  .ant-card-body {
    padding: 1rem;
    h3 {
      margin: 0;
    }
    p {
      margin-top: 0;
      margin-bottom: 1rem;
      font-size: 13px;
      font-weight: 600;
    }
  }

    .ant-card-head{
      min-height: 35px!important;
      border-bottom: 1px solid rgba(0,0,0,0.1);
      
    }
    .ant-card-head-wrapper{
      min-height: 100%;
      font-size: 12px;
    }
    .ant-form-item{
      margin-bottom: 3rem;
    }
`;

export const UsageBasedFeeFormContainer = styled.div`

`;

export const UsageFeeAddFilterContainer = styled.div`
 display: flex;
  gap: 1rem;
  align-items: center;


`;

export const DeleteIconDiv = styled.div`
    border: 1px solid grey;
    background-color: white;
    border-radius: 10px;
    padding: 0.2rem 0.5rem  ;
    cursor: pointer;
`;

export const UsageBasedAddPriceContainer = styled.div`
  .priceLimitHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom:1rem;

    p{
      margin-bottom: 0;
    }
  }
  .addConfigureText{
    margin-bottom: 0 !important;
  }
  .priceLimitFields{
    display: flex;
    gap: 1rem;
    margin-top: 0.5rem;

    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-input-suffix {
      color:#8693A5
    }
  }
`;

export const PriceModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background-color: white;
  height: auto;
  /* padding: 10px 0px; */

  

  .indexWrapper{
    border-right: 1px solid #d9d9d9;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    background-color: #F1F5F9;
    height: 100%;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }
  .lastItem{
    border:none !important;
  }
  .itemWrapper{
    height: 100%;
    padding: 0 0.7rem;
    border-right: 1px solid #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .type{
      width: 100%;
    }
    .currency{
      padding: 5px 0;
    }
    .ant-input{
      height: 25px !important;
      background-color: #F1F5F9;
    }
    .ant-form-item-label{
      font-size: 12px;  
      padding: 0 !important;
    }
    .ant-form-item-label >label{
      font-size: 12px;
    }

  .priceTag{
    cursor: pointer;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-bottom: 0;
    width: max-content;
  }

  }
  .ant-form-item{
    margin-bottom: 0;
  }
`;

export const EventSchemaDetailsCard = styled.div`
  background: #F1F5F9;
  border-radius: 10px;
  margin-bottom: 15px;
  
    .divider{
      margin:0;
    }
    .heading{
      padding: 12px;
      
    }
    .title{
      color: #64748b;
      font-size: 12px;
      font-weight: 400;
    }
    .details{
      padding: 12px;
      display: flex;
      flex-direction: column;
      gap: 16px;

    }
    .name{
      font-size: 14px;
      font-weight: 500
    }
    .attributes{
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
    }
    .dimensions{
      .ant-tag{
        margin-bottom: 8px;
        display: inline-block;
      }
    }
`;
