import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../api/apiClient";
import { message } from "antd";

export const getInvoices = createAsyncThunk(
  "billing/getEvents",
  async (filterData: any, { rejectWithValue }) => {
    const { sort, search, status, endDate, startDate, customerAccountId } = filterData;

    const params = new URLSearchParams({
      sort,
      ...(search && { search }),
      ...(status && { status }),
      ...(customerAccountId && { owner_id: customerAccountId }),
      ...(startDate && { "invoice_date[lte]": startDate }),
      ...(endDate && { "invoice_date[gte]": endDate }),
    }).toString();

    try {
      const response = await api.get(`/invoices?${params}`);
      return response.data.data;
    } catch (error: any) {
      console.error("🚀 ~ file: authThunk.tsx:24 ~ error:", error);
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);


export const getInvoiceById = createAsyncThunk(
  "billing/getInvoiceById",
  async (invoiceId: any, { rejectWithValue }) => {
    try {
      const response = await api.get(`/invoice/${invoiceId}`);
      return response.data.data;
    } catch (error: any) {
      console.log("🚀 ~ file: authThunk.tsx:24 ~ error:", error);
      message.error(error.response.data.Error);
      return rejectWithValue(error.response.data.Error);
    }
  }
);
