import styled from "styled-components";

export const InvoiceGroupDetailsContainer = styled.div`
  p , h5 , h6 {
    margin:0;
  }
 .detailBody{
    background-color: #F1F5F9;
    padding: 1rem 3rem;
  }
  .leftSide{
    display: flex;
    flex-direction: column;
    gap: 2rem;

      .segment{
        background:transparent;
        border:1px solid rgba(0,0,0,0.1) !important ;
          padding: 0.3rem !important;
          width: fit-content;

          .ant-segmented-item-label{
            font-size: 0.8rem;
            font-weight: 500;
          }
      }
      .ant-card-body{
          padding: 1rem;
          .userProfile{
            width: 50%;
            display: flex;
            align-items: center;
            gap: 1rem;
          }
          .profileDescription{
              p{
                font-size: 0.8rem;
                color: rgba(0,0,0,0.5);
                font-weight: 600;
              }
              h5{
                font-weight: 600;
                font-size: 1.1rem;
                margin-bottom: 0.2rem;
              }
          }
        }
      .invoicesHeader{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5rem;
          .text{
            font-size: 14px;
            font-weight: 600;
          }
      }
      .settingHeader{
        justify-content: end;
     
      }
  }
  .rightSide{
    .ant-card-body{
      padding:12px 0;
    }
    .rightContentCardItem{
      padding: 12px 24px;
      .edit{
        cursor: pointer;
      }
      p{
        color: #64748b;
        margin-bottom: 2px;
      }
      h5{
        font-size: 14px;
        font-weight: 600;
      }
      .dummy{
        display: flex;
        align-items: center;
      }
    }
    .divider{
      margin: 0 24px; 
    }
  }
`;
