import { Form, Input, Select } from "antd";
import { DeleteIconDiv, UsageFeeAddFilterContainer } from "../styles";
import { DeleteOutlined } from "@ant-design/icons";
export const operatorData = [
  { label: "Equals (==)", value: "==" },
  { label: "Not Equals (!=)", value: "!=" },
  { label: "Greater Than (>)", value: ">" },
  { label: "Greater Than or Equals (>=)", value: ">=" },
  { label: "Less Than (<)", value: "<" },
  { label: "Less Than or Equals (<=)", value: "<=" },
  { label: "In", value: "in" },
];
const UsageFeeAddFilter: React.FC<{
  remove: (id: number) => void;
  id: number;
  onChangeProperty: () => void;
  onChangeOperator: () => void;
  name1: string;
  attributeDimension?: any;
  name2: string;
  name3: string;
}> = ({
  remove,
  id,
  name1,
  name2,
  name3,
  attributeDimension,
  onChangeProperty,
  onChangeOperator,
}) => {
  return (
    <div>
      <UsageFeeAddFilterContainer>
        <Form.Item
          label="Property"
          name={name1}
          required={false}
          rules={[
            {
              required: true,
              message: `Please choose Property First!`,
            },
          ]}
        >
          <Select
            placeholder="Choose Property"
            options={attributeDimension}
            onChange={onChangeProperty}
          />
        </Form.Item>
        <Form.Item
          label="Operator"
          name={name2}
          required={false}
          className="flexItem"
          rules={[
            {
              required: true,
              message: `Please select operator First!`,
            },
          ]}
        >
          <Select
            placeholder="Operator"
            onChange={onChangeOperator}
            options={operatorData}
          />
        </Form.Item>
        <Form.Item
          label="Value"
          name={name3}
          required={false}
          rules={[
            {
              required: true,
              message: `Please enter value First!`,
            },
          ]}
        >
          <Input placeholder="Value" type="text" />
        </Form.Item>

        <DeleteIconDiv onClick={() => remove(id)}>
          <DeleteOutlined />
        </DeleteIconDiv>
      </UsageFeeAddFilterContainer>
    </div>
  );
};

export default UsageFeeAddFilter;
