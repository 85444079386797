import { CalendarOutlined, ReloadOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import dayjs from "dayjs";
import { DetailedDiv, StyledRangePicker } from "./styles";
import { EmptyData } from "../../../components/common/empty";
import { useNavigate } from "react-router-dom";
import { StyledTable } from "../../../components/common/table/styles";
import { useMemo } from "react";
import { AnyIfEmpty, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import moment from "moment";
import { getInvoiceById } from "../../../redux/feature/billing/billingThunk";

const InvoiceTable: React.FC<{
  type: string;
  searchValue?: string;
  filterInvoice?: any;
}> = ({ type, searchValue, filterInvoice }) => {
  console.log("►►► ~ type:", type);
  const { invoices } = useSelector((state: RootState) => state.billing);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { filter } = useSelector((state: RootState) => state.customer);

  const invoiceDataSource = useMemo(() => {
    const { customerAccountId, status, startDate, endDate } =
      filterInvoice || {};
    const today = new Date();

    return invoices
      .filter((data) => {
        const invoiceTypeFilter =
          type === "Historic Invoices"
            ? data.status !== "DRAFT" || new Date(data.due_date) < today
            : type === "Ongoing Invoices"
            ? !(new Date(data.due_date) < today)
            : true;

        const matchesCustomerAccountId = customerAccountId
          ? String(data.customer_account_id).includes(customerAccountId)
          : true;

        const matchesStatus = status ? data.status.includes(status) : true;

        const matchesStartDate = startDate
          ? new Date(data.start_date) >= new Date(startDate)
          : true;

        const matchesEndDate = endDate
          ? new Date(data.end_date) <= new Date(endDate)
          : true;

        return (
          invoiceTypeFilter &&
          matchesCustomerAccountId &&
          matchesStatus &&
          matchesStartDate &&
          matchesEndDate
        );
      })
      .filter((data) =>
        searchValue
          ? data.customer_account_name
              ?.toLowerCase()
              .includes(searchValue.toLowerCase())
          : true
      )
      .sort((a, b) => {
        switch (filter) {
          case "created_at":
            return a.created_at?.localeCompare(b.created_at);
          case "2Bcreated_at":
            return b.created_at?.localeCompare(a.created_at);
          case "id":
            return a.invoice_id - b.invoice_id;
          case "2Bid":
            return b.invoice_id - a.invoice_id;
          default:
            return 0; // No sorting
        }
      });
  }, [invoices, searchValue, filter, filterInvoice, type]);

  const allColumns = useMemo(
    () => [
      {
        title: "Owner",
        dataIndex: "customer_account_name",
        key: "customer_account_name",
        render: (text: string, record: { customer_account_id: string }) => (
          <DetailedDiv>
            <span className="title">{text}</span>
            <span>{record.customer_account_id}</span>
          </DetailedDiv>
        ),
      },
      {
        title: "Customer",
        dataIndex: "customer_name",
        key: "customer_name",
        render: (text: string, record: { customer_id: string }) => (
          <DetailedDiv>
            <span className="title">{text}</span>
            <span>{record.customer_id}</span>
          </DetailedDiv>
        ),
      },
      {
        title: "Sequence ID",
        dataIndex: "sequence_id",
        key: "sequence_id",
      },
      {
        title: "Billing Cycle",
        dataIndex: "billingCycle",
        key: "billingCycle",
        render: (_: any, record: { start_date: string; end_date: string }) => {
          return (
            <StyledRangePicker
              size="small"
              defaultValue={[dayjs(record.start_date), dayjs(record.end_date)]}
              disabled
              suffixIcon={<CalendarOutlined />}
              format="MMM DD, YYYY"
            />
          );
        },
      },
      ...(type === "Ongoing Invoices"
        ? [
            {
              title: "Generation Date",
              dataIndex: "generationDate",
              key: "generationDate",
              render: (_: any, record: { created_at: string }) => (
                <div>{moment(record.created_at).format("MMM DD YYYY")}</div>
              ),
            },
          ]
        : []),
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (status: string) => (
          <Tag
            color={
              status === "PAID" ? "green" : status === "DUE" ? "purple" : "red"
            }
          >
            • {status}
          </Tag>
        ),
      },
      ...(type !== "Ongoing Invoices"
        ? [
            {
              title: "Total Value",
              dataIndex: "total_amount",
              key: "total_amount",
              render: (total_amount: number) => `$${total_amount}`,
            },
          ]
        : [
            {
              title: "Invoice Value",
              dataIndex: "invoiceValue",
              key: "invoiceValue",
              render: (_: any, record: { total_amount: number }) =>
                `$${record.total_amount}`,
            },
          ]),

      {
        title: "Paid Value",
        dataIndex: "paid_amount",
        key: "paid_amount",
        render: (paid_amount: number) => `$${paid_amount}`,
      },
      ...(type === "Ongoing Invoices"
        ? [
            {
              dataIndex: "action",
              render: () => <ReloadOutlined />,
            },
          ]
        : []),
    ],
    [type]
  );

  return invoiceDataSource.length === 0 ? (
    <EmptyData description="No Invoices found" />
  ) : (
    <StyledTable
      columns={allColumns}
      dataSource={invoiceDataSource}
      pagination={false}
      scroll={{ x: "max-content" }}
      onRow={(record: any) => {
        console.log("►►► ~ record:", record);
        return {
          onClick: () => {
            dispatch(getInvoiceById(record?.invoice_id));
            navigate(`/billing/${record.invoice_id}`);
          },
        };
      }}
    />
  );
};

export default InvoiceTable;
