import { Modal } from "antd";
import styled from "styled-components";

export const ModalContainer = styled(Modal)`
  & .ant-modal-content{
    padding: 0px !important;
}
& .ant-modal-header{
  margin-bottom: 0;
}
& .ant-modal-title{
  
  padding: 1rem 1.5rem;
  background-color: #F8FAFC;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px; 
  border-bottom: 1px solid #E5EAF0;
}

& .actions{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  background-color: #F8FAFC;
  border-top: 1px solid #E5EAF0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  .goBack{
    margin-right: 1rem;
  }
}
& .body{
  padding: 0px 1.5rem;
  max-height: 460px;
  overflow-y: auto;
}
`;