import { Card, Col, Form, Row, Select, Spin, TableColumnsType } from "antd";
import { PaymentContainer, WalletContainer } from "./styles";
import { InfoCircleOutlined } from "@ant-design/icons";
import CustomButton from "../../../../components/common/button";
import SearchInput from "../../../../components/common/search";
import FilterDropdown from "../../../../components/common/filter";
import { StyledTable } from "../../../../components/common/table/styles";
import AddPaymentModal from "./modal/AddPaymentModal";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { setIsAddPaymentModalOpen } from "../../../../redux/feature/customer/customerSlice";
import { getWallet } from "../../../../redux/feature/customer/customerAsyncThunk";
import { useNavigate, useParams } from "react-router-dom";
import PageLoading from "../../../../components/Loader";
import CustomDrawer from "../../../../components/common/drawer";
import { Typography, Table } from "antd";

const { Title, Text } = Typography;

interface WalletDataType {
  key: React.Key;
  paymentId: string;
  entity_id: string;
  totalAmount: string;
  closing_balance: string;
  createdAt: string;
}

const Wallet = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { accountId, customerId } = useParams();
  const [searchTerm, setSearchTerm] = useState<any>();
  const [form] = Form.useForm();
  const [filterPayment, setFilterPayment] = useState({
    paymentId: "",
    statue: "",
  });
  const queryParams = new URLSearchParams();
  console.log("►►► ~ Wal ~ filterPayment:", filterPayment);
  const [selectedRow, setSelectedRow] = useState<any>(null);

  const { isAddPaymentModalOpen, payments, loading } = useSelector(
    (state: RootState) => state.customer
  );
  const [openingBalance, setOpeningBalance] = useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (payments.length) {
      const lastClosingBalance = payments?.reduce(
        (acc, payment) => acc + Number(payment.totalAmount),
        0
      );
      setOpeningBalance(lastClosingBalance); // Set opening balance based on last totalAmount sum
    }
  }, [payments]);

  const dataSources = useMemo(() => {
    const openingBalance = 0;

    return payments
      ?.slice()
      .sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      )
      .reduce((acc, payment) => {
        const currentTotal = Number(payment.totalAmount);
        const runningTotal = acc.length
          ? Number(acc[acc.length - 1].closing_balance) + currentTotal
          : openingBalance + currentTotal;

        acc.push({
          ...payment,
          closing_balance: runningTotal.toFixed(2),
        });
        return acc;
      }, [])
      .filter((payment: any) =>
        searchTerm
          ? payment.paymentId.toString().includes(searchTerm)
          : true && filterPayment.paymentId
          ? filterPayment.paymentId === payment.paymentId
          : true
      );
  }, [payments, searchTerm, filterPayment]);

  useEffect(() => {
    if (!payments.length) dispatch(getWallet(accountId));
    if (searchTerm) {
    }
  }, [accountId]);

  const columns: TableColumnsType<WalletDataType> = [
    {
      title: "Transaction ID",
      dataIndex: "transaction_id",
      render: (_, record) => {
        console.log("►►► ~ Wal ~ record:", record);
        return <div style={{ color: "green" }}>{record.paymentId}</div>;
      },
    },
    {
      title: "Entity ID",
      dataIndex: "entity_id",
      render: (_, record) => {
        return <div>-</div>;
      },
    },
    {
      title: "Amount",
      dataIndex: "totalAmount",
      render: (_, record) => {
        return <div style={{ color: "green" }}>+{record.totalAmount}</div>;
      },
    },
    {
      title: "Closing Balance",
      dataIndex: "closing_balance",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (_, record) => {
        return <div>{record.createdAt}</div>;
      },
    },
  ];

  const onSubmitFilter = async () => {
    try {
      const values = await form.validateFields();
      console.log("►►► ~ onSubmitFilter ~ values:", values);
      setFilterPayment({
        paymentId: values.entity_id,
        statue: values.transaction_type,
      });
      if (searchTerm) queryParams.set("__search__", searchTerm);
      if (values.transaction_type)
        queryParams.set("transaction_type", values.transaction_type);
      if (values.entity_id) queryParams.set("entity_id", values.entity_id);

      navigate(
        `/customers/${customerId}/accounts/${accountId}/wallet?${queryParams.toString()}`
      );
    } catch (error) {}
  };

  return loading && !payments.length ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "50vh",
      }}
    >
      <Spin size="large" />
    </div>
  ) : (
    <WalletContainer>
      <div className="header">
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Card className="cardItem">
              <div className="title">
                <span>Total Balance</span>
                <InfoCircleOutlined style={{ marginLeft: "8px" }} />
              </div>
              <div className="subTitle">
                <span className="green">{openingBalance}</span>
                <span>{dataSources?.[0]?.currency ?? ""}</span>
              </div>
            </Card>
          </Col>
          <Col span={12}>
            <Card className="cardItem">
              <div className="title">
                <span>Hold Amount</span>
                <InfoCircleOutlined style={{ marginLeft: "8px" }} />
              </div>
              <div className="subTitle">
                <span>0</span>
                <span>{dataSources?.[0]?.currency ?? ""}</span>
              </div>
            </Card>
          </Col>
        </Row>
        <CustomButton
          text="Add Payment"
          position="end"
          onClick={() => {
            dispatch(setIsAddPaymentModalOpen(true));
          }}
        />
      </div>
      <div className="tableHeader">
        <div className="detail">
          <span>Wallet ID</span>
          <p>wallet.23VJOJE7AqG.OYGbz</p>
        </div>
        <div className="actions">
          <SearchInput
            width="320px"
            onChange={(e) => {
              setSearchTerm(e?.target.value);
              if (e?.target.value)
                queryParams.set("__search__", e?.target.value);
              if (filterPayment.statue)
                queryParams.set("transaction_type", filterPayment.statue);
              if (filterPayment.paymentId)
                queryParams.set("entity_id", filterPayment.paymentId);
              navigate(
                `/customers/${customerId}/accounts/${accountId}/wallet?${queryParams.toString()}`
              );
            }}
          />
          {filterPayment.paymentId || filterPayment.statue ? (
            <CustomButton
              text="Clear filter"
              type="link"
              icon={false}
              onClick={() => {
                form.resetFields();
                setFilterPayment({
                  paymentId: "",
                  statue: "",
                });
              }}
            />
          ) : (
            <></>
          )}
          <FilterDropdown onSubmitFilter={onSubmitFilter}>
            <Form layout="vertical" form={form}>
              <Form.Item className="type" name="transaction_type">
                <Select placeholder="Transaction Type">
                  <Select.Option value="CREDIT">Credit</Select.Option>
                  <Select.Option value="DEBIT">Debit</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="entity_id">
                <Select placeholder="Entity ID">
                  {dataSources?.map((data: any) => {
                    return (
                      <Select.Option value={data.paymentId}>
                        {data.paymentId}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Form>
          </FilterDropdown>
        </div>
      </div>
      <StyledTable
        pagination={false}
        columns={columns}
        dataSource={dataSources}
        onRow={(record: any) => ({
          onClick: () => setSelectedRow(record),
        })}
      />
      <AddPaymentModal isOpen={isAddPaymentModalOpen} />
      <CustomDrawer
        title="Payment Details"
        isOpen={selectedRow}
        onClose={() => {
          setSelectedRow(null);
        }}
        isSubmitBtnShown={false}
      >
        <PaymentContainer>
          <div>
            <b>Payment Details</b>
          </div>
          <div className="item">
            <div>Payment ID</div>
            <div>{selectedRow?.paymentId}</div>
          </div>
          <div className="item">
            <div>Amount</div>
            <div>{selectedRow?.totalAmount}</div>
          </div>
          <div className="item">
            <div>Description</div>
            <div>{selectedRow?.description}</div>
          </div>
          <div className="item">
            <div>Notes</div>
            <div>{selectedRow?.notes ?? "-"}</div>
          </div>
          <div className="item">
            <div>Payer Name</div>
            <div>{selectedRow?.accountId}</div>
          </div>
          <div className="item">
            <div>Payment Mode</div>
            <div>{selectedRow?.sourceType}</div>
          </div>
          <div className="item">
            <div>Txn Number</div>
            <div>{selectedRow?.transactionNumber}</div>
          </div>
          <div className="item">
            <div>Txn Description</div>
            <div>{selectedRow?.externalDescription ?? "-"}</div>
          </div>
          <div>
            <b>Payment Usage</b>
          </div>

          <div>
            <StyledTable
              pagination={false}
              columns={[
                {
                  title: "Type",
                  dataIndex: "type",
                },
                {
                  title: "id",
                  dataIndex: "transaction_id",
                },
                {
                  title: "Amount",
                  dataIndex: "amount",
                },
              ]}
              dataSource={[]}
            />
          </div>
        </PaymentContainer>
      </CustomDrawer>
    </WalletContainer>
  );
};

export default Wallet;
