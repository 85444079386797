import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { ReactNode } from "react";
import { StyledButton } from "./styles";

interface CustomButtonProps {
  text?: string;
  icon?: ReactNode;
  position?: "start" | "end";
  type?: "primary" | "dashed" | "link" | "text" | "default";
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  stopPropagation?: boolean;
  isGrey?: boolean;
  loading?: boolean;
  isShowIcon?: boolean;
  border?: boolean;
  disabled?: boolean;
  htmlType?: "button" | "submit" | "reset";
  height?: string;
  block?: boolean;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  text,
  icon = <PlusOutlined />,
  position = "start",
  onClick = () => {},
  stopPropagation = false,
  type = "primary",
  isGrey = false,
  loading = false,
  isShowIcon = true,
  border = true,
  disabled = false,
  htmlType = "button",
  height = "33px",
  block = false,
}) => {
  const style = {
    height,
    ...(isGrey
      ? {
          background: "#F1F5F9",
          color: "#334155",
          fontWeight: 500,
          border: "1px solid #C3CCD9",
          boxShadow: "none",
        }
      : {
          background:
            type === "primary"
              ? "#2853c2"
              : type === "default"
              ? "white"
              : "initial",
          boxShadow: border ? "initial" : "none",
          color: disabled
            ? "lightsteelblue"
            : type === "default"
            ? "#334155" 
            : type=== 'link' ? 'rgb(40, 83, 194)' 
            : "white",
        }),
  };
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (stopPropagation) {
      e.stopPropagation();
    }
    onClick(e);
  };
  return (
    <StyledButton
      type={type}
      size="small"
      icon={isShowIcon ? icon : undefined}
      style={style}
      onClick={handleClick}
      loading={loading}
      disabled={disabled}
      htmlType={htmlType}
      iconPosition={position}
      block={block}
    >
      {text}
    </StyledButton>
  );
};

export default CustomButton;
