import { ExclamationCircleOutlined } from "@ant-design/icons";
import { StyledModal } from "./styles";
import { ReactNode } from "react";

const AlertModal: React.FC<{
  open: boolean;
  close: () => void;
  title: string;
  subtitle: string | ReactNode;
  submitText?: string;
  onSubmit?: () => void;
}> = ({
  open,
  close,
  title,
  subtitle,
  submitText = "Ok",
  onSubmit = () => {},
}) => {
  return (
    <StyledModal
      centered
      open={open}
      onCancel={close}
      closeIcon={null}
      width={400}
      okText={submitText}
      onOk={onSubmit}
    >
      <div className="modalContent">
        <ExclamationCircleOutlined className="icon" />
        <h3>{title}</h3>
        <p>{subtitle}</p>
      </div>
    </StyledModal>
  );
};

export default AlertModal;
