import React, { useMemo, useState } from "react";
import { ProfileContainer, StyledButton, StyledDiv } from "./Styles";
import CustomButton from "../../../components/common/button";
import { DeleteOutlined, PlusOutlined, RightOutlined } from "@ant-design/icons";
import { EmptyDataComponent } from "../../../components/common/empty";
import CustomDrawer from "../../../components/common/drawer";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Table,
  Tag,
  Card,
  Divider,
  Typography,
  Space,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import {
  createBillingInfo,
  deleteBillingInfo,
  getBillingInfo,
} from "../../../redux/feature/adminCenter/adminCenterAsyncThunk";
import "antd/dist/reset.css"; // Make sure to import Ant Design styles
import { CustomDivider } from "../userProfile/style";
import BusinessDetails from "./BusinessDetails";
import { TabHeader } from "../Styles";
import { StyledTable } from "../../../components/common/table/styles";

const { Title, Text } = Typography;

const { Option } = Select;

const BillingInformation = () => {
  const [isOpenBillInfoModal, setIsOpenBillInfoModal] = useState(false);
  const { country, billingInfo } = useSelector(
    (state: any) => state.adminCenter
  );
  const [selectedRow, setSelectedRow] = useState<any>(null);

  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const [customFields, setCustomFields] = useState<any[]>([]);

  const addCustomField = () => {
    setCustomFields([...customFields, { label: "", value: "" }]);
  };

  const removeCustomField = (index: number) => {
    const updatedFields = customFields.filter((_, i) => i !== index);
    setCustomFields(updatedFields);
  };

  const countryOptions = useMemo(() => {
    return country.map((country: any) => (
      <Option value={country.country_id} key={country.country_id}>
        {country.country_name}
      </Option>
    ));
  }, [country]);

  const rules = useMemo(() => {
    return [{ required: true, message: "Field is required!" }];
  }, []);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const selectedCountry = country.find(
        (item: any) => item.country_id === values.countryId
      );
      const payload = {
        data: {
          name: values.name,
          email: values.email,
          address: {
            phone: values.phone,
            line1: values.line1,
            line2: values.line2,
            city: values.city,
            state: values.state,
            postalCode: values.postalCode,
            countryId: values.countryId,
          },
          metadata: values.metadata,
          isDefault: billingInfo.length ? values.isDefault : true,
        },
        country_name: selectedCountry.country_name,
      };
      dispatch(createBillingInfo(payload));
      handleClose();
    } catch (error) {
      console.log("►►► ~ handleSubmit ~ error:", error);
    }
  };

  const handleClose = () => {
    setIsOpenBillInfoModal(false);
    setCustomFields([]);
    form.resetFields();
    setSelectedRow(null);
  };
  const billInfoDataSource = useMemo(() => {
    return billingInfo?.map((bill: any) => bill);
  }, [billingInfo]);

  const handleBillInfoDelete = (id: any, e: any) => {
    e.stopPropagation();
    dispatch(deleteBillingInfo(id));
  };

  const columns = [
    {
      title: "Business Name & Address",
      dataIndex: "businessName",
      key: "businessName",
      render: (_: any, record: any) => (
        <div>
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <b>{record?.name}</b>
            {record?.isDefault && <Tag color="green">PRIMARY</Tag>}
          </div>
          {record?.address?.line1 && <div>{record?.address?.line1},</div>}
          {record?.address?.line2 && <div>{record?.address?.line2}.</div>}
          {record?.address?.city && record?.address.state && (
            <div>
              {record?.address?.city},{record?.address.state},
              {record?.address?.postalCode}
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Primary Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      render: (_: any, record: any) => (
        <div style={{ textAlign: "left" }}>{record?.address?.phone || "-"}</div>
      ),
    },
    {
      title: "",
      key: "actions",
      render: (_: any, record: any) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              gap: "24px",
              marginRight: "10px",
              fontSize: "12px",
            }}
          >
            {!record.isDefault && (
              <DeleteOutlined
                onClick={(e) =>
                  handleBillInfoDelete(record?.billingInformationId, e)
                }
              />
            )}

            <RightOutlined />
          </div>
        );
      },
    },
  ];
  return (
    <ProfileContainer>
      <div className="info-header">
        <TabHeader style={{ fontSize: "14px" }}>Billing Information</TabHeader>
        <CustomButton
          type="primary"
          text={"Add New Address"}
          icon={<PlusOutlined />}
          position="end"
          onClick={() => {
            setIsOpenBillInfoModal(true);
          }}
        />
      </div>
      {!billingInfo.length ? (
        <EmptyDataComponent
          title="No Records Found"
          addButton={() => {}}
          isAddButton={false}
        />
      ) : (
        <StyledTable
          dataSource={billInfoDataSource}
          columns={columns}
          onRow={(record: any) => ({
            onClick: () => setSelectedRow(record),
          })}
          pagination={false}
        />
      )}
      <CustomDrawer
        isOpen={isOpenBillInfoModal || !!selectedRow}
        onClose={handleClose}
        onFinish={handleSubmit}
        width={400}
        submitText={"Submit"}
        title={selectedRow ? "Billing Information" : "Billing Info"}
        isActionButtonsShown={selectedRow ? false : true}
        isCancelBtnShown={selectedRow ? true : false}
      >
        {selectedRow ? (
          <BusinessDetails selectedRow={selectedRow} />
        ) : (
          <Form
            layout="vertical"
            form={form}
            style={{ padding: "20px" }}
            requiredMark={false}
          >
            <Form.Item label={"Business Name"} name={"name"} rules={rules}>
              <Input placeholder="Business Name" />
            </Form.Item>
            <Form.Item label={"Primary Email"} name={"email"} rules={rules}>
              <Input placeholder="Primary Email" />
            </Form.Item>
            <Form.Item label={"Phone Number"} name={"phone"}>
              <Input placeholder="Phone Number" />
            </Form.Item>

            <Form.Item
              label="Billing Address Line 1"
              name="line1"
              rules={rules}
            >
              <Input placeholder="Billing Address Line 1" />
            </Form.Item>
            <Form.Item label="Billing Address Line 2" name="line2">
              <Input placeholder="Billing Address Line 2" />
            </Form.Item>

            <Row gutter={24}>
              <Col span={12}>
                {" "}
                <Form.Item label="City" name="city">
                  <Input placeholder="City" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Postal Code" name="postalCode">
                  <Input placeholder="Postal Code" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="State" name="state">
              <Input placeholder="State" />
            </Form.Item>
            <Form.Item label="Country" name="countryId" rules={rules}>
              <Select
                filterOption={(input: any, option: any) =>
                  option?.children.toLowerCase().includes(input.toLowerCase())
                }
                placeholder="Select a country"
                showSearch
              >
                {countryOptions || (
                  <Option disabled>Loading countries...</Option>
                )}
              </Select>
            </Form.Item>
            <Form.Item label={"Custom Fields"}>
              {customFields.map((field, index) => (
                <Row gutter={24} style={{ alignItems: "center" }}>
                  <Col span={10}>
                    <Form.Item
                      label="Label"
                      name={["metadata", index, "key"]}
                      rules={rules}
                    >
                      <Input placeholder="Label" />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      label="Value"
                      name={["metadata", index, "value"]}
                    >
                      <Input placeholder="Value" />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <StyledButton
                      icon={<DeleteOutlined />}
                      onClick={() => removeCustomField(index)}
                    />
                  </Col>
                </Row>
              ))}
              <StyledDiv
                className="action"
                onClick={addCustomField}
                style={{ cursor: "pointer" }}
              >
                <PlusOutlined /> <span>Add Custom Field</span>
              </StyledDiv>
            </Form.Item>
            {billInfoDataSource.length ? (
              <Form.Item name="isDefault" valuePropName="checked">
                <Checkbox>Set address as default</Checkbox>
              </Form.Item>
            ) : (
              <></>
            )}
          </Form>
        )}
      </CustomDrawer>
    </ProfileContainer>
  );
};

export default BillingInformation;
