import React, { useMemo, useState } from "react";
import { Button, Card, Tag, Tooltip, Row, Col, Divider, Space } from "antd";
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  CalendarOutlined,
  DownloadOutlined,
  EditOutlined,
  CloseOutlined,
  RightOutlined,
} from "@ant-design/icons";
import CustomButton from "../../../../components/common/button";
import {
  ScheduleContainer,
  ScheduleCycleCard,
  ScheduleData,
  ScheduleVersion,
} from "./Styles";
import CalendarCard from "./CalendarCard";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useLocale } from "antd/es/locale";
import ScheduleCard from "./ScheduleCard";
import { EmptyDataComponent } from "../../../../components/common/empty";

const ViewSchedule: React.FC<any> = ({ attachPricePlan, onPlanClick }) => {
  const [isShowPast, setIsShowPast] = useState(false);
  const { customerId, accountId } = useParams();
  const navigate = useNavigate();


  const AddNewPlan = () => (
    <CustomButton
      type="primary"
      icon={<RightOutlined />}
      position="end"
      onClick={() => {
        navigate(
          `/customers/${customerId}/accounts/${accountId}/attach-price-plan`
        );
      }}
      text="Attach Price Plan"
    />
  );
  return attachPricePlan.length ? (
    <ScheduleContainer>
      <div style={{ textAlign: "right" }}>
        <Button
          type="link"
          className="view-schedules"
          onClick={() => {
            setIsShowPast(!isShowPast);
          }}
        >
          <span>{isShowPast ? "Hide" : "View"} Past Schedules</span>{" "}
          {isShowPast ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
        </Button>
      </div>

      <Row
        gutter={24}
        style={{
          display: "flex",
          gap: "20px",
          margin: "0",
        }}
      >
        <ScheduleCard
          attachPricePlan={attachPricePlan}
          onPlanClick={onPlanClick}
          isNavigatable={true}
          showPast={isShowPast}
        />
      </Row>
    </ScheduleContainer>
  ) : (
    <EmptyDataComponent
      title="No Price Plan Attached"
      description="Please attach a price plan to this account to initiate billing."
      addButton={AddNewPlan}
    />
  );
};

export default ViewSchedule;
