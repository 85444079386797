import styled from "styled-components";

export const ManageTokenContainer = styled.div`
padding: 24px 0;
 & .header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 24px;
  }
  & .table{
  border: 1px solid rgba(0,0,0,0.1); 
  border-top-left-radius: 8px; 
  border-top-right-radius: 8px; 
}

`;