import { Form } from "antd";
import styled from "styled-components";

export const StyledForm = styled(Form)`  
    .ant-card{
      border: 1px solid #CBD5E1;
      width: 95%;
      margin-bottom: 1rem;
    }
    .ant-card-body{
      padding:1rem;
      padding-bottom:0.3rem ;
      background-color: #F1F5F9;
      border-radius: inherit;
    }
    .ant-form-item{
      margin-bottom: 1rem;
    }
    .ant-form-item-label{
      padding-bottom: 5px !important;
    }
    .ant-form-item-label > label {
      font-size: 13px;
      font-weight: 500;
    }
    .ant-picker{
      width: 100%;
    }
    .addItemBtn{
      margin-top: 0;
      font-size: 13px;
      font-weight: 600;
      cursor: pointer;
    }
    .switches{
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
`;