import { Layout, Menu } from "antd";
import styled from "styled-components";

export const LayoutContainer = styled(Layout)`
  .ant-layout-content {
    background: #f1f5f9;
    margin: 0 !important;
  }
`;

export const DropdownContainer = styled.div`
  margin-bottom: 10px;
`;

export const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const ProfileInfo = styled.div`
  .email {
    width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Username = styled.div`
  font-weight: 700;
`;

export const MenuItem = styled(Menu.Item)`
  display: flex;
  align-items: center;
`;
