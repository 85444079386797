import {
  Empty,
  Form,
  Input,
  message,
  Radio,
  RadioChangeEvent,
  Typography,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import CustomButton from "../../../components/common/button";
import { useEffect, useState } from "react";
import Modal from "../../../components/common/modal";
import { AliasContainer } from "./styles";
import AliasForm from "./form";
import AliasTable, { DataType } from "./table";
import { EmptyData } from "../../../components/common/empty";
import { AxiosError } from "axios";
import api from "../../../api/apiClient";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  addAlias,
  setAlias,
} from "../../../redux/feature/adminCenter/adminCenterSlice";

const AliasManagement = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const alias = useSelector((state: RootState) => state.adminCenter.alias);
  const [loading, setLoading] = useState(false);

  const modalHandler = () => {
    setIsOpen(!isOpen);
    form.resetFields();
  };
  const [form] = Form.useForm();

  const fetchAlias = async () => {
    try {
      const response = await api.get(`/get-alias`);
      dispatch(setAlias(response.data.data));
    } catch (err) {
      console.log("🚀 ~ file: ActiveUsers.tsx:56 ~ fetchUsers ~ err:", err);
    }
  };

  useEffect(() => {
    if (!alias || alias.length === 0) {
      fetchAlias();
    }
  }, []);

  const submitAlias = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();
      const response = await api.post("/create-alias", values);

      dispatch(addAlias(response.data.data));
      form.resetFields();
      message.success("alias added successfully");
      setIsOpen(false);
    } catch (err) {
      const error = err as AxiosError;
      console.log("🚀 ~ file: EditProfile.tsx:22 ~ onFinish ~ error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AliasContainer>
      <div className="header">
        <CustomButton
          text="Add Alias"
          icon={<PlusOutlined />}
          onClick={modalHandler}
        />
      </div>
      {alias.length <= 0 ? (
        <EmptyData
          description="Create your first Alias"
          btnText="Add Alias"
          onClick={modalHandler}
        />
      ) : (
        <AliasTable />
      )}
      <Modal
        title="Add Alias"
        isOpen={isOpen}
        onSubmit={submitAlias}
        onClose={modalHandler}
        loading={loading}
        width="370px"
      >
        <AliasForm form={form} />
      </Modal>
    </AliasContainer>
  );
};

export default AliasManagement;
