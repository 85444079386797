import {
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Switch,
} from "antd";
import { FixedFeeFormContainer, NestedCard, StyledRadio } from "./styles";
import { useEffect, useMemo, useState } from "react";
import Extra from "../../../../../components/common/extra";
import TextedSwitch from "../../../../../components/common/textedSwitch";
import AddonItem from "./AddonItem";
import GroupByTag from "../../../../../components/GroupTag.tsx";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { useSearchParams } from "react-router-dom";

const FixedFeeForm: React.FC<any> = ({
  addonValue,
  setAddonValue,
  editCardData,
}) => {
  const Option = Select.Option;
  // const [addonList, setAddonList] = useState<any>([]);
  const [tagValue, setTagValue] = useState<any>([]);
  const [checked, setChecked] = useState<boolean>(false);
  const [fixedFeeType, setFixedFeeType] = useState("ONETIME");
  const { billingDetails } = useSelector((state: any) => state.pricePlan);
  const [invoiceTiming, setInvoiceTiming] = useState("IN_ARREARS");
  const { midCyclePricing } = useSelector(
    (state: RootState) => state.pricePlan
  );
  const fixedFeeTypeHandler = (e: RadioChangeEvent) => {
    setFixedFeeType(e.target.value);
  };
  const type = useSearchParams()[0].get("plan-type");

  const handleInvoiceTimingChange = (e: RadioChangeEvent) => {
    setInvoiceTiming(e.target.value);
  };

  console.log("⏩ ~ midCyclePricing:", midCyclePricing);
  useEffect(() => {
    if (editCardData) {
      console.log(
        "🚀 ~ file: FixedFeeForm.tsx:46 ~ useEffect ~ editCardData:",
        editCardData
      );
      setFixedFeeType(editCardData?.type ?? "ONETIME");
      setInvoiceTiming(editCardData?.invoiceTiming ?? "IN_ARREARS");
      setTagValue(editCardData?.tag ?? []);
      setChecked(editCardData?.anniversary_cycle ?? false);
    }
  }, [editCardData]);

  return (
    <FixedFeeFormContainer>
      <AddonItem
        editCardData={editCardData}
        addonValue={addonValue}
        setAddonValue={setAddonValue}
      />
      <Form.Item
        label="Rate Card Name"
        name="displayName"
        rules={[
          {
            required: true,
            message: "Please input the rate card name!",
          },
        ]}
      >
        <Input placeholder="Rate Card Name" />
      </Form.Item>
      {/* {type === "RECURRING" && (
        <> */}
      <Form.Item
        label="Invoice Association"
        name="invoiceTiming"
        hidden={type !== "RECURRING"}
        initialValue={type !== "RECURRING" ? "IN_ADVANCE" : "IN_ARREARS"}
        extra={
          <Extra
            text={
              invoiceTiming === "IN_ARREARS"
                ? "Fixed Fee will be applied to the current invoice. Proration is supported only for this mode."
                : "Fixed Fee will be applied to the previous invoice."
            }
          />
        }
      >
        <StyledRadio
          defaultValue={"IN_ARREARS"}
          onChange={handleInvoiceTimingChange}
        >
          <Radio value="IN_ARREARS">In Arrears</Radio>
          <Radio value="IN_ADVANCE" disabled={midCyclePricing ? true : false}>
            In Advance
          </Radio>{" "}
        </StyledRadio>
      </Form.Item>
      <Form.Item
        name="anniversary_cycle"
        valuePropName="checked"
        hidden={type !== "RECURRING"}
        extra={
          <Extra text=" This option allows to compute revenue at the end of the cycle." />
        }
      >
        <TextedSwitch
          text="Proration of Fixed Fee"
          checked={checked}
          onChange={() => setChecked(!checked)}
        />
      </Form.Item>{" "}
      <Form.Item
        label="Fixed Fee Type"
        name="type"
        hidden={type !== "RECURRING"}
        initialValue={"ONETIME"}
      >
        <StyledRadio defaultValue={"ONETIME"} onChange={fixedFeeTypeHandler}>
          <Radio value="ONETIME">One Time</Radio>
          <Radio value="RECURRING">Recurring</Radio>
        </StyledRadio>
      </Form.Item>
      {fixedFeeType === "RECURRING" && !checked && (
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Recurrence Interval"
              name="interval"
              rules={[
                {
                  required: true,
                  message: "Please input the Recurrence Interval!",
                },
              ]}
            >
              <Input type="number" placeholder="Recurrence Interval" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Recurrence Offset"
              name="offset"
              rules={[
                {
                  required: true,
                  message: "Please input the Recurrence Offset!",
                },
              ]}
            >
              <Input type="number" placeholder="Recurrence Offset" />
            </Form.Item>
          </Col>
        </Row>
      )}
      {/* </>
      )} */}
      {billingDetails?.supportedCurrencies?.length > 0 &&
        billingDetails?.supportedCurrencies.map((currency: any) => (
          <Form.Item
            label={`${currency} Price`}
            name={`${currency}-Price`}
            rules={[
              {
                required: true,
                message: "Please input the AFN Price!",
              },
            ]}
          >
            <Input type="number" placeholder={`${currency} Price`} />
          </Form.Item>
        ))}
      <GroupByTag />
    </FixedFeeFormContainer>
  );
};

export default FixedFeeForm;
