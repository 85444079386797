import styled from "styled-components";

export const StyledDiv = styled.div`

& .table{
  border: 1px solid rgba(0,0,0,0.1); 
  border-top-left-radius: 8px; 
  border-top-right-radius: 8px; 
}

`;

export const DrawerHeader = styled.div`
  h4{
    margin: 0;
  }
  p{
    margin: 0;
    font-size: 13px;
    cursor: pointer;
    color: grey;
  }
`;

export const SettingDetailData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  p{
    margin: 0;
  }
  .title{
    font-size: 13px;
    color: grey;
    font-weight: 500;
    margin-bottom:5px;
  }
  .data{
    font-size: 14px;
    color: black;
    font-weight: 600;
  }
`;