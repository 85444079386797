import React, { useEffect } from "react";
import {
  NewPricePlanContainer,
  PricePlanContainer,
} from "../../../../price-plans/NewPricePlan/Styles";
import { Button, Col, Row, Tag } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import CustomButton from "../../../../../components/common/button";
import PlanRateCardTable from "../../../../price-plans/ViewPricePlan/PlanRateCardTable";
import useSelection from "antd/es/table/hooks/useSelection";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { AccountDetails, CardContainer } from "./Styles";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import PageLoading from "../../../../../components/Loader";
import { setViewAttachPricePlan } from "../../../../../redux/feature/pricePlan/pricePlanSlice";
import { getSinglePricePlan } from "../../../../../redux/feature/pricePlan/pricePlanThunk";

const ViewAttachPricePlan = () => {
  const { customerId, accountId, aPlanId } = useParams();
  const navigate = useNavigate();
  const { viewAttachPricePlan } = useSelector(
    (state: RootState) => state.pricePlan
  );
  console.log(
    "►►► ~ ViewAttachPricePlan ~ viewAttachPricePlan:",
    viewAttachPricePlan
  );
  const dispatch = useDispatch<AppDispatch>();

  const { viewAccountDetails } = useSelector(
    (state: RootState) => state.customer
  );
  const { AccountCurrency } = useSelector(
    (state: RootState) => state.adminCenter
  );

  useEffect(() => {
    const { price_plan_id } = viewAttachPricePlan ?? {};
    if(price_plan_id)dispatch(getSinglePricePlan(price_plan_id));
    if(!viewAttachPricePlan) navigate(
      `/customers/${customerId}/accounts/${accountId}/price-plan-schedule`
    );
  }, [viewAttachPricePlan]);

  return !viewAttachPricePlan ? (
    <PageLoading />
  ) : (
    <NewPricePlanContainer>
      <div className="pricePlanHeader">
        <h3 className="title">
          <Button
            icon={<ArrowLeftOutlined />}
            style={{ width: "50px" }}
            onClick={() => {
              navigate(
                `/customers/${customerId}/accounts/${accountId}/price-plan-schedule`
              );
            }}
          />
          Pricing Schedule Details
        </h3>
      </div>
      <PricePlanContainer>
        <Row gutter={24}>
          <Col span={18}>
            <PlanRateCardTable span={24} isAddRoutes={false} />
          </Col>
          <Col span={6}>
            <AccountDetails>
              <CardContainer>
                <div className="card_body">
                  <div className="card_header">PRICING SCHEDULE DETAILS</div>
                  <div className="card-details">
                    <div className="item">
                      <div>Price Plan Name</div>
                      <div>{viewAttachPricePlan?.display_name}</div>
                    </div>
                    <div className="item">
                      <div>Start Date</div>
                      <div>
                        {moment(viewAttachPricePlan?.effective_from).format(
                          "MMM DD YYYY"
                        )}
                      </div>
                    </div>
                    <div className="item">
                      <div>End Date</div>
                      <div>
                        {moment(viewAttachPricePlan?.effective_until).format(
                          "MMM DD YYYY"
                        )}
                      </div>
                    </div>
                    <div className="item">
                      <div>Pricing Cycle</div>
                      <div>{viewAttachPricePlan?.pricing_cycle_interval}</div>
                    </div>
                    <div className="item">
                      <div>Cycle Start Month</div>
                      <div>{viewAttachPricePlan?.start_month_offset}</div>
                    </div>
                    <div className="item">
                      <div>Cycle Start Date</div>
                      <div>{viewAttachPricePlan?.start_day_offset}</div>
                    </div>
                    <div className="item">
                      <div>Grace Period</div>
                      <div>{viewAttachPricePlan?.grace_period}</div>
                    </div>
                  </div>
                </div>
              </CardContainer>
              <CardContainer>
                <div className="card_body">
                  <div className="card_header">ACCOUNT DETAILS</div>
                  <div className="card-details">
                    <div className="item">
                      <div>Account Name</div>
                      <div>{viewAccountDetails.account_name}</div>
                    </div>
                    <div className="item">
                      <div>Account ID</div>
                      <div>{viewAccountDetails.customer_account_id}</div>
                    </div>
                    <div className="item">
                      <div>Customer ID</div>
                      <div>{viewAccountDetails.billoptim_customer_id}</div>
                    </div>
                    <div className="item">
                      <div>Currency</div>
                      <div>
                        <Tag>
                          {
                            AccountCurrency.find(
                              (currency: any) =>
                                currency.additional_currency_id ===
                                viewAccountDetails.additional_currency_id
                            )?.currency_code
                          }
                        </Tag>
                      </div>
                    </div>
                    <div className="item">
                      <div>Primary Email</div>
                      <div>{viewAccountDetails?.account_email}</div>
                    </div>
                    <div className="item">
                      <div>Tags</div>
                      <div>
                        {Array.isArray(viewAccountDetails?.tags) ? (
                          viewAccountDetails.tags.map(
                            (tag: string, index: number) => (
                              <Tag key={index} color="magenta">
                                {tag}
                              </Tag>
                            )
                          )
                        ) : typeof viewAccountDetails?.tags === "string" ? (
                          JSON.parse(viewAccountDetails.tags).map(
                            (tag: string, index: number) => (
                              <Tag key={index} color="magenta">
                                {tag}
                              </Tag>
                            )
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </CardContainer>
            </AccountDetails>
          </Col>
        </Row>
      </PricePlanContainer>
    </NewPricePlanContainer>
  );
};

export default ViewAttachPricePlan;
