// styles.ts
import styled from "styled-components";
import { Input, Select } from "antd";

const { Option } = Select;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
`;

export const SearchInput = styled(Input)`
  width: 400px;
  height: 30px;
  align-items: center;
`;

export const UserNameContainer = styled.div`
  display: flex;
  align-items: center;

  .textContainer {
    margin-left: 8px;

    .name {
      font-weight: bold;
    }

    .email {
      color: #888;
    }
  }
`;
