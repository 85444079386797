import { useState } from "react";
import { Card, Checkbox, Form, Input, message, Radio, Typography } from "antd";
import CustomDrawer from "../../../../components/common/drawer";
import api from "../../../../api/apiClient";
import { AppDispatch } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import {
  setInvitedUsers,
  updateUserRole,
} from "../../../../redux/feature/auth/authSlice";
import { AxiosError } from "axios";
import PageLoading from "../../../../components/Loader";
import { UserCard } from "./styles";

export const roles = [
  {
    value: "administrator",
    label: "Administrator",
    description: "Full read & write access to all features.",
  },
  {
    value: "analyst",
    label: "Analyst",
    description: "Read-only access to all features.",
  },
  {
    value: "developer",
    label: "Developer",
    description:
      "Full access to Event Schemas, Usage Meters, Customers, Accounts, Settings.",
  },
  {
    value: "product_manager",
    label: "Product Manager",
    description:
      "Full access to Price Plans, Schedules, Invoices & Credits. Read-only access to Event Schema, Usage Meters, Customers, Accounts, Settings.",
  },
];

const UserDrawer: React.FC<{
  isOpen: boolean;
  type: string;
  onClose: () => void;
  user?: any;
}> = ({ isOpen, type, onClose, user }) => {
  const [form] = Form.useForm();
  const [selectedRole, setSelectedRole] = useState<string>("administrator");
  const [selectedRoles, setSelectedRoles] = useState<string[]>(
    user?.account_roles || ["administrator"]
  );
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);

  const handleRoleChange = (e: any) => {
    setSelectedRole(e.target.value);
  };

  const handleRolesChange = (checkedValues: string[]) => {
    setSelectedRoles(checkedValues);
  };

  const onFinish = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();
      console.log("⏩ ~ values:", values);

      if (type === "edit") {
        const response = await api.post("/manage-roles", {
          user_id: user.user_id,
          roles: selectedRoles,
        });
        dispatch(updateUserRole(response.data.data));
        message.success("user role update successfully");
      } else {
        const response = await api.post("/invite-user", {
          email: values.email,
          role: values.roles,
        });
      }
      form.resetFields();
      onClose();
    } catch (err: any) {
      console.log("🚀 ~ file: EditProfile.tsx:22 ~ onFinish ~ error:", err);
      // message.error(err?.errorFields?.map((error: any) => error?.errors));
      message.error(err?.response?.data?.Error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CustomDrawer
      title={type === "edit" ? "Manage User" : "Invite New User"}
      onClose={onClose}
      isOpen={isOpen}
      onFinish={onFinish}
      submitText={type === "new" ? "Send Invite" : "Save"}
      loading={loading}
    >
      {loading ? (
        <PageLoading />
      ) : (
        <Form
          layout="vertical"
          initialValues={{
            roles: type === "edit" ? selectedRoles : selectedRole,
          }}
          requiredMark={false}
          form={form}
          style={{ padding: "24px" }}
        >
          <Form.Item
            label="Email ID"
            name="email"
            rules={
              type !== "edit"
                ? [
                    { required: true, message: "Please input Email First!" },
                    {
                      pattern: /^[a-zA-Z0-9.]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,}$/,
                      message: "Please enter valid Email!",
                    },
                  ]
                : []
            }
          >
            <Input
              placeholder={user ? user.email : "Email ID"}
              type="email"
              disabled={type === "edit"}
            />
          </Form.Item>

          <Form.Item label="Roles" name="roles" initialValue={selectedRole}>
            {type === "edit" ? (
              <Checkbox.Group
                onChange={handleRolesChange}
                value={selectedRoles}
              >
                {roles.map((item) => (
                  <UserCard
                    key={item.value}
                    style={{
                      border: selectedRoles.includes(item.value)
                        ? "1px solid #2853c2 "
                        : "1px solid #cbd5e1",
                      backgroundColor: selectedRoles.includes(item.value)
                        ? " #f0f6fe"
                        : "transparent",
                    }}
                  >
                    <Checkbox value={item.value}>
                      <div className="data">
                        <Typography.Text strong>{item.label}</Typography.Text>
                        <br />
                        <Typography.Text
                          className="description"
                          style={{ color: "rgba(0,0,0,0.5)" }}
                        >
                          {item.description}
                        </Typography.Text>
                      </div>
                    </Checkbox>
                  </UserCard>
                ))}
              </Checkbox.Group>
            ) : (
              <Radio.Group onChange={handleRoleChange} value={selectedRole}>
                {roles.map((item) => (
                  <UserCard
                    key={item.value}
                    style={{
                      border:
                        item.value === selectedRole
                          ? "1px solid #2853c2 "
                          : "1px solid #cbd5e1",
                      backgroundColor:
                        item.value === selectedRole
                          ? " #f0f6fe"
                          : "transparent",
                    }}
                  >
                    <Radio value={item.value}>
                      <div className="data">
                        <Typography.Text strong>{item.label}</Typography.Text>
                        <br />
                        <Typography.Text
                          className="description"
                          style={{ color: "rgba(0,0,0,0.5)" }}
                        >
                          {item.description}
                        </Typography.Text>
                      </div>
                    </Radio>
                  </UserCard>
                ))}
              </Radio.Group>
            )}
          </Form.Item>
        </Form>
      )}
    </CustomDrawer>
  );
};

export default UserDrawer;
