import { Card } from "antd";
import styled from "styled-components";

export const StyledCard = styled(Card)`
  .ant-card-body{
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 8px 0px #cbd5e1 !important;

    h5{
      margin: 0;
      font-size: 14px;
      color:rgba(0,0,0,0.6);
      font-weight: 500;
      margin-bottom: 1rem;
    }
  }
  .mainRow{
    width: 100%;
    background-color: #F8FAFC;
    border-radius: 10px;
    padding: 10px;
    
    .mainColumn{
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

  .innerRow{
    padding:0;
  }

  }
  .ant-col{
    h6{
      margin: 0;
      color:#6476A8;
      font-size: 13px;
    } 
    p{
      margin-bottom: 0;
      font-size: 15px;
      margin-top: 0;
    }
  }
`;