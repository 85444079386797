import styled from "styled-components";

export const UserContainer = styled.div`
  & .header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 2rem;
    gap: 1rem;
  }

  & .segment {
    margin-right: 1rem;
  }

  & .btn {
    height: 35px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .table {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  & .drawer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
 
`;

