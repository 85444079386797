import { Button, Col, Divider, Row } from "antd";
import Edit from "../../../assets/editIcon";
import { CustomDivider, ProfileContainer } from "./style";
import { ArrowRightOutlined, EditOutlined } from "@ant-design/icons";
import GoogleLogo from "../../../assets/google";
import MicrosoftLogo from "../../../assets/microsoft";
import { useMemo, useState } from "react";
import EditProfile from "./EditProfile";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import { formatDateToLocalTime } from "../../../utils/helper";
import { TabHeader } from "../Styles";
import PageLoading from "../../../components/Loader";
import CustomButton from "../../../components/common/button";
import moment from "moment";

const UserProfile = () => {
  const [editProfile, setEditProfile] = useState<any>(null);
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const { user: userData, loading } = useSelector(
    (state: RootState) => state.auth
  );

  const profileData = useMemo(
    () => [
      {
        title: "Full Name",
        value: userData?.name,
      },
      {
        title: "Email",
        value: userData?.email,
      },
      {
        title: "Company Name",
        value: userData?.company_name,
      },
      {
        title: "Account Creation",
        value: `${moment(userData?.created_at).format(
          "DD MMM, YYYY HH:MM"
        )} ${userData?.timezone}`,
      },
      {
        title: "Timezone",
        value: userData?.timezone,
      },
    ],
    [userData]
  );

  if (!userData) {
    return null;
  }

  if (loading) {
    return <PageLoading />;
  }

  return (
    <ProfileContainer>
      <TabHeader>Profile Details</TabHeader>
      <div className="description">
        <Row>
          <Col span={12}>
            <h5>BASIC INFO</h5>
          </Col>
          <Col span={12}>
            {profileData.map((item) => {
              return (
                <div key={item.title}>
                  <div className="basicInfoContainer">
                    <div className="infoDescription">
                      <div className="profile-details__title">{item.title}</div>
                      <div className="profile-details__value">
                        {" "}
                        {item.value}
                      </div>
                    </div>
                    <span>
                      {item.title !== "Email" &&
                        item.title !== "Account Creation" && (
                          <EditOutlined
                            style={{ fontSize: "16px" }}
                            onClick={() => {
                              setEditProfile(item.title);
                            }}
                          />
                        )}
                    </span>
                  </div>
                  {item.title !== "Timezone" && (
                    <CustomDivider variant="solid" />
                  )}
                </div>
              );
            })}
          </Col>
        </Row>
        <Divider
          variant="dashed"
          style={{ borderColor: " black", margin: "24px 0" }}
        />
        <Row>
          <Col span={12}>
            <h5>PASSWORD</h5>
          </Col>
          <Col span={12}>
            <CustomButton
              block={true}
              text="Set Password"
              type="default"
              icon={<ArrowRightOutlined />}
              position="end"
              onClick={() => {
                setEditProfile("Change Password");
              }}
            />
          </Col>
        </Row>
        <Divider
          variant="dashed"
          style={{ borderColor: " black", margin: "24px 0" }}
        />
        {/* <Row>
          <Col span={12}>
            {" "}
            <h4>CONTACT WITH</h4>
          </Col>
          <Col span={12}>
            <Button
              block
              type="default"
              icon={<GoogleLogo />}
              iconPosition={"end"}
              style={{ marginBottom: 20 }}
            >
              <p className="btn-text">Google</p>
            </Button>
            <Button
              block
              type="default"
              icon={<MicrosoftLogo />}
              iconPosition={"end"}
            >
              <p className="btn-text">Microsoft</p>
            </Button>
          </Col>
        </Row> */}
      </div>
      <EditProfile editProfile={editProfile} setEditProfile={setEditProfile} />
    </ProfileContainer>
  );
};

export default UserProfile;
