import { StyledSegmented } from "./styles";

type SegmentedPropsType = {
  options: string[];
  value: string;
  onChange: (value: any) => void;
  isWhite?: boolean;
};
const Segment: React.FC<SegmentedPropsType> = ({
  options,
  value,
  onChange,
  isWhite = false,
}) => {
  return (
    <StyledSegmented
      options={options}
      value={value}
      onChange={onChange}
      isWhite={isWhite}
    />
  );
};

export default Segment;
