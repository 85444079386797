import { Card } from "antd";
import styled from "styled-components";

export const StyledCard = styled(Card)`
box-shadow: -4px 4px #bac2cb33 !important;
.ant-row{
  width: 100%;
}
.ant-col{
  padding-left:0!important;
  padding-bottom: 5px !important;
}
.ant-card-body{
  display: flex;
  justify-content: left;
  align-items: start;
  gap: 2rem;
  width: 100%;
  
  .subtitle{
    font-size: 0.8rem;
    font-weight: 400;
    color: #475A77;
    margin-top: 0.3rem !important;
  }

  .title{
    display: flex;
    gap: 1rem;
    align-items: center;
    
    h3{
      margin: 0;
      font-weight:600;
      font-size: 0.9rem;
    }
  }
}
`;