import { Table, TableColumnsType, Tag } from "antd";
import { EditFilled, RightOutlined } from "@ant-design/icons";
import CustomButton from "../../../../../components/common/button";
import { EmptyData } from "../../../../../components/common/empty";
import { StyledTable } from "../../../../../components/common/table/styles";

export interface CustomInvoiceTableType {
  key: React.Key;
  seqId: string;
  refId: string;
  status: string;
  date: string;
  totalValue: string;
}

const CustomInvoiceTable: React.FC<{
  data: CustomInvoiceTableType[];
}> = ({ data }) => {
  const columns: TableColumnsType<CustomInvoiceTableType> = [
    {
      title: "Sequence ID",
      dataIndex: "seqId",
    },
    {
      title: "Reference ID",
      dataIndex: "refId",
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (_, { status }) => {
        let color = status === "Paid" ? "green" : "gold";

        return (
          <Tag color={color} key={status}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: "Invoice Generation Date",
      dataIndex: "date",
    },
    {
      title: "Total Value",
      dataIndex: "totalValue",
    },
  ];

  return (
    <div>
      {data.length > 0 ? (
        <StyledTable
          className="table"
          pagination={false}
          columns={columns}
          dataSource={data}
          size="middle"
        />
      ) : (
        <EmptyData description="No Invoice Found" />
      )}
    </div>
  );
};

export default CustomInvoiceTable;
