import React, { useEffect } from "react";
import { Container } from "../../table/Styles";
import { Card, Col, Row } from "antd";
import { EmptyDataComponent } from "../../../../components/common/empty";
import CustomButton from "../../../../components/common/button";
import {
  DownloadOutlined,
  DownOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ViewSchedule from "./ViewSchedule";
import { getAccountById } from "../../../../redux/feature/customer/customerAsyncThunk";
import { AppDispatch } from "../../../../redux/store";
import { getAttachPricePlan, getPricePlans } from "../../../../redux/feature/pricePlan/pricePlanThunk";
import { setViewAttachPricePlan } from "../../../../redux/feature/pricePlan/pricePlanSlice";

const PricePlanSchedule = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { accountId,customerId } = useParams();

  const { viewAccountDetails, filter } = useSelector(
    (state: any) => state.customer
  );
  const { attachPricePlan } = useSelector((state: any) => state.pricePlan);
  useEffect(() => {
    if (!viewAccountDetails) {
      dispatch(getAccountById(accountId));
    }
    // if (!viewAccountDetails) navigate(`/customer?__sort__=${filter}`);
  }, []);

  useEffect(() => {
    if (!attachPricePlan?.length) {
      dispatch(getAttachPricePlan(accountId));
    }
  }, []);

  const onPlanClick = (plan: any) => {
    dispatch(setViewAttachPricePlan(plan));
    navigate(
      `/customers/${customerId}/accounts/${accountId}/price-plan-schedule/${plan?.price_plan_attach_id}`
    );
  };
  const AddNewPlan = () => (
    <CustomButton
      type="primary"
      icon={<RightOutlined />}
      position="start"
      onClick={() => {
        navigate(
          `/customers/${viewAccountDetails.customer_id}/accounts/${viewAccountDetails.customer_account_id}/attach-price-plan`
        );
        dispatch(
          getPricePlans({
            sort: "-updated_at",
            type: "BILLING",
          })
        );
      }}
      text="Attach New Plan"
    />
  );
  return (
    <Row gutter={24}>
      <Col span={24}>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            padding: "10px 5px",
            gap: "10px",
          }}
        >
          {" "}
          <CustomButton
            type="default"
            icon={<DownloadOutlined />}
            position="start"
            onClick={() => {
              // navigate("/price-plan/create");
            }}
            text="Download VSC"
          />{" "}
          <CustomButton
            type="default"
            position="end"
            isShowIcon={false}
            onClick={() => {
              // navigate("/price-plan/create");
            }}
            text="Detach Plan for Specific Range"
            disabled={true}
          />{" "}
          <CustomButton
            type="primary"
            icon={<RightOutlined />}
            position="end"
            onClick={() => {
              navigate(
                `/customers/${viewAccountDetails.customer_id}/accounts/${viewAccountDetails.customer_account_id}/attach-price-plan`
              );
              dispatch(
                getPricePlans({
                  sort: "-updated_at",
                  type: "BILLING",
                })
              );
            }}
            text="Attach New Plan"
          />
        </div>
      </Col>
      <Col span={24}>
          {attachPricePlan.length ? (
            <ViewSchedule attachPricePlan={attachPricePlan} onPlanClick={onPlanClick} />
          ) : (
            <EmptyDataComponent
              title="No Price Plan Attached"
              description="Please attach a price plan to this account to initiate billing."
              addButton={AddNewPlan}
            />
          )}
      </Col>
    </Row>
  );
};

export default PricePlanSchedule;
