import { lazy, ReactNode, Suspense, useEffect, useState } from "react";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  useLocation,
  useParams,
} from "react-router-dom";
import Loader from "./components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./redux/store";
import Main from "./components/layout/Main";
import PricePlan from "./pages/price-plans";
import NewPricePlan from "./pages/price-plans/NewPricePlan";
import {
  getAccountCurrency,
  getAllCountry,
  getCurrencies,
} from "./redux/feature/adminCenter/adminCenterAsyncThunk";
import Events from "./pages/events";
import NewEventSchema from "./pages/events/schemas/newEventSchema";
import { getAuthToken } from "./utils/localStorage";
import { getUser } from "./redux/feature/auth/authThunk";

import BillableItems from "./pages/billableItems";
import NewUsageMeter from "./pages/billableItems/usageMeters/newUsagemeter";
import ViewCustomerDetails from "./pages/customer/viewCustomer";
import CurrencyModel from "./pages/admin-center/currencies/modal";
import CustomerTab from "./pages/customer";
import ViewAccount from "./pages/customer/viewAccount";
import EventDetail from "./pages/events/schemas/eventDetail";
import UsageMeterDetail from "./pages/billableItems/usageMeters/details";

import {
  getAddons,
  getUsageMeters,
} from "./redux/feature/billableItems/billableThunk";
import AttachPricePlan from "./pages/customer/viewAccount/PricePlanSchedule/AttachPricePlan";
import { getPricePlans } from "./redux/feature/pricePlan/pricePlanThunk";
import ViewPricePlan from "./pages/price-plans/ViewPricePlan";
import ViewAttachPricePlan from "./pages/customer/viewAccount/PricePlanSchedule/ViewAttachPricePlan";
import Details from "./pages/billing/detailsPage";
import InvoiceGroupDetail from "./pages/billing/detailsPage/invoiceGroupDetail";
import PricePlanTable from "./pages/price-plans/table";
import PageLoading from "./components/Loader";
import {
  getAllCustomer,
  getAllCustomerAccounts,
} from "./redux/feature/customer/customerAsyncThunk";
import CreditDetails from "./pages/customer/viewAccount/credits/CreditDetail";
import { getInvoices } from "./redux/feature/billing/billingThunk";
import Billing from "./pages/billing";
import AdminCenter from "./pages/admin-center";

export const RenderRoutes = () => {
  return (
    <Suspense >
      <RouterProvider router={router} />
    </Suspense>
  );
};

const ProtectedRoute = () => {
  const token = getAuthToken();
  const userData = useSelector((state: RootState) => state.auth.user);
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const { AccountCurrency, currencies, country, isCurrencyModalOpen } =
    useSelector((state: any) => state.adminCenter);
  const { usageMeters, addOns } = useSelector(
    (state: RootState) => state.billable
  );
  const { customers, customerAccounts, filter } = useSelector(
    (state: any) => state.customer
  );
  const { invoices } = useSelector((state: RootState) => state.billing);

  const { pricePlans } = useSelector((state: RootState) => state.pricePlan);
  const { customerId, accountId, aPlanId } = useParams();

  useEffect(() => {
    if (!token) return;
    if (!userData) {
      dispatch(getUser());
    } else {
      dispatch(getCurrencies());
    }
    if (!AccountCurrency.length) dispatch(getAccountCurrency());
    if (!currencies.length) dispatch(getCurrencies());
    if (!country.length) dispatch(getAllCountry());
    if (!customerAccounts.length)
      dispatch(
        getAllCustomerAccounts({
          sort: "-updated_at",
        })
      );
    if (!customers.length)
      dispatch(
        getAllCustomer({
          sort: "-updated_at",
        })
      );

    if (usageMeters?.length === 0) {
      dispatch(
        getUsageMeters({
          sort: "-updated_at",
        })
      );
    }
    if (addOns?.length === 0) {
      dispatch(
        getAddons({
          sort: "-updated_at",
        })
      );
    }
    if (pricePlans.length === 0) {
      dispatch(
        getPricePlans({
          sort: "-updated_at",
          type: "BILLING",
        })
      );
    }

    // if (!invoices.length) {
    //   dispatch(getInvoices());
    // }
  }, []);

  if (!token && !userData) {
    return <Navigate to="/login" replace />;
  }
  const isFullScreen =
    location.pathname === "/price-plan/create" ||
    location.pathname ===
      `/customers/${customerId}/accounts/${accountId}/price-plan-schedule/${aPlanId}` ||
    location.pathname ===
      `/customers/${customerId}/accounts/${accountId}/attach-price-plan` ||
    location.pathname ===
      `/customers/${customerId}/accounts/${accountId}/edit-schedule/${aPlanId}`;

  if (isFullScreen) {
    return <Outlet />;
  }

  return (
    <>
      <Main>
        <Outlet />
      </Main>

      <CurrencyModel
        isOpen={isCurrencyModalOpen}
        onClose={() => {}}
        isBaseCurrency={true}
      />
    </>
  );
};

const AuthRoute: React.FC<{ children: ReactNode }> = ({ children }) => {
  const userData = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch<AppDispatch>();

  const token = getAuthToken();

  useEffect(() => {
    if (token && !userData) dispatch(getUser());
  }, [token]);

  if (userData) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

const SignIn = lazy(() => import("./pages/Login/SignIn"));
const SignUp = lazy(() => import("./pages/Login/SignUp"));
// const AdminCenter = lazy(() => import("./pages/admin-center"));
// const Billing = lazy(() => import("./pages/billing"));
const VerifyEmail = lazy(() => import("./pages/Login/VerifyEmail"));

const router = createBrowserRouter([
  {
    path: "/login",
    element: (
      <AuthRoute>
        <SignIn />
      </AuthRoute>
    ),
  },
  {
    path: "/onboarding",
    element: (
      <AuthRoute>
        <SignUp />
      </AuthRoute>
    ),
  },
  {
    path: "/verify-email",
    element: (
      <AuthRoute>
        <VerifyEmail />
      </AuthRoute>
    ),
  },
  {
    path: "/",
    element: <ProtectedRoute />,
    children: [
      {
        path: "/",
        element: <AdminCenter />,
      },
      {
        path: "/admin-center/",
        element: <AdminCenter />,
        children: [
          {
            path: "/admin-center/profile",
            element: <AdminCenter />,
          },
          {
            path: "/admin-center/users",
            element: <AdminCenter />,
          },
          {
            path: "/admin-center/currencies",
            element: <AdminCenter />,
          },
          {
            path: "/admin-center/aliases",
            element: <AdminCenter />,
          },
          {
            path: "/admin-center/api-tokens",
            element: <AdminCenter />,
          },
          // {
          //   path: "/admin-center/settings",
          //   element: <AdminCenter />,
          // },
          {
            path: "/admin-center/billing-information",
            element: <AdminCenter />,
          },
        ],
      },
      {
        path: "/price-plan",
        element: <PricePlan />,
        children: [
          {
            path: "/price-plan/recurring",
            element: <PricePlanTable keyProp="BILLING" />,
          },
          {
            path: "/price-plan/one-time",
            element: <PricePlanTable keyProp="PURCHASE" />,
          },
        ],
      },
      {
        path: "/price-plan/create",
        element: <NewPricePlan />,
      },
      {
        path: "/price-plan/:id",
        element: <ViewPricePlan />,
      },
      {
        path: "/events",
        element: <Events />,
      },
      {
        path: "/event-schemas",
        element: <Events />,
      },
      {
        path: "/events/create",
        element: <NewEventSchema />,
      },
      {
        path: "/usage-meter",
        element: <BillableItems keyProp="1" />,
      },
      {
        path: "/add-ons",
        element: <BillableItems keyProp="2" />,
      },
      {
        path: "/usage-meter/create",
        element: <NewUsageMeter />,
      },
      {
        path: "/usage-meter/:id",
        element: <UsageMeterDetail />,
      },
      {
        path: "/usage-meter/:id/edit",
        element: <NewUsageMeter />,
      },
      {
        path: "/events/:name",
        element: <EventDetail />,
      },
      {
        path: "/events/:name/edit",
        element: <NewEventSchema />,
      },
      {
        path: "/billing/invoice",
        element: <Billing />,
      },
      {
        path: "/billing/invoice-groups",
        element: <Billing />,
      },
      {
        path: "/billing/orders",
        element: <Billing />,
      },
      {
        path: "/billing/bill-runs",
        element: <Billing />,
      },
      {
        path: "/billing/:id",
        element: <Details />,
      },
      {
        path: "/billing/invoice-group/:id",
        element: <InvoiceGroupDetail />,
      },
      {
        path: "/accounts",
        element: <CustomerTab />,
      },
      {
        path: "/customer",
        element: <CustomerTab />,
      },
      {
        path: "/customer/:customerId/details",
        element: <ViewCustomerDetails />,
      },
      {
        path: "/customers/:customerId/accounts/:accountId",
        element: <ViewAccount />,
        children: [
          {
            path: "/customers/:customerId/accounts/:accountId/details",
            element: <ViewAccount />,
          },
          {
            path: "/customers/:customerId/accounts/:accountId/wallet",
            element: <ViewAccount />,
          },
          {
            path: "/customers/:customerId/accounts/:accountId/credits",
            element: <ViewAccount />,
          },
          {
            path: "/customers/:customerId/accounts/:accountId/billing",
            element: <ViewAccount />,
          },
          {
            path: "/customers/:customerId/accounts/:accountId/price-plan-schedule",
            element: <ViewAccount />,
          },
        ],
      },
      {
        path: "/customers/:customerId/accounts/:accountId/attach-price-plan",
        element: <AttachPricePlan />,
      },
      {
        path: "/customers/:customerId/accounts/:accountId/edit-schedule/:aPlanId",
        element: <AttachPricePlan isEdit={true} />,
      },
      {
        path: "/customers/:customerId/accounts/:accountId/price-plan-schedule/:aPlanId",
        element: <ViewAttachPricePlan />,
      },
      {
        path: "/customers/:customerId/accounts/:accountId/credits/:creditId",
        element: <CreditDetails />,
      },

      {
        path: "*",
        element: <Navigate to="/" replace />,
      },
    ],
  },
]);
