import {
  Avatar,
  Card,
  Col,
  Divider,
  message,
  Row,
  Segmented,
  Switch,
  Tag,
} from "antd";
import PageHeader from "../../../../components/common/pageHeader";
import { DetailsContainer } from "./styles";
import {
  CopyOutlined,
  EditOutlined,
  LockOutlined,
  RightOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import ConfigurationItemCard from "../configurationItemCard";
import { AppDispatch, RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  activeUsageMeter,
  getUsageMeter,
} from "../../../../redux/feature/billableItems/billableThunk";
import { AxiosError } from "axios";
import PageLoading from "../../../../components/Loader";
import { StyledCard } from "../configurationItemCard/styles";
import BackPageHeader from "../../../../components/common/BackPageHeader";
import { Label } from "../../../billing/detailsPage/styles";
import { copyToClipboard } from "../../../../utils/helper";

const UsageMeterDetail = () => {
  const [type, setType] = useState<
    | "Configuration"
    | "Associated Price Plans"
    | "Ingest Usage"
    | "External Usage Sources"
    | "Settings"
  >("Configuration");
  const { id } = useParams();
  const navigate = useNavigate();

  const { currentUsageMeter, loading } = useSelector(
    (state: RootState) => state.billable
  );

  console.log("►►► ~ UsageMeterDetail ~ currentUsageMeter:", currentUsageMeter);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (id) {
      dispatch(getUsageMeter(id))
        .unwrap()
        .catch((e) => {
          console.log("🚀 ~ file: index.tsx:45 ~ useEffect ~ e:", e);
          message.error("Failed to fetch event details.");
        });
    }
  }, [id, dispatch]);

  if (loading) {
    return <PageLoading />;
  }

  const onChange = (checked: boolean) => {
    try {
      const payload = {
        usageMeterId: currentUsageMeter?.usageMeterId,
        status: checked,
      };

      dispatch(activeUsageMeter(payload));
    } catch (err) {
      const error = err as AxiosError;
      console.log("🚀 ~ file: index.tsx:73 ~ onChange ~ error:", error);
    }
  };

  return (
    <DetailsContainer>
      <BackPageHeader
        actions={[
          {
            label: "Edit Usage Meter",
            icon: <EditOutlined />,
            onClick: () => {
              if (
                currentUsageMeter.status !== "ACTIVE" &&
                currentUsageMeter.status !== "INACTIVE"
              ) {
                navigate(
                  `/usage-meter/${currentUsageMeter?.usageMeterId}/edit`
                );
              } else {
                message.warning(
                  "Active or inactive usage meter cannot be edited"
                );
              }
            },
          },
        ]}
        text="Back to All Usage Meters"
        path="/usage-meter"
      />
      <div className="detailBody">
        <Row gutter={32}>
          <Col span={16} className="leftSide">
            <Card className="userDetailCard">
              <div className="userProfile">
                <div>
                  <Avatar size={50} icon={<UserOutlined />} />
                </div>
                <div className="profileDescription">
                  <p>Usage Meter Details</p>
                  <h5>{currentUsageMeter.name}</h5>
                  <Tag color="green">• {currentUsageMeter.status}</Tag>
                </div>
              </div>
              <div className="toggleContainer">
                <p>TOGGLE STATUS</p>
                <div className="toggle">
                  {currentUsageMeter?.status === "ACTIVE" && <LockOutlined />}
                  <Switch
                    defaultChecked={
                      currentUsageMeter?.status === "ACTIVE" ? true : false
                    }
                    onChange={onChange}
                    disabled={currentUsageMeter?.status === "ACTIVE"}
                  />
                </div>
              </div>
            </Card>
            <Segmented
              className="segment"
              options={[
                "Configuration",
                "Associated Price Plans",
                "Ingest Usage",
                "External Usage Sources",
                "Settings",
              ]}
              value={type}
              onChange={setType}
            />
            <div className="detailContent">
              <ConfigurationItemCard
                filterCondition={currentUsageMeter?.filterCondition}
              />
            </div>
          </Col>
          <Col span={8} className="rightSide">
            <StyledCard>
              <div className="rightContentCardItem">
                <p>Aggregation Type</p>
                <h5>{currentUsageMeter?.aggregation}</h5>
              </div>
              <div className="rightContentCardItem">
                <p>Usage Meter ID</p>
                <h5>
                  {currentUsageMeter?.usageMeterId}{" "}
                  <span>
                    {currentUsageMeter?.usageMeterId && <CopyOutlined className="copyIcon" onClick={()=>{copyToClipboard(currentUsageMeter?.usageMeterId)}}/>}
                  </span>
                </h5>
              </div>
              <div
                className="rightContentCardItem"
                onClick={() => {
                  if (currentUsageMeter?.eventSchemaName) {
                    navigate(`/events/${currentUsageMeter?.eventSchemaName}`);
                  }
                }}
              >
                <p>Attached Event Schema</p>
                {currentUsageMeter?.eventSchemaName ? (
                  <h5 className="dummy">
                    {currentUsageMeter?.eventSchemaName}{" "}
                    <span className="dummy">
                      <RightOutlined className="rightIcon" />
                    </span>
                  </h5>
                ) : (
                  <>-</>
                )}
              </div>
              <div className="rightContentCardItem">
                <p>Usage MTD</p>
                <h5>0</h5>
              </div>
              <Divider dashed className="divider" />
              <div className="rightContentCardItem">
                <p>Created {currentUsageMeter?.createdAt}</p>
                <p>Last Updated {currentUsageMeter?.updatedAt}</p>
              </div>
            </StyledCard>
          </Col>
        </Row>
      </div>
    </DetailsContainer>
  );
};

export default UsageMeterDetail;
