import styled from "styled-components";

export const StyledHeaderDiv = styled.div`
.headerTitle{
  font-size: 16px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom:5px
}
.headerSubTitle{
  font-size: 13px;
  color: #919BAE;
  margin: 0;
}

`;