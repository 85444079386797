import { Card, Empty } from "antd";
import styled from "styled-components";

export const StyledEmpty = styled(Empty)`
   padding: 24px !important;
   border-radius: 1rem;
   margin: 0;
   background: white;
   width: 100%;
     box-shadow: 0px 2px 8px 0px #cbd5e1 !important;

  .ant-empty-image , .ant-empty-description{
    display: none;
  }
  .ant-empty-footer{
    margin-top: 0;
  }
  .titlePart{
        display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin-bottom: 16px;
  }
  .ant-typography {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
  }
  .iconContainer{
    background-color: #f0f5ff;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

`;

export const EmptyDataContainer = styled(Card)`
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  border-radius: 8px;
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0px 2px 8px 0px #cbd5e1 !important;
  /* width: calc(100% - 20px); */
  /* margin: 10px; */

  .iconWrapper {
    background-color: #f0f5ff;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
  }

  .title {
    color: black;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
  }

  .description {
    color: #595959;
    text-align: center;
    max-width: 488px;
  }
`;