import { Card } from "antd";
import styled from "styled-components";

export const ScheduleCardContainer = styled(Card)`
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  width: 100%;
  padding: 1em;
`;


export const StyledCard = styled(Card)`
    border-radius: 12px;
    border:1px solid #cbd5e1;
    width: 100%;

    .ant-card-body{
      padding: 16px 0 ;
    }
    .copy {
       height: 12px;
       width: 12px;
     }
    p {
      margin: 0;
    }
    .orderDetailContainer {
      padding: 0 16px 16px 16px;
     .orderDetailHeader {
       

        h3 {
          margin: 0;
          font-weight: 600;
        }
        .title{
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .icon{
          border: 1px solid #e2e8f0;
          border-radius: 8px;
          padding: 5px 9px;
          font-weight: 600;
          svg{
            height: 12px;
            width: 12px;
          }
        }
      }

      .orderDetailItem {
        display: flex;
        gap: 1rem;
        align-items: center;

        p {
          margin: 0;
          font-size: 12px;
        }
        .version{
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 0.5rem;
        }
     
      }
    }

    .cardContainer {
     padding: 0 10px;
      margin: 0 !important;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      /* gap: 12px; */
      /* .ant-col{
        padding:0 !important;
      } */
      .cardItem{
        background-color: #F8FAFC;
        border: none;
        width: 100%;
        .ant-card-body{
          padding: 8px;
        }
        p{
          font-size: 12px;
          font-weight: 500;
        }
        .cardTitle{
            font-size: 10px;
            text-transform: uppercase;
            color: #475569;
            letter-spacing: .05em;
            font-weight: 400;
        }
      }
    }
    .dueDetails{
      .item{
        display: flex;
        gap: 0.5rem;
        align-items: center;
        p{
          font-size: 2rem !important;
          font-weight: 600 !important;
        }
        .currency{
          font-size: 16px;
          margin-right: 1rem;
        }
      }
    }
    .timing{
      margin-top: 0.5rem;
    }
    .detailDivider{
      margin: 0.7rem 0;
    }
    .details{
      display: flex;
      align-items: center;
      padding: 0.5rem 0;

      p{
        width: 50%;
        text-align: left;
        font-size : 13px;
      }
      .value{
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
`;

export const Label = styled.p`
  font-size: 13px;
  color: #8c8c8c;
`;