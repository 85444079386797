// src/features/auth/authSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { changeEventStatus, createEvent, createIngest, getEvent, getEvents, getIngest, getIngestById } from "./eventThunk";

export interface AuthState {
  loading: boolean;
  eventLoading: boolean;
  error: string | null;
  events?: any;
  currentEvent? : any
  ingest?: any;
  ingestEventValue:any
  isModalLoading:boolean 
  selectedIngest:any
}

const initialState: AuthState = {
  events: [],
  currentEvent: null,
  eventLoading: false,
  loading: false,
  error: null,
  ingest:[],
  ingestEventValue:'',
  isModalLoading:false,
  selectedIngest:null,
};

const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    setIngestValue:(state,action)=>{
      state.ingestEventValue=action.payload
    },
    emptySelectedIngest:(state,action)=>{
      state.selectedIngest=null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEvents.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEvents.fulfilled, (state, action) => {
        state.loading = false;
        state.events = action.payload;
      })
      .addCase(getEvents.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getEvent.pending, (state, action) => {
        state.eventLoading = true;
      })
      .addCase(getEvent.fulfilled, (state, action) => {
        state.eventLoading = false;
        state.currentEvent = action.payload;
      })
      .addCase(getEvent.rejected, (state, action: any) => {
        state.eventLoading = false;
      })
      .addCase(createEvent.pending, (state, action) => {
        state.eventLoading = true;
      })
      .addCase(createEvent.fulfilled, (state, action) => {
        state.events = [...state.events, ...action.payload];
        state.eventLoading = false;
      })
      .addCase(createEvent.rejected, (state, action) => {
        state.eventLoading = false;
      })
      .addCase(changeEventStatus.fulfilled, (state, action:any) => {
        state.events = state.events.map((event:any) => {
          if(event.event_schema_id===action.payload.eventSchemaId){
            event.status = action.payload.status
            return event
          }
          return event
        })
        if(state.currentEvent && state.currentEvent?.event_schema_id===action.payload?.eventSchemaId){
          state.currentEvent.status = action.payload.status
        }
      })
      .addCase(createIngest.pending, (state, action) => {
        state.isModalLoading=true

      })
      .addCase(createIngest.fulfilled, (state, action) => {
        state.ingestEventValue=''
        state.isModalLoading=false

      })
      .addCase(createIngest.rejected, (state, action) => {
        state.isModalLoading=false
        state.ingestEventValue=''


      })
      .addCase(getIngest.pending, (state, action) => {
        state.loading = true;

      })
      .addCase(getIngest.fulfilled, (state, action) => {
        state.ingest = action.payload;
        state.loading = false;

      })
      .addCase(getIngest.rejected, (state, action) => {
        state.loading = false;

      })
      .addCase(getIngestById.pending, (state, action) => {
        state.loading = true;

      })
      .addCase(getIngestById.fulfilled, (state, action) => {
        console.log("►►► ~ .addCase ~ action:", action)
        state.selectedIngest = action.payload;
        state.loading = false;

      })
      .addCase(getIngestById.rejected, (state, action) => {
        state.loading = false;

      })
  },
});

export const {
  setIngestValue,
  emptySelectedIngest
}=eventSlice.actions
export const eventReducer = eventSlice.reducer;
