import React from "react";

import { AvatarContainer } from "./Styles";
import { Avatar } from "antd";

const UserAvatar: React.FC<any> = ({ user }) => {
  let displayName = "";
  if (user?.name) {
    displayName += user?.name?.[0]?.toUpperCase();
  } else {
    displayName += user?.customer_name?.[0]?.toUpperCase();
  }

  return (
    <Avatar
      style={{
        height: "32px",
        width: "32px",
        borderRadius: "100%",
        backgroundColor: "#6b99d7",
      }}
    >
      {displayName}
    </Avatar>
  );
};

export default UserAvatar;
