import { Tabs } from "antd";
import { PageLayoutContainer, StyledDiv } from "./styles";
import CustomButton from "../button";
import { Link } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";

type PageLayoutPropsType = {
  items: any;
  title?: string;
  handleTabChange?: (key: string) => void;
  activeKey?: string;
  isCustomExtraContentShown?: boolean;
  tabBarExtraContent?: any;
  isBtnShow?: boolean;
  isNavLinkShow?: boolean;
  btnText?: string;
  btnOnClick?: () => void;
};

const PageLayout: React.FC<PageLayoutPropsType> = ({
  items,
  title,
  handleTabChange,
  activeKey,
  isCustomExtraContentShown = false,
  tabBarExtraContent = null,
  isBtnShow = true,
  isNavLinkShow = false,
  btnText,
  btnOnClick,
}) => {
  return (
    <PageLayoutContainer>
      <Tabs
        onTabClick={handleTabChange}
        activeKey={activeKey}
        tabBarExtraContent={
          !isCustomExtraContentShown ? (
            <StyledDiv>
              <span className="title">{title}</span>
              {isBtnShow && (
                <div className="actions">
                  <CustomButton text={btnText} onClick={btnOnClick} />
                  {isNavLinkShow && (
                    <Link
                      to={"/billing/bill-runs"}
                      style={{ marginLeft: "1rem" }}
                    >
                      View Bill Runs{" "}
                      <ArrowRightOutlined style={{ marginLeft: "5px" }} />
                    </Link>
                  )}
                </div>
              )}
            </StyledDiv>
          ) : (
            tabBarExtraContent
          )
        }
        items={items}
      />
    </PageLayoutContainer>
  );
};

export default PageLayout;
