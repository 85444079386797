import { useMemo, useState } from "react";
import Segment from "../../../../components/common/segmented";
import { BillingContainer } from "./styles";
import CustomButton from "../../../../components/common/button";
import SearchInput from "../../../../components/common/search";
import FilterDropdown from "../../../../components/common/filter";
import FilterOption from "../../../../components/common/filter/FilterOption";
import { DatePicker, Form, Select, Tag } from "antd";
import { StyledTable } from "../../../../components/common/table/styles";
import { StyledRangePicker } from "../../../billing/invoices/styles";
import { CalendarOutlined, ReloadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import Modal from "../../../../components/common/modal";
import CustomInvoiceForm from "../../../billing/detailsPage/invoiceGroupDetail/customInvoiceForm";
import CustomChargeModal from "../../../billing/detailsPage/CustomChargeModal";
import BillingTable from "./table";
import { useSelector } from "react-redux";
import { Root } from "react-dom/client";
import { RootState } from "../../../../redux/store";

const options = [
  {
    value: "-updated_at",
    label: "Created At Descending",
  },
  {
    value: "2Bupdated_at",
    label: "Created At Ascending",
  },
  {
    value: "-id",
    label: "ID Descending",
  },
  {
    value: "2Bid",
    label: "ID Ascending",
  },
];

const customChargeData = [
  {
    key: "1",
    charge_name: "sasa",
    charge_type: "Extra charge",
    considered_for_revenue: "No",
    created_on: "Oct 04, 2024 UTC ",
    price: "USD 111",
  },
];



const Billing = () => {
  const [billType, setBillType] = useState<
    "Invoices" | "Orders" | "Custom Charges"
  >("Invoices");
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const createCustomModalHandler = () => {
    setIsCreateModalOpen(!isCreateModalOpen);
  };
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const AddCustomModalHandler = () => {
    setIsAddModalOpen(!isAddModalOpen);
  };
  const rules = useMemo(() => {
    return [{ required: true, message: "Field is required!" }];
  }, []);

  const {invoices}=useSelector((state:RootState)=>state.billing)

  const invoicesData=useMemo(()=>{
    return invoices.map((item)=>item)
  },[invoices])
  return (
    <BillingContainer>
      <div className="billingHeader">
        <Segment
          isWhite={true}
          options={["Invoices", "Orders", "Custom Charges"]}
          value={billType}
          onChange={setBillType}
        />
        {billType !== "Orders" && (
          <CustomButton
            text={
              billType === "Invoices"
                ? "Create Custom Charge"
                : "Add Custom Charge"
            }
            position={billType === "Invoices" ? "start" : "end"}
            onClick={
              billType === "Invoices"
                ? createCustomModalHandler
                : AddCustomModalHandler
            }
          />
        )}
      </div>
      <div className="tableHeader">
        <p className="bold">
          {" "}
          All {billType} <span className="count">(0)</span>
        </p>
        {billType !== "Custom Charges" && (
          <div className="actions">
            <SearchInput />
            <FilterDropdown>
              <Form layout="vertical" requiredMark={false}>
                <Form.Item label="Status" name="status" rules={rules}>
                  <Select
                    placeholder="Status"
                    style={{ width: "100%", marginBottom: "10px" }}
                  >
                    <Select.Option value="active">Active</Select.Option>
                    <Select.Option value="inactive">Inactive</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Invoice Date ( End Date + Grace Period )"
                  name="range"
                  rules={rules}
                >
                  <DatePicker.RangePicker
                    style={{
                      width: "100%",
                      marginBottom: billType === "Invoices" ? "10px" : "0px",
                    }}
                    placeholder={["Start date", "End date"]}
                  />
                </Form.Item>

                {billType !== "Orders" && (
                  <Form.Item label="Price-Plan" name="pricePlan" rules={rules}>
                    <Select placeholder="Price Plan" style={{ width: "100%" }}>
                      <Select.Option value="plan1">Plan 1</Select.Option>
                      <Select.Option value="plan2">Plan 2</Select.Option>
                    </Select>
                  </Form.Item>
                )}
              </Form>
            </FilterDropdown>
            <FilterOption options={options} />
          </div>
        )}
      </div>
      <BillingTable
        billType={billType}
        data={
          billType === "Invoices"
            ? invoicesData
            : billType === "Custom Charges"
            ? customChargeData
            : []
        }
      />
      <Modal
        title="Create Custom Invoice"
        isOpen={isCreateModalOpen}
        onClose={createCustomModalHandler}
      >
        <CustomInvoiceForm />
      </Modal>
      <CustomChargeModal
        isOpen={isAddModalOpen}
        onClose={AddCustomModalHandler}
      />
    </BillingContainer>
  );
};

export default Billing;
