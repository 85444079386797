import {
  CopyOutlined,
  InfoCircleOutlined,
  MoreOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import CustomButton from "../../../../../components/common/button";
import { AliasContainer } from "../../../aliasManagement/styles";
import {
  Alert,
  Dropdown,
  Form,
  Input,
  Table,
  TableColumnsType,
  Tag,
  Typography,
} from "antd";
import { useState } from "react";
import Modal from "../../../../../components/common/modal";
import { ManageTokenContainer } from "./styles";
import { EmptyData } from "../../../../../components/common/empty";
import { StyledTable } from "../../../../../components/common/table/styles";
import AlertModal from "../../../../../components/common/alertModal";

interface ManageTokenDataType {
  key: React.Key;
  token: string;
  generated: string;
  status: string;
}

const data: ManageTokenDataType[] = [
  {
    key: "1",
    token: "d4b15254-6b90-4221-a700-be7eb89f2d0e",
    generated: "04 Sep, 2024 10:36 GMT+5:30",
    status: "active",
  },
];

const ManageToken = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const modalHandler = () => {
    setIsModalOpen(!isModalOpen);
  };
  const deleteModalHandler = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };
  const items = [
    {
      key: "1",
      label: <span onClick={deleteModalHandler}>Delete Token</span>,
    },
  ];

  const columns: TableColumnsType<ManageTokenDataType> = [
    {
      title: "Token ID",
      dataIndex: "token",
    },
    {
      title: "Generated At",
      dataIndex: "generated",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, { status }) => {
        return <Tag color="green">{status}</Tag>;
      },
    },
    {
      title: "",
      dataIndex: "action",
      render: () => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <CustomButton
            icon={<RightOutlined />}
            position="end"
            text="Deactivate"
            type="default"
            onClick={() => console.log("test")}
          />
          <Dropdown placement="bottom" menu={{ items }}>
            <MoreOutlined />
          </Dropdown>
        </div>
      ),
    },
  ];
  const { Text } = Typography;
  return (
    <ManageTokenContainer>
      <div className="header">
        <CustomButton
          text="Generate New Token"
          icon={<PlusOutlined />}
          onClick={modalHandler}
        />
      </div>
      {data.length <= 0 ? (
        <EmptyData
          description="Create Your First Token"
          btnText="Generate New Token"
          onClick={modalHandler}
        />
      ) : (
        <StyledTable
          className="table"
          pagination={false}
          columns={columns}
          dataSource={data}
          size="middle"
        />
      )}
      <Modal
        title="Generate New API Token"
        isOpen={isModalOpen}
        onClose={modalHandler}
        successBtnTitle="Copy Secret Key"
        icon={<CopyOutlined />}
        iconPosition="end"
      >
        <Form
          layout="vertical"
          requiredMark={false}
          style={{ paddingTop: "20px" }}
        >
          <Form.Item
            label="Token ID"
            name="token_id"
            rules={[
              { required: true, message: "Please input Currency First!" },
            ]}
          >
            <Input
              size="small"
              disabled
              placeholder="8f704453-1c68-4171-9fd3-0d8a7fad59e3"
              type="text"
            />
          </Form.Item>
          <Form.Item
            label="Secret Key"
            name="secret_key"
            rules={[
              { required: true, message: "Please input Exchange Rate First!" },
            ]}
          >
            <Input
              size="small"
              disabled
              placeholder="vC75uHuGBgBjOLhHoqOqlTnfYWsgsREkBmCxIjdt"
              type="text"
            />
          </Form.Item>

          <Form.Item>
            <Alert
              message={
                <Text>
                  This is the <Text strong> only</Text> time that the secret
                  access keys can be viewed or downloaded. You cannot recover
                  them later. However, you can create new access keys at any
                  time.
                </Text>
              }
              type="warning"
              icon={<InfoCircleOutlined />}
              showIcon
              style={{
                backgroundColor: "#FFFBE6",
                border: "none",
                padding: "1.5rem",
              }}
            />
          </Form.Item>
        </Form>
      </Modal>

      <AlertModal
        open={isDeleteModalOpen}
        close={deleteModalHandler}
        title="API Token Delete Confirmation"
        subtitle="Are you sure you want to delete the API token 'a448a396-f675-451f-9572-a34b6c993325' ?"
      />
    </ManageTokenContainer>
  );
};

export default ManageToken;
