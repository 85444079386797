import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addedPayment,
  createCredit,
  createCustomer,
  createCustomerAccount,
  deleteCustomer,
  deleteCustomerAccount,
  editCustomer,
  editCustomerAccount,
  getAccountById,
  getAllCustomer,
  getAllCustomerAccounts,
  getCreditById,
  getCustomerById,
  getWallet,
} from "./customerAsyncThunk";

export interface CustomerState {
  customers: any[];
  isNewCustomer: boolean;
  viewCustomerDetails: any;
  isEditCustomer: boolean;
  filter: string;
  customerAccounts: any[];
  viewAccountDetails: any;
  isEditAccount: boolean;
  creditData: { credits: any; loading: boolean };
  loading: boolean;
  payments: any[];
  isAddPaymentModalOpen: boolean;
  viewCreditDetails:any
}

const initialState: CustomerState = {
  customers: [],
  isNewCustomer: false,
  viewCustomerDetails: "",
  isEditCustomer: false,
  filter: "-updated_at",
  customerAccounts: [],
  viewAccountDetails: "",
  isEditAccount: false,
  creditData: { credits: [], loading: false },
  loading: false,
  payments: [
    
  ],
  isAddPaymentModalOpen: false,
  viewCreditDetails:null
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setIsNewCustomer: (state, action: PayloadAction<boolean>) => {
      state.isNewCustomer = action.payload;
    },
    setFilter: (state, action: PayloadAction<string>) => {
      state.filter = action.payload;
    },
    setIsAddPaymentModalOpen: (state, action) => {
      state.isAddPaymentModalOpen = action.payload;
    },

    setViewAccountDetails: (state, action: PayloadAction<any>) => {
      state.viewAccountDetails = action.payload;
    },
    setIsEditCustomer: (state, action: PayloadAction<boolean>) => {
      state.isEditCustomer = action.payload;
    },
    setIsEditAccount: (state, action: PayloadAction<boolean>) => {
      state.isEditAccount = action.payload;
    },
    setViewCreditDetails: (state, action) => {
      state.viewCreditDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCustomer.fulfilled, (state, action) => {
        state.customers = action.payload;
      })
      .addCase(createCustomer.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        state.isNewCustomer = false;
        state.loading = false;
      })
      .addCase(createCustomer.rejected, (state, action) => {
        state.isNewCustomer = false;
        state.loading = false;
      })

      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.customers = state.customers.filter(
          (customer) => customer.customer_id !== action.payload
        );
        state.viewCustomerDetails = "";
      })
      .addCase(editCustomer.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(editCustomer.fulfilled, (state, action) => {
        const updatedCustomer = action.payload;
        state.customers = state.customers.map((customer) =>
          customer.customer_id === updatedCustomer.customer_id
            ? updatedCustomer
            : customer
        );
        state.viewCustomerDetails = updatedCustomer;
        state.isNewCustomer = false;
        state.isEditCustomer = false;
        state.loading = false;
      })
      .addCase(editCustomer.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllCustomerAccounts.fulfilled, (state, action) => {
        state.customerAccounts = action.payload;
      })
      .addCase(createCustomerAccount.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createCustomerAccount.fulfilled, (state, action) => {
        state.isEditAccount = false;
        state.loading = false;
      })
      .addCase(createCustomerAccount.rejected, (state, action) => {
        state.isEditAccount = false;
        state.loading = false;
      })
      .addCase(deleteCustomerAccount.fulfilled, (state, action) => {
        state.customerAccounts = state.customerAccounts.filter(
          (ca) => ca.customer_account_id !== action.payload
        );
        state.viewAccountDetails = "";
      })
      .addCase(editCustomerAccount.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(editCustomerAccount.fulfilled, (state, action) => {
        const updatedCustomerAccount = action.payload;
        state.customerAccounts = state.customerAccounts.map((ca) =>
          ca.customer_account_id === updatedCustomerAccount.customer_account_id
            ? updatedCustomerAccount
            : ca
        );
        state.viewAccountDetails = updatedCustomerAccount;
        state.isEditAccount = false;
        state.loading = false;

      })
      .addCase(editCustomerAccount.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getCustomerById.fulfilled, (state, action) => {
        state.viewCustomerDetails = action.payload;
      })
      .addCase(getAccountById.fulfilled, (state, action) => {
        state.viewAccountDetails = action.payload;
      })
      .addCase(createCredit.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createCredit.fulfilled, (state, action) => {
        state.loading = false;
        state.creditData.credits = [
          action.payload,
          ...state.creditData.credits,
        ];
      })
      .addCase(createCredit.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getCreditById.pending, (state, action) => {
        state.creditData.loading = true;
      })
      .addCase(getCreditById.fulfilled, (state, action) => {
        state.creditData.loading = false;
        state.creditData.credits = action.payload;
      })
      .addCase(addedPayment.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addedPayment.fulfilled, (state, action) => {
        state.loading = false;
        state.isAddPaymentModalOpen = false;
      })
      .addCase(addedPayment.rejected, (state, action) => {
        state.loading = false;
        state.isAddPaymentModalOpen = false;
      })
      .addCase(getWallet.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getWallet.fulfilled, (state, action) => {
        state.loading = false;
        state.payments = action.payload;
      })
      .addCase(getWallet.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const {
  setIsNewCustomer,
  setIsEditCustomer,
  setFilter,
  setViewAccountDetails,
  setIsEditAccount,
  setIsAddPaymentModalOpen,
  setViewCreditDetails
} = customerSlice.actions;

export const customerReducer = customerSlice.reducer;
