import { Alert } from "antd";
import styled from "styled-components";

export const StyledDiv = styled.div`
  .alert{
    background-color: white!important;
    border: 1.5px solid #CBD5E1;
    width: 100%;
    .anticon{
      color: grey;
    }
    
  }
  .ant-alert{
      padding: 0.7rem 1rem !important;
    }
  h3{
    margin: 0;
    font-size:14px;
    color: #475569;
    font-weight: 600;
    margin-bottom: 5px;
  }
  
`;