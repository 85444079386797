import { AppDispatch } from "../redux/store";
import { logout as logoutAction } from "../redux/feature/auth/authSlice";
import axios from "axios";

export const getAuthToken = () => {
  return localStorage.getItem("authToken");
};

export const setAuthToken = (token: string) => {
  localStorage.setItem("authToken", token);
};

export const removeAuthToken = () => {
  localStorage.removeItem("authToken");
};

export const removeRefreshToken = () => {
  localStorage.removeItem("refreshToken");
};

export const getRefreshToken = () => {
  return localStorage.getItem("refreshToken");
};

export const refreshToken = async () => {
  try {
    const refreshToken = getRefreshToken();
    const { data } = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/refresh-token`, { refreshToken });
    setAuthToken(data.accessToken);
    return data.accessToken;
  }
  catch (error) {
    console.log("🚀 ~ file: localStorage.ts:33 ~ refreshToken ~ error:", error)
    throw error;
  }
};

export const logout = (dispatch: AppDispatch) => {
  dispatch(logoutAction());
  localStorage.clear();
  sessionStorage.clear();
  window.location.href = "/login";
};