import { BranchesOutlined, PlusOutlined } from "@ant-design/icons";
import { Alert } from "antd";
import { StyledDiv } from "./styles";

type PipelineItemType = {
  title: string;
  startIcon: React.ReactNode;
  content: string;
  endIcon?: React.ReactNode;
  onClick?:()=>void;
};
const PipelineItem: React.FC<PipelineItemType> = ({
  title,
  startIcon,
  content,
  endIcon,
  onClick=()=>{}
}) => {
  return (
    <StyledDiv>
      <h3>{title}</h3>
      <Alert
        className="alert"
        message={content}
        type="info"
        onClick={onClick}
        showIcon
        icon={startIcon}
        closeIcon={
          <div onClick={(e) => e.stopPropagation()}>
            {endIcon ? endIcon : null}
          </div>
        }
      />
    </StyledDiv>
  );
};

export default PipelineItem;
