import { DatePicker } from "antd";
import styled from "styled-components";

export const InvoicesContainer = styled.div`
    border-radius: 10px;
    padding: 0.5rem 0rem;

    .filter{
      display: flex;
      gap: 1rem;
    }
    .header{ 
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;

      .ant-form-item{
        margin-bottom: 5px !important;
      }
    }
    .dueInvoice{
      display: flex;
      justify-content: end;
      align-items: center;
      margin-bottom: 2rem;
    }
`;

export const StyledRangePicker = styled(DatePicker.RangePicker)`
    cursor: default !important;
    background-color: white !important;
    width: 240px;;
`;

export const DetailedDiv = styled.div`
  display: flex ;
   flex-direction: column;
   .title{
    font-weight: 600;
   }
`;