import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { getAllCustomer } from "../../redux/feature/customer/customerAsyncThunk";
import { setIsNewCustomer } from "../../redux/feature/customer/customerSlice";
import EditCustomer from "./viewCustomer/EditCustomer";
import Accounts from "./table/Accounts";
import Customers from "./table/Customers";
import { useLocation, useNavigate } from "react-router-dom";
import PageLayout from "../../components/common/pageLayout";

const CustomerTab = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const { customers, customerAccounts, filter } = useSelector(
    (state: any) => state.customer
  );

  const currentTabKey = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const sortParam = searchParams.get("__sort__");
    if (location.pathname === "/accounts" && sortParam === filter) {
      return "2";
    }
    return "1";
  }, [location.pathname, location.search, filter]);

  useEffect(() => {
    const currentPath = location.pathname;
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("__sort__", filter);
    navigate(`${currentPath}?${searchParams.toString()}`);
  }, [filter, location.pathname, navigate]);

  const handleTabChange = (key: string) => {
    const path = key === "1" ? "/customer" : "/accounts";

    navigate(`${path}?__sort__=${filter}`);
  };
  useEffect(() => {
    if (!customers.length) dispatch(getAllCustomer({sort: '-updated_at'}));
  }, []);

  const items = useMemo(() => {
    return [
      {
        label: `All customers`,
        key: "1",
        children: <Customers currentTabKey={currentTabKey}/>,
      },
      {
        label: `All Accounts`,
        key: "2",
        children: <Accounts  currentTabKey={currentTabKey}/>,
      },
    ];
  }, [currentTabKey]);

  return (
    <div>
      <PageLayout
        items={items}
        title="Customers"
        activeKey={currentTabKey}
        handleTabChange={handleTabChange}
        btnText="New Customer"
        btnOnClick={() => dispatch(setIsNewCustomer(true))}
      />
      <EditCustomer />
    </div>
  );
};
export default CustomerTab;
