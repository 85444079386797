import styled from "styled-components";

export const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 24px; */
  background-color: white;
  padding: 1rem 5rem;
  box-shadow: 0px 2px 8px 0px #cbd5e1;


  .actions {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .headerItem {
    color: black;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
`;