import { Card, Col, Row } from "antd";
import { StyledCard } from "./styles";
import { useMemo } from "react";

// Define a type for the condition
interface Condition {
  var: string;
  value: number;
}

interface FilterCondition {
  [operator: string]: Condition[];
}

interface ConditionItem {
  attribute: string;
  operator: string;
}

interface ConfigurationItemCardProps {
  filterCondition: FilterCondition;
}

const ConfigurationItemCard: React.FC<ConfigurationItemCardProps> = ({ filterCondition }) => {

  const extractConditions = useMemo(() => {
    const conditions: any = [];

    const extractFromCondition = (condition: any) => {
      for (const operator in condition) {
        if (condition.hasOwnProperty(operator)) {
          if (operator === "and") {
            condition[operator].forEach((nestedCondition: any) => {
              extractFromCondition(nestedCondition);
            });
          } else {
            const [attributeVar, value] = condition[operator];

            const attribute = attributeVar?.var ? attributeVar.var.split(".").pop() : null;

            conditions.push({
              operator,
              attribute,
              value,
            });
          }
        }
      }
    };
    extractFromCondition(filterCondition);

    return conditions;
  }, [filterCondition]);

  return (
    <StyledCard>
      <h5>FILTER CONDITIONS</h5>
      <Row className="mainRow">
        <Col span={24} className="mainColumn">
          {extractConditions?.map((data: any, index: number) => (
            <Row className="innerRow" key={index}>
              <Col span={8}>
                <h6>Attribute</h6>
                <p>{data?.attribute}</p>
              </Col>
              <Col span={8}>
                <h6>Operator</h6>
                <p>{data?.operator}</p>
              </Col>
              <Col span={8}>
                <h6>Value</h6>
                <p>{data?.value}</p>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </StyledCard>
  );
};

export default ConfigurationItemCard;
