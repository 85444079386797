import { Form, Steps } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  .billingDetailForm {
    padding: 16px 24px;
    max-height: 640px; /* Set a max-height or height based on your design */
    overflow-y: auto; /* Add vertical scrollbar */
  }
`;

export const CustomSteps = styled(Steps)`
  width: max-content;
`;

export const StyledForm = styled(Form)`
  padding: 0 0.5rem;
  .anniversaryCycle{
    display: flex;
    justify-content: space-between;
    border: 1px solid rgba(0,0,0,0.5);
    align-items: center;
    padding: 0 1rem;
    border-radius: 10px;
    width: 250px;

    p{
      margin: 0.5rem 0;
    }
  }
  .extraContent{
    display: flex;
    align-items: center;
    margin-top: 5px;
    gap: 10px;
  }
  .addDescription{
    cursor: pointer;
    margin-top: 16px;
    margin-bottom: 24px;
  }
  .descriptionField{
    margin-top: 24px;
  }
  .text{
    font-size: 14px;
    font-weight: 400;
  }
  .name{
    margin-bottom: 0;
  }
  .addDescription:hover{
    .text{
      border-bottom: 1px solid black;
    }
  }
   .ant-radio-wrapper{
    border:1px solid rgba(0,0,0,0.1);
    padding: 0.3rem 0.5rem;
    border-radius: 0.5rem;
  }

  .ant-radio-wrapper-checked{
    border:1px solid #8CA4E0;
    background-color: #F0F6FE;
  }
  .ant-select,.ant-picker{
    width: 320px;
  }
`;


