import React, { useEffect, useMemo, useState } from "react";
import { LicenseFeeFormContainer } from "./Styles";
import AddonItem from "../AddonItem";
import { Form, Input, Radio, Select } from "antd";
import Extra from "../../../../../../components/common/extra";
import { InfoCircleOutlined } from "@ant-design/icons";
import TextedSwitch from "../../../../../../components/common/textedSwitch";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";
import UsageBasedAddPrice from "../usageBased/addPrice";
import { StyledRadio } from "../styles";

const LicenseFee: React.FC<any> = ({
  addonValue,
  setAddonValue,
  editCardData,
  form,
  current,
}) => {
  const [licenseType, setLicenseType] = useState("");
  const [addonList, setAddonList] = useState<any>([]);
  const [defaultLicenseOpened, setDefaultLicenseOpened] = useState<any>(false);
  const [selectedAddon, setSelectedAddon] = useState<any>("");
  const [enableProration, setEnableProration] = useState(false);
  const [invoiceTiming, setInvoiceTiming] = useState("IN_ARREARS");

  const selectedAddonValue = useMemo(() => {
    return selectedAddon;
  }, [selectedAddon]);

  useEffect(() => {
    if (editCardData) {
      setDefaultLicenseOpened(editCardData?.invoiceTiming ? true : false);
    }
    if (licenseType !== "LICENSE" || selectedAddonValue?.type !== "LICENSE") {
      form.resetFields([
        "license_quantity",
        "invoiceTiming",
        "enableProration",
        "proratedRefundMode",
      ]);
    }
  }, [editCardData, licenseType, selectedAddonValue]);

  return current === 0 ? (
    <LicenseFeeFormContainer>
      <AddonItem
        editCardData={editCardData}
        addonValue={addonValue}
        setAddonValue={setAddonValue}
        isAddLicenseType
        setLicenseType={setLicenseType}
        setSelectedAddon={setSelectedAddon}
      />
      <Form.Item
        label="Rate Card Name"
        name="displayName"
        rules={[
          {
            required: true,
            message: "Please input the rate card name!",
          },
        ]}
      >
        <Input placeholder="Rate Card Name" />
      </Form.Item>
      {((licenseType === "LICENSE" && selectedAddonValue === "new") ||
        selectedAddonValue?.type === "LICENSE" ||
        defaultLicenseOpened) && (
        <>
          <Form.Item
            label="Maximum License Quantity"
            name="license_quantity"
            extra={
              <Extra text="Maximum number of licenses that can be purchased in the priceplan. If left blank, there will be no limit." />
            }
          >
            <Input type="number" placeholder="Maximum License Quantity" />
          </Form.Item>
          <Form.Item
            label="Invoice Association"
            name="invoiceTiming"
            extra={
              <Extra text="License fee will be billed in the current invoice." />
            }
          >
            <StyledRadio
              defaultValue={"IN_ARREARS"}
              onChange={(e: any) => {
                setInvoiceTiming(e.target.value);
              }}
            >
              <Radio value="IN_ARREARS">In Arrears</Radio>
              <Radio value="IN_ADVANCE">In Advance</Radio>
            </StyledRadio>
          </Form.Item>
          <Form.Item name="enableProration" valuePropName="checked">
            <TextedSwitch
              text="Proration of license fee"
              onChange={() => {
                setEnableProration(!enableProration);
              }}
            />
          </Form.Item>
          {enableProration && invoiceTiming === "IN_ADVANCE" && (
            <Form.Item
              label="Prorated refund options"
              name="proratedRefundMode"
              extra={
                <Extra text="This controls how your prorated refund is provided during the cycle." />
              }
            >
              <StyledRadio defaultValue="NONE">
                <Radio value="NONE">No Refund</Radio>
                <Radio value="CREDITS">Refund via Credits</Radio>
              </StyledRadio>
            </Form.Item>
          )}
        </>
      )}
      {selectedAddonValue?.type === "NAMED_LICENSE" && (
        <Form.Item
          label="Usage Cycle"
          name="usageCycle"
          extra={
            <Extra text="This would be the aggregation window for the licenses within the pricing cycle." />
          }
        >
          <Select defaultValue={"monthly"}>
            <Select.Option value="monthly">Monthly</Select.Option>
            <Select.Option value="weekly">Weekly</Select.Option>
          </Select>
        </Form.Item>
      )}
    </LicenseFeeFormContainer>
  ) : (
    <UsageBasedAddPrice form={form} isGroupFieldShow={true} />
  );
};

export default LicenseFee;
