import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UserAvatar from "../../../components/common/avatar";
import { EmptyData } from "../../../components/common/empty";
import { AppDispatch } from "../../../redux/store";
import {
  setIsNewCustomer,
  setFilter,
  setViewAccountDetails,
} from "../../../redux/feature/customer/customerSlice";
import { Container, UserNameContainer } from "./Styles"; // Adjust the path if necessary
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Table } from "antd";
import { render } from "@testing-library/react";
import { getAllCustomerAccounts } from "../../../redux/feature/customer/customerAsyncThunk";
import { getAttachPricePlan } from "../../../redux/feature/pricePlan/pricePlanThunk";
import FilterOption from "../../../components/common/filter/FilterOption";
import { StyledTable } from "../../../components/common/table/styles";
import SearchInput from "../../../components/common/search";

type CustomerColumnsType = {
  key: React.Key;
  name: string;
  customer_id?: string;
  created_at: string;
};
type DataIndex = keyof CustomerColumnsType;

const Accounts = ({ currentTabKey }: { currentTabKey?: string }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const { customers, customerAccounts, filter } = useSelector(
    (state: any) => state.customer
  );

  useEffect(() => {
    return () => {
      dispatch(setFilter("-updated_at"));
    };
  }, []);

  useEffect(() => {
    console.log("►►► ~ useEffect ~ currentTabKey:", currentTabKey)
    if (currentTabKey === "2")
      dispatch(
        getAllCustomerAccounts({
          sort: filter.startsWith("2B") ? `${filter.slice(2)}` : filter,
          search: searchTerm,
        })
      );
  },[ searchTerm, filter,currentTabKey]);

  const columns = [
    {
      title: "Name",
      dataIndex: "accountName",
      render: (text: string, record: any) => (
        <UserNameContainer>
          <div className="textContainer">
            <div className="name">{record.account_name}</div>
            <div className="email">{record.account_email}</div>
          </div>
        </UserNameContainer>
      ),
    },
    {
      title: "Customer Details",
      dataIndex: "name",
      render: (text: string, record: any) => {
        const customerDetails = customers.find(
          (customer: any) => customer.customer_id === record?.customer_id
        );
        console.log("►►► ~ Accounts ~ customerDetails:", customerDetails);
        return (
          <UserNameContainer>
            <UserAvatar user={customerDetails} />
            <div className="textContainer">
              <div className="name">{customerDetails?.name}</div>
              <div className="email">{record?.customer_account_id}</div>
            </div>
          </UserNameContainer>
        );
      },
    },
    {
      title: "Price Plan",
      dataIndex: "price_plan",
    },
    {
      title: "Created on",
      dataIndex: "created_at",
      render: (_: any, record: any) => {
        return <div>{record?.created_at}</div>;
      },
    },
  ];

  const filteredAccount = useMemo(() => {
    return customerAccounts.map((item:any)=>item)
  }, [customerAccounts]);

  return (
    <div style={{ padding: "0.5rem 0px" }}>
      <CustomerHeader searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      {filteredAccount.length > 0 ? (
        <StyledTable
          pagination={false}
          columns={columns}
          dataSource={filteredAccount}
          size="middle"
          onRow={(record: any) => ({
            onClick: () => {
              dispatch(setViewAccountDetails(record));
              dispatch(getAttachPricePlan(record?.customer_account_id));
              navigate(
                `/customers/${record?.customer_id}/accounts/${record?.customer_account_id}/details`
              );
            },
          })}
        />
      ) : (
        <EmptyData
          description="No Customer Added"
          btnText="New Customer"
          onClick={() => {
            dispatch(setIsNewCustomer(true));
          }}
        />
      )}
    </div>
  );
};

export default Accounts;

export const CustomerHeader: React.FC<any> = ({
  searchTerm,
  setSearchTerm,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleOnChange = (e: any) => {
    console.log("►►► ~ handleOnChange ~ e:", e);
    dispatch(setFilter(e));
  };

  const options = [
    {
      value: "-updated_at",
      label: "Updated At Descending",
    },
    {
      value: "2Bupdated_at",
      label: "Updated At Ascending",
    },
    {
      value: "-id",
      label: "ID Descending",
    },
    {
      value: "2Bid",
      label: "ID Ascending",
    },
  ];

  return (
    <Container>
      <SearchInput
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <FilterOption options={options} defaultValue="-updated_at" />
    </Container>
  );
};
