import React, { useState } from "react";
import { Avatar, Dropdown, Menu } from "antd";
import {
  DownOutlined,
  UserOutlined,
  LogoutOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { logout } from "../../utils/localStorage";
import {
  DropdownContainer,
  ProfileInfo,
  ProfileWrapper,
  Username,
} from "./Styles";

const ProfileDropdown = () => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector((state: any) => state.auth);

  const handleMenuClick = ({ key }: { key: string }) => {
    if (key === "logout") {
      logout(dispatch);
    } else if (key === "edit") {
      navigate("/admin-center/profile");
      setVisible(false);
    }
  };
  const UserAvatar = () => (
    <ProfileInfo style={{ display: "flex", alignItems: "center" }}>
      <Avatar style={{ backgroundColor: "#7e22ce" }} size="large">
        {user?.name?.[0]}
      </Avatar>
      <div style={{ marginLeft: 8 }}>
        <Username>{user?.name}</Username>
        <div className="email">{user?.email}</div>
      </div>
    </ProfileInfo>
  );

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="profile">{UserAvatar()}</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="edit" icon={<UserOutlined />}>
        Edit Profile
      </Menu.Item>
      <Menu.Item key="logout" icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <DropdownContainer>
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        visible={visible}
        onVisibleChange={setVisible}
      >
        <ProfileWrapper>
          {UserAvatar()}
          {visible ? (
            <UpOutlined style={{ marginLeft: "auto" }} />
          ) : (
            <DownOutlined style={{ marginLeft: "auto" }} />
          )}
        </ProfileWrapper>
      </Dropdown>
    </DropdownContainer>
  );
};

export default ProfileDropdown;
