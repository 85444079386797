import { Tree } from "antd";
import styled from "styled-components";

export const StyledTree = styled(Tree)`
  .ant-tree-treenode{
    width: 100% !important;
  }
  .ant-tree-node-content-wrapper {
    width: 100%;
    margin-bottom: 1.5rem;
  }
  .ant-tree-node-content-wrapper:first-of-type{
    padding-bottom: 1.5rem !important;
    margin-bottom: 0;
  }
  .ant-tree-node-content-wrapper:hover{
    background-color: transparent;
  }
  .ant-tree-treenode > .ant-tree-treenode-content {
    padding: 0; 
  }
 
  .ant-tree-switcher_open{
    display: none !important;
  }
`;