import { Layout, Popconfirm, Tabs } from "antd";
import styled from "styled-components";

// style.ts
export const LayoutStyles = styled.div`
  background: #f1f5f9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 10;
`;

export const HeaderStyles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  position: relative;
  z-index: 1000;
  .headerTitle{
    display: flex;
    align-items: center;
  }
  .headerAction{
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  .leftIcon{
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 10px;
    padding: 5px 10px 3px 10px; 
    margin-right: 1rem;
  }
  .icon{
    height: 12px;
    width: 12px;
    font-weight: 600;
  }
  p{
    font-size: 16px;
    font-weight:500;
    color: black;
    margin-bottom: 0;
  }
  
`;

export const TitleStyles = styled.div`
  max-width: 400px;
  font-size: 15px;
  font-weight: 600;
  margin: 0;
`;

export const BreadcrumbStyles = styled.div`
  margin: 16px 0;
`;

export const CustomPopconfirm = styled(Popconfirm)`
  .ant-popover {
    transform: translate(-50%, -50%) !important;
    top: 50% !important;
    left: 50% !important;
    margin: 0 !important;
  }
  .ant-popover-content {
    width: 50% !important;
  }
`;
export const CustomTabs = styled(Tabs)`
  padding-left: 20px;
  .ant-tabs-nav {
    margin: unset !important;
  }
`;


