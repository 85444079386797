import styled from "styled-components";

export const EnrichValueContainer = styled.div`

.ant-alert{
  background-color: #F8FAFC;
  border:none
}
h4{
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.alert{
  margin-bottom: 0.7rem;
}
`;