import { Button } from "antd";
import { StyledDrawer } from "./styles";
import { ReactNode } from "react";
import CustomButton from "../button";

type DrawerPropType = {
  title: string | ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onFinish?: () => void;
  children: React.ReactNode;
  submitText?: string;
  isActionButtonsShown?: boolean;
  width?: string | number;
  isStyledHeader?: boolean;
  loading?: boolean;
  isSubmitBtnShown?: boolean;
  isCancelBtnShown?: boolean;
};

const CustomDrawer: React.FC<DrawerPropType> = ({
  isOpen,
  onClose,
  children,
  onFinish,
  submitText = "Submit",
  title,
  isActionButtonsShown = true,
  width = 378,
  isStyledHeader = false,
  loading = false,
  isSubmitBtnShown = true,
  isCancelBtnShown = true,
}) => {
  return (
    <StyledDrawer
      title={title}
      width={width}
      onClose={onClose}
      open={isOpen}
      isStyledHeader={isStyledHeader}
    >
      {children}
      {isActionButtonsShown && (
        <div
          className="actions"
          style={{ justifyContent: isCancelBtnShown ? "space-between" : "end" }}
        >
          {isCancelBtnShown && (
            <CustomButton
              text="Cancel"
              type="default"
              onClick={onClose}
              disabled={loading}
              isShowIcon={false}
            />
          )}
          {isSubmitBtnShown && (
            <CustomButton
              text={submitText}
              onClick={onFinish}
              isShowIcon={false}
            />
          )}
        </div>
      )}
    </StyledDrawer>
  );
};

export default CustomDrawer;
