import { DeleteOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { NewFieldContainer } from "./styles";

type NewFieldPropType = {
  id: number;
  removeField: (id: number) => void;
  title: string;
  isUnitShow?: boolean;
  name1:string
  name2: string,
  disable?: boolean
};
const NewField: React.FC<NewFieldPropType> = ({
  id,
  removeField,
  title,
  isUnitShow = true,
  name1,
  name2,
  disable = false,
}) => {
  return (
    <NewFieldContainer>
      <Form.Item
        className="antFormItem"
        label={title}
        name={name1}
        required={false}
        rules={[
          {
            required: true,
            message: `Please input  ${title} First!`,
          },
        ]}
      >
        <Input
          disabled={disable}
          placeholder={`${title} Name`}
          type="text"
          style={{ height: "initial" }}
        />
      </Form.Item>
      {isUnitShow && (
        <Form.Item
          className="antFormItem"
          label="Unit"
          name={name2}
          required={false}
          rules={[
            {
              required: true,
              message: "Please input  unit First!",
            },
          ]}
        >
          <Input placeholder="Unit" disabled={disable} type="text" style={{ height: "initial" }} />
        </Form.Item>
      )}
      <Button disabled={disable} className="icon" onClick={() => removeField(id)}>
        <DeleteOutlined />
      </Button>
    </NewFieldContainer>
  );
};

export default NewField;
