import { Form, Input, Radio, RadioChangeEvent, Select } from "antd";
import Modal from "../../../components/common/modal";
import { useState } from "react";
import { StyledRadio } from "../../price-plans/NewPricePlan/RateCard/forms/styles";
import TextedSwitch from "../../../components/common/textedSwitch";

const applyOnData = [
  { value: "1", label: "All Items Excl. Custom Charges" },
  { value: "2", label: "All Items Excl. Credits & Custom Charges" },
  { value: "3", label: "Credits Deducted" },
  { value: "4", label: "All RateCards Excl. Advanced Fees" },
  { value: "5", label: "All Advanced Fees" },
  { value: "6", label: "Specific Items" },
];

const CustomChargeModal: React.FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose,
}) => {
  const [applyChargeAs, setApplyChargeAs] = useState<
    "EXTRA_CHARGE" | "DISCOUNT"
  >("EXTRA_CHARGE");
  const [chargeType, setChargeType] = useState<
    "FIXED" | "PERCENTAGE" | "PER_UNIT"
  >("FIXED");
  const [isConsiderChargeForRevenue, setIsConsiderChargeForRevenue] =
    useState(false);
  const [applyOnValue, setApplyOnValue] = useState("");

  const applyChargeAsHandler = (e: RadioChangeEvent) => {
    setApplyChargeAs(e.target.value);
  };
  const chargeTypeHandler = (e: RadioChangeEvent) => {
    setChargeType(e.target.value);
  };
  const considerChargeHandler = () => {
    setIsConsiderChargeForRevenue(!isConsiderChargeForRevenue);
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="New Custom Charge"
      successBtnTitle="Create"
    >
      <Form
        layout="vertical"
        requiredMark={false}
        style={{ paddingTop: "20px" }}
      >
        <Form.Item label="Name" name="name">
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item label="Apply the charge as" name="applyChargeAs">
          <StyledRadio
            onChange={applyChargeAsHandler}
            defaultValue={applyChargeAs}
          >
            <Radio value="EXTRA_CHARGE">Extra Charge</Radio>
            <Radio value="DISCOUNT">Discount</Radio>
          </StyledRadio>
        </Form.Item>
        <Form.Item label="Type of charge" name="chargeType">
          <StyledRadio onChange={chargeTypeHandler} defaultValue={chargeType}>
            <Radio value="FIXED">Fixed</Radio>
            <Radio value="PERCENTAGE">Percentage</Radio>
            <Radio value="PER_UNIT">Per Unit</Radio>
          </StyledRadio>
        </Form.Item>
        {chargeType === "FIXED" && (
          <Form.Item label="Total Charge" name="Total">
            <Input size="small" placeholder="Total Charge" suffix="USD" />
          </Form.Item>
        )}
        {chargeType === "PERCENTAGE" && (
          <>
            <Form.Item label="Apply On" name="applyOnValue">
              <Select
                onChange={setApplyOnValue}
                options={applyOnData}
                placeholder="Apply on"
              />{" "}
            </Form.Item>
            <Form.Item
              label="Enter the charge in percentage"
              name="chargeInPercentage"
            >
              <Input placeholder="Enter the charge in percentage" />
            </Form.Item>
          </>
        )}
        {chargeType === "PER_UNIT" && (
          <>
            <Form.Item label="Quantity" name="quantity">
              <Input placeholder="Quantity" />
            </Form.Item>
            <Form.Item label="Price per unit" name="pricePerUnit">
              <Input size="small" placeholder="Price per unit" suffix="USD" />
            </Form.Item>
          </>
        )}
        <Form.Item name="Total">
          <TextedSwitch
            text="Consider Charge For Revenue"
            onChange={considerChargeHandler}
          />{" "}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CustomChargeModal;
