import styled from "styled-components";

export const InvoiceGroupContainer = styled.div`
    border-radius: 10px;
    padding: 0.5rem 0rem;
    .ant-table-tbody > tr > td p {
      color: black !important;
    }
    .HeaderDiv{
      margin-bottom: 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
`;

export const Details = styled.div`
  p{
    font-size: 16px !important;
    font-weight: 600;
  }
  span{
    font-size: 11px;
  }
`;

export const DrawerContent = styled.div`
    padding: 20px;
    padding-bottom: 0 !important;

    .nestedDiv{
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    .addAccount{
      margin-bottom: 24px;
    }
`;

export const AddAccountModalContainer = styled.div`
  padding: 20px;

  .selectedItems{
    margin-top: 1rem;
    font-size: 12px;
    font-weight: 600;
  }
  .search{
    margin-bottom: 1.5rem !important; 
  }
`;