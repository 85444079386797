import { Alert } from "antd";
import { EnrichValueContainer } from "./styles";
import CustomButton from "../../../../components/common/button";
import EnrichValueItemCard from "../enrichValueItemcard";

type EnrichPropType = {
  title: string;
  btnText: string;
  onClick: () => void;
};

const EnrichValue: React.FC<EnrichPropType> = ({ title, btnText, onClick }) => {
  return (
    <EnrichValueContainer>
      <h4>{title} (0)</h4>
      <Alert
        message="No Dependency Added"
        className="alert"
        type="info"
        showIcon
      />
      {/* <EnrichValueItemCard /> */}
      <CustomButton text={btnText} onClick={onClick} isGrey={true} />
    </EnrichValueContainer>
  );
};

export default EnrichValue;
