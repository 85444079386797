import React, { useEffect, useMemo, useState } from "react";
import { PlanModal } from "./Styles";
import CustomButton from "../../../../components/common/button";
import {
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Steps,
  Switch,
} from "antd";
import { CustomForm } from "../../../admin-center/aliasManagement/form/styles";
import Modal from "../../../../components/common/modal";
import FixedFeeForm from "./forms/FixedFeeForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import {
  addOverRideRateCards,
  setBillingDetails,
  setBillingRateCards,
  setPlanModalDetail,
  updateBillingRateCard,
  updateBillingRateCards,
  updateOverRideRateCards,
} from "../../../../redux/feature/pricePlan/pricePlanSlice";
import CreditGrant from "./forms/CreditGrant/CreditGrant";
import AddPrice from "./forms/AddPrice";
import LicenseFee from "./forms/LicenseFee";
import UsageBasedFeeForm from "./forms/usageBased/UsageBasedFee";
import UsageBasedAddPrice from "./forms/usageBased/addPrice";
import {
  createAddons,
  getAddons,
} from "../../../../redux/feature/billableItems/billableThunk";
import { transformPricePlanPayload } from "../../../../utils/helper";
import { useSearchParams } from "react-router-dom";

const Option = Select.Option;

const cardTypeMapping: any = {
  FIXED_FEE: "fixedFeeRateCards",
  CREDIT_GRANT: "creditGrantRateCards",
  LICENSE: "licenseRateCards",
  USAGE_BASED_FEE: "usageRateCards",
};

const steps = [
  {
    title: "Configure Rate Card",
    content: "First-content",
  },
  {
    title: "Add Price",
    content: "Second-content",
  },
];

const EditPlan: React.FC<any> = ({
  setEditCardData,
  editCardData,
  rateCards,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { billingDetails, planModalDetail, isOverridePlan, overRideRateCards } =
    useSelector((state: any) => state.pricePlan);
  const [form1] = Form.useForm();
  const type = useSearchParams()[0].get("plan-type") || " ";
  const [usageMeterValue, setUsageMeterValue] = useState("");
  const [createdEventSchema, setCreatedEventSchema] = useState<any>("");
  const [createdUsageMeter, setCreatedUsageMeter] = useState<any>("");
  const [priceModal, setPriceModal] = useState<
    "STANDARD" | "TIERED" | "VOLUME"
  >("STANDARD");
  const [addonValue, setAddonValue] = useState("");
  const [specificCreditGrantValues, setSpecificCreditGrantValues] = useState({});
  console.log("🚀 ~ file: EditPlan.tsx:72 ~ specificCreditGrantValues:", specificCreditGrantValues)
  const [initData, setInitData] = useState("");
  const [current, setCurrent] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const items = steps.map((item) => ({ key: item.title, title: item.title }));
  const [expiryType, setExpiryType] = useState(
    type === "RECURRING" ? "PRICING_CYCLE" : "NO_EXPIRY"
  );

  const updateCurrent = async (value: "next" | "prev") => {
    try {
      if (value === "next") {
        const values = await form1.validateFields();
      }
      setCurrent((prev) => (value === "next" ? prev + 1 : prev - 1));
    } catch (error) {}
  };
  const handleModalClose = () => {
    dispatch(setPlanModalDetail(""));
    setAddonValue("");
    setCurrent(0);
    setEditCardData("");
    setInitData("");
    setUsageMeterValue("");
    setCreatedEventSchema("");
    setCreatedUsageMeter("");
    setExpiryType(type === "RECURRING" ? "PRICING_CYCLE" : "NO_EXPIRY");
    form1.resetFields();
  };
  useEffect(() => {
    if (editCardData) {
      setInitData(editCardData);
      form1.setFieldsValue(editCardData);
    } else {
      setInitData("");
      form1.resetFields();
    }
  }, [editCardData, form1, planModalDetail]);

  const handleSubmit = async () => {
    try {
      const values = await form1.validateFields();
      console.log("⏩ ~ values:", values);
      if (Object.keys(values).length > 0) {
        dispatch(setBillingDetails(values));
        dispatch(setPlanModalDetail(""));
      }
    } catch (error) {
      console.log("Validation failed:", error);
    }
  };

  const handleStepChange = async (e: number) => {
    try {
      if (current === 0) {
        await form1.validateFields();
      }
      setCurrent(e);
    } catch (error) {
      console.log("►►► ~ handleStepChange ~ error:", error);
    }
  };

  const handleOverrideRateCardAction = (
    data: any,
    values: any,
    uniqueID: string
  ) => {
    const selectedCardType = cardTypeMapping[values.cardType];
    console.log("►►► ~ selectedCardType:", selectedCardType);

    const actionType = uniqueID ? "create" : "update";

    const payload = {
      ...data.pricePlanDetails[selectedCardType]?.[0],
      actionType,
      uniqueID,
      rateCardId: values.rateCardId,
    };

    dispatch(updateOverRideRateCards(payload));
    dispatch(updateBillingRateCards(payload));
  };

  const handleFormSubmit = async () => {
    setIsLoading(true);
    try {
      const cardObj: any = {};
      const data = await form1.validateFields();
      const values = form1.getFieldsValue(true);
      console.log("🚀 ~ file: EditPlan.tsx:156 ~ handleFormSubmit ~ values:", values)
      if (
        planModalDetail === "FIXED_FEE" ||
        planModalDetail === "CREDIT_GRANT" ||
        planModalDetail === "LICENSE"
      ) {
        if (values.id === "new") {
          const payload = {
            name: values.addonName,
            billableName: values.billableName,
            type: planModalDetail === "LICENSE" ? values.type : planModalDetail,
          };
          const addonData: any = await dispatch(createAddons(payload));
          await dispatch(
            getAddons({
              sort: "-updated_at",
            })
          );
          values.id = addonData.payload;
        }
      }
      if (values.tag === "newTag") {
        values.tag = values.newTagName;
      }
      if(planModalDetail === "CREDIT_GRANT"){
        if(values.creditID==="SPECIFIC_USAGE_METERS_ADD_ONS_AND_FEATURES"){
          console.log("🚀 ~ file: EditPlan.tsx:180 ~ handleFormSubmit ~ values.creditID:", values.creditID)
          values.creditID = {
            value: "SPECIFIC_USAGE_METERS_ADD_ONS_AND_FEATURES",
            ...specificCreditGrantValues,
          };
        }else{
          values.creditID = {
            value: values.creditID,
          };
        }
      }
      values.cardType = planModalDetail;
      if (editCardData) {
        if (isOverridePlan) {
          const { uniqueID, pricePlanMetadata, ...rateCardData } = values;
          const { rateCards: Cards, ...planMetaData } = rateCards;

          const data: any = transformPricePlanPayload({
            ...(pricePlanMetadata || planMetaData),
            rateCards: [rateCardData],
          });

          handleOverrideRateCardAction(data, rateCardData, uniqueID);

          form1.resetFields();
        } else {
          dispatch(updateBillingRateCard({ data: values, override: false }));
        }
      } else {
        values.uniqueID = Date.now();
        if (isOverridePlan) {
          const { rateCards: rateCardDetails, ...pricePlanMetadata } =
            rateCards;
          const data: any = transformPricePlanPayload({
            ...pricePlanMetadata,
            rateCards: [values],
          });
          handleOverrideRateCardAction(data, values, values.uniqueID);
          dispatch(setBillingRateCards(values));
          form1.resetFields();
        } else {
          console.log("🚀 ~ file: EditPlan.tsx:223 ~ handleFormSubmit ~ values:", values)
          dispatch(setBillingRateCards(values));
        }
      }
      setAddonValue("");
      form1.resetFields();
      setCurrent(0);
      dispatch(setPlanModalDetail(""));
    } catch (error) {
      console.log("🚀 ~ file: EditPlan.tsx:147 ~ handleFormSubmit ~ error:",error);
    } finally {
      handleModalClose();
      // setExpiryType(type === "RECURRING" ? "PRICING_CYCLE" : "NO_EXPIRY");
      setIsLoading(false);
    }
  };

  if (editCardData && !initData) {
    return null;
  }

  return (
    <PlanModal
      centered
      title={
        <div className="planModalTitle">
          <div className="title">
            {planModalDetail === "Plan Minimum"
              ? planModalDetail
              : editCardData
              ? `Edit ${planModalDetail} Rate Card`
              : `Add ${planModalDetail} Rate Card`}
          </div>
          {planModalDetail === "Plan Minimum" && (
            <div className="description">
              This is a bare-minimum fee that a customer is required to pay if
              their projected overall cost is not met.
            </div>
          )}

          {planModalDetail !== "Plan Minimum" &&
            planModalDetail !== "FIXED_FEE" && (
              <Steps
                className="steps"
                size="small"
                current={current}
                items={items}
                onChange={handleStepChange}

                // style={{ width: "50%" }}
              />
            )}
        </div>
      }
      open={planModalDetail}
      onClose={handleModalClose}
      closeIcon={null}
      onCancel={handleModalClose}
      footer={[
        <div className="footer">
          <CustomButton
            text="Cancel"
            onClick={handleModalClose}
            icon={false}
            type={"default"}
            disabled={isLoading}
          />
          <div className="footerStepAction">
            {current === 1 && (
              <CustomButton
                text="Go Back"
                onClick={() => updateCurrent("prev")}
                icon={false}
                type={"default"}
                disabled={isLoading}
              />
            )}
            <CustomButton
              type="primary"
              htmlType={current === 1 ? "submit" : "button"}
              text={
                planModalDetail === "Plan Minimum"
                  ? "Add  Plan Minimum"
                  : planModalDetail === "FIXED_FEE" || current === 1
                  ? "Submit"
                  : "Proceed"
              }
              onClick={
                planModalDetail === "Plan Minimum"
                  ? handleSubmit
                  : planModalDetail === "FIXED_FEE" || current === 1
                  ? () => handleFormSubmit()
                  : () => updateCurrent("next")
              }
              icon={false}
              loading={isLoading}
            />
          </div>
        </div>,
      ]}
      width={
        planModalDetail === "Plan Minimum"
          ? 530
          : planModalDetail === "USAGE_BASED_FEE"
          ? 950
          : 1300
      }
    >
      <div className="modal-body">
        <div className="PlanModalBody">
          <CustomForm
            form={form1}
            layout="vertical"
            onFinish={handleFormSubmit}
            requiredMark={false}
            initialValues={{ proratedRefundMode: "NONE" }}
          >
            <Row>
              <Col span={16}>
                {planModalDetail === "Plan Minimum" ? (
                  <Form.Item label="Plan Minimum (AFN)" name="plan_minimum">
                    <Input placeholder="Plan Minimum (AFN)" />
                  </Form.Item>
                ) : planModalDetail === "FIXED_FEE" ? (
                  <FixedFeeForm
                    editCardData={editCardData}
                    addonValue={addonValue}
                    setAddonValue={setAddonValue}
                  />
                ) : planModalDetail === "CREDIT_GRANT" ? (
                  current === 0 ? (
                    <CreditGrant
                      addonValue={addonValue}
                      editCardData={editCardData}
                      setAddonValue={setAddonValue}
                      setExpiryType={setExpiryType}
                      expiryType={expiryType}
                      setSpecificCreditGrantValues={setSpecificCreditGrantValues}
                    />
                  ) : (
                    <AddPrice expiryType={expiryType} />
                  )
                ) : planModalDetail === "LICENSE" ? (
                  <LicenseFee
                    addonValue={addonValue}
                    setAddonValue={setAddonValue}
                    editCardData={editCardData}
                    form={form1}
                    current={current}
                  />
                ) : planModalDetail === "USAGE_BASED_FEE" ? (
                  current === 0 ? (
                    <UsageBasedFeeForm
                      form={form1}
                      usageMeterValue={usageMeterValue}
                      setUsageMeterValue={setUsageMeterValue}
                      createdEventSchema={createdEventSchema}
                      setCreatedEventSchema={setCreatedEventSchema}
                      createdUsageMeter={createdUsageMeter}
                      setCreatedUsageMeter={setCreatedUsageMeter}
                    />
                  ) : (
                    <UsageBasedAddPrice
                      editCardData={editCardData}
                      form={form1}
                    />
                  )
                ) : (
                  <UsageBasedAddPrice />
                )}
              </Col>
            </Row>
          </CustomForm>
        </div>
      </div>
    </PlanModal>
  );
};

export default EditPlan;
