import {
  Input,
  InputRef,
  Table,
  TableColumnsType,
  TableColumnType,
  Tag,
} from "antd";
import {
  CloseCircleOutlined,
  CopyOutlined,
  InfoCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useEffect, useMemo, useRef, useState } from "react";
import { FilterDropdownProps } from "antd/es/table/interface";
import { useLocation, useNavigate } from "react-router-dom";
import { EmptyData } from "../../../components/common/empty";
import { useDispatch, useSelector } from "react-redux";
import UserAvatar from "../../../components/common/avatar";
import { Container, SearchInput, UserNameContainer } from "./Styles";
import { AppDispatch } from "../../../redux/store";
import {
  setFilter,
  setIsNewCustomer,
} from "../../../redux/feature/customer/customerSlice";
import { CustomerHeader } from "./Accounts";
import {
  getAllCustomer,
  getAllCustomerAccounts,
  getCustomerById,
} from "../../../redux/feature/customer/customerAsyncThunk";
import { StyledTable } from "../../../components/common/table/styles";

type CustomerColumnsType = {
  key: React.Key;
  name: string;
  customer_id?: string;
  created_at: string;
};
type DataIndex = keyof CustomerColumnsType;

const Customers = ({ currentTabKey }: { currentTabKey?: string }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const { customers, filter } = useSelector((state: any) => state.customer);

  const columns: TableColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text: string, record: any) => (
        <UserNameContainer>
          <UserAvatar user={record} />
          <div className="textContainer">
            <div className="name">{record?.name}</div>
            <div className="email">{record?.email}</div>
          </div>
        </UserNameContainer>
      ),
    },
    {
      title: "Customer ID",
      dataIndex: "customer_id",
    },
    {
      title: "Created on",
      dataIndex: "created_at",
    },
  ];

  useEffect(() => {

      if(currentTabKey === '1'){
        dispatch(getAllCustomer({
          sort: filter.startsWith("2B") ? `${filter.slice(2)}` : filter,
          search:searchTerm
        }));
      }
  }, [currentTabKey,filter,searchTerm]);


 
  const filteredCustomers = useMemo(() => {
   return customers?.map((item:any)=>item)
  }, [customers,]);

  const handleRowClick = (record: any) => {
    console.log("Clicked row data:", record);
    // You can perform any action with the row data here
  };
  return (
    <div style={{ padding: "0.5rem 0px" }}>
      <CustomerHeader searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      {filteredCustomers.length > 0 ? (
        <StyledTable
          pagination={false}
          columns={columns}
          dataSource={filteredCustomers}
          size="middle"
          onRow={(record: any) => ({
            onClick: () => {
              navigate(`${location.pathname}/${record?.customer_id}/details`);
            },
          })}
        />
      ) : (
        <EmptyData
          description="No Customer Added"
          btnText="New Customer"
          onClick={() => {
            dispatch(setIsNewCustomer(true));
          }}
        />
      )}
    </div>
  );
};

export default Customers;
